import React, {FunctionComponent, useState} from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {Logout, refreshToken} from "../actions/UserActions";
import IdleTimer from 'react-idle-timer';
import {ThunkFunction} from "../interfaces/utils";
import {FormattedMessage, useIntl} from "react-intl";

interface IdleHandlerProps{
    dispatch: (action) => any;
}
interface IdleHandlerDispatchProps{
    logout: ThunkFunction<typeof Logout>;
    refreshToken: ThunkFunction<typeof refreshToken>;
}


export const IdleHandler: FunctionComponent<IdleHandlerProps & IdleHandlerDispatchProps> = (props) => {
    let logoutTimer: IdleTimer | null = null;

    const intl = useIntl();
    const f = id => intl.formatMessage({id: id});
    
    const onAction = () => {
        // Refresh Token
        props.refreshToken();
    };

    const onIdle = () => {
        toast.info(f("toast.session_timeout"), {position: toast.POSITION.TOP_RIGHT, autoClose: false});
        props.logout();
    };
    
    return (
        <IdleTimer
            ref={ref => {
                logoutTimer = ref
            }}
            element={document}
            onAction={onAction}
            throttle={1000* 60* 5}
            onIdle={onIdle}
            timeout={1000 * 60 * 30}>
            {props.children}
        </IdleTimer>);
    
};

export const IdleHandlerConnected = connect<any, any, any, any>(() => ({}), {
    logout: Logout,
    refreshToken
}, )(IdleHandler);
