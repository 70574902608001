import React, {Component, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import SystemMaintenance from '../../../../components/Maintenance/MaintenanceLogin/SystemMaintenance';

export function MaintenancePage(props) {
    return (
        <div className="row">
          <div className="col-12 card">

            <SystemMaintenance hideHeader={true} setConfig={props.setConfig} paymentConfig={props.paymentConfig}/>
          </div>
        </div>
    );
}

export default connect<any, any, any, any>((store) => ({
    //sapDetails: store.app.config.sap?.data,
}), {

})(MaintenancePage);
