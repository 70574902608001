import React, { Component } from "react";
import { connect } from "react-redux";
import {
  setSelectedSoldTos,
  updatePrimaryAccount,
  clearSelectedInvoices,
  setSelectedCurrency,
  setsearchedinvoiceStatus,
  setsearchedinvoiceDueDate,
  setsearchedinvoiceType,
  setsearchedInvoiceNumber,
  setsearchedArticleNumber,
  setsearchedArticleDescription,
  setinvoiceSearchedPeriod,
  setinvoiceSearchedPeriodFromTo,
  setsearchedInvoices,
} from "../../actions/InvoiceActions";
import { getSelectedPrimaryAccount } from "../../selectors/Selectors";
import { AccountType } from "../../interfaces/Accounts";
import { ThunkFunction } from "../../interfaces/utils";
import { Dropdown } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { isStringNotEmptyOrNull } from "../../utils";
import { useDispatch } from "react-redux";
interface NavAccountSelectStateProps {
  secondaryAccounts: any[];
  selectedPrimaryAccountIndex: number;
  accountType: AccountType;
  primaryAccounts: any[];
  selectedPrimaryAccount: any;
}

interface NavAccountSelectDispatchProps {
  setSelectedSoldTos: ThunkFunction<typeof setSelectedSoldTos>;
  clearSelectedInvoices: ThunkFunction<typeof clearSelectedInvoices>;
  updatePrimaryAccount: ThunkFunction<typeof updatePrimaryAccount>;
  setSelectedCurrency: ThunkFunction<typeof setSelectedCurrency>;
  setsearchedInvoiceNumber: ThunkFunction<typeof setsearchedInvoiceNumber>;
  setsearchedinvoiceDueDate: ThunkFunction<typeof setsearchedinvoiceDueDate>;
  setinvoiceSearchedPeriodFromTo: ThunkFunction<
    typeof setinvoiceSearchedPeriodFromTo
  >;
  setsearchedinvoiceStatus: ThunkFunction<typeof setsearchedinvoiceStatus>;
  setinvoiceSearchedPeriod: ThunkFunction<typeof setinvoiceSearchedPeriod>;
  setsearchedInvoices: ThunkFunction<typeof setsearchedInvoices>;
}

export class NavAccountSelect extends Component<
  NavAccountSelectStateProps & NavAccountSelectDispatchProps,
  any
> {
  constructor(props) {
    super(props);
    this.state = {
      hasMorethanOneHeaderItems: true,
    };
    this.onHeaderDropdownSelected = this.onHeaderDropdownSelected.bind(this);
    this.createSelectItems = this.createSelectItems.bind(this);
  }
  componentDidMount() {
    this.props.setSelectedSoldTos(Object.keys(this.props.secondaryAccounts));
  }

  onHeaderDropdownSelected(i) {
    if (
      this.props.selectedPrimaryAccountIndex !==
      this.props.primaryAccounts[i].PrimaryAcct
    ) {
      this.props.setSelectedCurrency("");
      this.props.clearSelectedInvoices();
      let key = this.props.primaryAccounts[i].PrimaryAcct;
      this.props.updatePrimaryAccount(key, true);
      this.props.setsearchedInvoices([]);
      this.props.setsearchedInvoiceNumber("");
      this.props.setsearchedinvoiceDueDate("", "");
      this.props.setinvoiceSearchedPeriodFromTo("", "");
      this.props.setsearchedinvoiceStatus("03");
      this.props.setinvoiceSearchedPeriod("all");
    }
  }

  createSelectItems() {
    const items: any[] = [];
    for (let i = 0; i < this.props.primaryAccounts.length; i++) {
      const headerItem = this.props.primaryAccounts[i];
      let name = headerItem?.Name;
      if (
        headerItem?.CompanyCode?.startsWith("JP") &&
        isStringNotEmptyOrNull(headerItem.name_2)
      ) {
        name = headerItem.name_2;
      }

      let activeClass = "dropdown-item";
      if (this.props.selectedPrimaryAccountIndex === i) {
        activeClass = "dropdown-item";
      }
      items.push(
        <Dropdown.Item
          className={activeClass}
          key={headerItem.PrimaryAcct}
          onClick={() => {
            this.onHeaderDropdownSelected(i);
          }}
        >
          <div>{name}</div>
          <div className={"account-subheader"}>{headerItem.PrimaryAcct}</div>
        </Dropdown.Item>
      );
    }

    return items;
  }

  render() {
    const overflowClass =
      this.props.primaryAccounts.length > 10 ? "header-dropdown-overflow" : "";

    let name = this.props.selectedPrimaryAccount?.Name;
    if (
      this.props.selectedPrimaryAccount?.CompanyCode?.startsWith("JP") &&
      isStringNotEmptyOrNull(this.props.selectedPrimaryAccount.name_2)
    ) {
      name = this.props.selectedPrimaryAccount.name_2;
    }

    return (
      <li className="nav-item">
        <div
          className="dropdown customSelect mouseHover"
          style={{ marginTop: "-5px" }}
        >
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-basic"
              className={"btn dropdown-toggle dropbtn truncate"}
            >
              <div className={"d-flex flex-row"}>
                <span>
                  <div>
                    <div className="truncate">
                      {this.props.selectedPrimaryAccount && name}
                    </div>
                    <div className={"account-subheader"}>
                      {this.props.selectedPrimaryAccount &&
                        this.props.selectedPrimaryAccount.PrimaryAcct}
                    </div>
                  </div>
                </span>
                {this.props.primaryAccounts.length > 1 && (
                  <span className="select-arrow">
                    <i className="fas fa-chevron-down" />
                  </span>
                )}
              </div>
            </Dropdown.Toggle>

            {this.props.primaryAccounts.length > 1 && (
              <Dropdown.Menu className={overflowClass}>
                {this.createSelectItems()}
              </Dropdown.Menu>
            )}
          </Dropdown>
        </div>
      </li>
    );
  }
}

export default connect<any, any, any, any>(
  (state, props) => {
    return {
      selectedPrimaryAccount: getSelectedPrimaryAccount(state),
      selectedPrimaryAccountIndex:
        state.user_options.selectedPrimaryAccountIndex,
      primaryAccounts: Object.values(state.user_options.primaryAccounts),
      secondaryAccounts: state.user_options.secondaryAccounts,
      accountType: state.user_options.accountType,
    };
  },
  {
    setSelectedSoldTos,
    clearSelectedInvoices,
    updatePrimaryAccount,
    setSelectedCurrency,
    setsearchedInvoiceNumber,
    setsearchedinvoiceDueDate,
    setinvoiceSearchedPeriodFromTo,
    setsearchedinvoiceStatus,
    setinvoiceSearchedPeriod,
    setsearchedInvoices,
  }
)(NavAccountSelect);
