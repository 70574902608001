import React from 'react';
import {connect} from "react-redux";

export default connect<any, any, any, any>((state, props) => {
  return {
    logo: state.app.config.style.login_logo,
  }
})(({logo, width, height}) => {
    if (logo === "") {
      return null;
    } else {
      return <img src={logo} alt={"Company Logo"} width={width} height={height}/>
    }
  }
);