import React from 'react';
import * as yup from 'yup';
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";

const schema = yup.object().shape({
  invoiceNumber: yup.string().required("Invoice number is required."),
  invoiceAmount: yup.number().required("Invoice amount is required."),
  accountNumber: yup.string().required("Account number is required."),
});

function AutoLinkAccountSuccess(props) {
  let address = "1234 Road Road";
    const intl = useIntl();
    const f = id => intl.formatMessage({ id: id });
  if(props.invoice) {
    let invoice = props.invoice;
   
    return (
      <div className="card">
        <div className="row">
          <div className={"col-12"}>
                    <strong>{f("success")}</strong>
            <p>
              {invoice.name}<br/>
                        {invoice.street}<br />
                        {invoice.city}, {invoice.state} {invoice.postalCode} {invoice.country}<br />
            </p>
                    <button type={"submit"} onClick={props.onClear} className={"btn btn-primary buttonColor buttonsize"}>{f("link_account.add_another_account")}</button>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}


export default AutoLinkAccountSuccess;
