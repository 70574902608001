import React, {Component, useEffect, useState} from 'react';
import ManageUserSapAccount from "./ManageUserSapAccount";
import { saveUserSapAccount, saveUserSapAccountByInvoice } from "../../requests/UserRequests";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index";
import {connect} from "react-redux";
import {FormattedMessage, useIntl} from 'react-intl';
import {SapAccount} from "../../interfaces/SapAccount";
import {useGetSapAccountTypes} from "../../hooks/CommonHooks";
import {makeStyles} from "@material-ui/core/styles";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import { toast } from 'react-toastify';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

function LinkedSapAccounts(props: { accounts: any, readonly: boolean, defaults: any, getAccounts: any, deleteAccount: any, refreshAccounts: Function, userId: string }) {
    const intl = useIntl();
    const f = id => intl.formatMessage({ id: id });

    let [account, setAccount] = useState<SapAccount | null>(null);
    let [accounts, setAccounts] = useState<SapAccount[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);


    const classes = useStyles();

    useEffect(() => {
        if (props.accounts) {
            setAccounts(props.accounts);
        } else {
            props.getAccounts(props.userId).then(accounts => {
                setAccounts(accounts);
            })
        }
    }, []);

    const accountTypes = useGetSapAccountTypes();

    let addNewAccount = () => {
        let accountType = "";
        if (accountTypes.length === 1) {
            accountType = accountTypes[0].accountTypeId;
        }
        setAccount(
            {
                account_type: accountType,
                company_code: '',
                distribution_channel: props.defaults.account.distribution_channel,
                division: props.defaults.account.division,
                primary_account: '',
                sales_organization: props.defaults.account.sales_organization,
            });
    };

    let close = () => {
        setAccount(null);
    };

    let refreshAccounts = () => {
        props.refreshAccounts(props.userId).then(accounts => {
            setAccounts(accounts);
        })
    };

    let editAccount = (account) => {
        setAccount(account);
    };

    let deleteAccount = (deleteAccount: SapAccount) => {
         props.deleteAccount(props.userId, deleteAccount).then(() => {
            if (account && (account.account_id === deleteAccount.account_id)) {
                setAccount(null);
            }
            refreshAccounts();
        });
    };

    return (
        <div className="card">
            <div className="row">
                <div className="col-12">
                    <div className="row justify-content-between">
                        <h5><FormattedMessage id={"settings.user.linked_accounts"}/></h5>
                    </div>
                </div>
            </div>
            <div className="row no-gutters">
                <div className="col-12 col-md-6">
                    <TableContainer>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Account</TableCell>
                                    <TableCell align="left">Company Code</TableCell>
                                    <TableCell align="left">Sales Organization</TableCell>
                                    <TableCell align="left">Distribution Channel</TableCell>
                                    <TableCell align="left">Division</TableCell>
                                    <TableCell align="left"/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {accounts.map((account) =>
                                    <TableRow>
                                        <TableCell>{account.primary_account}</TableCell>
                                        <TableCell>{account.company_code}</TableCell>
                                        <TableCell>{account.sales_organization}</TableCell>
                                        <TableCell>{account.distribution_channel}</TableCell>
                                        <TableCell>{account.division}</TableCell>
                                        <TableCell>
                                            {!props.readonly && <>
                                              <div style={{width: "60px"}} role={"button"} className={"font-button-icon"}
                                                    onClick={() => editAccount(account)}><FontAwesomeIcon
                                                icon={"edit"}/>{f("edit")}</div>
                                              <div role={"button"} className={"font-button-icon"} style={{width: "60px"}}
                                                    onClick={() => deleteAccount(account)}><FontAwesomeIcon
                                                icon={"trash"}/> {f("delete")}</div>
                                            </>}
                                        </TableCell>
                                    </TableRow>
                                )}
                                {/*{rows.map((row) => (*/}
                                {/*    <TableRow key={row.name}>*/}
                                {/*        <TableCell component="th" scope="row">*/}
                                {/*            {row.name}*/}
                                {/*        </TableCell>*/}
                                {/*        <TableCell align="right">{row.calories}</TableCell>*/}
                                {/*        <TableCell align="right">{row.fat}</TableCell>*/}
                                {/*        <TableCell align="right">{row.carbs}</TableCell>*/}
                                {/*        <TableCell align="right">{row.protein}</TableCell>*/}
                                {/*    </TableRow>*/}
                                {/*))}*/}
                            </TableBody>
                        </Table>
                    </TableContainer>


                    <div className="d-flex justify-content-center m-4">
                        {!props.readonly && <>
                          <button className="btn btn-primary buttonsize buttonColor"
                                  onClick={addNewAccount}>{f("user.add_linked_account")}</button>
                        </>}
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    {account && <div className={"modify-section"}>
              <span className={"float-right font-button-icon top-right"} onClick={close}>
                <FontAwesomeIcon icon={"times"}/>
                        </span>
                        <ManageUserSapAccount account={account} accountTypes={accountTypes} errorMessage={errorMessage}
                            saveAccount={(account: SapAccount) => {
                                return saveUserSapAccount(props.userId, account).then(() => {
                                    refreshAccounts();
                                    setAccount(null);
                                });
                            }}
                            saveAccountByInvoice={(invoice) => {
                                saveUserSapAccountByInvoice(props.userId, invoice)
                                    .then(() => {
                                        refreshAccounts();
                                        setAccount(null);
                                        setErrorMessage('');
                                    })
                                    .catch((error) => {
                                        if (error.response?.status === 401) {
                                            setErrorMessage(error.response.data.message);
                                        } else {
                                            setErrorMessage(error.response?.data ?? error.message);
                                        }
                                    });
                            }}

                        /></div>}
                </div>
            </div>
        </div>
    );
}

export default connect<any, any, any, any>(state => ({
        defaults: state.app.config.defaults
    })
)(LinkedSapAccounts);
