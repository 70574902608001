import {useIntl} from "react-intl";

export function useCalendarTranslation() {
    const intl = useIntl();
    const f = id => intl.formatMessage({id: id});
    return {
        weekdays: {
            shorthand: [
                f("calendar.days.short.sunday"),
                f("calendar.days.short.monday"),
                f("calendar.days.short.tuesday"),
                f("calendar.days.short.wednesday"),
                f("calendar.days.short.thursday"),
                f("calendar.days.short.friday"),
                f("calendar.days.short.saturday"),
            ],
            longhand: [
                f("calendar.days.sunday"),
                f("calendar.days.monday"),
                f("calendar.days.tuesday"),
                f("calendar.days.wednesday"),
                f("calendar.days.thursday"),
                f("calendar.days.friday"),
                f("calendar.days.saturday"),
            ],
        },

        months: {
            shorthand: [
                f("calendar.months.short.january"),
                f("calendar.months.short.february"),
                f("calendar.months.short.march"),
                f("calendar.months.short.april"),
                f("calendar.months.short.may"),
                f("calendar.months.short.june"),
                f("calendar.months.short.july"),
                f("calendar.months.short.august"),
                f("calendar.months.short.september"),
                f("calendar.months.short.october"),
                f("calendar.months.short.november"),
                f("calendar.months.short.december"),
            ],
            longhand: [
                f("calendar.months.january"),
                f("calendar.months.february"),
                f("calendar.months.march"),
                f("calendar.months.april"),
                f("calendar.months.may"),
                f("calendar.months.june"),
                f("calendar.months.july"),
                f("calendar.months.august"),
                f("calendar.months.september"),
                f("calendar.months.october"),
                f("calendar.months.november"),
                f("calendar.months.december"),
            ],
        },
    }
}