import React, {Component} from 'react';
import {connect} from 'react-redux';
import RegisterForm from "./RegisterForm";
import AutoLinkAccount from "./AutoLinkAccount";
import AutoLinkAccountSuccess from "./AutoLinkAccountSuccess";
import AutoLinkAccountError from "./AutoLinkAccountError";
import {Footer} from "../../footer/Footer";
import SimpleHeader from "../../header/SimpleHeader";
import {Link} from "react-router-dom";
import BackToLogin from "../../BackToLogin";

function mapStateToProps(state) {
  return {};
}

class AutoRegisterPage extends Component<any, any> {
  render() {
    return (
      <React.Fragment>
        <SimpleHeader/>
        <div className={"container mt-5"}>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mx-auto">
              <RegisterForm/>
            </div>
          </div>
          <div className="row justify-content-center flex mt-3">
            <BackToLogin/>
          </div>
        </div>
        
        <Footer/>
      </React.Fragment>
    );
  }
}

export default connect<any, any, any, any>(
  mapStateToProps,
)(AutoRegisterPage);