import React, { Component } from 'react';
import axios from 'axios';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Public } from '@material-ui/icons';
import { FormattedDate, useIntl } from "react-intl";
import { CurrencyDisplay, CurrencySymbol } from "../../common/CurrencyDisplay";
import { REASON_CODE } from "../../constants/ApplicationConstants";
import Moment from 'moment';

let date = new Date();

function getDifferenceInDays(DueDate: any,DataDiff: any, paymentDue: any) {
    const date1 = new Date(DueDate);   
    if (paymentDue > 0) {
        if (date > date1) {
            return "-" + DataDiff;
        }
        else {
            return DataDiff;
        }
    }
    else {
        return null;
    }    
}

function isCredit(open_amount: any): boolean {
    return (typeof open_amount !== "undefined" && open_amount < 0);
}
function csvStatusFormatter(clearing_date: any, item_is_a_payment: any, open_amount: any)
{    
    if (isCredit(open_amount)) {
        return "credit";
    }
    if ((clearing_date ?? "" != "") || (clearing_date != undefined)) {
        return "paid";
    }
    if ((item_is_a_payment === "X") || (item_is_a_payment != undefined)) {
        return "paid";
    }        
    return "open";
}
function ExportExcel(props) {
    const intl = useIntl();
    const f = id => intl.formatMessage({ id: id });
    return (

        <div>
            <table id="Invoice" className="table" style={{ display: 'none' }}>
                    <tr>
                    <th>{props.data.columnsData[0].text}</th>
                    <th>{props.data.columnsData[1].text}</th>
                    <th>{props.data.columnsData[2].text}</th>
                    <th>{props.data.columnsData[3].text}</th>
                    <th>{props.data.columnsData[4].text}</th>
                    <th>{props.data.columnsData[5].text}</th>
                    <th>{props.data.columnsData[6].text}</th>
                    <th>{props.data.columnsData[7].text}</th>
                    <th>{props.data.columnsData[8].text}</th>
                    <th>{props.data.columnsData[10].text}</th>
                    </tr>               
                { 
                    props.data.invoicesData.map((p, index) => {
                        return <tr key={index}>
                            <td>{csvStatusFormatter(p.clearing_date, p.item_is_a_payment, p.open_amount)}</td>
                            {/*<td>{p.open_amount < 0 ? f("invoice_detail.credit") : f("invoice_detail.open")}</td>*/}
                            <td>{p.reference_number}</td>
                            <td>{p.billing_document_number != null ?  p.billing_document_number  :  p.document_number_finance } </td>
                            <td>{p.document_date}</td>
                            <td>{p.total_amount}</td>
                            <td>{p.paid_amount}</td>
                            <td>{p.open_amount}</td>
                            <td>{p.due_date}</td>                         
                            <td>{getDifferenceInDays(p.due_date, p.days_in_arrears,  p.open_amount) } </td>
                            <td>{p.soldto_number}</td>
                            <td style={{ paddingRight: "114px" }} >{p.Department}</td>
                        </tr>
                    })
                }               
            </table> 
            <div>               
                <ReactHTMLTableToExcel
                    className="dropdown-item !important"
                    table="Invoice"
                    filename="InvoiceExport"
                    sheet="Sheet"
                    buttonText="All as Excel" />
            </div >

        </div >

    );
}

export default ExportExcel;