import {createSelector} from 'reselect';
import { CompanyCodeDetails, PaymentType } from "../interfaces/CnbsConfigInterfaces";
import { getAppConfiguration, getConfiguration } from "../requests/ConfigRequests";
import {useSelector} from "react-redux";
import {RootState} from "../reducers/RootReducer";
import {getPayerAccount} from "./Selectors";
import {PayerDropdownData} from "../interfaces/PayerDropdown";
import {canMakePayment} from "./PermissionSelectors";
import {isStringNotEmptyOrNull} from "../utils";

const paymentTypes = state => state.app.config.sap?.data.payment_types;
const isPartialPaymentsAllowedString = state => state.app.config.sap?.data?.generel_data?.partial_payments_allowed ?? "";
const isCvvRequiredString = state => state.app.config.sap?.data?.generel_data?.cvv_use_control ?? "1";
interface apiparv {
    parnam: string;
    genky: string;
    parval: string;
}

const apiparvList = state => state.app.config.sap?.data?.custom_fields?.apiparv ?? new Array<apiparv>();

export const isInvoiceOverpaymentAllowed = createSelector([paymentTypes], (payment_types: PaymentType[]) => {
    for (let i = 0; i < payment_types.length; i += 1) {
        const paymentType = payment_types[i];
        if (paymentType.payment_type && paymentType.payment_type === 'O') {
            return paymentType.overpayment_allowed === "X";
        }
    }
    return false;
});

const ALLOW_ECHECK_KEY = 'ZENABLEEC';

export const isPartialPaymentsAllowed = createSelector([isPartialPaymentsAllowedString], (allowed) => {
    return typeof allowed !== 'undefined' && allowed !== null && allowed === 'X' || allowed === 'R'
});

export const isCvvRequired = createSelector([isCvvRequiredString], (isRequired) => {
    return typeof isRequired !== 'undefined' && isRequired !== null && isRequired === '1'
});

export const companyCodesDepositsEnabled = createSelector([apiparvList], (apiparvList: apiparv[]) => {
    return apiparvList.filter(_ => _.parnam === "ZENABLEDEP");
});

export const companyCodesPaymentsDisabled = createSelector([apiparvList], (apiparvList: apiparv[]) => {
    return apiparvList.filter(_ => _.parnam === "ZDISABLEPY");
});


export const companyCodesAllowEcheck = createSelector([apiparvList], (apiparvList: apiparv[]) => {
    return apiparvList.filter(_ => _.parnam === ALLOW_ECHECK_KEY);
});

// Function developed for customization
export function useCanMakeDepositsInternal(companyCode: string | null, config): boolean {
    let value = false;
     config?.company_codes?.map((item) => {
         if (item.company_code === companyCode) {
                if (item.enable_deposits === "X") {
                    value= true;
                }

            }
        });
    return value;
}

// Function developed for customization
export function useCanMakePaymentsInternal(companyCode: string): boolean {
    let value = false;
    useSelector<RootState, any>(
        state => {
            state.app.config.sap?.data?.company_codes?.map((item) => {
                if (item.company_code === companyCode) {
                    if (item.disable_payments === "X") {
                        value= true;
                    }
                }
            });
        }
    );
    return value;
}


// Function developed for customization
export function useCanUseEcheck(): boolean {
    const config = useSelector<RootState, any>(state => state.app.config.sap?.data);
    const payer = useSelector<RootState, PayerDropdownData>(getPayerAccount);
    //const canMakeDeposits = useCanMakeDepositsInternal(payer?.CompanyCode, config);
    let value = false;
    try {
        config?.company_codes?.map((item) => {
            if (item.company_code === payer.CompanyCode) {
                if (item.enable_echeck === "X") {
                    value = true;
                }

            }
        });
        return value;
    } catch (e) {
        return false;
    }
}


// Function developed for Nobel
export function useCanMakeDeposits(): boolean {
    const config = useSelector<RootState, any>(state => state.app.config.sap?.data);
    const payer = useSelector<RootState, PayerDropdownData>(getPayerAccount);
    const canMakeDeposits = useCanMakeDepositsInternal(payer?.CompanyCode, config);
    try {
       return canMakeDeposits && config && config.payment_types && config.payment_types.find(t => t.payment_type === "D") !== "undefined" && config.payment_types.find(t => t.payment_type === "D").active === "X";
    } catch (e) {
        return false;
    }
}



// Function developed for Nobel
export function useCanMakePayments(): boolean {
    const permissionsCanMakePayment = useSelector<RootState, boolean>(canMakePayment);
    const payer = useSelector<RootState, PayerDropdownData>(getPayerAccount);
    const companyCanMakePayments = useCanMakePaymentsInternal(payer?.CompanyCode);
    try {
        return permissionsCanMakePayment && !companyCanMakePayments;
    } catch (e) {
        return false;
    }
}

export function useCurrencyKey(): string {
    const company_codes = useSelector<RootState, CompanyCodeDetails[]>(state => state.app.config?.sap?.data?.company_codes ?? []);
    const payer = useSelector<RootState, PayerDropdownData>(getPayerAccount);
    if (isStringNotEmptyOrNull(payer?.CurrencyKey)) {
        return payer.CurrencyKey;
    }
    return company_codes.find(_ => _.company_code === payer?.CompanyCode)?.currency_key ?? "USD";
}

export function useCurrencyDecimals(currencyKey: string){
    const lookup = useCurrencyDecimalLookup();
    return lookup(currencyKey);
}


export function useCurrencyDecimalLookup() {
    const currency_decimals = useSelector<RootState, Array<{ currkey: string, currdec: number }>>(state => state.app.config.sap?.data?.sapcust?.currency_decimals ?? []);
    return (currencyKey: string) => {
        let set = currency_decimals.find(_ => _.currkey === currencyKey) ?? { currkey: "", currdec: 2};
        return set?.currdec ?? 0;
    }
}