import {withRouter} from "react-router-dom";
import React from "react";
import {Logout} from "../actions/UserActions";
import {connect} from "react-redux";
import { useIntl } from "react-intl";

function BackToLogin(props) {

  const intl = useIntl();
  const f = id => intl.formatMessage({ id: id });
  return (
    <a href={"/login"} onClick={(e) => {
      e.preventDefault();
      props.logout().then(() => {
        props.history.replace('/login'); 
      })
    }}> {f("system.go_back_login")}</a>
  );
}

export default withRouter(connect<any, any, any, any>(() =>{}, {
    logout: Logout,
})(BackToLogin));