import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import UserList from "./UserList";
import {Link} from "react-router-dom";
import * as _ from 'lodash';
import {canManageUsers, canViewUsers} from "../../selectors/PermissionSelectors";
import {FormattedMessage, useIntl} from "react-intl";

function ListUsersPage(props) {
    const intl = useIntl();
    const f = id => intl.formatMessage({id: id});
    
    let [users, setUsers] = useState<any[]>([]);
    let [status, setStatus] = useState('all');
    let [search, setSearch] = useState('');
    let [profile] = useState(null);

    let onSearchChangeDebounce = _.debounce((value) => {
        setSearch(value);
    }, 400);

    useEffect(getUsers, [status, search, profile]);

    function getUsers() {
        props.getUsers(status, profile, search).then((new_users) => {
            setUsers(new_users);
        });
    }

    function statusFilterChange(event) {
        setStatus(event.target.value);
    }

    function onSearchChange(event) {
        onSearchChangeDebounce(event.target.value);
    }

    return (<div className={"container"}>
        {
            (props.canViewUsers) ?
                <div>
                    <div className={"row"}>
                        <div className="col-12 col-md-3 d-flex">
                            <input type={"text"} className={"form-control"}
                                   placeholder={f("manage_users.search_placeholder")}
                                   onChange={onSearchChange}/>
                            <div className="search ml-2 mr-1">
                                <span className="fas fa-search fa-lg"/>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="customSelect">
                                <select onChange={statusFilterChange} value={status} className={"form-control"}>
                                    <option value="all">{f("manage_users.status_filter.all")}</option>
                                    <option value="active">{f("manage_users.status_filter.active")}</option>
                                    <option value="deactive">{f("manage_users.status_filter.deactive")}</option>
                                    <option value="locked">{f("manage_users.status_filter.locked")}</option>
                                    <option value="waiting-confirmation">{f("manage_users.status_filter.waiting_confirmation")}</option>
                                </select>
                                <span className="select-arrow">
                    <i className="fas fa-chevron-down"></i>
                  </span>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                        </div>
                        <div className="col-12 col-md-2">
                            {props.canManageUsers && <Link to={"/settings/users/add"}>
                              <button className="btn btn-primary buttonColor buttonsize">{f("manage_users.add_user_button")}</button>
                            </Link>}
                        </div>
                        <div className="col-12 col-md-1 d-flex align-items-center">
                            <span><FormattedMessage id={"manage_users.user_count_label"} values={{count: users.length}}/></span>
                        </div>
                    </div>
                    <div className={"row mt-4"}>
                        <div className={"col-12"}>
                            <UserList users={users}/>
                        </div>
                    </div>
                </div>
                : null
        }
    </div>)
}

export default connect<any, any, any, any>((state, props) => ({
    canManageUsers: canManageUsers(state),
    canViewUsers: canViewUsers(state),
}))(ListUsersPage);
