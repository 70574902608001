import {applyMiddleware, createStore, compose} from 'redux';
import {rootReducer} from './reducers/RootReducer';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';

export default createStore(
    rootReducer as any,
    composeWithDevTools(
        applyMiddleware(thunk) as any,
    )
);
