import React from "react";
import { FormattedMessage, IntlShape } from "react-intl";
import {
  csvDateFormatter,
  csvDaysToPayFormatter,
  csvDocumentNumberFormatter,
  csvDueDateFormatter,
  csvMoneyFormatter,
  csvReferenceFormatter,
  csvStatusFormatter,
  dateFormatter,
  daysToPayFormatter,
  documentNumberFormatter,
  dueFormatter,
  isCredit,
  isInvoice,
  moneyTableCell,
  paidFormatter,
  pdfFormatter,
  referenceFormatter,
    statusFormatter,
    statementIdFormatter
} from "../../common/invoiceHelpers";
import { SoldToCellConnected } from "./SoldToCell";
import { ReactComponent as SortUpIcon } from "../../images/sort-icons/sort-up.svg";
import { ReactComponent as SortDownIcon } from "../../images/sort-icons/sort-down.svg";
import { ReactComponent as SortNoneIcon } from "../../images/sort-icons/sort-none.svg";
import { Invoice } from "../../interfaces/invoice";
import moment from "moment";

function mapSoldTo(cell) {
  return <SoldToCellConnected value={cell} />;
}

function renderCaret(order, column) {
  if (order === "asc") {
    return <SortUpIcon className={"d-inline-block table-header-sort-icon"} />;
  }
  if (order === "desc") {
    return <SortDownIcon className={"d-inline-block table-header-sort-icon"} />;
  }
  return <SortNoneIcon className={"d-inline-block table-header-sort-icon"} />;
}

function sortStringNumber(a: string, b: string, order: "desc" | "asc") {
  if (order === "asc") {
    if (typeof a === "undefined" && typeof b !== "undefined") {
      return -1;
    }
    if (typeof a !== "undefined" && typeof b === "undefined") {
      return 1;
    }
    if (typeof a === "undefined" && typeof b === "undefined") {
      return 0;
    }
    if (parseFloat(a) < parseFloat(b)) {
      return -1;
    } else if (parseFloat(a) > parseFloat(b)) {
      return 1;
    } else {
      return 0;
    }
  } else {
    if (parseFloat(a) > parseFloat(b)) {
      return -1;
    } else if (parseFloat(a) < parseFloat(b)) {
      return 1;
    } else {
      return 0;
    }
  }
}

function sortStringDate(a: string, b: string, order: "asc" | "desc") {
  let aDate = moment(a, "MM-DD-YYYY");
  let bDate = moment(b, "MM-DD-YYYY");
  if (order === "desc") {
    let diff = aDate.diff(bDate, "days");
    return diff;
  } else {
    let diff = bDate.diff(aDate, "days");
    return diff;
  }
}

export function buildColumnConfigs(
  intl: IntlShape,
  tableConfig: { columns: any },
    isTablet: any,
    StatementId: string

   
){
    const f = (id) => intl.formatMessage({ id: id });
    const showStatementIdColumn = StatementId && StatementId.trim().length > 0

  const columns = [
    {
      headerClasses: "column-header",
      dataField: "billing_document_type",
      formatter: statusFormatter,
      csvFormatter: csvStatusFormatter(intl),
      text: f("invoice_table.status"),
      headerStyle: { width: "65px" },
      headerAlign: "center",
      align: "center",
    },
    {
      headerClasses: "column-header",
      dataField: "reference_number",
      formatter: referenceFormatter,
      csvFormatter: csvReferenceFormatter(intl),
      text: f("invoice_table.reference_number"),
      sort: true,
      sortCaret: renderCaret,
      headerStyle: { width: "85px" },
      headerAlign: "center",
      align: "center",
      hidden: isTablet,
    },
    {
      headerClasses: "column-header",
      dataField: "billing_document_number",
      formatter: documentNumberFormatter,
      csvFormatter: csvDocumentNumberFormatter(intl),
      csvType: String,
      text: f("invoice_table.document_number"),
      sortCaret: renderCaret,
      headerStyle: { width: "85px" },
      sort: true,
      headerAlign: "center",
      align: "center",
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === "asc") return a - b;
        else return b - a;
      },
    },
    // {
    //     headerClasses: "column-header",
    //     dataField: "document_number_finance",
    //     formatter: documentNumberFormatter,
    //     csvFormatter: csvDocumentNumberFormatter(intl),
    //     csvType: String,
    //     text: f("invoice_table.document_number"),
    //     sortCaret: renderCaret,
    //     headerStyle: { width: '95px' },
    //     sort: true,
    //     headerAlign: "center",
    //     align: "center",

 
      {
          headerClasses: "column-header",
          dataField: "STATEMENT_ID",
          text: f("invoice_table.statement_id"),
          headerStyle: { width: "115px" },
          headerAlign: "center",
          align: "center",
          formatter: () => {    
              return (
                  <span className="main-table-cell">{StatementId}</span>
              )
          },
          hidden: !showStatementIdColumn
      },
    {
      headerClasses: "column-header",
      dataField: "document_date",
      formatter: dateFormatter,
      csvFormatter: csvDateFormatter(intl),
      text: f("invoice_table.document_date"),
      sort: true,
      sortCaret: renderCaret,
      headerStyle: { width: "65px" },
      headerAlign: "center",
      sortFunc: sortStringDate,
      align: "center",
    },
    {
      headerClasses: "column-header",
      dataField: "total_amount",
      formatter: moneyTableCell,
      csvFormatter: csvMoneyFormatter(intl),
      csvType: Number,
      headerFormatter: (col, colIndex, components) => {
        return (
          <span>
            <FormattedMessage id={"invoice_table.total_amount"} />
            {components.sortElement}
          </span>
        );
      },
      text: f("invoice_table.total_amount"),
      sort: true,
      sortCaret: renderCaret,
      headerStyle: { width: "80px" },
      headerAlign: "center",
      align: "center",
      sortFunc: sortStringNumber,
    },
    {
      headerClasses: "column-header",
      dataField: "paid_amount",
      formatter: paidFormatter,
      csvFormatter: csvMoneyFormatter(intl),
      csvType: Number,
      text: f("invoice_table.paid_amount"),
      sort: true,
      sortCaret: renderCaret,
      headerStyle: { width: "80px" },
      headerAlign: "center",
      align: "center",
      sortFunc: sortStringNumber,
    },
    {
      headerClasses: "column-header",
      dataField: "open_amount",
      formatter: moneyTableCell,
      csvFormatter: csvMoneyFormatter(intl),
      csvType: Number,
      headerFormatter: (col, colIndex, components) => {
        return (
          <span>
            <FormattedMessage id={"invoice_table.open_amount"} />
            {components.sortElement}
          </span>
        );
      },
      text: f("invoice_table.open_amount"),
      sort: true,
      sortCaret: renderCaret,
      headerStyle: { width: "80px" },
      headerAlign: "center",
      align: "center",
      sortFunc: sortStringNumber,
    },
    {
      headerClasses: "column-header",
      dataField: "due_date",
      formatter: dueFormatter,
      csvFormatter: csvDueDateFormatter(intl),
      text: f("invoice_table.due_date"),
      sort: true,
      sortCaret: renderCaret,
      headerStyle: { width: "70px" },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (isInvoice(rowA) && isInvoice(rowB)) {
          return sortStringDate(a, b, order);
        }
        if (isInvoice(rowA) && !isInvoice(rowB)) {
          return -1;
        }
        if (isInvoice(rowB) && !isInvoice(rowA)) {
          return 1;
        }
        if (isCredit(rowA) && !isCredit(rowB)) {
          return -1;
        }
        if (isCredit(rowB) && !isCredit(rowA)) {
          return 1;
        }
        if (isCredit(rowA) && isCredit(rowB)) {
          return sortStringDate(rowA.document_date, rowB.document_date, order);
        }
        return sortStringDate(rowA.document_date, rowB.document_date, order);
      },
      headerAlign: "center",
      align: "center",
    },
    {
      headerClasses: "column-header",
      dataField: "days_in_arrears",
      formatter: daysToPayFormatter,
      csvFormatter: csvDaysToPayFormatter(intl),
      text: f("invoice_table.days_in_arrears"),
      headerStyle: { width: "100px" },
      headerAlign: "center",
      align: "center",
      hidden: isTablet,
    },
    {
      headerClasses: "column-header",
      dataField: "pdf_document_available",
      formatter: pdfFormatter,
      text: f("invoice_table.pdf"),
      headerStyle: { width: "45px" },
      headerAlign: "center",
      align: "center",
      csvExport: false,
    },
    {
      headerClasses: "column-header",
      dataField: "soldto_number",
      formatter: mapSoldTo,
      text: f("invoice_table.soldto_number"),
      headerStyle: { width: "65px" },
      headerAlign: "center",
      align: "center",
      hidden: isTablet,
    },
  ];

  if (isTablet) {
    // columns.splice(1, 1)
    // columns.splice(7, 1)
    // columns.splice(9, 1)
    //columns.splice(11, 1)
  }

  // Here we get columns based on what fields are in the config.
 return tableConfig.columns
    .filter((config) => {
      return columns.find((_) => _.dataField === config.key);
    })
    .map((config) => columns.find((_) => _.dataField === config.key));
}
