import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { Link } from "react-router-dom";
import * as appConstants from "../../constants/ApplicationConstants";
import "../../../node_modules/flatpickr/dist/themes/material_blue.css";
import {
  getDefaultFilters,
  getImpersonatedUserId,
  getPayerSoldToForInvoiceQuery,
  getSelectedPrimaryAccount,
  getSelectedSecondaryAccount,
} from "../../selectors/Selectors";
import {
  adjustCurrency,
  getInvoicesAjax,
  setsearchedInvoices,
  setsearchedinvoiceStatus,
  setsearchedinvoiceDueDate,
  setsearchedinvoiceType,
  setsearchedInvoiceNumber,
  setsearchedArticleNumber,
  setsearchedArticleDescription,
  setinvoiceSearchedPeriod,
  setinvoiceSearchedPeriodFromTo,
} from "../../actions/InvoiceActions";
import { InvoiceSearchList } from "./InvoiceList";
import {
  DocumentStatus,
  DocumentType,
  InvoiceRequestBody,
} from "../../interfaces/invoice";
import { FormattedMessage, useIntl } from "react-intl";
import { useCalendarTranslation } from "../../hooks/CalendarTranslation";
import { isStringNotEmptyOrNull } from "../../utils";
import { SecondarySelect } from "../SecondarySelect";
import { AccountType } from "../../interfaces/Accounts";
import { RootState } from "../../reducers/RootReducer";
import { useCurrencyDecimalLookup } from "../../selectors/ConfigSelectors";
import { useDispatch } from "react-redux";
import { CurrencySelector, useHasMultiCurrency } from "./CurrencySelector";
import { BootstrapField } from "../../helpers/Field";
import { PregnantWomanOutlined } from "@material-ui/icons";
interface InvoiceSearchState {
  invoices: any[];
  searched: boolean;
  status: DocumentStatus;
  dueDateFrom: string;
  dueDateTo: string;
  period: string;
  dateFrom: string;
  dateTo: string;
  characteristics: string;
  characteristicsValue: string;
  characteristicsLabel: string;
  showDatesDue: boolean;
  showPeriod: boolean;
  showDatesPeriod: boolean;
  showCharacteristicsValue: boolean;
}

function _InvoiceSearch(props) {
  //props.searchInvoices = null;
  const hasMultiCurrency = useHasMultiCurrency();
  const selectedCurrency = useSelector<RootState, string>(
    (state) => state.default.selectedCurrencyOptionValue
  );
  const intl = useIntl();
  const f = (id) => intl.formatMessage({ id: id });
  const calIl8n = useCalendarTranslation();
  const [toDate, setToDate] = useState(moment(new Date()).format("MM-DD-YYYY"));
  const [fromDate, setFromDate] = useState(
    moment(new Date()).format("MM-DD-YYYY")
  );
  const accountType = useSelector<RootState, AccountType>(
    (store) => store?.user_options?.accountType
  );
  const decimalLookup = useCurrencyDecimalLookup();
  const dispatch = useDispatch();
  let [state, setState] = useState({
    invoices: [],
    searched: false,
    status: DocumentStatus.OpenOrComplete,
    dueDateFrom: "",
    dueDateTo: "",
    period: "all",
    dateFrom: "",
    dateTo: "",
    characteristics: "",
    characteristicsValue: "",
    characteristicsLabel: "",
    showDatesDue: false,
    showPeriod: true,
    showDatesPeriod: false,
    showCharacteristicsValue: false,
    materialNumber: "",
    materialDescription: "",
    documentType: "",
    SoldToSearchParams: props.selectedSoldTo,
  });

  useEffect(() => {
    setState({
      ...state,
      invoices: [],
      searched: false,
      status: DocumentStatus.OpenOrComplete,
      dueDateFrom: "",
      dueDateTo: "",
      period: "all",
      dateFrom: "",
      dateTo: "",
      characteristics: "",
      characteristicsValue: "",
      characteristicsLabel: "",
      showDatesDue: false,
      showPeriod: true,
      showDatesPeriod: false,
      showCharacteristicsValue: false,
      materialNumber: "",
      materialDescription: "",
      documentType: "",
      SoldToSearchParams: props.selectedSoldTo,
    });
  }, [
    props.primaryAccountIndex,
    props.selectedSecondaryAccount,
    props.selectedCurrency,
  ]);

  //alert(props.selectedSoldTo);

  useEffect(() => {
    let searched = props.searchInvoices ? true : false;
    let invoices = [];
    let materialDescription = props.searchedArticleDescription;
    let SoldToSearchParams = props.selectedSoldTo;
    let materialNumber = props.searchedArticleNumber;
    let characteristicsValue = props.searchedInvoiceNumber;
    let status = props.searchedinvoiceStatus
      ? props.searchedinvoiceStatus
      : DocumentStatus.OpenOrComplete;
    let showDatesDue =
      props.searchedinvoiceStatus && props.searchedinvoiceStatus === "01"
        ? true
        : false;
    let showPeriod =
      props.searchedinvoiceStatus && props.searchedinvoiceStatus !== "01"
        ? true
        : false;
    let documentType = props.searchedinvoiceType;
    let period = props.invoiceSearchedPeriod;
    let showDatesPeriod =
      props.invoiceSearchedPeriod && props.invoiceSearchedPeriod === "-1";
    let dueDateFrom =
      props.searchedinvoiceDueDateFrom !== ""
        ? props.searchedinvoiceDueDateFrom
        : "";
    let dueDateTo =
      props.searchedinvoiceDueDateTo !== ""
        ? props.searchedinvoiceDueDateTo
        : "";
    let dateFrom =
      props.invoiceSearchedPeriodFrom !== ""
        ? props.invoiceSearchedPeriodFrom
        : "";
    let dateTo =
      props.invoiceSearchedPeriodTo !== "" ? props.invoiceSearchedPeriodTo : "";
    if (
      props.searchedinvoiceStatus === "01" &&
      props.searchedinvoiceDueDateFrom !== "" &&
      props.searchedinvoiceDueDateTo !== ""
    ) {
      setFromDate(props.searchedinvoiceDueDateFrom);
      setToDate(props.searchedinvoiceDueDateTo);
    }
    if (
      props.invoiceSearchedPeriod === "-1" &&
      props.invoiceSearchedPeriodFrom !== "" &&
      props.invoiceSearchedPeriodTo !== ""
    ) {
      setFromDate(props.invoiceSearchedPeriodFrom);
      setToDate(props.invoiceSearchedPeriodTo);
    }

    if (props.searchedinvoiceStatus === "01") {
      status = DocumentStatus.OpenOrComplete;
    }
    let currency = props.selectedCurrencyOptionValue;
    //let selectedSoldTo: props.user_options.selectedSoldTos,
    //let SoldToSearchParams=
    setState({
      ...state,
      searched: searched,
      invoices: invoices,
      materialDescription: materialDescription,
      materialNumber: materialNumber,
      characteristicsValue: characteristicsValue,
      status: status,
      showDatesDue: showDatesDue,
      showPeriod: showPeriod,
      documentType: documentType,
      period: period,
      showDatesPeriod: showDatesPeriod,
      dueDateFrom: dueDateFrom,
      dueDateTo: dueDateTo,
      dateFrom: dateFrom,
      dateTo: dateTo,
      SoldToSearchParams: props.SoldToSearchParams,
      //currency:currency,
    });
  }, []);

  let handleDueFromChange = (date) => {
    let fromDue = date;
    if (fromDue && fromDue.length > 0) {
      fromDue = moment(fromDue[0]).format("MM-DD-YYYY");
    } else {
      fromDue = moment([new Date().getFullYear(), 11, 31]).format("MM-DD-YYYY");
    }

    setFromDate(fromDue);

    // setState({
    //     ...state,
    //     dueDateFrom: fromDue,

    // });

    setState((prevState) => ({
      ...prevState,
      dueDateFrom: fromDue,
    }));
    dispatch(setsearchedinvoiceDueDate(fromDue, toDate));
  };

  let handleDueToChange = (date) => {
    let toDue = date;
    if (toDue && toDue.length > 0) {
      toDue = moment(toDue[0]).format("MM-DD-YYYY");
    } else {
      toDue = moment([new Date().getFullYear(), 11, 31]).format("MM-DD-YYYY");
    }

    setToDate(toDue);
    // setState({
    //     ...state,
    //     dueDateTo: toDue,

    // });
    setState((prevState) => ({
      ...prevState,
      dueDateTo: toDue,
    }));
    dispatch(setsearchedinvoiceDueDate(fromDate, toDue));
  };

  let handlePeriodFromChange = (date) => {
    let fromPeriod = date;
    if (fromPeriod && fromPeriod.length > 0) {
      fromPeriod = moment(fromPeriod[0]).format("MM-DD-YYYY");
    } else {
      fromPeriod = moment([new Date().getFullYear(), 11, 31]).format(
        "MM-DD-YYYY"
      );
    }

    setFromDate(fromPeriod);

    // setState({
    //     ...state,
    //     dateFrom: fromPeriod,

    // });

    setState((prevState) => ({
      ...prevState,
      dateFrom: fromPeriod,
    }));

    dispatch(setinvoiceSearchedPeriodFromTo(fromPeriod, toDate));
  };

  let handlePeriodToChange = (date) => {
    let toPeriod = date;
    if (toPeriod && toPeriod.length > 0) {
      toPeriod = moment(toPeriod[0]).format("MM-DD-YYYY");
    } else {
      toPeriod = moment([new Date().getFullYear(), 11, 31]).format(
        "MM-DD-YYYY"
      );
    }

    setToDate(toPeriod);
    // setState({
    //     ...state,
    //     dateTo: toPeriod,
    // });

    setState((prevState) => ({
      ...prevState,
      dateTo: toPeriod,
    }));

    dispatch(setinvoiceSearchedPeriodFromTo(fromDate, toPeriod));
  };

  function onDateChange(selectedDates, dateStr, instance) {
    if (selectedDates && selectedDates.length > 0) {
      setToDate(selectedDates[0]);
    }
    if (selectedDates && selectedDates.length > 1) {
      setFromDate(selectedDates[1]);
    }

    let from = "";
    let to = "";
    if (selectedDates && selectedDates.length > 0) {
      from =
        selectedDates[0] && selectedDates[1]
          ? moment(selectedDates[0]).format("MM-DD-YYYY")
          : "";
      to =
        selectedDates[0] && selectedDates[1]
          ? moment(selectedDates[1]).format("MM-DD-YYYY")
          : "";
    }
    const controlId = instance.input.id;
    switch (controlId) {
      case "datesDue":
        // setState({
        //     ...state,
        //     dueDateFrom: from,
        //     dueDateTo: to
        // });

        setState((prevState) => ({
          ...prevState,
          dueDateFrom: from,
          dueDateTo: to,
        }));

        dispatch(setsearchedinvoiceDueDate(selectedDates[0], selectedDates[1]));

        break;
      case "datesPeriod":
        // setState({
        //     ...state,
        //     dateFrom: from, dateTo: to
        // });

        setState((prevState) => ({
          ...prevState,
          dateFrom: from,
          dateTo: to,
        }));
        dispatch(
          setinvoiceSearchedPeriodFromTo(selectedDates[0], selectedDates[1])
        );
        break;
      default:
        break;
    }
  }

  function onSelectChange(name) {
    return function (e) {
      let option = e.target.value;
      switch (name) {
        case "status":
          // setState({
          //     ...state,
          //     status: option,
          //     showDatesDue: option && option === '01',
          //     showPeriod: option && option !== '01',
          //     period: 'all',
          //     showDatesPeriod: false,
          // });

          setState((prevState) => ({
            ...prevState,
            status: option,
            showDatesDue: option && option === "01",
            showPeriod: option && option !== "01",
            period: "all",
            showDatesPeriod: false,
          }));
          setFromDate(moment(new Date()).format("MM-DD-YYYY"));

          setToDate(moment(new Date()).format("MM-DD-YYYY"));
          dispatch(setsearchedinvoiceDueDate("", ""));
          dispatch(setinvoiceSearchedPeriodFromTo("", ""));
          dispatch(setsearchedinvoiceStatus(option));
          dispatch(setinvoiceSearchedPeriod("all"));
          break;
        case "period":
          setState((prevState) => ({
            ...prevState,
            period: option,
            showDatesPeriod: option && option === "-1",
          }));

          setFromDate("");
          setToDate("");
          dispatch(setsearchedinvoiceDueDate("", ""));
          dispatch(setinvoiceSearchedPeriodFromTo("", ""));
          dispatch(setinvoiceSearchedPeriod(option));
          break;
        case "characteristics":
          // setState({
          //     ...state,
          //     characteristics: option ? option : '',
          //     showCharacteristicsValue: option && option,
          //     characteristicsLabel: option ? e.target.options[e.target.selectedIndex].text : '',
          // });

          setState((prevState) => ({
            ...prevState,
            characteristics: option ? option : "",
            showCharacteristicsValue: option && option,
            characteristicsLabel: option
              ? e.target.options[e.target.selectedIndex].text
              : "",
          }));

          break;
        default:
          break;
      }
    };
  }

  function onCharacteristicsValueChange(e) {
    // setState({
    //     ...state,
    //     characteristicsValue: e.target.value
    // });
    setState((prevState) => ({
      ...prevState,
      characteristicsValue: e.target.value,
    }));

    dispatch(setsearchedInvoiceNumber(e.target.value));
  }

  function onMaterialDescriptionChange(e) {
    // setState({
    //     ...state,
    //     materialDescription: e.target.value
    // });
    setState((prevState) => ({
      ...prevState,
      materialDescription: e.target.value,
    }));
    dispatch(setsearchedArticleDescription(e.target.value));
  }

  function onMaterialNumberChange(e) {
    // setState({
    //     ...state,
    //     materialNumber: e.target.value
    // });

    setState((prevState) => ({
      ...prevState,
      materialNumber: e.target.value,
    }));

    dispatch(setsearchedArticleNumber(e.target.value));
  }

  function onDocumentTypeChange(e) {
    // setState({
    //     ...state,
    //     documentType: e.target.value
    // });

    setState((prevState) => ({
      ...prevState,
      documentType: e.target.value,
    }));

    dispatch(setsearchedinvoiceType(e.target.value));
  }

  function getSearch(validate: boolean = false): InvoiceRequestBody | null {
    let { dueDateFrom, dueDateTo, dateFrom, dateTo } = state;
    const { status, period, characteristics, characteristicsValue } = state;
    const { selectedSoldTo } = props.selectedSoldTo;
    if (status === "01") {
      dueDateFrom = moment(dueDateFrom).format("MM-DD-YYYY");
      dueDateTo = moment(dueDateTo).format("MM-DD-YYYY");
    } else {
      dueDateFrom = "";
      dueDateTo = "";
    }
    if (period === "all") {
      dateFrom = "01-01-1800";
      dateTo = "12-31-9999";
    } else if (period === "-1") {
      dateFrom = moment(dateFrom).format("MM-DD-YYYY");
      dateTo = moment(dateTo).format("MM-DD-YYYY");
    } else if (period === "0") {
      //Today
      dateFrom = moment(new Date()).format("MM-DD-YYYY");
      dateTo = moment(new Date()).format("MM-DD-YYYY");
    } else if (period === "1") {
      //Since yesterday
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      dateFrom = moment(yesterday).format("MM-DD-YYYY");
      dateTo = moment(today).format("MM-DD-YYYY");
    } else if (period === "7") {
      //Since last week
      const today = new Date();
      const lastSevenDays = new Date();
      lastSevenDays.setDate(today.getDate() - 7);
      dateFrom = moment(lastSevenDays).format("MM-DD-YYYY");
      dateTo = moment(today).format("MM-DD-YYYY");
    } else if (period === "30") {
      //Since last 30 days
      const today = new Date();
      const lastThirtyDays = new Date();
      lastThirtyDays.setDate(today.getDate() - 30);
      dateFrom = moment(lastThirtyDays).format("MM-DD-YYYY");
      dateTo = moment(today).format("MM-DD-YYYY");
    } else if (period === "365") {
      //Since last 30 days
      const today = new Date();
      const lastOneYear = new Date();
      lastOneYear.setDate(today.getDate() - 365);
      dateFrom = moment(lastOneYear).format("MM-DD-YYYY");
      dateTo = moment(today).format("MM-DD-YYYY");
    } else {
      dateFrom = "";
      dateTo = "";
    }
    const filters: { filter_type: string; value: string }[] = [];
    if (isStringNotEmptyOrNull(characteristicsValue)) {
      filters.push({ filter_type: "01", value: characteristicsValue });
    }
    if (isStringNotEmptyOrNull(state.materialNumber)) {
      filters.push({ filter_type: "90", value: state.materialNumber });
    }
    if (isStringNotEmptyOrNull(state.materialDescription)) {
      filters.push({ filter_type: "91", value: state.materialDescription });
    }
    if (state.documentType == "invoice") {
      filters.push({ filter_type: "92", value: "X" });
    }
    if (state.documentType == "credit") {
      filters.push({ filter_type: "93", value: "X" });
    }
    //props.selectedSoldTo
    let { payer_data, soldto_data } = getPayerSoldToForInvoiceQuery(
      props.accountType,
      props.secondaryAccount,
      props.primaryAccount
    );
    return {
      payer_data,
      soldto_data,
      search_parameters: {
        document_type: DocumentType.Invoice,
        status: status,
        date_from: dateFrom,
        date_to: dateTo,
        due_date_from: dueDateFrom,
        due_date_to: dueDateTo,
        currency_key: "",
        SoldTo_SearchParams: props.selectedSoldTo,
        filter: getDefaultFilters(
          props.accountType,
          props.impersonatedUserId
          ).concat(filters as any),
          invoice_filter: "",
          STATEMENT_ID:"",
      },
    };
  }

  function exportToPdf() {
    try {
      let search = getSearch();
      props.getInvoiceSearchPDF(search);
    } catch (e) {
      return null;
    }
  }

  function onFindClick() {
    let { dueDateFrom, dueDateTo, dateFrom, dateTo } = state;
    const { status, period, characteristics, characteristicsValue } = state;
    try {
      if (
        status === "01" &&
        (dueDateFrom === "" ||
          dueDateFrom === null ||
          dueDateTo === "" ||
          dueDateTo === null)
      ) {
        toast.error(f("invoice_search.error.due_dates_required"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      } else if (
        status === "01" &&
        moment(dueDateTo).isBefore(moment(dueDateFrom))
      ) {
        toast.error(f("invoice_search.error.due_to_date_should_greater"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      } else if (
        period === "-1" &&
        (dateFrom === "" ||
          dateFrom === null ||
          dateTo === "" ||
          dateTo === null)
      ) {
        toast.error(f("invoice_search.error.document_dates_required"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      if (props.accoutType === "Payer" && props.selectedSoldTo.length === 0) {
        toast.error(f("invoice_search.error.select_customer"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      } else {
        let search = getSearch(true);

        if (search) {
          getInvoicesAjax(search).then((response) => {
            let invoices = response.data.document_list as any;
            invoices.forEach((invoice) => {
              const soldToNum = parseInt(invoice.soldto_number, 10);
              const payerNum = parseInt(invoice.payer_number, 10);
              invoice.open_amount = invoice.open_amount
                ? adjustCurrency(
                    invoice.open_amount,
                    decimalLookup(invoice.currency_key)
                  )
                : invoice.open_amount;
              invoice.total_amount = invoice.total_amount
                ? adjustCurrency(
                    invoice.total_amount,
                    decimalLookup(invoice.currency_key)
                  )
                : invoice.total_amount;
              invoice.paid_amount = invoice.paid_amount
                ? adjustCurrency(
                    invoice.paid_amount,
                    decimalLookup(invoice.currency_key)
                  )
                : invoice.paid_amount;
              invoice.soldto_number = isNaN(soldToNum)
                ? invoice.soldto_number
                : soldToNum.toString();
              invoice.payer_number = isNaN(payerNum)
                ? invoice.payer_number
                : payerNum.toString();
            });
            dispatch(setsearchedInvoices(invoices));
            setState({
              ...state,
              invoices: invoices,
              searched: true,
            });
            sessionStorage.setItem("searched", JSON.stringify(true));
          });
        }
      }
    } catch (e) {
      return null;
    }
  }

  function getDateFrom(days) {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return moment(date).format("MM-DD-YYYY");
  }

  const countOfInvoices = state.invoices ? state.invoices.length : 0;
  return (
    <div className="container invoicePage">
      <div className="row" style={{ marginLeft: "15px" }}>
        <div className="col-lg-11 col-md-11 col-sm-11 col-11">
          <h2 className="invoicesHeading bottompadding">
            <FormattedMessage id={"invoice_search.page_title"} />
          </h2>
        </div>
        {/*<div className="col-lg-1 col-md-1 col-sm-1 col-1 nopadding">*/}
        {/*    <span>*/}
        {/*        <Link to="/invoices">*/}
        {/*            <i className="fas fa-times text-secondary float-right" />*/}
        {/*        </Link>*/}
        {/*    </span>*/}
        {/*</div>*/}
      </div>
      <div
        className="card"
        style={{
          paddingTop: "60px",
          marginLeft: "30px",
          marginRight: "30px",
          marginBottom: "30px",
        }}
      >
        <div className="card-body nopadding">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 col-4 bottompadding">
              <span className="float-right col-form-label col-form-label-sm text-secondary">
                <FormattedMessage id={"deposits.Account_label"} />
              </span>
            </div>
            <div className="col-lg-4 col-md-8 col-sm-8 col-8 bottompadding customSelect">
              <SecondarySelect accountType={accountType} />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 col-4 bottompadding">
              <span className="float-right col-form-label col-form-label-sm text-secondary">
                <FormattedMessage id={"invoice_search.status_label"} />
              </span>
            </div>
            <div className="col-lg-4 col-md-8 col-sm-8 col-8 bottompadding customSelect">
              <select
                name="status"
                className="form-control"
                // defaultValue={state.status}
                onChange={onSelectChange("status")}
              >
                <option value={"03"} selected={state.status === "03"}>
                  {f("invoice_search.status_filter.all")}
                </option>
                <option value={"01"} selected={state.status === "01"}>
                  {f("invoice_search.status_filter.due")}
                </option>
                <option value={"02"} selected={state.status === "02"}>
                  {f("invoice_search.status_filter.complete")}
                </option>
              </select>
              <i className="fas fa-chevron-down select-arrow" />
            </div>
            {/* <div
                            className={`${'col-lg-2 col-md-4 col-sm-4 col-4 bottompadding'}${' '} ${state.showDatesDue ? '' : 'hideControl'}`}>
                            <span
                                className="float-right col-form-label col-form-label-sm text-secondary">{f("invoice_search.status_filter.due")}</span>
                        </div> */}
            <div
              className={`${"col-lg-4 col-md-8 col-sm-8 col-8 bottompadding"}${" "} ${
                state.showDatesDue ? "" : "hideControl"
              }`}
            >
              <div className="col datetimepickerdiv">
                <div className="form-check-inline-date-first">
                  <div>
                    <i className="calendarPosition fa fa-calendar-alt" />
                    <Flatpickr
                      className="inp form-control inv-calendar"
                      options={{
                        formatDate: (date) => {
                          return intl.formatDate(date);
                        },
                        locale: calIl8n,
                      }}
                      onClose={handleDueFromChange}
                      placeholder="From"
                    />
                  </div>
                </div>

                <div className="form-check-inline-date">
                  <div>
                    <i className="calendarPosition fa fa-calendar-alt" />
                    <Flatpickr
                      className="inp form-control inv-calendar"
                      options={{
                        formatDate: (date) => {
                          return intl.formatDate(date);
                        },
                        locale: calIl8n,
                      }}
                      onClose={handleDueToChange}
                      placeholder="To"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className={`${"col-lg-2 col-md-4 col-sm-4 col-4 float-right bottompadding"}${" "}${
                state.showPeriod ? "" : "hideControl"
              }`}
            >
              <span className="float-right col-form-label col-form-label-sm text-secondary">
                <FormattedMessage id={"invoice_search.period_label"} />
              </span>
            </div>
            <div
              className={`${"col-lg-4 col-md-8 col-sm-8 col-8 bottompadding customSelect"}${" "}${
                state.showPeriod ? "" : "hideControl"
              }`}
            >
              <select
                name="period"
                className="form-control"
                value={state.period}
                onChange={onSelectChange("period")}
              >
                <option value={"all"} selected={state.period === "all"}>
                  {intl.formatMessage({
                    id: "invoice_search.status_filter.all",
                    defaultMessage: "All",
                  })}
                </option>
                <option value={"0"} selected={state.period === "0"}>
                  {f("invoice_search.date_filter.today")}
                </option>
                <option value={"1"} selected={state.period === "1"}>
                  {f("invoice_search.date_filter.yesterday")}
                </option>
                <option value={"7"} selected={state.period === "7"}>
                  {f("invoice_search.date_filter.last_7_days")}
                </option>
                <option value={"30"} selected={state.period === "30"}>
                  {f("invoice_search.date_filter.last_30_days")}
                </option>
                <option value={"365"} selected={state.period === "365"}>
                  {f("invoice_search.date_filter.last_year")}
                </option>
                <option value={"-1"} selected={state.period === "-1"}>
                  {f("invoice_search.date_filter.custom")}
                </option>
              </select>
              <i className="fas fa-chevron-down select-arrow" />
            </div>
            {/* <div
                            className={`${'col-lg-2 col-md-4 col-sm-4 col-4 bottompadding'}${' '}${state.showPeriod && state.showDatesPeriod ? '' : 'hideControl'}`}>
                            <span
                                className="float-right col-form-label col-form-label-sm text-secondary"><FormattedMessage
                                    id={"invoice_search.from"} /></span>
                        </div> */}
            <div
              className={`${"col-lg-4 col-md-8 col-sm-8 col-8 bottompadding"}${" "}${
                state.showPeriod && state.showDatesPeriod ? "" : "hideControl"
              }`}
            >
              {/* <i className="calendarPosition fa fa-calendar" />
                            <Flatpickr
                                className="inp form-control"
                                id="datesPeriod"
                                value={[fromDate, toDate]}
                                onChange={onDateChange}
                                options={{
                                    mode: 'range',
                                    minDate: moment().add(-5, 'year').format("YYYY-MM-DD"),
                                    maxDate: moment().add(1, 'year').format("YYYY-MM-DD"),
                                    formatDate: (date) => {
                                        return intl.formatDate(date)
                                    },
                                    locale: calIl8n,
                                }}
                            /> */}

              <div className="col datetimepickerdiv">
                <div className="form-check-inline-date-first">
                  <div>
                    <i className="calendarPosition fa fa-calendar-alt" />
                    <Flatpickr
                      className="inp form-control inv-calendar"
                      options={{
                        formatDate: (date) => {
                          return intl.formatDate(date);
                        },
                        locale: calIl8n,
                      }}
                      onClose={handlePeriodFromChange}
                      placeholder="From"
                    />
                  </div>
                </div>

                <div className="form-check-inline-date">
                  <div>
                    <i className="calendarPosition fa fa-calendar-alt" />
                    <Flatpickr
                      className="inp form-control inv-calendar"
                      options={{
                        formatDate: (date) => {
                          return intl.formatDate(date);
                        },
                        locale: calIl8n,
                      }}
                      onClose={handlePeriodToChange}
                      placeholder="To"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
                        <div className="col-lg-2 col-md-4 col-sm-4 col-4 bottompadding">
                            <span
                                className="float-right col-form-label col-form-label-sm text-secondary">
                                <FormattedMessage id={"invoice_search.search_options.type_label"}
                                                  defaultMessage={"Type"}/>
                            </span>
                        </div>
                        <div className={"col-lg-4 col-md-8 col-sm-8 col-8 bottompadding customSelect"}>

                            <select
                                className="form-control"
                                value={state.documentType}
                                onChange={onDocumentTypeChange}>
                                <option value={'any'}>{intl.formatMessage({
                                    id: "invoice_search.status_filter.all",
                                    defaultMessage: "Any"
                                })}</option>
                                <option value={'invoice'}>{intl.formatMessage({
                                    id: "invoice_detail.invoice_label",
                                    defaultMessage: "Invoice"
                                })}</option>
                                <option value={'credit'}>{intl.formatMessage({
                                    id: "invoice_detail.credit_note",
                                    defaultMessage: "Credit"
                                })}</option>
                            </select>
                            <i className="fas fa-chevron-down select-arrow"/>
                        </div>
                    </div>  */}
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 col-4 bottompadding">
              <span className="float-right col-form-label col-form-label-sm text-secondary">
                <FormattedMessage
                  id={"invoice_search.search_options.invoice_number"}
                />
              </span>
            </div>
            <div className={"col-lg-4 col-md-8 col-sm-8 col-8 bottompadding"}>
              <input
                type="text"
                className="form-control control-border"
                placeholder={f("invoice_search.search_options.invoice_number")}
                value={state.characteristicsValue}
                name="invNumbner"
                onChange={onCharacteristicsValueChange}
              />
            </div>
          </div>
          {/* <div className="row">
                        <div className="col-lg-2 col-md-4 col-sm-4 col-4 bottompadding">
                            <span
                                className="float-right col-form-label col-form-label-sm text-secondary">
                                <FormattedMessage id={"invoice_search.material_number"}/>
                            </span>
                        </div>
                        <div className={"col-lg-4 col-md-8 col-sm-8 col-8 bottompadding"}>
                            <input type="text" className="form-control control-border"
                                   placeholder={f("invoice_search.material_number")}
                                   value={state.materialNumber} name="materialNumber"
                                   onChange={onMaterialNumberChange}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2 col-md-4 col-sm-4 col-4 bottompadding">
                            <span
                                className="float-right col-form-label col-form-label-sm text-secondary">
                                <FormattedMessage id={"invoice_search.material_description"}/>
                            </span>
                        </div>
                        <div className={"col-lg-4 col-md-8 col-sm-8 col-8 bottompadding"}>
                            <input type="text" className="form-control control-border"
                                   placeholder={f("invoice_search.material_description")}
                                   value={state.materialDescription} name="materialDescription"
                                   onChange={onMaterialDescriptionChange}/>
                        </div>
                    </div> */}

          <div className="form-row text-center mt-4">
            <div className="col-12">
              <button
                type="button"
                className="btn btn-primary findButtonColor form-control control-border button-class"
                onClick={onFindClick}
              >
                <FormattedMessage id={"invoice_search.find_button"} />
              </button>
            </div>
          </div>

          <br />
        </div>
      </div>

      {/*{state.invoices && state.invoices.length > 0 && <div className="row mt-2">*/}
      {/*  <div className="col-lg-12 col-md-12 col-sm-12 col-12 justify-content-end d-flex">*/}
      {/*    <button type="button"*/}
      {/*            className="btn btn-primary btn-sm"*/}
      {/*            onClick={exportToPdf}>Export to PDF*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*}*/}
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <span className="invoiceSearch col-form-label col-form-label-sm text-secondary float-right">
            {props.searched && (
              <span>
                {countOfInvoices} {f("invoices.found")}
              </span>
            )}
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <InvoiceSearchList
            invoices={state.invoices}
            isSearch={true}
            invoiceSearchQuery={getSearch()}
          />
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default connect<any, any, any, any>(
  (store, props) => ({
    accountType: store.user_options.accountType,
    primaryAccount: getSelectedPrimaryAccount(store),
    primaryAccountIndex: store.user_options.selectedPrimaryAccountIndex, //getSelectedPrimaryAccountIndex(store, props),
    secondaryAccount: getSelectedSecondaryAccount(store),
    characteristicsOptions: store.default.characteristicsOptions,
    filter: store.user_options.filter,
    impersonatedUserId: getImpersonatedUserId(store),
    searchInvoices: store.invoice.searchInvoices,
    searchedinvoiceStatus: store.invoice.searchedinvoiceStatus,
    searchedinvoiceDueDateFrom: store.invoice.searchedinvoiceDueDateFrom,
    searchedinvoiceDueDateTo: store.invoice.searchedinvoiceDueDateTo,
    searchedinvoiceType: store.invoice.searchedinvoiceType,
    searchedInvoiceNumber: store.invoice.searchedInvoiceNumber,
    searchedArticleNumber: store.invoice.searchedArticleNumber,
    searchedArticleDescription: store.invoice.searchedArticleDescription,
    invoiceSearchedPeriod: store.invoice.invoiceSearchedPeriod,
    invoiceSearchedPeriodFrom: store.invoice.invoiceSearchedPeriodFrom,
    invoiceSearchedPeriodTo: store.invoice.invoiceSearchedPeriodTo,
    selectedSecondaryAccount: store.user_options.selectedSecondaryAccount,
    selectedSoldTo: store.user_options.selectedSoldTos,
    accoutType: store.user_options.accountType,
  }),
  {},
  null,
  { pure: false }
)(_InvoiceSearch);
