import React, {Component, useState} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {resendConfirmation} from "../../../requests/UserRequests";
import SimpleHeader from "../../header/SimpleHeader";
import {Footer} from "../../footer/Footer";
import BackToLogin from "../../BackToLogin";
import { render } from 'react-dom';
import { useHistory } from "react-router";

function mapStateToProps(state) {
    return {};
}

function AutoRegisterWaitingConfirmation(props) {
    let [email, setEmail] = useState("");

    return (


            <React.Fragment>
                <SimpleHeader />
                <div className={"container mt-5"}>
                    <div className="row mt-4">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mx-auto">
                            <div className="card">
                                <div className="row">
                                    <div className={"col-12"}>
                                        <p>
                                            You have not confirmed your account yet.
                                        </p>
                                        <form>
                                            <div className={"form-group"}>
                                                <button className={"btn btn-primary buttonColor buttonsize"} onClick={e => {
                                                    e.preventDefault();
                                                    resendConfirmation(email)
                                                }}>Resend Confirmation Email
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center flex mt-3">
                        <BackToLogin />
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
   
}

export default AutoRegisterWaitingConfirmation;



