import React, {Component, useContext, useState} from 'react';
import * as yup from 'yup';
import {InvoicesContext} from "../../invoice/InvoiceDataManager";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";

const schema = yup.object().shape({
  invoiceNumber: yup.string().required("Invoice number is required."),
  invoiceAmount: yup.number().required("Invoice amount is required."),
  accountNumber: yup.string().required("Account number is required."),
});

function AutoLinkAccountForm(props) {

    const intl = useIntl();
    const f = id => intl.formatMessage({ id: id });
  let [requestComplete, setRequestComplete] = useState(false);
  const invoicesContext = useContext(InvoicesContext);

  return (
    <div className="card">
      <div className="row">
        <div className={"col-12"}>
          <p><strong>Error</strong> - {props.message}</p>
                  <button type={"submit"} className={"btn btn-primary buttonColor buttonsize"} onClick={props.onClear}>{f("register.try_again")}</button>
        </div>
      </div>
    </div>
  );
}

export default AutoLinkAccountForm;
