import React, {useState} from 'react';
import AutoLinkAccountSuccess from "./AutoLinkAccountSuccess";
import AutoLinkAccountError from "./AutoLinkAccountError";
import AutoLinkAccountForm from "./AutoLinkAccountForm";



function AutoLinkAccount(props) {
  let [status, setStatus] = useState("form");
  let [invoice, setInvoice] = useState(null);
  let [errorMessage, setErrorMessage] = useState("");

  return (
    <div>
      {status === "success" && <AutoLinkAccountSuccess invoice={invoice} onClear={() => {
        setStatus("form");
        setInvoice(null);
      }}/>}
      {status === "error" && <AutoLinkAccountError onClear={() => {
        setStatus("form");
        setInvoice(null);
      }} message={errorMessage}/>}
      {status === "form" && <AutoLinkAccountForm
        onSuccess={(invoice) => {
          setStatus("success");
          setInvoice(invoice);
          props.onAccountAdded();
        }}
        onError={(message) => {
          setStatus("error");
          setErrorMessage(message);
        }}
      />
      }
    </div>
  );
}
export default AutoLinkAccount;
