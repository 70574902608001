import React, {Component, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import ChecksList from "../ChecksList";
import CreditCardsList from "../CreditCardsList";
import classNames from "classnames";
import {FormattedMessage} from 'react-intl';
import {useCanUseEcheck} from "../../../selectors/ConfigSelectors";

export function PaymentCardLists(props) {
    const [showCards, setShowCards] = useState(true);
    const echecksAvailable = useCanUseEcheck();
    // constructor(props) {
    //   super(props);
    //   this.state = {
    //     showCards: true,
    //   };
    //   this.showCards = this.showCards.bind(this);
    //   this.showChecks = this.showChecks.bind(this);
    // }
    function toggleShowCards() {
        setShowCards(true);
        props.onTabChange('CC');
    }

    function toggleShowChecks() {
        setShowCards(false);
        props.onTabChange('EC');
    }
   
    let isPaymentDisable = (sessionStorage.getItem("isPaymentDisable")?.toString() === "true") ? true : false;
    return (<div>
        <div className={"payment-type-tab-container"}>
            <div className={classNames("payment-type-tab", {"active": showCards})} onClick={toggleShowCards}>
                <FormattedMessage id={"payment_methods.my_cards"}/></div>
            {echecksAvailable &&
            <div className={classNames("payment-type-tab", {"active": !showCards})} onClick={toggleShowChecks}>
              <FormattedMessage id={"payment_methods.my_echecks"}/></div>}
        </div>
        <div aria-disabled={isPaymentDisable} className={(isPaymentDisable) ? 'is-disabled row' : 'row'}>
            {showCards ?
                <CreditCardsList onEdit={props.onEdit} onDelete={props.onDelete} /> :
                echecksAvailable && <ChecksList onEdit={props.onEdit} onDelete={props.onDelete} />
            }
        </div>
    </div>)


}

export default connect<any, any, any, any>((state, props) => {
    return {}
})(PaymentCardLists);
