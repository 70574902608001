import React from 'react';
import { Route } from 'react-router-dom';
import DepositPaymentStatus from './DepositPaymentStatus';
import { FormattedMessage } from "react-intl";

export function DepositPaymentStatusPage() {    
    return (
        <div className="container">
            <div className="row">
                <div className={"col-12"}>
                    <Route render={({ history }) => {
                        return <button
                            type="button"
                            id="closePaymentStatus"
                            className="btn button-back d-print-none"
                           
                            onClick={() => {
                                history.push("/");
                            }}
                        >
                            {' '}

                            <i className="fas fa-angle-left" /> <FormattedMessage id={"payment_page.back_button"} />{' '}
                        </button>
                    }} />
                </div>
            </div>
            <br />
            <div className="row">
                <div className={"col-12"}>
                    <DepositPaymentStatus />
                </div>
            </div>
        </div>
    )
}