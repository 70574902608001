import React, {Component} from 'react';
import Register from "./Register";
import {completeRegistration} from "../../requests/UserRequests";

class RegisterPage extends Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {
      userNotFound: false,
      user: null
    }
  }


  componentDidMount() {
    this.props.getUserByInvite(this.props.invite).then((user) => {
      this.setState({user: user});
    }).catch(() => {
      this.setState({userNotFound: true});
    });
  }

  render() {
    return (
      <div className={"container mt-5"}>
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mx-auto">
            <div className="card">
                        {this.state.user && <Register completeRegistration={completeRegistration} inviteId={this.props.invite}/>}
              {this.state.userNotFound && <div>
                <h1>Invalid invite token.</h1> 
              </div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterPage;
