import React, { Component } from 'react';

class Statements extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
        <div className="container">
          <h1>Statements Page</h1>
        </div>
    );
  }
}
export default Statements;
