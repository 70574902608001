import axios from '../axios';
import {EmailConfig} from "../interfaces/EmailConfig";
import {get} from "../fetch";

export function getAppConfiguration() {
  return axios.get(`/api/config`).then((response) => response.data);
}

export function getConfiguration() {
  return axios.get(`/api/configuration`).then((response) => response.data);
}

export function getMaximumPaymentAllowed() {
  return axios.get(`/api/configuration/maxPaymentAllowed`).then((response) => response.data && parseFloat(response.data));
}

export function getECheckMaximumPaymentAllowed() {
  return axios.get(`/api/configuration/maxECheckPaymentAllowed`).then((response) => response.data && parseFloat(response.data));
}


export function getisPaymentDisable() {
    return axios.get(`/api/configuration/isPaymentDisabled`).then((response) => response.data);
}

export function getPaymentDisabledTranslations() {
  return axios.get(`/api/configuration/paymentDisabledMessages`).then((response) => response.data);
}

export function updateConfiguration(config) {
    return axios.put(`/api/configuration`, config).then((response) => response.data);
}

export function updateApplicationConfiguration(config, language: string) {
    return axios.put(`/api/configuration/Application/${language}`, config).then((response) => response.data);
}

export function uploadImage(file) {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  return axios.post(`/api/configuration/upload`, file, config).then((response) => response.data);
}

export function smtpTest(smtpConfig) {
  return axios.post(`/api/configuration/smtp-test`, smtpConfig).then((response) => response.data);
}

export function getEmailConfig(key: string) {
  return get(`/api/configuration/email/${key}`);
}

export function updateEmailConfig(key: string, config: EmailConfig) {
  return axios.put(`/api/configuration/email/${key}`, config).then((response) => response.data);
}


function twoDigits(text) {
  if ( text.toString().length === 1 )
    text = "0" + text;
  return text;
}

function DateUTCtoLocalText(utcText) {

  if ( utcText === null || utcText === undefined )
    return null;

  let utc = new Date(utcText + "Z");

  let localText =
    utc.getFullYear() + "-" +
    twoDigits((utc.getMonth()+1)) + "-" +
    twoDigits(utc.getDate()) + "T" + 
    twoDigits(utc.getHours()) + ":" +
    twoDigits(utc.getMinutes()) + ":" +
    twoDigits(utc.getSeconds());

  return localText;
}

function DateLocalToUtcText(localText) {

  if ( localText === null || localText === undefined )
    return null;

  let local = new Date(localText);

  let utcText =
    twoDigits(local.getUTCFullYear()) + "-" +
    twoDigits((local.getUTCMonth()+1)) + "-" +
    twoDigits(local.getUTCDate()) + "T" + 
    twoDigits(local.getUTCHours()) + ":" +
    twoDigits(local.getUTCMinutes()) + ":" +
    twoDigits(local.getUTCSeconds());

  return utcText;
}

//{
//  "isEnabled": false,
//  "fromUtcDate": "2020-01-01T00:00:00",
//  "toUtcDate": "2031-01-01T00:00:00",
//  "redirectUrl": "https://www.google.com",
//  "messages": [
//    {
//      "language": "en",
//      "message": "Enn"
//    }
//  ]
//}
export async function getBlackoutConfig() {
  try {
    const response = await axios.get(`/api/configuration/blackout`);

    if ( response.status === 200 ) {
      let config = response.data;

      config.fromDateLocal = DateUTCtoLocalText(config.fromUtcDate);
      config.toDateLocal = DateUTCtoLocalText(config.toUtcDate);

      return config;
    }
  } catch ( e ) {
  }

  return null;
}

export async function getIsBlackoutActive() {
  try {
    const response = await axios.get(`/api/configuration/isBlackoutActive`);

    if ( response.status === 200 ) {
      return response.data; // true or false
    }
  } catch ( e ) {
  }

  return false;
}

export function updateBlackoutConfig(config) {

  config.fromUtcDate = DateLocalToUtcText(config.fromDateLocal);
  config.toUtcDate = DateLocalToUtcText(config.toDateLocal);

  return axios.put(`/api/configuration/blackout`, config);
}

export function getAllowRegistration(key:string) {
    return axios.get(`/api/configuration/getallowregistration/${key}`).then((response) => response.data);
}
export function getFooterLinks() {
    return axios.get(`/api/configuration/getfooterlinks`).then((response) => response.data);
}