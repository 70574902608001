import React, {useEffect} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    getBeforeCreditTotal, getCreditTotal, getCustomerNumber, getInvoiceTotal, getOverpayingAmount,
    getPayerAccount, getPayTotal, getSelectedPaymentOption,
    getSelectedPrimaryAccount,
    getSelectedSecondaryAccount
} from "../../selectors/Selectors";
import {
    createPayerData,
    mapDepositDetails,
    mapInvoiceListToPaymentDocuments,
    mapPaymentDetails
} from "./PaymentFunctions";
import {PaymentRequest} from "../../interfaces/PaymentRequest";
import {getAndClearSessionStorage} from "../../utils";
import {
    PAYMENT_METHOD_DEFAULT,
    PAYMENT_METHOD_SAVE
} from "../payment-methods/manage-payment-methods/ManagePaymentMethods";
import {
    finalizeAddPaymentMethod,
    finalizePaymentMethodAndRefresh
} from "../payment-methods/manage-payment-methods/FinalizePaymentProcess";
import {PaymentCard} from "../../interfaces/PayerDetails";
import {getPaymentResponse} from "../../requests/TokenRequests";
import {
    isCvvRequired, isInvoiceOverpaymentAllowed, isPartialPaymentsAllowed,
    useCurrencyDecimalLookup,
    useCurrencyDecimals,
    useCurrencyKey
} from "../../selectors/ConfigSelectors";
import {useInvoiceSearchQuery} from "./InvoiceDataManager";
import { getInvoices, makeDeposit, makePayment } from "../../actions/InvoiceActions";
import * as appConstants from '../../constants/ApplicationConstants';
import {canManagePaymentMethods} from "../../selectors/PermissionSelectors";
import {DepositRequest} from "../../interfaces/DepositRequest";
import { CACHED_CVV } from "./Payment";
import { TraceLog } from '../../interfaces/TraceLog';

export function ProcessingDepositPage(props: {}) {
    const location = useLocation();
    const history = useHistory();
    const search = new URLSearchParams(location.search);
    const accessToken = search.get("id");
    const currencyKey = useCurrencyKey();
    const currencyDecimals = useCurrencyDecimals(currencyKey);
    const dispatch = useDispatch();
    const payer = useSelector<any, any>(getPayerAccount);
    const primaryAccount = useSelector<any, any>(getSelectedPrimaryAccount);
    const secondaryAccount = useSelector<any, any>(getSelectedSecondaryAccount);
    const currentUser = useSelector<any, any>(state => state.default.currentUser);
    const decimalLookup = useCurrencyDecimalLookup();
    const invoiceSearchQuery = useInvoiceSearchQuery();
    const customerNumber = useSelector(getCustomerNumber);
    const invoices = useSelector<any, any>(store => store.invoice.selected);
    const invoiceDetails = useSelector<any, any>(store => store.invoice.invoiceDetail);
    const payerDetails = useSelector<any, any>(store => store.default.payerDetails);
    const selectedPrimaryAccount = useSelector(getSelectedPrimaryAccount);
    const paymentOption = useSelector(getSelectedPaymentOption);

    useEffect(() => {
        if (accessToken) {
            (async () => {
                if (accessToken) {

                    // Get cached deposit details
                    const cvv = getAndClearSessionStorage(CACHED_CVV);
                    let cached_deposit = sessionStorage.getItem("DEPOSIT_CACHE");
                    if (!cached_deposit) {
                        return; // Hopefully should never happen.         
                    }

                    let deposit_form = JSON.parse(cached_deposit);
                    let {paymentcard, cardinal} = await getPaymentResponse(accessToken);
                    let depositDetail = mapDepositDetails(deposit_form, currencyKey);
                    let paymentDetails = mapPaymentDetails(paymentOption, cvv, payerDetails.address_data, cardinal);
                    let payerData = createPayerData(customerNumber, selectedPrimaryAccount.CompanyCode);
                    let log: TraceLog = {
                        id: "POST_DEPOSIT",
                        login: currentUser.email,
                        epayv: appConstants.APP_VERSION,
                        usrag: navigator.userAgent
                    }
                    let depositRequest: DepositRequest = {
                        deposit_detail: depositDetail,
                        payer_data: payerData,
                        payment_detail: paymentDetails,
                        log:log
                    };
                    let success: any = await dispatch(makeDeposit(depositRequest));
                    history.replace('/deposits/payment-status');
                    if (success) {
                        dispatch(getInvoices(invoiceSearchQuery, decimalLookup, ''));
                    }
                }
            })();
        } else {
            history.replace("/");
        }
    }, [accessToken]);

    return <div/>;
}