import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {Invoice} from "../interfaces/invoice";
import {User} from "../interfaces/User";

export function useGetSapAccountTypes() {
    const intl = useIntl();
    const f = id => intl.formatMessage({id: id});

    return [
        {accountTypeId: "master", accountTypeValue: f("sap_account_type.master")},
        {accountTypeId: "transactional", accountTypeValue: f("sap_account_type.transactional")},
        {accountTypeId: "both", accountTypeValue: f("sap_account_type.both")},
    ];
}

export function useUser(): User | null {
    return useSelector<any, User | null>(state => state?.default?.currentUser);
}
