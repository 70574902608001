import { Dispatch, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  getCurrentAccount,
  getSelectedPrimaryAccount,
  getSelectedSecondaryAccount,
} from "../../selectors/Selectors";
import {
  getPayerDetailsBuilder,
  setPaymentMethods,
  updatePrimaryAccount,
  updateSecondaryAccount,
} from "../../actions/InvoiceActions";
import { getPaymentMethods } from "../../requests/InvoiceRequests";
import * as React from "react";
import { AccountType } from "../../interfaces/Accounts";
import { getAndClearSessionStorage } from "../../utils";
import {
  SELECTED_PRIMARY_INDEX_KEY,
  SELECTED_SECONDARY_INDEX_KEY,
} from "../../reducers/UserOptionsReducer";

export const PayerDetailsContext = React.createContext([]);

export function reestablishSelectedValues(dispatch: Dispatch<any>) {
  const primaryAccount = getAndClearSessionStorage(SELECTED_PRIMARY_INDEX_KEY);
  if (primaryAccount !== null && primaryAccount !== "") {
    dispatch(updatePrimaryAccount(primaryAccount, false));
  }
  const secondaryAccount = getAndClearSessionStorage(
    SELECTED_SECONDARY_INDEX_KEY
  );

  if (secondaryAccount !== null) {
    dispatch(updateSecondaryAccount(secondaryAccount));
  }
}

function PayerDetailsDataManager(props) {
  const selectedPayer =
    props.accountType === AccountType.PayerAll || AccountType.Payer
      ? props.selectedSecondaryAccount
      : props.selectedPrimaryAccount;
  const prevPayer = useRef("");
  const [isReady, setIsReady] = useState(false);
  const dispatch = useDispatch();
  // This will change to be the "loader"
  // getuseraccounts

  useEffect(() => {
    // token/getuseraccounts
    // customer/getuserpayers
    // customer/payer
    // payment-methods
    (async function () {
      if (
        props.selectedPrimaryAccount !== null &&
        selectedPayer?.PrimaryAcct != prevPayer.current
      ) {
        prevPayer.current = selectedPayer?.PrimaryAcct;
        await dispatch(
          getPayerDetailsBuilder(
            props.selectedPrimaryAccount,
            props.selectedSecondaryAccount,
            props.accountType
          )
        );
        await getPaymentMethods(props.currentAccount).then(
          async (paymentMethods) => {
            await dispatch(setPaymentMethods(paymentMethods));
          }
        );
      }
      setIsReady(true);
    })();
  }, [
    props.selectedSecondaryAccount,
    props.selectedPrimaryAccount,
    props.accountType,
    props.currentAccount,
  ]);

  // TODO wait till done loading data before rendering...
  return (
    <PayerDetailsContext.Provider value={[]}>
      {isReady && props.render}
    </PayerDetailsContext.Provider>
  );
}

export default connect<any, any, any, any>((state, props) => {
  return {
    selectedPrimaryAccount: getSelectedPrimaryAccount(state),
    selectedSecondaryAccount: getSelectedSecondaryAccount(state),
    accountType: state.user_options.accountType,
    currentAccount: getCurrentAccount(state),
  };
}, {})(PayerDetailsDataManager);
