import axios from "./axios";
import {loadingDecrement, loadingIncrement} from "./actions/CommonActions";
import store from "./store";
import {refreshToken} from "./actions/UserActions";
import {clearSessionStorage} from "./reducers/RootReducer";

let _token = sessionStorage.getItem('token');

export function requestDone(response) {
    loadingDecrement(store.dispatch);
    return response;
}


export function setToken(token: string, refreshToken: string) {
    _token = token;
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('refreshToken', refreshToken);
    axios.instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export function get(url: string) {
    loadingIncrement(store.dispatch);
    return fetch(url, {
        headers: {
            'Authorization': `Bearer ${_token}`
        }
    }).then(requestDone);
}

export function post(url: string, body: string | Object) {
    loadingIncrement(store.dispatch);
    return fetch(url, {
        headers: {
            'Authorization': `Bearer ${_token}`
        },
        method: "POST",
        body: (typeof body == "string") ? body : JSON.stringify(body),
    }).then(requestDone);
}

export function put(url: string, body: string | Object) {
    loadingIncrement(store.dispatch);
    return fetch(url, {
        headers: {
            'Authorization': `Bearer ${_token}`
        },
        method: "PUT",
        body: (typeof body == "string") ? body : JSON.stringify(body),
    }).then(requestDone);
}

export function deleteRequest(url: string) {
    loadingIncrement(store.dispatch);
    return fetch(url, {
        headers: {
            'Authorization': `Bearer ${_token}`
        },
        method: "DELETE",
    }).then(requestDone);
}

export function checkStatus(statusCode: number) {
    return (response: Response) => {
        if (response.status != statusCode) {
            throw new Error("Invalid status code");
        }
        return response;
    }
}

export function parseJson(response: Response) {
    try {
        return response.json();
    } catch {
        return null;
    }
}

export function returnNull(error) {
    return null;
}
