import {createSelector} from 'reselect';

const CanManagePaymentMethods = "CanManagePaymentMethods";
const CanEditSystemConfig = "CanEditSystemConfig";
const CanViewUsers = "CanViewUsers";
const CanManageUsers = "CanManageUsers";
const CanImpersonate = "CanImpersonate";
const CanManageRegistrationRequests = "CanManageRegistrationRequests";
const CanMakePayment = "CanMakePayment";
const CanManageOwnSAPAccounts = "CanManageOwnSAPAccounts";

const getCurrentUser = state => state.default.currentUser;

export function userCan(user, permission){
  if(user === null || typeof user === "undefined"){
    return false;
  }
  return user.Permissions.indexOf(permission) !== -1;
}

function createUserCanSelector(permission){
  return createSelector([getCurrentUser], user => {
    return userCan(user, permission);
  });
}
export const canManagePaymentMethods = createUserCanSelector(CanManagePaymentMethods);
export const canEditSystemConfig = createUserCanSelector(CanEditSystemConfig);
export const canViewUsers = createUserCanSelector(CanViewUsers);
export const canManageUsers = createUserCanSelector(CanManageUsers);
export const canImpersonate = createUserCanSelector(CanImpersonate);
export const canManageRegistrationRequests = createUserCanSelector(CanManageRegistrationRequests);
export const canMakePayment = createUserCanSelector(CanMakePayment);
export const canManageOwnSAPAccounts = createUserCanSelector(CanManageOwnSAPAccounts);

