import {useSelector} from "react-redux";
import {Invoice} from "../interfaces/invoice";
import {RootState} from "../reducers/RootReducer";
import {isCredit, isOverDue} from "../common/invoiceHelpers";

export function useInvoiceTotal() {
    const selectedCurrency = useSelector<RootState, string>(state => state.default.selectedCurrencyOptionValue);
    const invoices = useSelector<RootState, Invoice[]>(state => state.default.invoices);
    return invoices.reduce((previousValue, currentValue) => {
        if (selectedCurrency === currentValue?.currency_key || !selectedCurrency) {
            return previousValue + (currentValue?.open_amount ?? 0);
        }
        return previousValue;
    }, 0);
}

export function useInvoiceTotalDue() {
    const selectedCurrency = useSelector<RootState, string>(state => state.default.selectedCurrencyOptionValue);
    const invoices = useSelector<RootState, Invoice[]>(state => state.default.invoices);
    return invoices.reduce((previousValue, currentValue) => {
        if (currentValue.item_is_a_payment != "X" && !isCredit(currentValue) && 
            (selectedCurrency === currentValue?.currency_key || !selectedCurrency)) {
            return previousValue + (currentValue?.open_amount ?? 0);
        }
        return previousValue;
    }, 0);
}

export function useInvoiceTotalCredits() {
    const selectedCurrency = useSelector<RootState, string>(state => state.default.selectedCurrencyOptionValue);
    const invoices = useSelector<RootState, Invoice[]>(state => state.default.invoices);
    return invoices.reduce((previousValue, currentValue) => {
        if (isCredit(currentValue) &&
            (selectedCurrency === currentValue?.currency_key || !selectedCurrency)) {
            return previousValue + (currentValue?.open_amount ?? 0);
        }
        return previousValue;
    }, 0);
}

export function useInvoiceTotalOverDue() {
    const selectedCurrency = useSelector<RootState, string>(state => state.default.selectedCurrencyOptionValue);
    const invoices = useSelector<RootState, Invoice[]>(state => state.default.invoices);
    return Math.abs(invoices.reduce((previousValue, currentValue) => {
        if (currentValue.item_is_a_payment != "X" && !isCredit(currentValue) &&
            (selectedCurrency === currentValue?.currency_key || !selectedCurrency)
            && isOverDue(currentValue)
        ) {
            return previousValue + (currentValue?.open_amount ?? 0);
        }
        return previousValue;
    }, 0));
}


export function useInvoiceBalance() {
    const due = useInvoiceTotalDue();
    const credit = useInvoiceTotalCredits();
    return due + credit;
}
