/**
 * This state/reducer focuses on modes that the user can change such as the soldto/payers.
 */
import * as types from '../constants/ActionTypes';
import {convertArrayToObject} from "../utils";

 export const SELECTED_SOLD_TOS_KEY = "SELECTED_SOLD_TOS_KEY";
 export const SELECTED_PRIMARY_INDEX_KEY = "SELECTED_PRIMARY_INDEX_KEY";
 export const SELECTED_SECONDARY_INDEX_KEY = "SELECTED_SECONDARY_INDEX_KEY";
 export const IMPERSONATED_USER_ID = "IMPERSONATED_USER_ID";
 export const UPDATE_IMPERSONATE_USER_NAME="UPDATE_IMPERSONATE_USER_NAME";

 export let userOptionsInitialState = 
  {
     accountType: "",
     secondaryAccounts: [],
     selectedSecondaryAccount: "",
     selectedSoldTos: [], // used for Payer Account Type
     invoiceSearchFromDate: '', //appConstants.INV_LIST_REQ_BEGIN_DATE,
     invoiceSearchToDate: '', //moment(new Date()).format('MM-DD-YYYY'),
     selectedPrimaryAccountIndex: 0,
     primaryAccounts: [] as any,
     invoiceSearchStatus: '01',
     invoiceSearchDueDateFrom: '',
     invoiceSearchDueDateTo: '',
     filter: [],
     impersonatedUserId: null,
 };

 
 export interface UserOptionsState {
     [key: string]: any;
 
     selectedSoldTos: any;
     selectedPrimaryAccountIndex: any;
     selectedSecondaryAccount: any;
 }
  
 
 export function userOptionsReducer(state = userOptionsInitialState, action): UserOptionsState {
  
     switch (action.type) {
         case types.UPDATE_SELECTED_SECONDARY_ACCOUNT: {
            sessionStorage.setItem(SELECTED_SECONDARY_INDEX_KEY, JSON.stringify(action.payload));
            return {...state, selectedSecondaryAccount: action.payload};
         }
         case types.INVOICE_SEARCH_DUE_FROM_DATE:
             return {...state, invoiceSearchDueDateFrom: action.payload};
 
         case types.INVOICE_SEARCH_DUE_TO_DATE:
             return {...state, invoiceSearchDueDateTo: action.payload};
 
         case types.SET_SELECTED_ACCOUNT: {
             let selectedSoldTos = action.payload.setSecondary ? (state.primaryAccounts[action.payload.selectedPrimaryAccountIndex]?.DropDownItems?.map(account => account.PrimaryAcct) ?? []) : [];
             
             sessionStorage.setItem(SELECTED_PRIMARY_INDEX_KEY, JSON.stringify(action.payload.selectedPrimaryAccountIndex));
             sessionStorage.setItem(SELECTED_SECONDARY_INDEX_KEY, JSON.stringify(state.primaryAccounts[action.payload.selectedPrimaryAccountIndex]?.DropDownItems[0]?.PrimaryAcct));
             return {
                 ...state,
                 selectedPrimaryAccountIndex: action.payload.selectedPrimaryAccountIndex,
                 selectedSoldTos: selectedSoldTos,
                 selectedSecondaryAccount: state.primaryAccounts[action.payload.selectedPrimaryAccountIndex]?.DropDownItems[0]?.PrimaryAcct,
                 secondaryAccounts: convertArrayToObject((state.primaryAccounts[action.payload.selectedPrimaryAccountIndex]?.DropDownItems ?? []), "PrimaryAcct"),
             };
         }

         case types.GET_PRIMARY_ACCOUNTS_SUCCESS: {
            let secondaryAccounts = convertArrayToObject(action.payload.clearSecondary ? (action.payload.accounts[0]?.DropDownItems ?? []) : state.secondaryAccounts, 'PrimaryAcct');
            let selectedPrimaryAccount = action.payload.accounts[0]?.PrimaryAcct ?? "";
            let selectedSecondaryAccount = Object.keys(secondaryAccounts)[0] ?? "";
            sessionStorage.setItem(SELECTED_PRIMARY_INDEX_KEY, JSON.stringify(selectedPrimaryAccount));
            sessionStorage.setItem(SELECTED_SECONDARY_INDEX_KEY, JSON.stringify(selectedSecondaryAccount));
            return {
                ...state,
                primaryAccounts: convertArrayToObject(action.payload.accounts, "PrimaryAcct"),
                secondaryAccounts: secondaryAccounts,
                selectedPrimaryAccountIndex: selectedPrimaryAccount,
                selectedSecondaryAccount: selectedSecondaryAccount,
            };
        }
 
         case types.UPDATE_IMPERSONATE_USER_ID: {
             if (action.payload === "") {
                 action.payload = null;
             }
             sessionStorage.setItem(IMPERSONATED_USER_ID, JSON.stringify(action.payload));
             return {
                 ...state,
                 impersonatedUserId: action.payload,
             };
         }
         case types.UPDATE_IMPERSONATE_USER_NAME: {
            if (action.payload === "") {
                action.payload = null;
            }
            sessionStorage.setItem(UPDATE_IMPERSONATE_USER_NAME, JSON.stringify(action.payload));
            return {
                ...state,
                impersonatedUserName: action.payload,
            };
        }
         case types.ADD_SOLD_TO_TO_SELECTED: {
             let newArray: any[] = state.selectedSoldTos.slice();
             newArray.push(action.payload);
             return {
                 ...state,
                 selectedSoldTos: newArray
             };
         }
 
         case types.REMOVE_SOLD_TO_FROM_SELECTED: {       
            return {
                 ...state,
                 selectedSoldTos: state.selectedSoldTos.filter(i => i !== action.payload)
             };
         }
         case types.SET_SELECTED_SOLD_TOS: {
             return {
                 ...state,
                 selectedSoldTos: action.payload
             };
         }
 
         case types.UPDATE_INVOICE_SEARCH:
             return {
                 ...state,
                 invoiceSearchStatus: action?.payload?.status ?? state.invoiceSearchStatus,
                 invoiceSearchDueDateFrom: action?.payload?.due_date_from ?? state.invoiceSearchDueDateFrom,
                 invoiceSearchDueDateTo: action?.payload?.due_date_to ?? state.invoiceSearchDueDateTo,
                 invoiceSearchFromDate: action?.payload?.date_from ?? state.invoiceSearchFromDate,
                 invoiceSearchToDate: action?.payload?.date_to ?? state.invoiceSearchToDate,
                 filter: action?.payload?.filter ?? state.filter,
             };
 
         case types.SET_ACCOUNT_TYPE:
             return {
                 ...state,
                 accountType: action.payload
             };
 
         default:
             return {...state};
     }
 }
  
