import React, { Component, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PayerSelect from "../invoice/PayerSelect";
import ManagePaymentMethods from "./manage-payment-methods/ManagePaymentMethods";
import * as appConstants from "../../constants/ApplicationConstants";
import { AccountType } from "../../interfaces/Accounts";
import { FinalizePaymentProcess } from "./manage-payment-methods/FinalizePaymentProcess";
import { FormattedMessage, useIntl } from "react-intl";
import { RootState } from '../../reducers/RootReducer';
import { getMessageForPaymentDisable } from '../../actions/CommonActions';
import { CurrencyString } from '../../common/CurrencyDisplay';
import { getMaximumPaymentAllowed,getECheckMaximumPaymentAllowed } from '../../requests/ConfigRequests';
import { useCurrencyKey } from '../../selectors/ConfigSelectors';

//const language;

const MaxPaymentMessage = (type) => {

  const intl = useIntl();
  let [maxPaymentAllowed, setmaxPaymentAllowed] = useState(0);
  let [maxECheckPaymentAllowed, setmaxECheckPaymentAllowed] = useState(0);

  let currencyKey = useSelector<RootState, string>(state => state.default.selectedCurrencyOptionValue);
  const currency_key = useCurrencyKey();
  if (currencyKey === '') {
    currencyKey = currency_key;
  }

  useEffect(() => {
    getMaximumPaymentAllowed().then(_ => setmaxPaymentAllowed(_))
    getECheckMaximumPaymentAllowed().then(_=> setmaxECheckPaymentAllowed(_))
  }, []);
  return <div className="row">
    <div className="col">
 
      {
        
        maxPaymentAllowed > 0 && type.selectedPaymentType === 'CC' &&
        <p className="text-danger"><FormattedMessage id={"payment_methods.maximum_card_payment_error"} />{' '}{CurrencyString(maxPaymentAllowed, currencyKey, intl)}</p>

      }

      {
        maxECheckPaymentAllowed > 0 && type.selectedPaymentType === 'EC' &&
        <p className="text-danger"><FormattedMessage id={"payment_methods.maximum_check_payment_error"} />{' '}{CurrencyString(maxECheckPaymentAllowed, currencyKey, intl)}</p>

      }
    </div>
  </div>
};
export class AccountPaymentPage extends Component<any, any> {

  constructor(props) {
    super(props);
    this.state = { SelectedPaymentType: 'CC' }; 
  }

  update(type) {
    this.setState({ SelectedPaymentType: type })

  }
  render() {
    const isSoldToAccount = this.props.accountType === AccountType.SoldTo;
    let isPaymentDisable = (sessionStorage.getItem("isPaymentDisable")?.toString() === "true") ? true : false;
    var paymentDisableMessage;
    var paymentDisableKey = (sessionStorage.getItem("Language")?.toString() + "_paymentDisableMessageText".trim());
    paymentDisableMessage = getMessageForPaymentDisable(paymentDisableKey);


    let MessageForPaymentDisable = sessionStorage.getItem("MessageForPaymentDisable")!?.toString();
    return <div className={'container'}>
      {isPaymentDisable && <div
        className={"col-12 col-md d-flex justify-content-center  align-items-baseline"}>
        <span className={"invoice-currency-warning"}>
          <label>{paymentDisableMessage}</label>
        </span>
      </div>}
      <br></br>
      <FinalizePaymentProcess />
      <div className="row mb-4">
        <div className="col-md-6">
          {isSoldToAccount ? <PayerSelect name={'payer_option'} /> : ''}
        </div>
      </div>
        <div className="card">
            <ManagePaymentMethods selectedType={this.state.SelectedPaymentType} updateState={this.update.bind(this)} alwaysSaveOnFile={true} />
      </div>
      {/* <MaxPaymentMessage selectedPaymentType={this.state.SelectedPaymentType} /> */}

    </div>
  }
}



export default connect<any, any, any, any>((state, props) => {
  return {
    accountType: state.user_options.accountType,
  }
})(AccountPaymentPage);
