import axios from "../axios";

export function submitRegistration(registration) {
  return axios.post(`/api/register`, registration).then((response) => {
    return response.data;
  });
}

export function getRegistrationRequests(registration) {
  return axios.get(`/api/register`).then((response) => {
    return response.data;
  });
}

export function getRegistrationRequestById(id) {
  return axios.get(`/api/register/${id}`).then((response) => {
    return response.data;
  });
}

export function markRegistrationCompleted(id) {
  return axios.post(`/api/register/${id}/completed`, {}).then((response) => {
    return response.data;
  });
}
