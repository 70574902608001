import { createSelector } from "reselect";
import * as appConstants from "../constants/ApplicationConstants";

import moment from "moment";
import { AccountType } from "../interfaces/Accounts";
import { InvoiceSearchParameters } from "../interfaces/invoice";

const getSelectedInvoices = (state) => state.invoice.selected;
const getLineItems = (state) => state.invoice.invoiceDetail;
const getPaymentOptions = (state) => state.default.payerDetails.payment_cards;
const getSelectedPaymentOptionIndex = (state) => state.default.selectedCard;
const getCurrentUser = (state) => state.default.currentUser;
const getAccountType = (state) => state.user_options.accountType;
//Added
const getSoldToSearchParams = (state) => state.user_options.SoldTo_SearchParams;

const getSelectedSecondaryAccountIndex = (state) =>
  state.user_options.selectedSecondaryAccount;
const getSelectedPrimaryAccountIndex = (state) =>
  state.user_options.selectedPrimaryAccountIndex;
const getPrimaryAccounts = (state) => state.user_options.primaryAccounts;
const getSecondaryAccounts = (state) => state.user_options.secondaryAccounts;
const getInvoiceSearchToDate = (state) =>
  state.user_options.invoiceSearchToDate;
const getInvoiceSearchFromDate = (state) =>
  state.user_options.invoiceSearchFromDate;
const getInvoiceSearchStatus = (state) =>
  state.user_options.invoiceSearchStatus;
const getInvoiceSearchDueDateFrom = (state) =>
  state.user_options.invoiceSearchDueDateFrom;
const getInvoiceSearchDueDateTo = (state) =>
  state.user_options.invoiceSearchDueDateTo;
const getInvoiceSearchFilter = (state) => state.user_options.filter;
const _getImpersonatedUserId = (state) => state.user_options.impersonatedUserId;
const getCnbsConfig = (state) => state.app.config.sap?.data;
const getCurrency = (state) => state.default.selectedCurrencyOptionValue;

export const getImpersonatedUserId = createSelector(
  [getCurrentUser, _getImpersonatedUserId],
  (user, impersonatedUserId) => {
    if (
      impersonatedUserId === null ||
      typeof impersonatedUserId === "undefined"
    ) {
      return user.user_id;
    }
    return impersonatedUserId;
  }
);

export const getInvoiceTotal = createSelector(
  [getSelectedInvoices],
  (items) => {
    let total = Object.keys(items)
      .map((key) => items[key])
      .reduce((total, item) => {
        total += item.open_amount;
        return Math.round(total * 100) / 100;
      }, 0);
    return total.toFixed(2);
  }
);

export const getSelectedPrimaryAccount = createSelector(
  [getSelectedPrimaryAccountIndex, getPrimaryAccounts],
  (index, accounts) => {
    if (typeof accounts === "undefined" || accounts.length === 0) {
      return null;
    }
    return accounts[index];
  }
);

export const getSelectedSecondaryAccount = createSelector(
  [getSelectedSecondaryAccountIndex, getSecondaryAccounts],
  (index, accounts) => {
    if (
      typeof accounts === "undefined" ||
      accounts == null ||
      accounts.length === 0
    ) {
      return null;
    }
    return accounts[index];
  }
);

export function handleDeleteIframe() {
  var frame = document.querySelector("#cardinal_iframe_post")!;
  if (frame !== null) {
    frame.remove();
  }
}

export const getPayTotal = createSelector([getLineItems], (items) => {
  let total = Object.keys(items)
    .map((key) => items[key])
    .reduce((total, item) => {
      total += parseFloat(item.amount);
      return Math.round(parseFloat(total) * 100) / 100;
    }, 0);
  return total.toFixed(2);
});

// Represents the amount the will be paid with credit card or EC.
export const getBeforeCreditTotal = createSelector(
  [getLineItems, getSelectedInvoices],
  (items, selected) => {
    return Object.keys(items)
      .map((key) => {
        return { details: items[key], invoice: selected[key] };
      })
      .reduce((total, item) => {
        if (item.invoice.open_amount >= 0) {
          total += item.details.amount;
        }
        return total;
      }, 0);
  }
);

export const getCreditTotal = createSelector(
  [getLineItems, getSelectedInvoices],
  (items, selected) => {
    return Object.keys(items)
      .map((key) => {
        return { details: items[key], invoice: selected[key] };
      })
      .reduce((total, item) => {
        if (item.invoice.open_amount < 0) {
          total += item.details.amount;
        }
        return total;
      }, 0);
  }
);

export const getSelectedInvoiceKeys = createSelector(
  [getSelectedInvoices],
  (items) => {
    return Object.keys(items);
  }
);

export const getOverpayingAmount = createSelector(
  [getInvoiceTotal, getPayTotal],
  (total, payTotal) => {
    return (
      Math.round((parseFloat(payTotal) - parseFloat(total)) * 100) / 100
    ).toFixed(2);
  }
);

export const getPayerAccount = createSelector(
  [getSelectedPrimaryAccount, getSelectedSecondaryAccount, getAccountType],
  (primary, secondary, accountType) => {
    if (accountType === AccountType.SoldTo) {
      return secondary;
    } else {
      return primary;
    }
  }
);

export const getAllPaymentOptions = createSelector(
  [
    getPaymentOptions,
    (state) => state.payment.sessionCards,
    (state) => state.payment.sessionChecks,
    getPayerAccount,
  ],
  (options, sessionCards, sessionChecks, payerAccount) => {
    let newCardData = options.slice(0);
    newCardData.forEach((c) => (c.is_session = false));

    if (payerAccount) {
      let account = payerAccount.PrimaryAcct;
      for (let prop in sessionCards[account]) {
        if (sessionCards[account].hasOwnProperty(prop)) {
          let card = sessionCards[account][prop];
          card.is_session = true;
          newCardData.push(sessionCards[account][prop]);
        }
      }

      for (let prop in sessionChecks[account]) {
        if (sessionChecks[account].hasOwnProperty(prop)) {
          let check = sessionChecks[account][prop];
          check.is_session = true;
          newCardData.push(sessionChecks[account][prop]);
        }
      }
    }

    return newCardData;
  }
);

export const getSelectedPaymentOption = createSelector(
  [getAllPaymentOptions, getSelectedPaymentOptionIndex],
  (options, selectedIndex) => {
    if (selectedIndex === null || selectedIndex == "") {
      return null;
    }
    let selected = options.find((p) => selectedIndex === p.payment_card_token);
    if (selected) {
      return selected;
    }
    return null;
  }
);

export const getCustomerNumber = createSelector(
  [getAccountType, getSelectedSecondaryAccount, getSelectedPrimaryAccount],
  (accountType, selectedSecondaryAccount, selectedPrimaryAccount) => {
    if (accountType === AccountType.SoldTo) {
      return selectedSecondaryAccount?.PrimaryAcct;
    } else {
      return selectedPrimaryAccount?.PrimaryAcct;
    }
  }
);

export const getCompanyCode = createSelector(
  [getAccountType, getSelectedSecondaryAccount, getSelectedPrimaryAccount],
  (accountType, selectedSecondaryAccount, selectedPrimaryAccount) => {
    if (accountType === AccountType.SoldTo) {
      return selectedSecondaryAccount?.CompanyCode;
    } else {
      return selectedPrimaryAccount?.CompanyCode;
    }
  }
);

//TODO move out of selectors.
export function getPayerSoldToForInvoiceQuery(
  accountType,
  secondaryAccount,
  primaryAccount
) {
  let payer_data;
  let soldto_data: any = { customer_number: "", company_code: "" };
  if (typeof primaryAccount == "undefined" || primaryAccount == null) {
    return { payer_data, soldto_data };
  }
  if (accountType === AccountType.SoldTo) {
    payer_data = {
      customer_number: secondaryAccount?.PrimaryAcct,
      company_code: primaryAccount?.CompanyCode,
    };
    soldto_data = {
      customer_number: primaryAccount?.PrimaryAcct,
      accounts: primaryAccount?.TransactionalAccounts,
      company_code: "",
    };
  } else {
    payer_data = {
      customer_number: primaryAccount?.PrimaryAcct ?? "",
      company_code: primaryAccount?.CompanyCode ?? "",
    };
    //soldto_data = {
    //    customer_number: secondaryAccount?.PrimaryAcct,
    //    accounts: primaryAccount?.TransactionalAccounts,
    //    company_code: "",
    //};
    soldto_data = null;
  }
  return { payer_data, soldto_data };
}

export function getSoldTo(accountType, secondaryAccount, primaryAccount) {
  let soldto_data: any = { customer_number: "", company_code: "" };
  if (typeof primaryAccount == "undefined" || primaryAccount == null) {
    return soldto_data;
  }
  if (accountType === AccountType.SoldTo) {
    soldto_data = {
      customer_number: primaryAccount?.PrimaryAcct,
      accounts: primaryAccount?.TransactionalAccounts,
      company_code: "",
    };
  } else {
    soldto_data = null;
  }
  return soldto_data;
}

export function getDefaultFilters(accountType, impersonatedUserId) {
  let defaultFilters: any[] = [];
  if (accountType === AccountType.PayerAll) {
    defaultFilters.push({
      filter_type: "90",
      value: impersonatedUserId,
    });
  }
  return defaultFilters;
}

export const getInvoiceQuery = createSelector(
  [
    getAccountType,
    getSoldToSearchParams,
    getSelectedPrimaryAccount,
    getSelectedSecondaryAccount,
    getInvoiceSearchFromDate,
    getInvoiceSearchToDate,
    getInvoiceSearchStatus,
    getInvoiceSearchDueDateFrom,
    getInvoiceSearchDueDateTo,
    getInvoiceSearchFilter,
    getImpersonatedUserId,
  ],
  (
    accountType,
    SoldToSearchParams,
    primaryAccount,
    secondaryAccount,
    fromDate,
    toDate,
    status,
    dueDateFrom,
    dueDateTo,
    filter,
    impersonatedUserId
  ) => {
    try {
      let { payer_data, soldto_data } = getPayerSoldToForInvoiceQuery(
        accountType,
        secondaryAccount,
        primaryAccount
      );
      return {
        payer_data,
        soldto_data,
        search_parameters: {
          document_type: "01",
          status: status,
          SoldTo_SearchParams: SoldToSearchParams,
          date_from:
            fromDate === "" ? appConstants.INV_LIST_REQ_BEGIN_DATE : fromDate,
          date_to:
            toDate === "" ? moment(new Date()).format("MM-DD-YYYY") : toDate,
          due_date_from: dueDateFrom,
          due_date_to: dueDateTo,
          currency_key: "",
          filter: getDefaultFilters(accountType, impersonatedUserId).concat(
            filter
          ),
        },
      } as {
        payer_data: any;
        soldto_data: any;
        search_parameters: InvoiceSearchParameters;
      };
    } catch (e) {
      return null;
    }
  }
);

export const getInvoiceQueryforPDFandCSVandexcel = createSelector(
  [
    getAccountType,
    getSelectedPrimaryAccount,
    getSelectedSecondaryAccount,
    getInvoiceSearchFromDate,
    getInvoiceSearchToDate,
    getInvoiceSearchStatus,
    getInvoiceSearchDueDateFrom,
    getInvoiceSearchDueDateTo,
    getInvoiceSearchFilter,
    getImpersonatedUserId,
    getCurrency,
  ],
  (
    accountType,
    primaryAccount,
    secondaryAccount,
    fromDate,
    toDate,
    status,
    // dueDateFrom,
    //dueDateTo,
    //filter,
    impersonatedUserId,
    currency
  ) => {
    try {
      let { payer_data, soldto_data } = getPayerSoldToForInvoiceQuery(
        accountType,
        secondaryAccount,
        primaryAccount
      );
      return {
        payer_data,
        soldto_data,
        search_parameters: {
          document_type: "01",
          status: status,
          date_from:
            fromDate === "" ? appConstants.INV_LIST_REQ_BEGIN_DATE : fromDate,
          date_to:
            toDate === "" ? moment(new Date()).format("MM-DD-YYYY") : toDate,
          //due_date_from: dueDateFrom,
          //due_date_to: dueDateTo,
          //currency_key: currency,
          //filter: getDefaultFilters(accountType, impersonatedUserId).concat(filter),
        },
      } as {
        payer_data: any;
        soldto_data: any;
        search_parameters: InvoiceSearchParameters;
      };
    } catch (e) {
      return null;
    }
  }
);
export const getStatementQueryforPDFandCSVandexcel = createSelector(
    [
        getAccountType,
        getSelectedPrimaryAccount,
        getSelectedSecondaryAccount,
        getInvoiceSearchFromDate,
        getInvoiceSearchToDate,
        getInvoiceSearchStatus,
        getInvoiceSearchDueDateFrom,
        getInvoiceSearchDueDateTo,
        getInvoiceSearchFilter,
        getImpersonatedUserId,
        getCurrency,
    ],
    (
        accountType,
        primaryAccount,
        secondaryAccount,
        fromDate,
        toDate,
        status,
        // dueDateFrom,
        //dueDateTo,
        //filter,
        impersonatedUserId,
        currency
    ) => {
        try {
            let { payer_data, soldto_data } = getPayerSoldToForInvoiceQuery(
                accountType,
                secondaryAccount,
                primaryAccount
            );
            return {
                payer_data,
                soldto_data,
                search_parameters: {
                    document_type: "01",
                    status: status,
                    date_from:
                        fromDate === "" ? appConstants.INV_LIST_REQ_BEGIN_DATE : fromDate,
                    date_to:
                        toDate === "" ? moment(new Date()).format("MM-DD-YYYY") : toDate,
                    //due_date_from: dueDateFrom,
                    //due_date_to: dueDateTo,
                    //currency_key: currency,
                    //filter: getDefaultFilters(accountType, impersonatedUserId).concat(filter),
                },
            } as {
                payer_data: any;
                soldto_data: any;
                search_parameters: InvoiceSearchParameters;
            };
        } catch (e) {
            return null;
        }
    }
);

export const getDepositReasonCodes = createSelector(
  [getCnbsConfig, getPayerAccount],
  (config, payerAccount) => {
    const payerCompanyCode =
      payerAccount && payerAccount.CompanyCode ? payerAccount.CompanyCode : "";
    if (config && config.payment_reason_codes) {
      return (config?.payment_reason_codes ?? [])
        .filter(
          (obj) =>
            obj.company_code === payerCompanyCode && obj.payment_type === "D"
        )
        .map((code) => {
          return {
            value: code.reason_code,
            label: code.description,
            commentsRequired: code.comments_required,
            clearableValue: false,
          };
        });
    }
    return [];
  }
);

export const getPayerAccounts = createSelector(
  [getPrimaryAccounts, getSecondaryAccounts, getAccountType],
  (primaryAccounts, secondaryAccounts, accountType) => {
    if (accountType === AccountType.SoldTo) {
      return secondaryAccounts;
    } else {
      return primaryAccounts;
    }
  }
);

export const hasDeposits = createSelector([getCnbsConfig], (config) => {
  try {
    return (
      typeof config &&
      config.payment_types &&
      config.payment_types.find((t) => t.payment_type === "D") !==
        "undefined" &&
      config.payment_types.find((t) => t.payment_type === "D").active === "X"
    );
  } catch (e) {
    return false;
  }
});

export const getCurrentAccount = createSelector(
  [getSelectedPrimaryAccount, getSelectedSecondaryAccount, getAccountType],
  (primary, secondary, accountType) => {
    let request;
    if (accountType === AccountType.SoldTo) {
      // Use Secondary Account
      request = {
        company_code: primary && primary.company_code,

        sales_organization: secondary && secondary.sales_organization,
        distribution_channel: secondary && secondary.distribution_channel,
        division: secondary && secondary.division,
      };
    } else {
      // Use Primary Account
      request = {
        company_code: primary && primary.company_code,
        sales_organization: primary && primary.sales_organization,
        distribution_channel: primary && primary.distribution_channel,
        division: primary && primary.division,
      };
    }
    return request;
  }
);
