import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getChecksSelector} from "./PaymentCardsSelectors";
import PaymentTypeDetails from "./PaymentTypeDetails";
import {FormattedMessage} from 'react-intl';

export class ChecksList extends Component<any, any> {

  render() {

    return <div>{
      this.props.checks.map((check, index) => {
        return <div key={index}><PaymentTypeDetails payment_card={check} onEdit={this.props.onEdit} onDelete={this.props.onDelete}/></div>
      })
    }{
      this.props.checks.length === 0 && <span><FormattedMessage id={"payment_methods.checks_empty"}/></span>
    }</div>
  }
}

export default connect<any, any, any, any>((state, props) => {
  return {
    checks: getChecksSelector(state),
  }
})(ChecksList);
