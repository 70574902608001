import axios from 'axios';
import store from './store';
import {loadingDecrement, loadingIncrement} from "./actions/CommonActions";
import {setAuthStatus} from "./actions/UserActions";
import {AuthStatus} from "./interfaces/AuthStatus";

const instance = axios.create({});
let token = sessionStorage.getItem('token');
instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

function handleErrors(e, useLoader = true) {
    if (e.response.status === 401) {
        if (e.response.data && e.response.data.reason === 'not_logged_in') {
            store.dispatch(setAuthStatus(AuthStatus.Unauthenticated));
        }
    }
    if(useLoader){
        loadingDecrement(store.dispatch);
    }
}

class EpayAxios {
    constructor(public instance: any) {

    }

    get<T = any>(url, config: any = {}) {
        loadingIncrement(store.dispatch);
        return axios.get<T>(url, config).then(response => {
            loadingDecrement(store.dispatch);
            return response;
        }).catch((e) => {
            handleErrors(e);
            throw e;
        });
    }

    post<T = any>(url, data, config: any = {}, useLoader=true) {
        if(useLoader){
            loadingIncrement(store.dispatch);
        }
        return axios.post<T>(url, data, config).then(response => {
            if(useLoader){
                loadingDecrement(store.dispatch);
            }
            return response;
        }).catch((e) => {
            handleErrors(e, useLoader);
            throw e;
        });
    }

    put<T = any>(url, data) {
        loadingIncrement(store.dispatch);
        return axios.put<T>(url, data).then(response => {
            loadingDecrement(store.dispatch);
            return response;
        }).catch((e) => {
            handleErrors(e);
            throw e;
        });
    }

    delete(url, config: any = {}) {
        loadingIncrement(store.dispatch);
        return axios.delete(url, config).then(response => {
            loadingDecrement(store.dispatch);
            return response;
        }).catch((e) => {
            handleErrors(e);
            throw e;
        });
    }

}

export default new EpayAxios(instance);
