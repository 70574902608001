import React, {Component, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import RegisterForm from "./RegisterForm";
import AutoLinkAccount from "./AutoLinkAccount";
import PromiseComponent from "../../../common/PromiseComponent";
import {Link, withRouter} from "react-router-dom";
import {confirmAccount} from "../../../requests/UserRequests";

function mapStateToProps(state) {
  return {};
}

function AutoRegisterConfirm(props) {
  let [confirm, setConfirm] = useState<any>(null);
  useEffect(() => {
    setConfirm(confirmAccount(props.match.params.token));
  }, []);

  return (
    <div className={"container mt-5"}>
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mx-auto">
                  <PromiseComponent promise={confirm}
                      then={() => (<div className={"card"}>
                          <div className="row">
                              <div className={"col-12"}>
                                  <p>Successfully confirmed your account.</p>
                                  <Link className={"btn btn-primary buttonColor buttonsize"}
                                      to={"/login"}>Continue to Login</Link>
                              </div>
                          </div>
                      </div>)}
                      catch={(resp) => {
                          const message = resp?.response?.data ?? "Could not validate account."
                          return(
                          <div className={"card"}>
                              <div className="row">
                                  <div className={"col-12"}>
                                          <p>{message}</p>
                                  </div>
                              </div>
                          </div>
                          )
                      }
                      }
                      wait={() => (<div></div>)}
          />
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect<any, any, any, any>(
  mapStateToProps,
)(AutoRegisterConfirm));