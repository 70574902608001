import React from 'react';

const RegistrationRequestDetails = props => {
  return (
    <div className={"row"}>
      <div className={"col-12"}>
        <div>
          <strong>Name:</strong> {props.request.first_name} {props.request.last_name}
        </div>
        <div>
          <strong>Company:</strong> {props.request.company}
        </div>
        <div>
          <strong>Email:</strong> {props.request.email}
        </div>
        <div>
          <strong>Message:</strong> {props.request.message}
        </div>
      </div>  
    </div>
  );
};


export default RegistrationRequestDetails;
