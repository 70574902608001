import React, {useEffect, useState} from 'react';

function PromiseComponent<T>(props: PromiseComponentProps<T>) {

  let [status, setStatus] = useState("waiting");
  let [value, setValue] = useState(null);

  useEffect(() => {
    if (props.promise) {
      setStatus("waiting");
      props.promise.then((result) => {
        setStatus("complete");
        setValue(value);
        return result;
      }).catch((error) => {
        setStatus("error");
        setValue(error);
      });
    }
  }, [
    props.promise
  ]);


  return (
    <React.Fragment>
      {status === "complete" && props.then(value)}
      {status === "error" && props.catch && props.catch(value)}
      {status === "waiting" && props.wait && props.wait(value)}
    </React.Fragment>
  );
}

interface PromiseComponentProps<T> {
  promise: Promise<T>,
  then: (T) => any,
  catch: (T) => any,
  wait: (T) => any,
}


export default PromiseComponent;