/* eslint-env browser */
/* eslint-disable no-console */
import axios from "../axios";
import { toast } from "react-toastify";
import * as validationTypes from "../constants/ValidationConstants";
import * as apiTypes from "../constants/ApiConstants";
import * as types from "../constants/ActionTypes";
import * as appConstants from "../constants/ApplicationConstants";
import { updateState, requestErrored, loginFailed } from "./CommonActions";
import { setSelectedSoldTos } from "./InvoiceActions";
import { ReduxAction } from "../interfaces/utils";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { AuthStatus } from "../interfaces/AuthStatus";
import { setToken } from "../fetch";
import { reestablishSelectedValues } from "../components/invoice/PayerDetailsDataManager";
import { clearSessionStorage } from "../reducers/RootReducer";
import { getPaymentDisabledTranslations } from "../requests/ConfigRequests";
import { setMessageForPaymentDisable } from "../actions/CommonActions";
import { useState } from "react";

export function setBlackoutConfig(blackoutConfig): ReduxAction<any> {
  return {
    type: types.SET_BLACKOUT_CONFIG,
    payload: blackoutConfig,
  };
}

export function setIsBlackoutActive(isBlackoutActive): ReduxAction<boolean> {
  return {
    type: types.SET_IS_BLACKOUT_ACTIVE,
    payload: isBlackoutActive,
  };
}

export function setAuthStatus(status: AuthStatus): ReduxAction<AuthStatus> {
  return {
    type: types.SET_AUTH_STATUS,
    payload: status,
  };
}

export function setLastUrl(url): ReduxAction<any> {
  return {
    type: types.SET_LAST_URL,
    payload: url,
  };
}

export function setLanguage(language): ReduxAction<any> {
  return {
    type: types.SET_LANGUAGE,
    payload: language,
  };
}

export function login(email: string, password: string) {
  return (dispatch) => {
    const loginRequest = { login: email, password: password };
    return axios
      .post(apiTypes.LOGIN_URL, loginRequest)
      .then((response) => {
        if (response.status === 200) {
          //store token in session storage.
          clearSessionStorage();
          setToken(response.data.token, response.data.refreshToken);
          dispatch(setAuthStatus(AuthStatus.Authenticated));
          getPaymentDisabledTranslations().then((value) => {
            setMessageForPaymentDisable(value);
          });
        } else {
          toast.error(validationTypes.CREDENTIALS_INVALID, {
            position: toast.POSITION.TOP_RIGHT,
          });
          dispatch(setAuthStatus(AuthStatus.Failed));
        }
      })
      .catch((error) => {
        // requestErrored(dispatch, error, validationTypes.AUTHENTICATION_ERROR);
        if (error && error.response && error.response.status === 401) {
          loginFailed(dispatch, error);
        } else {
          requestErrored(dispatch, error, validationTypes.AUTHENTICATION_ERROR);
          throw error.response.statusText;
        }
      });
  };
}

export function loginSSO(token: string) {
    return (dispatch) => {
        return axios
            .get(`${apiTypes.LOGIN_SSO_URL}token=${token}`)
            .then((response) => {
                if (response.status === 200) {
                    //store token in session storage.
                    clearSessionStorage();
                    setToken(response.data.token, response.data.refreshToken);
                    dispatch(setAuthStatus(AuthStatus.Authenticated));
                    getPaymentDisabledTranslations().then((value) => {
                        setMessageForPaymentDisable(value);
                    });
                } else {
                    toast.error(validationTypes.CREDENTIALS_INVALID, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    dispatch(setAuthStatus(AuthStatus.Failed));
                }
            })
            .catch((error) => {
                // requestErrored(dispatch, error, validationTypes.AUTHENTICATION_ERROR);
                if (error && error.response && error.response.status === 401) {
                    loginFailed(dispatch, error);
                } else {
                    requestErrored(dispatch, error, validationTypes.AUTHENTICATION_ERROR);
                    throw error.response.statusText;
                }
            });
    };
}

export function refreshToken() {
  return (dispatch) => {
    let token = sessionStorage.getItem("refreshToken");
    const requestBody = { refreshToken: token };
    axios
      .post(apiTypes.REFRESH_TOKEN_URL, requestBody, {}, false)
      .then((response) => {
        if (response.status === 200) {
          //store token in session storage.
          setToken(response.data.token, response.data.refreshToken);
        }
      })
      .catch((error) => {
        // requestErrored(dispatch, error, validationTypes.AUTHENTICATION_ERROR);
        if (error && error.response && error.response.status === 401) {
          loginFailed(dispatch, error);
        } else {
          requestErrored(dispatch, error, validationTypes.AUTHENTICATION_ERROR);
        }
      });
  };
}

export function getUser() {
  return (dispatch) => {
    const finalGetUser = `${apiTypes.GET_USER_URL}`;
    return axios
      .post(finalGetUser, "")
      .then((userData) => {
        if (userData.status === 200 && userData.data) {
          if (userData.data && userData.data) {
            updateState(dispatch, types.GET_USER_SUCCESS, userData.data);
            updateState(
              dispatch,
              types.SET_ACCOUNT_TYPE,
              userData.data.primary_account_type_id
            );
          }
          return userData.data;
        } else {
          return null;
        }
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          requestErrored(dispatch, err, validationTypes.CREDENTIALS_INVALID);
        } else {
          requestErrored(dispatch, err, validationTypes.GET_USER_ERROR);
        }
      });
  };
}

/**
 * Dispatches HTTP request to get users Accounts. These accounts show up on the header of the application.
 * @param dispatch
 * @param userId
 * @param clearSecondary
 */
export function getUserCustomers(userId: string, clearSecondary: boolean) {
  return (dispatch) => {
    let finalGetUserPayerUrl = `${apiTypes.USER_HEADER_ACCOUNTS}`;
    if (userId) {
      finalGetUserPayerUrl = `${finalGetUserPayerUrl}${"userid="}${userId}`;
    }
    return axios
      .post(finalGetUserPayerUrl, {})
      .then((userData) => {
        if (
          userData.status === 200 &&
          userData.data &&
          userData.data.HeaderItems
        ) {
          updateState(dispatch, types.GET_PRIMARY_ACCOUNTS_SUCCESS, {
            accounts: userData.data.HeaderItems,
            clearSecondary,
          });
          if (clearSecondary) {
            dispatch(
              setSelectedSoldTos(
                userData.data.HeaderItems[0]?.DropDownItems?.map(
                  (account) => account.PrimaryAcct
                ) ?? []
              )
            );
          }
          if (typeof userId !== "undefined") {
            updateState(dispatch, types.UPDATE_IMPERSONATE_USER_ID, userId);
          }
          reestablishSelectedValues(dispatch);
        }
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          requestErrored(dispatch, err, validationTypes.CREDENTIALS_INVALID);
          } else if (err.response === undefined) {
              toast.error("There was an error registering your account. Please contact customer service.", { position: toast.POSITION.TOP_RIGHT });
          }
          else {
              requestErrored(dispatch, err, validationTypes.GET_USER_PAYER_ERROR);
          }
      });
  };
}

export function Logout(): ThunkAction<Promise<void>, {}, {}, AnyAction> {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<any> => {
    return axios
      .post(apiTypes.LOGOUT_URL, {})
      .then((response) => {
        dispatch({ type: types.LOGOUT });
      })
      .catch((error) => {
        requestErrored(dispatch, error, validationTypes.AUTHENTICATION_ERROR);
      });
  };
}

export function ResetPassword(
  currentPassword: string,
  newPassword: string,
  confirmNewPassword: string
) {
  return (dispatch) => {
    const resetPasswordRequest = {
      CurrentPassword: currentPassword,
      NewPassword: newPassword,
      ConfirmNewPassword: confirmNewPassword,
    };
    axios
      .post(apiTypes.RESET_PASSWORD, resetPasswordRequest)
      .then((userData) => {
        if (
          userData.status === 200 &&
          userData.data.message === appConstants.SUCCESS
        ) {
          toast.success(validationTypes.RESET_PASSWORD_SUCCESS, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState(
            dispatch,
            types.UPDATE_PASSWORD_RESPONSE,
            userData.data.message
          );
        } else {
          toast.warn(userData.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState(
            dispatch,
            types.UPDATE_PASSWORD_RESPONSE,
            userData.data.message
          );
        }
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          requestErrored(dispatch, err, validationTypes.CREDENTIALS_INVALID);
        } else {
          requestErrored(dispatch, err, validationTypes.RESET_PASSWORD_ERROR);
        }
      });
  };
}

export function setAccountType(accountType): ReduxAction<any> {
  return {
    type: types.SET_ACCOUNT_TYPE,
    payload: accountType,
  };
}

export function setFooterLinks(footerLinks): ReduxAction<any> {
  return {
    type: types.SET_FOOTER_LINKS,
    payload: footerLinks,
  };
}
