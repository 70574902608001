import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { Formik } from "formik";
import AceEditor from "react-ace";
import { toast } from 'react-toastify';
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-xcode";
import { getEmailConfig, updateEmailConfig } from "../../../../requests/ConfigRequests";
import { createEmailConfig, EmailConfig, EmailTemplate } from "../../../../interfaces/EmailConfig";
import { checkStatus, parseJson, returnNull } from "../../../../fetch";
import { useIntl, FormattedMessage, IntlShape } from "react-intl";
import { BootstrapField, BootstrapError } from "../../../../helpers/Field";
import { getConfiguration, smtpTest, updateConfiguration } from "../../../../requests/ConfigRequests";

//ReactSession.setStoreType("localStorage");

interface EmailTypes {
    key: string;
    label: string;
}
//constructor(){
//    super();
//    this.state = { name: '' };
//}

const schema = (intl: IntlShape) => {
    return yup.object().shape({
        smtpAddress: yup.string().required(),
        smtpPort: yup.number().required(),
        smtpUseUser: yup.boolean(),
        isPaymentsDisable: yup.boolean(),
        registrationRequestEmail: yup.string().email(),
        overrideEmail: yup.string().email(),
        emailTemplateBody: yup.string(),
        maxPaymentAllowed: yup.string(),
        emailConfirmationContent: yup.string(),
        fromAddress: yup.string().email(),
        fromAddressName: yup.string(),
        smtpUser: yup.string().when('smtpUseUser', {
            is: true,
            then: yup.string().required(),
        }),
        smtpPassword: yup.string().when('smtpUseUser', {
            is: true,
            then: yup.string(),
        })
    });
};
export function EmailConfiguration(props: {}) {
    //state = { Template: '' };
    let [config, setConfig] = useState(null);
    const [currentEmailConfig, setCurrentEmailConfig] = useState<EmailConfig>();
    const [selectedKey, setSelectedKey] = useState("welcome_email_content");
    const [selectedLanguage, setSelectedLanguage] = useState("en");
    const [emailTemplate, setEmailTemplate] = useState<EmailTemplate>({ email: "", language: "", title: "", template: "" });
    let [testEmail, setTestEmail] = useState("");
    const intl = useIntl();
    const f = id => intl.formatMessage({ id: id });
    const emailTypes: EmailTypes[] = [
        //{key: "registration_request_email", label: "Registration Request Email"},
        //{key: "registration_requestor_email", label: "Registration Requestor Email"},
        { key: "welcome_email_content", label: "Welcome Email" },
        { key: "confirmation_email_content", label: "Confirmation Email" },
        { key: "reset_password_email_content", label: "Password Reset Email" },
        { key: "password_changed_email_content", label: "Password Changed Email" },
        { key: "invoice_receipt_content", label: "Payment Confirmation Email" },
    ];

    const languages = [
        { key: "en", label: "English" },
        { key: "fr", label: "French" },
        { key: "de", label: "German" },
        { key: "es", label: "Spanish" },
        { key: "it", label: "Italian" },
        { key: "ja", label: "Japanese" },
        { key: "pt", label: "Portuguese" },
        { key: "ru", label: "Russian" },
    ];

    let fetchData = async () => {
        let config: EmailConfig | null = await getEmailConfig(selectedKey)
            .then(checkStatus(200))
            .then(parseJson)
            .catch(returnNull);
        if (config == null) {
            setCurrentEmailConfig(createEmailConfig(selectedKey));
            createNewTemplate();
        } else {
            setCurrentEmailConfig(config);
            if (config.templates[selectedLanguage]) {
                //setEmailTemplate(this.state.Template);
                //emailTemplate = this.state.Template;
                setEmailTemplate(config.templates[selectedLanguage]);

                // this.setState({ Template: [config.templates[selectedLanguage] })
                //this.state = { Template: [config.templates[selectedLanguage]]};
            } else {
                createNewTemplate();
            }
        }
    };

    function createNewTemplate() {
        setEmailTemplate({ title: "", language: selectedLanguage, email: "", template: "" });
    }

    useEffect(() => {
        fetchData();
    }, [selectedKey, selectedLanguage]);

    useEffect(() => {
        getConfiguration().then(_ => setConfig(_));
    }, []);

    function handleEditorChange(content: string, editor) {
        setEmailTemplate({
            ...emailTemplate,
            email: content
        })
    }

    function handleTitleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setEmailTemplate({
            ...emailTemplate,
            title: e.target.value
        })
    }
    function saveEmail(values) {
        if (currentEmailConfig) {
            sessionStorage.setItem("Template", emailTemplate.email.toString());
            currentEmailConfig.templates[selectedLanguage] = emailTemplate;
            updateConfiguration(values).then(() => {
                updateEmailConfig(selectedKey, currentEmailConfig).then(() => {
                    toast.success(f("configuration.save_configuration_alert"), { position: toast.POSITION.TOP_RIGHT });
                })
            })
        }
    }

    function onEmailChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setSelectedKey(e.target.value);
    }

    function onLanguageChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setSelectedLanguage(e.target.value);
    }

    function sendTestEmail(values) {
        // createNewTemplate();
        //values = useState("Template").toString();
        // var templateval = useState("Template").toString();
        // this.state.Template
        //alert(EmailConfig);
        //template=config.templates[selectedLanguage];
        var vTemplate = sessionStorage.getItem("Template");
        let _testEmail = prompt("Test Email", testEmail);
        if (_testEmail == null) {
            setTestEmail("");
        } else {
            setTestEmail(_testEmail);

            smtpTest({ ...values, testEmail: _testEmail, emailTemplate: vTemplate }).then((message) => {
                alert(message);
            });
        }
        //, template: useState("Template").toString()
    }

    return (
        <div  >
            {config && <React.Fragment>
                <Formik validationSchema={schema(intl)}
                    initialValues={config}
                    onSubmit={(values, bag) => {
                        //if (currentEmailConfig) {
                        //    bag.setSubmitting(true);
                        //    updateConfiguration(values).then(() => {
                        //        toast.success(f("configuration.save_configuration_alert"), { position: toast.POSITION.TOP_RIGHT });
                        //        bag.setSubmitting(false);
                        //    })
                        //}
                        saveEmail(values);
                    }
                    }

                    render={
                        (formProps: any) => <form onSubmit={formProps.handleSubmit}>
                            <div className="row">
                                <div className="col-12 card">
                                    <div className="col-lg-9 col-md-12 mt-2 mb-3" >
                                        <div  >
                                            <h5>{f("configuration.email.parameters")}</h5>
                                            <div className={"form-group row"}>
                                                <label
                                                    className="col-4 col-form-label-sm text-secondary ">{f("configuration.smtp_address_label")}</label>
                                                <div className="col">
                                                    <BootstrapField name="smtpAddress"
                                                        placeholder={f("configuration.smtp_address_label")} />
                                                    <BootstrapError name="smtpAddress" />
                                                </div>
                                            </div>

                                            <div className={"form-group row"}>
                                                <label
                                                    className="col-4 col-form-label-sm text-secondary ">{f("configuration.smtp_port_label")}</label>
                                                <div className="col">
                                                    <BootstrapField name="smtpPort"
                                                        placeholder={f("configuration.smtp_port_label")} />
                                                    <BootstrapError name="smtpPort" />
                                                </div>
                                            </div>
                                            <div className={"form-group row"}>
                                                <label
                                                    className="col-4 col-form-label-sm text-secondary ">{f("configuration.from_address_label")}</label>
                                                <div className="col">
                                                    <BootstrapField name="fromAddress"
                                                        placeholder={f("configuration.from_address_label")} />
                                                    <BootstrapError name="fromAddress" />
                                                </div>
                                            </div>

                                            <div className={"form-group row"}>
                                                <label
                                                    className="col-4 col-form-label-sm text-secondary ">{f("configuration.from_address_name")}</label>
                                                <div className="col">
                                                    <BootstrapField name="fromAddressName"
                                                        placeholder={f("configuration.from_address_name")} />
                                                    <BootstrapError name="fromAddressName" />
                                                </div>
                                            </div>

                                            {/*Email To Override*/}

                                            <div className={"form-group row"}>
                                                <label
                                                    className="col-4 col-form-label-sm text-secondary bold">{f("configuration.email.test_address_label")}</label>
                                                <div className="col">
                                                    <BootstrapField name="overrideEmail"
                                                        placeholder={f("configuration.email.test_address_label")} />
                                                    <BootstrapError name="overrideEmail" />
                                                    <p>
                                                        <small>{f("configuration.email.test_request_address_desc")}</small>
                                                    </p>
                                                </div>
                                            </div>




                                            <div className={"form-group row"}>
                                                <label
                                                    className="col-4 col-form-label-sm text-secondary ">{f("configuration.Authentication")}</label>
                                                <div className="col form-group form-check">
                                                    <BootstrapField id="smtpUseUser" name="smtpUseUser"
                                                        placeholder={f("configuration.email_use_auth_user")}
                                                        type={"checkbox"}
                                                        checked={formProps?.values?.smtpUseUser} />
                                                    <label htmlFor="smtpUseUser"
                                                        className="form-check-label">{f("configuration.email_use_auth_user")}</label>
                                                </div>
                                            </div>

                                            {formProps?.values?.smtpUseUser && <React.Fragment>
                                                <div className={"form-group row"}>
                                                    <label
                                                        className="col-4 col-form-label-sm text-secondary ">{f("configuration.smtp_user_label")}</label>
                                                    <div className="col">
                                                        <BootstrapField name="smtpUser"
                                                            placeholder={f("configuration.smtp_user_label")} />
                                                        <BootstrapError name="smtpUser" />
                                                    </div>
                                                </div>
                                                <div className={"form-group row"}>
                                                    <label
                                                        className="col-4 col-form-label-sm text-secondary ">{f("configuration.smtp_password_label")}</label>
                                                    <div className="col">
                                                        <BootstrapField name="smtpPassword" placeholder="******"
                                                            type="password" />
                                                        <BootstrapError name="smtpPassword" />
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                            }

                                            <React.Fragment>
                                                <div className={"form-group row"}>
                                                    <label
                                                        className="col-4 col-form-label-sm text-secondary "></label>
                                                    <div className="col Emailbutton">
                                                        <button className="btn button-back Emailbutton" onClick={(event) => {
                                                            event.preventDefault();
                                                            sendTestEmail(formProps.values);
                                                        }}>{f("configuration.smtp_test_email_button")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        </div>
                                        <h5>{f("configuration.email.templates")}</h5>

                                        <div className="form-group row">

                                            <label className="col-4 col-form-label-sm text-secondary"
                                                htmlFor="exampleInputEmail1"> {f("configuration.template_type_label")}
                                            </label>
                                            <div className="col">
                                                <select className={"form-control"} onChange={onEmailChange}>
                                                    {emailTypes.map(_ => <option key={_.key} value={_.key}>{_.label}</option>)}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-4 col-form-label-sm text-secondary"
                                                htmlFor="exampleInputEmail1">
                                                {f("configuration.template_language_label")}
                                            </label>
                                            <div className="col">
                                                <select className={"form-control"} onChange={onLanguageChange}>
                                                    {languages.map(_ => <option key={_.key} value={_.key}>{_.label}</option>)}
                                                </select>
                                            </div>

                                            {/* <div className={"col-auto"}>
                                            <button className={"btn btn-primary"}
                                                onClick={saveEmail}
                                            >
                                                <FormattedMessage id={"save"} />
                                            </button>
                                        </div> */}
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-4 col-form-label-sm text-secondary"
                                                htmlFor="exampleInputEmail1">{f("configuration.email_subject_label")}</label>
                                            <div className="col">
                                                <input className={"form-control"} type={"text"} value={emailTemplate?.title}
                                                    onChange={handleTitleChange} />
                                            </div>
                                        </div>

                                        <div className={"row"}>
                                            <div className={"col"}>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">{f("configuration.email_body_label")}</label>
                                                    <AceEditor
                                                        name="emailConfirmationContent"
                                                        mode="html"
                                                        theme="xcode"
                                                        width={'100%'}
                                                        onChange={handleEditorChange}
                                                        fontSize={14}
                                                        highlightActiveLine={true}
                                                        wrapEnabled={false}
                                                        showPrintMargin={false}
                                                        value={emailTemplate?.email}
                                                        setOptions={{
                                                            enableBasicAutocompletion: false,
                                                            enableLiveAutocompletion: false,
                                                            enableSnippets: false,
                                                            showLineNumbers: true,
                                                            tabSize: 2,
                                                        }} />
                                                </div>


                                            </div>
                                        </div>
                                        <div className='row'>
                                            <button className={"btn btn-primary buttonColor buttonsize"} type={"submit"}><FormattedMessage
                                                id={f("configuration.save_configuration")} /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    }

                >

                </Formik>

            </React.Fragment>
            }



        </div>
    )

}