import React from "react";
import {connect} from "react-redux";
import {RootState} from "../../reducers/RootReducer";
import {setLanguage} from "../../actions/UserActions";
import { setMessageForPaymentDisable } from "../../actions/CommonActions";


function LanguageSelectorInternal(props) {
    function handleClick(e) {
        e.preventDefault();
        props.setLanguage(e.target.value);
        sessionStorage.setItem("Language", e.target.value.toString());
    }
    return (
        <select onChange={handleClick} value={props.language}>
            <option value={"en"}>English</option>
            <option value={"fr"}>French</option>
            <option value={"es"}>Spanish</option>
            <option value={"it"}>Italian</option>
            <option value={"de"}>German</option>
            <option value={"pt"}>Portuguese</option>
            <option value={"ru"}>Russian</option>
            <option value={"ja"}>Japanese</option>
        </select>
    );
}

export const LanguageSelector = connect((state: RootState, props) => ({
    language: state.user.language,
}), {
    setLanguage,    
})(LanguageSelectorInternal);
