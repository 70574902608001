import React, {Component, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import ManageUserForm from "./ManageUserForm";
import {
    deleteUser as deleteUserCall,
    deleteUserSapAccount,
    getExclusionList,
    getUserSapAccounts,
    saveUser,
    updateExclusionList
} from "../../requests/UserRequests";
import LinkedSapAccounts from "./LinkedSapAccounts";
import {Link, withRouter} from 'react-router-dom';
import RegistrationRequestDetails from "../settings-page/registration-requests/RegistrationRequestDetails";
import {markRegistrationCompleted} from "../../requests/RegistrationRequests";
import ExclusionList from "./exclusions/ExclusionList";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FormattedMessage, useIntl} from "react-intl";
import {Modal} from "react-bootstrap";
import {isStringNotEmptyOrNull} from "../../utils";

function ManageUserPage(props) {
    const intl = useIntl();
    const f = id => intl.formatMessage({id: id});
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let [user, setUser] = useState<any>({
        login: "",
        first_name: "",
        last_name: "",
        company: "",
        email: "",
        status: "active",
        created_date: "",
        last_login_date: "",
        num_logins: "",
        last_password_change: "",
        primary_account_type: "",
        user_role: "",
        third_party_id: "",
    });

    let [request, setRequest] = useState<any | null>(null);

    useEffect(() => {
        loadUser();
        if (props.fromRequest) {
            props.getRegistrationRequest(props.fromRequest).then((_request) => {
                setRequest(_request);
                setUser({
                    ...user,
                    first_name: _request.last_name,
                    company: _request.company,
                    email: _request.email,
                });
            });
        }
    }, []);

    function loadUser() {
        if (props.getUser) {
            props.getUser().then((user) => {
                setUser(user);
                return user;
            })
        }
    }

    function deleteUser() {
        deleteUserCall(user.user_id).then(() => {
            props.history.push("/settings/users");
        })
    }

    function _saveUser(form) {
        return saveUser(form).then((user) => {
            if (request) {
                markRegistrationCompleted(request.registrationRequestId);
            }
            setUser(user);
        });
    }

    const firstName = user && user.first_name ? user.first_name : '';
    const lastName = user && user.last_name ? user.last_name : '';
    const readonly = isStringNotEmptyOrNull(user?.third_party_id);

    return (
        <div className={"container"}>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{f("edit_user.confirm_delete")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{f("edit_user.confirm_delete_message")}</Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary buttonColor" data-dismiss="modal"
                            onClick={deleteUser}>
                        {f("ok")}
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary buttonColor"
                        onClick={handleClose}
                    >
                        <FormattedMessage id={"cancel"}/>
                    </button>
                </Modal.Footer>
            </Modal>
            <div className="row">
                <div className="col-4">
                    <Link to="/settings/users" className="btn button-back">
                        <i className="fas fa-angle-left button-back-text"/> &nbsp;
                        <span className="button-back-text">{f("edit_user.back_to_users")}</span>
                    </Link>
                </div>
                <div className="col-4 text-center">
                    <span className="user-name">{`${firstName}${' '}${lastName}`}</span>
                </div>
            </div>
            {request && <div className="row mt-2">
              <div className={"col-12"}>
                <div className="card">
                  <RegistrationRequestDetails request={request}/>
                </div>
              </div>
            </div>}
            <div className="row mt-2">
                <div className={"col-12"}>
                    <div className="card">
                        {user && <ManageUserForm save={_saveUser} user={user}
                                                 primaryAccountTypes={props.primaryAccountTypes}/>}
                    </div>
                </div>
            </div>

            {user && user.accounts && <div className="row mt-4">
              <div className={"col-12"}>
                <LinkedSapAccounts accounts={user.accounts} userId={user.user_id}
                                   refreshAccounts={getUserSapAccounts}
                                   getAccounts={getUserSapAccounts} deleteAccount={deleteUserSapAccount}
                                   accountTypes={props.accountTypes}
                                   readonly={readonly}/>
              </div>
            </div>
            }

            {user && user.primary_account_type_id === "PayerAll" && <div className="row mt-4">
              <div className={"col-12"}>
                <ExclusionList getExclusions={getExclusionList} updateExclusions={updateExclusionList}
                               userId={user.user_id}/>
              </div>
            </div>
            }
            <div className={"row mt-4"}>
                <div className={"col"}>
                    {!readonly && <span role={"button"} className={"font-button-icon"}
                                       onClick={handleShow}
                    >
                        <FontAwesomeIcon icon={"trash"}/> {f("edit_user.delete_user_button")}
                    </span>
                    }
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        accountTypes: state.default.accountTypes,
        primaryAccountTypes: state.default.primaryAccountTypes,
    };
}

export default withRouter(connect<any, any, any, any>(
    mapStateToProps,
)(ManageUserPage));
