import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green, orange, red } from "@material-ui/core/colors";
import { SecondarySelect } from "../SecondarySelect";
import { AccountType } from "../../interfaces/Accounts";
import { Invoice } from "../../interfaces/invoice";
import { useSelector } from "react-redux";
import { CurrencyDisplay } from "../../common/CurrencyDisplay";
import {
    useInvoiceBalance,
    useInvoiceTotalCredits,
    useInvoiceTotalDue,
    useInvoiceTotalOverDue,
} from "../../hooks/InvoiceHooks";
import { useIntl } from "react-intl";
import { RootState } from "../../reducers/RootReducer";

const useStyles = makeStyles({
    due: {
        color: orange["500"],
        fontFamily:"Inter,sans-serif",
    },
    pastdue: {
        color: red["500"],
        fontFamily: "Inter,sans-serif",
    },
    credit: {
        color: green["500"],
        fontFamily: "Inter,sans-serif",

    },
    title: {
        fontSize: "1em",
        fontFamily: "Inter,sans-serif",
    },
    value: {
        fontSize: "1.1em",
        fontFamily: "Inter,sans-serif",
    }
});


export function DashboardPage(props: {}) {

    return (<div className={"container"}>
        <div className={"row my-3"}>
            <div className={"col-12 col-md-3"}>
                <SecondarySelect accountType={AccountType.SoldTo} />
            </div>
        </div>
        <DashboardDetails />
    </div>)
}

export function DashboardDetails(props: any) {
    const classes = useStyles();
    const totalDue = useInvoiceTotalDue();
    const totalCredits = useInvoiceTotalCredits();
    const totalOverDue = useInvoiceTotalOverDue();
    const totalBalance = useInvoiceBalance();
    const intl = useIntl();
    const f = id => intl.formatMessage({ id });
    const currency = useSelector<RootState, string>(state => state.default.selectedCurrencyOptionValue);
    const accountType = useSelector<RootState, string>(state => state.user_options.accountType);
    const payerTotalDue = useSelector<RootState, string>(state => state.invoice.payerDueAmount);
    const payerTotalCredits = useSelector<RootState, string>(state => state.invoice.totalCreditAmount);
    const payerTotalOverDue = useSelector<RootState, string>(state => state.invoice.totalPastDueAmount);
    const balance = useSelector<RootState, string>(state => state.invoice.balanceAmount);


    return <div className={"row"}>
        <div className={"col-12 col-sm-6 col-md d-flex align-content-stretch"}>
            <Card elevation={0} className={"flex-fill"}>
                <CardContent>
                    <div className={"d-flex justify-content-between"}>
                        <Typography className={classes.title}>{f("dashboard.invoice_amount_due")}</Typography>
                    </div>
                    <div className={"d-flex justify-content-between"}>
                        {/* <Typography className={`${classes.due} ${classes.value}`}><CurrencyDisplay currency={currency} value={totalDue} /></Typography> */}
                        {
                            accountType === AccountType.Payer ? <Typography className={`${classes.due} ${classes.value}`}><CurrencyDisplay currency={currency} value={payerTotalDue} /></Typography>
                                : <Typography className={`${classes.due} ${classes.value}`}><CurrencyDisplay currency={currency} value={totalDue} /></Typography>
                        }
                    </div>
                </CardContent>
            </Card>
        </div>
        <div className={"col-12 col-sm-6 col-md d-flex align-content-stretch mt-2 mt-sm-0"}>
            <Card elevation={0} className={"flex-fill"}>
                <CardContent>
                    <div className={"d-flex justify-content-between"}>
                        <Typography className={classes.title}>{f("dashboard.total_credits")}</Typography>
                    </div>
                    <div className={"d-flex justify-content-between"}>
                        {/* <Typography className={`${classes.credit} ${classes.value}`}><CurrencyDisplay currency={currency} value={totalCredits} /></Typography> */}
                        {
                            accountType === AccountType.Payer ? <Typography className={`${classes.credit} ${classes.value}`}><CurrencyDisplay currency={currency} value={payerTotalCredits} /></Typography>
                                : <Typography className={`${classes.credit} ${classes.value}`}><CurrencyDisplay currency={currency} value={totalCredits} /></Typography>
                        }
                    </div>
                </CardContent>
            </Card>
        </div>
        <div className={"col-12 col-sm-6 col-md d-flex align-content-stretch mt-2 mt-sm-2 mt-md-0"}>
            <Card elevation={0} className={"flex-fill"}>
                <CardContent>
                    <div className={"d-flex justify-content-between"}>
                        <Typography className={classes.title}>{f("dashboard.balance")}</Typography>
                    </div>
                    <div className={"d-flex justify-content-between"}>
                        {/* {totalBalance <= 0 && <Typography className={`${classes.credit} ${classes.value}`}><CurrencyDisplay currency={currency} value={totalBalance} /></Typography>}
                        {totalBalance > 0 && <Typography className={`${classes.due} ${classes.value}`}><CurrencyDisplay currency={currency} value={totalBalance} /></Typography>} */}
                        {totalBalance <= 0 && <Typography className={`${classes.credit} ${classes.value}`}><CurrencyDisplay currency={currency} value={balance} /></Typography>}
                        {totalBalance > 0 && <Typography className={`${classes.due} ${classes.value}`}><CurrencyDisplay currency={currency} value={balance} /></Typography>}
                    </div>
                </CardContent>
            </Card>
        </div>
        <div className={"col-12 col-sm-6 col-md d-flex align-content-stretch mt-2 mt-sm-2 mt-md-0"}>
            <Card elevation={0} className={"flex-fill"}>
                <CardContent>
                    <div className={"d-flex justify-content-between"}>
                        <div>
                            <Typography className={classes.title}>{f("dashboard.total_past_due")}</Typography>
                        </div>
                    </div>
                    <div className={"d-flex justify-content-between"}>
                        {/* <Typography className={`${classes.pastdue} ${classes.value}`}><CurrencyDisplay currency={currency} value={totalOverDue} /></Typography> */}
                        {
                            accountType === AccountType.Payer ? <Typography className={`${classes.pastdue} ${classes.value}`}><CurrencyDisplay currency={currency} value={payerTotalOverDue} /></Typography>
                                : <Typography className={`${classes.pastdue} ${classes.value}`}><CurrencyDisplay currency={currency} value={totalOverDue} /></Typography>
                        }
                    </div>
                </CardContent>
            </Card>
        </div>
    </div>
}