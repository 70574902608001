/**
 * This state/reducer focuses on modes that the user can change such as the soldto/payers.
 */
import * as types from '../constants/ActionTypes';
import {AuthStatus} from "../interfaces/AuthStatus";
import axios from "../axios";

let browserLanguage = window.navigator.language.slice(0, 2);
let country = window.navigator.language.slice(3,5).toLowerCase();

let urlParams = new URLSearchParams(window.location.search);
if(urlParams.has("lang")){
  let _lang = urlParams.get("lang") ?? "en";
  browserLanguage = _lang.slice(0,2);
  country = _lang.slice(3,5);
  if(country == ""){
    country = "us"; // set country to international
  }
}

axios.instance.defaults.headers.common['Accept-Language'] = browserLanguage;
axios.instance.defaults.headers.common['X-Country'] = country;

const initialState = {
  authStatus: AuthStatus.Waiting,
  loginCount: 0,
  lastUrl: '/invoices',
  language: browserLanguage,
  country: country,
  isBlackoutActive: false,
  blackoutConfig: null,
  footerLinks: {},
};

export interface UserState{
  
}

export function userReducer(state = initialState, action): UserState {
  switch (action.type) {
    case types.SET_BLACKOUT_CONFIG:
      return {...state, blackoutConfig: action.payload};
    case types.SET_IS_BLACKOUT_ACTIVE:
      return {...state, isBlackoutActive: action.payload};
    case types.SET_AUTH_STATUS:
      return {...state, authStatus: action.payload};
    case types.LOGOUT:
      return {...state, authStatus: AuthStatus.LoggedOut};
    case types.SET_LAST_URL:
      return {...state, lastUrl: action.payload};
    case types.SET_LANGUAGE:
      axios.instance.defaults.headers.common['Accept-Language'] = action.payload;
      return { ...state, language: action.payload };
    case types.SET_FOOTER_LINKS:
      return { ...state, footerLinks: action.payload };
    default:
      return {...state};
  }
}
