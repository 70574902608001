import React, { useEffect, useState } from 'react';
import {Formik} from "formik";
import {BootstrapError, BootstrapField} from "../../helpers/Field";
import * as yup from 'yup';
import {toast} from 'react-toastify';
import moment from 'moment';
import {FormattedDate, IntlShape, useIntl} from "react-intl";
import {User} from "../../interfaces/User";
import {isStringNotEmptyOrNull} from "../../utils";

let schema = (intl: IntlShape) => {
    const f = id => intl.formatMessage({id: id});
    return yup.object().shape({
        login: yup.string().required(f("edit_user.user_id_required")),
        first_name: yup.string().required(f("edit_user.first_name_required")),
        last_name: yup.string().required(f("edit_user.last_name_required")),
        company: yup.string().required(f("edit_user.company_required")),
        email: yup.string().email().required(f("edit_user.email_required")),
        password: yup.string(),
        status: yup.string().required(f("edit_user.status_required")),
        primary_account_type_id: yup.string().required(f("edit_user.primary_account_type_required")),
        user_role: yup.string().required(f("edit_user.user_role_required")),
    });
};



function ManageUserForm(props: {user: User, save: Function, primaryAccountTypes: any}) {
    const intl = useIntl();
    const f = id => intl.formatMessage({id: id});
    
    function onSubmit(values, bag) {
        bag.setSubmitting(true);
        props.save(values).then((user) => {
            // this.setState({user: user});
            toast.success(f("edit_user.save"), {position: toast.POSITION.TOP_RIGHT});
            bag.setSubmitting(false);
        }).catch(error => {
            if (error.response.data && error.response.data.message) {
                toast.warn(error.response.data.message, {position: toast.POSITION.TOP_RIGHT});
                bag.setSubmitting(false);
            }
        });
    }

    const createdDate = props?.user?.created_date_date ? new Date(props?.user?.created_date_date) : null;
    const lastLoginDate = props?.user?.last_login ? new Date(props?.user?.last_login) : null;
    const lastPasswordChange = props?.user?.last_password_change ? new Date(props?.user?.last_password_change) : null;
    
    const readonly = isStringNotEmptyOrNull(props?.user?.third_party_id);
    const [Checked, setChecked] = useState(false);
    const [passwordValue, setpasswordValue] = useState(false);   
    function handleChange(e) {
        e.preventDefault();
        if (e.target.value != '') {
            setpasswordValue(true);            
        }
        else {
            setpasswordValue(false);
        }        
    }
    function checkboxSelectChange(e)
    {
        if (e.target.checked) {
            setChecked(true);
        }
        else {
            setChecked(false);
        }

    }
    return (
        <Formik
            initialValues={props.user}
            enableReinitialize={true}
            validationSchema={schema(intl)}
            onSubmit={onSubmit}
            render={(formProps) =>
                <form autoComplete={"new-user-form"} onSubmit={formProps.handleSubmit}>
                    <div className="row">
                        <div className="col-12">
                            <div className={"row no-gutters mb-2"}>
                                <h5 className={"col-6"}>{f("edit_user.user_info_title")}</h5>
                                <div className={"col-6 text-right"}>
                                    <button className="btn btn-primary buttonsize buttonColor" type={"submit"} disabled={readonly || (Checked == true && passwordValue == false)}>{f("edit_user.save_user_button")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    < div className="row">
                        <div className="col-12 col-md-4">
                            <div className="form-group row no-gutters">
                                <label className={"col-4 col-form-label wa-label"}>{f("edit_user.user_id_label")}</label>
                                <div className="col-8">
                                    <BootstrapField type="text" name="login" placeholder={f("edit_user.user_id_label")} disabled={readonly}/>
                                    <BootstrapError name="login"/>
                                </div>
                            </div>
                            <div className="form-group row no-gutters">
                                <label className={"col-4 col-form-label wa-label"}>{f("edit_user.first_name_label")}</label>
                                <div className="col-8">
                                    <BootstrapField type="text" name="first_name" placeholder={f("edit_user.first_name_label")} disabled={readonly}/>
                                    <BootstrapError name="first_name"/>
                                </div>
                            </div>
                            <div className="form-group row no-gutters">
                                <label className={"col-4 col-form-label wa-label"}>{f("edit_user.last_name_label")}</label>
                                <div className="col-8">
                                    <BootstrapField type="text" name="last_name" placeholder={f("edit_user.last_name_label")} disabled={readonly}/>
                                    <BootstrapError name="last_name"/>
                                </div>
                            </div>
                            <div className="form-group row no-gutters">
                                <label className={"col-4 col-form-label wa-label"}>{f("edit_user.company_label")}</label>
                                <div className="col-8">
                                    <BootstrapField type="text" name="company" placeholder={f("edit_user.company_label")} disabled={readonly}/>
                                    <BootstrapError name="company"/>
                                </div>
                            </div>
                            <div className="form-group row no-gutters">
                                <label className={"col-4 col-form-label wa-label"}>{f("edit_user.email_label")}</label>
                                <div className="col-8">
                                    <BootstrapField type="text" name="email" placeholder={f("edit_user.email_label")} disabled={readonly}/>
                                    <BootstrapError name="email"/>
                                </div>
                            </div>
                            <div className={"form-group form-check"}>
                                <BootstrapField id="setPasswordForTheUser" name="setPasswordForTheUser"
                                    placeholder={f("configuration.Users-Set-password")}
                                    type={"checkbox"} checked={Checked} onChange = { checkboxSelectChange } />
                                <label htmlFor="setPasswordForTheUser"
                                    className="form-check-label">{f("configuration.Users-Set-password")}</label>
                            </div>
                            {Checked && <div className="form-group row no-gutters">
                                <label className={"col-4 col-form-label wa-label"}>{f("edit_user.password_label")}</label>
                                <div className="col-8">
                                    <BootstrapField type="password" name="password" onKeyUp={handleChange} placeholder={formProps.values.email == "" ? f("edit_user.password_label") : "******"} disabled={readonly}  />
                                    <BootstrapError name="password"/>
                                </div>
                            </div>}
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group row no-gutters">
                                <label className={"col-4 col-form-label wa-label"}>{f("edit_user.status_label")}</label>
                                <div className="col-8">
                                    <BootstrapField component="select" name="status" placeholder={f("edit_user.status_label")}
                                                    value={formProps.values.status} disabled={readonly}>
                                        <option value="active">{f("edit_user.status.active")}</option>
                                        <option value="deactivated">{f("edit_user.status.deactivated")}</option>
                                        <option value="waiting-confirmation">{f("edit_user.status.waiting_confirmation")}</option>
                                        <option value="locked">{f("edit_user.status.locked")}</option>
                                    </BootstrapField>
                                    <BootstrapError name="status"/>
                                </div>
                            </div>
                            <div className="form-group row no-gutters">
                                <label className={"col-4 col-form-label wa-label"}>{f("edit_user.primary_account_type_label")}</label>
                                <div className="col-8">
                                    <BootstrapField component="select" name="primary_account_type_id"
                                                    placeholder={f("edit_user.primary_account_type_label")}
                                                    value={formProps.values.primary_account_type_id} disabled={readonly}>
                                        <option value="">{f("edit_user.primary_account_type.select")}</option>
                                        <option value="SoldTo">{f("edit_user.primary_account_type.sold_to")}</option>
                                        <option value="Payer">{f("edit_user.primary_account_type.payer")}</option>
                                    </BootstrapField>
                                    <BootstrapError name="primary_account_type_id"/>
                                </div>
                            </div>
                            <div className="form-group row no-gutters">
                                <label className={"col-4 col-form-label wa-label"}>{f("edit_user.user_role_label")}</label>
                                <div className="col-8">
                                    <BootstrapField component="select" name="user_role" placeholder={f("edit_user.user_role_label")} disabled={readonly}>
                                        <option value="">{f("edit_user.user_role.select")}</option>
                                        <option value="admin">{f("edit_user.user_role.admin")}</option>
                                        <option value="manager">{f("edit_user.user_role.manager")}</option>
                                        <option value="internal">{f("edit_user.user_role.internal")}</option>
                                        <option value="user">{f("edit_user.user_role.user")}</option>
                                    </BootstrapField>
                                    <BootstrapError name="user_role"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="row no-gutters">
                                <div className="col-12">
                                    <div className="row no-gutters">
                                        <div className="col-6 wa-label">{f("edit_user.created_date_label")}</div>
                                        <div className="col-6">
                                            {createdDate && <FormattedDate value={createdDate}/>}
                                        </div>
                                    </div>
                                    <div className="row no-gutters">
                                        <div className="col-6 wa-label">{f("edit_user.last_login_label")}</div>
                                        <div className="col-6">
                                            {lastLoginDate && <FormattedDate value={lastLoginDate}/>}
                                            
                                            </div>
                                    </div>
                                    <div className="row no-gutters">
                                        <div className="col-6 wa-label">{f("edit_user.login_count_label")}</div>
                                        <div className="col-6"><span>{props.user.num_logins}</span></div>
                                    </div>
                                    <div className="row no-gutters">
                                        <div className="col-6 wa-label">{f("edit_user.last_password_change_label")}</div>
                                        <div className="col-6">
                                            {lastPasswordChange && <FormattedDate value={lastPasswordChange}/>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            }/>
    );
}

export default ManageUserForm;
