import React, { useEffect, useState } from 'react';
import { Route, Switch, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUser, getUserList } from "../../requests/UserRequests";
import { getRegistrationRequestById, getRegistrationRequests } from "../../requests/RegistrationRequests";
import ResetPassword from "../resetpassword/AccountSettingsPage";
import AccountPaymentPage from "../payment-methods/AccountPaymentPage";
import ListUsersPage from "../user-management/ListUsersPage";
import ManageUserPage from "../user-management/ManageUserPage";
import RegistrationRequestsPage from "./registration-requests/RegistrationRequestsPage";
import EditConfiguration from './configuration/EditConfiguration';
import { updatePrimaryAccountTypes } from "../../actions/InvoiceActions";
import {
    canEditSystemConfig, canManagePaymentMethods,
    canManageRegistrationRequests,
    canManageUsers,
    canViewUsers
} from "../../selectors/PermissionSelectors";
import { FormattedMessage } from 'react-intl';
import { useUser } from "../../hooks/CommonHooks";
import { isThirdPartyUser } from "../../helpers/UserHelpers";

function _SettingsPage(props: any) {
    const [collapsed, setCollapsed] = useState(true);
    const user = useUser();

    function navBarToggleClick() {
        setCollapsed(!collapsed);
    }

    let collapsedClass = `${'collapse navbar-collapse webar-navbar-collapse'}`;

    if (!collapsed) {
        collapsedClass = 'navbar-collapse webar-navbar-collapse';
    }
    return (<div className={"settings-content"}>
        <nav
            className="settings-sub-header webar-navbar navbar fixed-top navbar-expand-lg navbar-light nav-color d-none d-lg-block">
            <div className="container">
                <button className="navbar-toggler" type="button" onClick={navBarToggleClick}>
                    <span className="navbar-toggler-icon" />
                </button>
                <div className={collapsedClass} id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        {!isThirdPartyUser(user) && <li className="nav-item">
                            <NavLink exact={true} to={"/settings/profile"} className="nav-link"><FormattedMessage
                                id={"settings.header.account"} /></NavLink>
                        </li>}
                        {(props.canViewUsers) ?
                            <li className="nav-item">
                                <NavLink exact={true} to={"/settings/users"} className="nav-link"><FormattedMessage
                                    id={"settings.header.user_management"} /></NavLink>
                            </li> : null
                        }
                        {props.canManagePaymentMethods && <li className="nav-item">
                            <NavLink to={"/settings/payment-methods"} className="nav-link"><FormattedMessage
                                id={"settings.header.payment_methods"} /></NavLink>
                        </li>
                        }
                        {props.canEditSystemConfig ?
                            <li className="nav-item">
                                <NavLink exact={true} to={"/settings/configuration"}
                                    className="nav-link"><FormattedMessage
                                        id={"settings.header.configuration"} /></NavLink>
                            </li> : null
                        }

                    </ul>
                </div>
            </div>
        </nav>
        <div className={"settings-page"}>
            <React.Fragment>
                <Switch>
                    {/* Order matters here! */}
                    {props.canManageUsers &&
                        <Route path="/settings/users/add/:request?" render={(props) => <ManageUserPage
                            fromRequest={props.match.params.request}
                            getRegistrationRequest={getRegistrationRequestById} />} />}
                    {props.canManageUsers && <Route path="/settings/users/:userId"
                        render={(props) => <ManageUserPage
                            getUser={getUser(props.match.params.userId)} />} />}
                    {props.canViewUsers &&
                        <Route path="/settings/users" render={(props) => <ListUsersPage getUsers={getUserList} />} />}
                    {props.canManageRegistrationRequests && <Route path="/settings/registration-requests"
                        render={(props) => <RegistrationRequestsPage
                            getRequests={getRegistrationRequests} />} />}
                    {props.canEditSystemConfig && <Route path="/settings/configuration"
                        render={(props) => <EditConfiguration />} />}
                    {props.canManagePaymentMethods &&
                        <Route path="/settings/payment-methods" component={AccountPaymentPage} />}
                    <Route path={"/settings/profile"} component={ResetPassword} />
                </Switch>
            </React.Fragment>
        </div>
    </div>)
}

export default connect<any, any, any, any>((state, props) => ({
    canEditSystemConfig: canEditSystemConfig(state),
    canManagePaymentMethods: canManagePaymentMethods(state),
    canManageRegistrationRequests: canManageRegistrationRequests(state),
    canManageUsers: canManageUsers(state),
    canViewUsers: canViewUsers(state),
}), {
    updatePrimaryAccountTypes
})(_SettingsPage);
