import React, { useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { useIntl, IntlShape } from 'react-intl';
import { BootstrapField, BootstrapError } from '../../helpers/Field';

function schema(t: IntlShape) {
    return yup.object().shape({
        invoiceNumber: yup.string().required(t.formatMessage({ id: "errors.invoice.required" })),
        invoiceAmount: yup.string().test('amount_validation', t.formatMessage({ id: "errors.invalid_amount" }),
            value => value ? /^-?\d+(\.\d{2})?$/.test(value) : true
        ).required(t.formatMessage({ id: "errors.amount.required" })),
        accountNumber: yup.string().matches(/^[a-zA-Z0-9]*$/, t.formatMessage({ id: "errors.invalid_characters" }))
            .required(t.formatMessage({ id: "errors.account.required" }))
    });
}

function AddInvoiceSapAccount(props: any) {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const intl = useIntl();
    const t = (id: string) => intl.formatMessage({ id: id });

    const initialAccount = {};

    return (
        <div>
            <Formik
                validationSchema={schema(intl)}
                onSubmit={async (values, bag) => {
                    bag.setSubmitting(true);
                    try {
                        await props.saveAccountByInvoice(values);
                    } catch (error: any) {
                        if (error.response && error.response.status === 400) {
                            // Display the error message coming from the controller
                            setErrorMessage(error.response.data.message);
                        } else {
                            setErrorMessage(error.message);
                        }
                    }
                }}
                initialValues={initialAccount}
                enableReinitialize={true}
                render={(formProps: any) => (
                    <form onSubmit={formProps.handleSubmit}>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group d-flex flex-row">
                                    <label className="wa-label col-form-label pr-2 col-4 text-nowrap">{t('account.billto_number')}</label>
                                    <div className="flex-grow-1 col-8">
                                        <BootstrapField type="text" name="accountNumber" placeholder={t('account.billto_number')} />
                                        <BootstrapError name="accountNumber" />
                                    </div>
                                </div>
                                <div className="form-group d-flex flex-row">
                                    <label className="wa-label col-form-label pr-2 col-4 text-nowrap">{t('invoice_search.search_options.invoice_number')}</label>
                                    <div className="flex-grow-1 col-8">
                                        <BootstrapField type="text" name="invoiceNumber" placeholder={t('invoice_search.search_options.invoice_number')} maxLength={10} />
                                        <BootstrapError name="invoiceNumber" />
                                    </div>
                                </div>
                                <div className="form-group d-flex flex-row">
                                    <label className="wa-label col-form-label pr-2 col-4 text-nowrap">{t('account.invoice_amount_label')}</label>
                                    <div className="flex-grow-1 col-8">
                                        <BootstrapField type="text" name="invoiceAmount" placeholder={t('account.invoice_amount_label')} />
                                        <BootstrapError name="invoiceAmount" />
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        <div className="row">
                            <div className="col-4">
                                <button type="submit" className="btn btn-primary buttonColor buttonsize">
                                    Save
                                </button>
                            </div>
                            {props.errorMessage &&
                               ( <div className="col">
                                <p style={{ color: 'red' }}>{props.errorMessage }</p>
                                </div>
                               )}
                        </div>
                       

                    </form>
                )}
            />
        </div>
    );
}

export default connect()(AddInvoiceSapAccount);
