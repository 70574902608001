// /* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { PaymentRequest } from '../../interfaces/PaymentRequest';
import { DepositRequest } from '../../interfaces/DepositRequest';
import { toast } from 'react-toastify';
import { Route } from 'react-router-dom';
import { RootState } from '../../reducers/RootReducer';
import {
    getBeforeCreditTotal,
    getCreditTotal,
    getCustomerNumber,
    getInvoiceTotal,
    getOverpayingAmount,
    getPayTotal, getSelectedPaymentOption,
    getSelectedPrimaryAccount, handleDeleteIframe
} from '../../selectors/Selectors'
import {
    getInvoices,
    makeDeposit,
    makePayment,
} from '../../actions/InvoiceActions';
import {
    createPayerData,
    mapDepositDetails,
    mapInvoiceListToPaymentDocuments,
    mapPaymentDetails,
    valueEquals
} from "./PaymentFunctions";
import ManagePaymentMethods from "../payment-methods/manage-payment-methods/ManagePaymentMethods";
import { canManagePaymentMethods } from "../../selectors/PermissionSelectors";
import { PaymentMethodDropdownConnected } from "./PaymentMethodDropdown";
import {
    isCvvRequired,
    isInvoiceOverpaymentAllowed,
    isPartialPaymentsAllowed,
    useCanMakePayments, useCurrencyDecimalLookup, useCurrencyDecimals, useCurrencyKey
} from "../../selectors/ConfigSelectors";
import { ThunkFunction } from "../../interfaces/utils";
import { FormattedMessage, useIntl } from "react-intl";
import { CurrencyDisplay, CurrencyString } from "../../common/CurrencyDisplay";
import { useInvoiceSearchQuery } from "./InvoiceDataManager";
import { getisPaymentDisable, getMaximumPaymentAllowed, getECheckMaximumPaymentAllowed } from "../../requests/ConfigRequests";
import * as appConstants from '../../constants/ApplicationConstants';
import {
    buildCardinalData,
    buildCCRequest, buildTORequest,
    getPaymentAccessToken, getPaymentResponse,
    getPaymetricResponse, paymetric3DSCheck
} from "../../requests/TokenRequests";
import { object } from 'yup/lib/locale';
import { TraceLog } from '../../interfaces/TraceLog';

interface PaymentOwnProps {
    mode: "invoice" | "deposit";
    form?: any;
}

interface PaymentStoreProps {
    allPaymentOptions: any;
    beforeCreditTotal: any;
    creditTotal: any;
    customerNumber: any;
    cvvRequired: any;
    invoiceDetails: any;
    invoiceTotal: any;
    invoices: any;
    isInvoiceOverpaymentAllowed: any;
    overPayingAmount: any;
    payTotal: any;
    payerDetails: any;
    reasonNotRequired: any;
    reason_codes: any;
    selectedCardIndex: any;
    selectedPaymentOption: any;
    selectedPrimaryAccount: any;
}

interface PaymentDispatchProps {
    getInvoices: ThunkFunction<typeof getInvoices>;
    makePayment: ThunkFunction<typeof makePayment>;
    makeDeposit: ThunkFunction<typeof makeDeposit>;
}

interface PaymentState {
    disablePay: boolean;
    showManagePayments: boolean;
    cvvValidationMessage: string;
    cvvValue: string;
    paymentMethod: string;
}

type PaymentAllProps = PaymentOwnProps & PaymentDispatchProps & PaymentStoreProps;

export const CACHED_CVV = "CACHED_CVV";

function _Payment(props) {
    const intl = useIntl();
    const f = id => intl.formatMessage({ id: id });
    let [PaymentDisable, setPaymentDisable] = useState(null);
    let [maxPaymentAllowed, setmaxPaymentAllowed] = useState(0);
    let [maxECheckPaymentAllowed, setmaxECheckPaymentAllowed] = useState(0);

    const canMakePayment = useCanMakePayments();
    let [disablePay, setDisablePay] = useState(props.impersonatedUserId === null && !canMakePayment && !PaymentDisable);
    let [showManagePayments, setShowManagePayments] = useState(false);
    let [cvvValidationMessage, setCvvValidationMessage] = useState('');
    let [cvvValue, setCvvValue] = useState('');
    let [cvvValueMaxLength, setCvvValueMaxLength] = useState(3);
    let currencyKey = useSelector<RootState, string>(state => state.default.selectedCurrencyOptionValue);
    const decimalLookup = useCurrencyDecimalLookup();
    const invoiceSearchQuery = useInvoiceSearchQuery();
    const currencyDecimals = useCurrencyDecimals(currencyKey);
    let secure_3ds_enabled = useSelector<RootState, any>(state => state.app.config.sap?.data?.payment_provider[0]?.secure_3ds_enabled === "X");
    const secure_3ds_version = useSelector<RootState, any>(state => state.app.config.sap?.data?.payment_provider[0]?.secure_3ds_version);
    const [paymentWarning, setPaymentWarning] = useState(false)
    const [clicked, setClicked] = useState(false);
    const overrideSecure_3ds_enabledd = useSelector<RootState, any>(
        state => {
            state.app.config.sap?.data?.company_codes?.map((item) => {
                if (item.company_code === props.selectedPrimaryAccount.CompanyCode) {
                    if (item.disable_3ds === "X") {
                        secure_3ds_enabled = false;
                    }
                }
            });
        }
    );


    const currency_key = useCurrencyKey();
    if (currencyKey == '') {
        currencyKey = currency_key;
    }

    useEffect(() => {
        setCvvValue('');
        setCvvValidationMessage("");
        getisPaymentDisable().then(_ => setPaymentDisable(_));
        getMaximumPaymentAllowed().then(_ => setmaxPaymentAllowed(_));
        getECheckMaximumPaymentAllowed().then(_ => setmaxECheckPaymentAllowed(_))
        if (props.selectedPaymentOption != null && props.selectedPaymentOption.payment_card_type === "AMEX") {
            setCvvValueMaxLength(4);
        }
        if (props.selectedPaymentOption != null && (props.selectedPaymentOption.payment_card_type === "MC" || props.selectedPaymentOption.payment_card_type === "VISA")) {
            setCvvValueMaxLength(3);
        }
    }, [props.selectedPaymentOption]);

    function onCVVChange(e) {
        e.target.value = e.target.value.replace(/\D+/g, '');
        setCvvValue(e.target.value);
        if (!e.target.value) {
            setCvvValidationMessage(f("payment.error.cvv_empty"));
        } else {
            validateCvv(e.target.value);
        }
    };

    function validateCvv(cvv) {
        if ((props.selectedPaymentOption.payment_card_type === "AMEX" && cvv.length < 4)
            || (props.selectedPaymentOption.payment_card_type === "MC" && cvv.length < 3)
            || (props.selectedPaymentOption.payment_card_type === "VISA" && cvv.length < 3)) {
            setCvvValidationMessage(f("payment.error.cvv_invalid"));
            return false;
        } else if (cvv.length >= 3 && cvv.length <= 4) {
            setCvvValidationMessage("");
            return true;
        }
    }

    function toggleShowManagePayments() {
        setShowManagePayments(true);
    }

    function hideManagePayments() {
        setShowManagePayments(false);
    }



    const getCardMaxAllowedMessage: any = () => {
        if (maxPaymentAllowed && maxPaymentAllowed > 0) {
            return <><FormattedMessage id={"payment_methods.maximum_card_payment_error"} />
                {' '}  {CurrencyString(maxPaymentAllowed, currencyKey, intl)}

            </>
        }

        return '';
    }

    const getECheckMaximumAllowedMessage: any = () => {

        if(maxECheckPaymentAllowed && maxECheckPaymentAllowed >0)
     {   return <> <FormattedMessage id={"payment_methods.maximum_check_payment_error"} />
            {' '} {CurrencyString(maxECheckPaymentAllowed, currencyKey, intl)}
        </>}

        return '';
    }
    async function onPayClick(event, history) {

        let invoicesLength = Object.keys(props.invoices).length;
        let overPaymentAllowed = true;
        if (invoicesLength > 1) {
            Object.keys(props.invoices).map((key) => {
                if (props.invoices[key].open_amount < props.invoicesDetail[key].amount) {
                    overPaymentAllowed = false;
                    toast.warn(f("payment.error.over_payment_not_allowed"), { position: toast.POSITION.TOP_RIGHT });
                }
            })
        }
        if (overPaymentAllowed) {
            toast.dismiss();
            if (props.selectedPaymentOption === null) {
                toast.warn(f("payment.error.card_not_selected"), { position: toast.POSITION.TOP_RIGHT });
                event.preventDefault();
                return false;
            }

            if (!props.isInvoiceOverpaymentAllowed && props.overPayingAmount > 0) {
                toast.warn(f("payment.error.over_pay_not_allowed"), { position: toast.POSITION.TOP_RIGHT });
                event.preventDefault();
                return false;
            }
            if (cvvRequired() && props.selectedPaymentOption.payment_card_type !== 'EC') {
                if (cvvValue === "") {
                    toast.warn(f("payment.error.cvv_empty"), { position: toast.POSITION.TOP_RIGHT });
                    event.preventDefault();
                    return false;
                } else if (!validateCvv(cvvValue)) {
                    toast.warn(f("payment.error.cvv_invalid"), { position: toast.POSITION.TOP_RIGHT });
                    event.preventDefault();
                    return false;
                }
            }
            // if credit present, to check if total is greater than 0, 
            // adding credit to total instead of deducting it.
            if (props.creditTotal < 0 && (props.beforeCreditTotal + props.creditTotal) <= 0) {
                toast.warn(
                    f("invoices_page.error.credit_amount_validation"),
                    { position: toast.POSITION.TOP_RIGHT },
                );
                event.preventDefault();
                return false;
            }

            if (props.payTotal <= 0) {
                toast.warn(
                    f("payment.error.pay_amount_validation_message"),
                    { position: toast.POSITION.TOP_RIGHT },
                );
                event.preventDefault();
                return false;
            }

            if (props.payTotal > maxPaymentAllowed && maxPaymentAllowed > 0 && props.selectedPaymentOption.payment_card_type !== 'EC') {
                toast.warn(
                    f("payment.error.maximum_card_payment_exceeded_message"),
                    { position: toast.POSITION.TOP_RIGHT },
                );
                event.preventDefault();
                return false;
            }

            if (props.payTotal > maxECheckPaymentAllowed && maxECheckPaymentAllowed > 0 && props.selectedPaymentOption.payment_card_type === 'EC') {
                toast.warn(
                    f("payment.error.maximum_echeck_payment_exceeded_message"),
                    { position: toast.POSITION.TOP_RIGHT },
                );
                event.preventDefault();
                return false;
            }



            // Check for reason codes
            let keys = Object.keys(props.invoiceDetails);
            if (props.reasonNotRequired !== 'X') {
                for (let i = 0; i < keys.length; i++) {
                    let details = props.invoiceDetails[keys[i]];
                    let invoice = props.invoices[keys[i]];

                    // Skip if credit 
                    if (invoice.total_amount >= 0) {
                        let reasonMissing = (details.reason === "" || details.reason === null);
                        let reasonRequired = details.amount < invoice.open_amount && reasonMissing;
                        if (reasonRequired) {
                            toast.warn(f("payment.error.reason_required"), { position: toast.POSITION.TOP_RIGHT });
                            return;
                        }
                        if (!reasonMissing) {
                            for (let j = 0; j < props.reason_codes.length; j++) {
                                if (details.reason === props.reason_codes[j].reason_code) {
                                    if (valueEquals(props.reason_codes[j].comments_required, "X")) {
                                        if (details.comment === "") {
                                            toast.warn(f("payment.error.reason_description_validation_message"), { position: toast.POSITION.TOP_RIGHT });
                                            return;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            let cardinal = null;
            if (secure_3ds_enabled) {
                sessionStorage.setItem(CACHED_CVV, cvvValue);
                const cardinalData = buildCardinalData(
                    parseFloat(props.payTotal) * 100,
                    props.currentUser,
                    props.payerDetails,
                    secure_3ds_version,
                    `${window.location.protocol}//${window.location.host}/invoices/processing-payment`,
                    currency_key,
                    props.selectedPaymentOption
                );
                const { access_token, merchant_guid, paymetric_xi_url } = await getPaymentAccessToken('TO', cardinalData);
                let body = buildTORequest(props.selectedPaymentOption.payment_card_token, merchant_guid, access_token);
                await paymetric3DSCheck(paymetric_xi_url, body, secure_3ds_version);
                const access_response = await getPaymentResponse(access_token);
                cardinal = access_response.cardinal;
                sessionStorage.removeItem(CACHED_CVV);
            }

            let invoices = mapInvoiceListToPaymentDocuments(props.invoices, props.invoiceDetails);
            let paymentDetails = mapPaymentDetails(props.selectedPaymentOption, cvvValue, props.payerDetails.address_data, cardinal);
            let payerData = createPayerData(props.customerNumber, props.selectedPrimaryAccount.CompanyCode);
            let paymentRequest: PaymentRequest = {
                payer_data: payerData,
                soldto: {
                    account_number: props.selectedPrimaryAccount.PrimaryAcct
                },
                payment_detail: paymentDetails,
                documents: invoices,
                currency_key: currencyKey,
                currency_decimals: currencyDecimals,
                log: {
                    id: "POST_INVOICE",
                    login: props.currentUser.email,
                    epayv: appConstants.APP_VERSION,
                    usrag: navigator.userAgent
                }
            };


            setDisablePay(true);
            //document.getElementById("div-ManagePayments").disabled = true;

            return props.makePayment(paymentRequest, () => history.push('/invoices/payment-status')).then((success) => {
                if (success) {
                    props.getInvoices(invoiceSearchQuery, decimalLookup, '');
                }
                setDisablePay(false);
                //document.getElementById("div-ManagePayments").disabled = false;
            });
        }
    };



    async function onDepositClick(event, history) {

        if (!props.form.isValid) {
            props.form.handleSubmit();
            event.preventDefault();
            return false;
        }
        if (props.selectedPaymentOption === null) {
            toast.warn(f("payment.error.card_not_selected"), { position: toast.POSITION.TOP_RIGHT });
            event.preventDefault();
            return false;
        }
        if (cvvRequired() && props.selectedPaymentOption.payment_card_type !== 'EC') {
            if (cvvValue === "") {
                toast.warn(f("payment.error.cvv_empty"), { position: toast.POSITION.TOP_RIGHT });
                event.preventDefault();
                return false;
            } else if (!validateCvv(cvvValue)) {
                toast.warn(f("payment.error.cvv_invalid"), { position: toast.POSITION.TOP_RIGHT });
                event.preventDefault();
                return false;
            }
        }

        let amoumtToDeposit= props.form.values.amount_to_process;// parseFloat(props.form.values.amount_to_process) * 100;

        if (amoumtToDeposit > maxPaymentAllowed && maxPaymentAllowed > 0 && props.selectedPaymentOption.payment_card_type !== 'EC') {
            toast.warn(
                f("payment.error.maximum_card_payment_exceeded_message"),{ position: toast.POSITION.TOP_RIGHT }
                
            );
            event.preventDefault();
            return false;
        }

        if (amoumtToDeposit> maxECheckPaymentAllowed && maxECheckPaymentAllowed > 0 &&props.selectedPaymentOption.payment_card_type === 'EC') {
           
            toast.warn(
                f("payment.error.maximum_echeck_payment_exceeded_message"),{ position: toast.POSITION.TOP_RIGHT }
            );
            event.preventDefault();
            return false;
        }

       
        let cardinal = null;
        if (secure_3ds_enabled) {
            sessionStorage.setItem("DEPOSIT_CACHE", JSON.stringify(props.form.values));
            sessionStorage.setItem(CACHED_CVV, cvvValue);
            const cardinalData = buildCardinalData(
                parseFloat(props.form.values.amount_to_process) * 100,
                props.currentUser,
                props.payerDetails,
                secure_3ds_version,
                `${window.location.protocol}//${window.location.host}/deposits/processing-payment`,
                currency_key,
                props.selectedPaymentOption
            );
            const { access_token, merchant_guid, paymetric_xi_url } = await getPaymentAccessToken('TO', cardinalData);
            let body = buildTORequest(props.selectedPaymentOption.payment_card_token, merchant_guid, access_token);
            await paymetric3DSCheck(paymetric_xi_url, body, secure_3ds_version);
            const access_response = await getPaymentResponse(access_token);
            cardinal = access_response.cardinal;
            sessionStorage.removeItem(CACHED_CVV);
        }

        let paymentDetails = mapPaymentDetails(props.selectedPaymentOption, cvvValue, props.payerDetails.address_data, cardinal);
        let payerData = createPayerData(props.customerNumber, props.selectedPrimaryAccount.CompanyCode);
        let depositDetail = mapDepositDetails(props.form.values, currencyKey);

        let log: TraceLog = {
            id: "POST_DEPOSIT",
            login: props.currentUser.email,
            epayv: appConstants.APP_VERSION,
            usrag: navigator.userAgent
        }
        let depositRequest: DepositRequest = {
            deposit_detail: depositDetail,
            payer_data: payerData,
            payment_detail: paymentDetails,
            log: log
        };


        setDisablePay(true);

        return props.makeDeposit(depositRequest, () => history.push('/deposits/payment-status')).then((success) => {
            setDisablePay(false);
        });
    }

    function cvvRequired() {
        return props.cvvRequired && props.selectedPaymentOption && props.selectedPaymentOption.payment_card_type !== 'EC';
    }

    function getDepositAmount(values) {
        return values && values.amount_to_process ? values.amount_to_process : '';
    }

    const depositAmount = props && props.mode && props.mode === 'deposit' ? getDepositAmount(props.form.values) : '';
    const isDepositMode = props && props.mode && props.mode === 'deposit' ? true : false;

    const [selectedPaymentType, setSelectedPaymentType] = useState(null)


    function update(type) {
        setSelectedPaymentType(type)

    }


    return (
        <div>
            <h2 className="invoicesHeading">{f("payment.title")}</h2>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center mb-2" style={{textAlignLast: 'center'}}>
                            You are about to make a one-time payment to Bausch + Lomb,
                            giving permission to Bausch + Lomb to debit your credit card or bank account for a specific amount chosen
                            by you.   If an eCheck (bank account) is provided as payment, you are authorizing Bausch + Lomb to use your
                            banking information to make a one-time electronic ACH transfer from your account or to process the payment
                            as a check transaction.  If your credit card payment is declined, you will promptly contact your credit
                            card issuer.   If your eCheck (ACH) payment is declined, you will promptly contact your bank and/or Certegy
                            Check Services (800) -237-4851.
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center mb-5">
                            <span>
                                <input className="mt-1" type="checkbox" checked={paymentWarning} onClick={() => setPaymentWarning(!paymentWarning)} /><strong>I Agree</strong>
                            </span>
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 text-secondary">
                            <div className="row no-gutters">
                                <div className="col-6 mb-3 d-flex align-items-center">
                                    {isDepositMode ? f("payment.deposit_total") : f("payment.invoice_total")}
                                </div>
                                <div className="col-6 mb-3 payment-totals">
                                    {isDepositMode ? CurrencyString(depositAmount, currencyKey, intl) : CurrencyString(props.invoiceTotal, currencyKey, intl)}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 text-secondary">
                            <div className="row no-gutters">
                                <div className="col-3 mb-3 d-flex align-items-center">
                                    {f("payment.pay_label")}
                                </div>
                                <div className="col-9 mb-3 payment-totals">
                                    {isDepositMode ? CurrencyString(depositAmount, currencyKey, intl) : CurrencyString(props.payTotal !== "NaN" ? props.payTotal : '0', currencyKey, intl)}
                                </div>
                            </div>
                        </div>
                        {props.overPayingAmount > 0 && !isDepositMode &&
                            <React.Fragment>
                                <div className="col-lg-3 text-secondary">
                                    <div className="row no-gutters">
                                        <div className="col-6 mb-3 d-flex align-items-end">
                                            {f("payment.overpaying_label")}
                                        </div>
                                        <div className="col-6 mb-3 payment-totals">
                                            <span
                                                className="overpaying_Amount_color"> {CurrencyString(props.overPayingAmount, currencyKey, intl)} </span>
                                        </div>
                                    </div>
                                </div>
                                {props.isInvoiceOverpaymentAllowed && <div className="col-lg-3 text-secondary">
                                    <div className="d-flex mb-3">
                                        <div className="d-flex align-items-center">
                                            <i className="fas fa-lg fa-exclamation-circle circle_Exclamation_Color" />
                                        </div>
                                        <div className="overpaying-text d-flex align-items-center">
                                            <div>
                                                <FormattedMessage id={"payment.overpaying_message"}
                                                    values={{ amount: CurrencyString(props.overPayingAmount, currencyKey, intl) }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                            </React.Fragment>
                        }
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="row no-gutters">
                                <div
                                    className="col-lg-4 col-md-6 col-sm-6 col-form-label col-form-label-sm text-secondary mb-3">
                                    {f("payment.payment_method_label")}
                                </div>
                                <PaymentMethodDropdownConnected />
                                <div className="col-lg-4 col-md-6 col-sm-6" />

                                <div id="div-ManagePayments" className="col-lg-8 col-md-6 col-sm-6 text-center">
                                    {!showManagePayments && disablePay == false ?
                                        <div className={"manage-payment-button"}
                                            onClick={toggleShowManagePayments} >{f("payment.manage_payment_methods")}</div> : null}
                                    {showManagePayments &&
                                        <div className={"manage-payment-button"} onClick={hideManagePayments}>
                                            <FormattedMessage id={"payment.hide_payment_methods"} /></div>}
                                </div>

                            </div>
                        </div>
                        <div className="text-left col-lg-2 col-md-8 col-sm-6 col-7">
                            {cvvRequired() &&
                                <input
                                    key="txtCvv" pattern="/\D+/g"
                                    style={{ width: "72px" }}
                                    maxLength={cvvValueMaxLength}
                                    type="text"
                                    className={`col form-control ${cvvValidationMessage ? 'invalid' : ''}`}
                                    placeholder={f("payment.cvv_label")}
                                    onChange={onCVVChange}
                                    value={cvvValue}
                                />
                            }
                            {
                                (cvvRequired()) && <h6 className="invalid-Text">
                                    <small>{cvvValidationMessage}</small>
                                </h6>
                            }
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 d-none d-lg-block">
                            <Route render={({ history }) => (
                                <button
                                    type="button"
                                    id="btnPay"
                                    className="btn buttonsize buttonColor btnPay"
                                    disabled={disablePay || !paymentWarning}
                                    onClick={(e) => {
                                        handleDeleteIframe();
                                        !isDepositMode ? onPayClick(e, history) : onDepositClick(e, history)
                                    }}
                                >
                                    <FormattedMessage id={"payment.pay_button"} />
                                </button>
                            )} />
                        </div>
                    </div>
                    <div className={"row mb-2"}>
                        <div className={"col-md-12"}>
                            {showManagePayments && <div className="manage-payment-section">
                                <ManagePaymentMethods selectedType={selectedPaymentType} updateState={update} alwaysSaveOnFile={false} />
                            </div>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-lg-none">
                            <Route render={({ history }) => (
                                <button
                                    type="button"
                                    id="btnPay"
                                    className="btn btn-primary buttonColor buttonsize"
                                    disabled={disablePay || !paymentWarning}
                                    onClick={(e) => {
                                        !isDepositMode ? onPayClick(e, history) : onDepositClick(e, history)
                                    }}
                                >

                                    <FormattedMessage id={"payment.pay_button"} />
                                </button>
                            )} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <p className="text-danger">
                                {
                                    
                                    props.selectedPaymentOption===null ?'': cvvRequired() ? getCardMaxAllowedMessage() : getECheckMaximumAllowedMessage()
                                }
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}


export const Payment = connect<any, any, any, any>((store: RootState, props) => {
    return {
        beforeCreditTotal: getBeforeCreditTotal(store),
        currentUser: store.default.currentUser,
        canManagePaymentMethods: canManagePaymentMethods(store),
        creditTotal: getCreditTotal(store),
        customerNumber: getCustomerNumber(store),
        cvvRequired: isCvvRequired(store),
        impersonatedUserId: store.user_options.impersonatedUserId,
        invoiceDetails: store.invoice.invoiceDetail,
        invoiceTotal: getInvoiceTotal(store),
        invoices: store.invoice.selected,
        invoicesDetail: store.invoice.invoiceDetail,
        isInvoiceOverpaymentAllowed: isInvoiceOverpaymentAllowed(store),
        isPartialPaymentsAllowed: isPartialPaymentsAllowed(store),
        overPayNote: store.default.overPayNote,
        overPayingAmount: getOverpayingAmount(store),
        payTotal: getPayTotal(store),
        payerDetails: store.default.payerDetails,
        reasonNotRequired: store.default.reasonNotRequired,
        reason_codes: store.app.config.sap?.data.payment_reason_codes,
        selectedPrimaryAccount: getSelectedPrimaryAccount(store),
        selectedSecondaryAccount: store.user_options.selectedSecondaryAccount,
        // Used to refresh, not really needed.
        selectedPaymentOption: getSelectedPaymentOption(store),
    }
}, {
    getInvoices,
    makePayment,
    makeDeposit,
})(_Payment);
