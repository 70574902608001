/* eslint-disable no-console */
import React, {Component} from 'react';
import { connect } from 'react-redux';
import { getInvoicePDF } from '../../actions/InvoiceActions';

class PdfCell extends Component<any, any> {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }
  handleClick = () => {
    if (this.props.row.pdf_document_available !== undefined) {
      this.props.getInvoicePDF(this.props.row);
    }
  };

  render() {
    const { row } = this.props;
    if(row && row.pdf_document_available && row.pdf_document_available === 'X'){
      return (
        <img
          src={"/images/ic_pdf.png"}
          alt="pdf"
          key={`${'docId_'}${row.document_number_finance}`}
          onClick={this.handleClick}
          role="presentation"
        />);
    } else {
      return (<img
        src={"/images/ic_pdf_disabled.png"}
        alt="pdf"
        key={`${'docId_'}${row.document_number_finance}`}
        onClick={this.handleClick}
        role="presentation"
      />);
    }
  }
}

export default connect<any, any, any, any>(() => ({}), {
  getInvoicePDF
})(PdfCell);
