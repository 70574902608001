import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import JSONViewer from './JSONViewer';
import { updateConfiguration, getAppConfiguration } from "../../../requests/ConfigRequests";

import { setConfig as setAppConfig } from "../../../reducers/AppReducer";
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { BootstrapField, BootstrapError } from "../../../helpers/Field";
import * as yup from 'yup';
import { Field, Formik } from "formik";
import { toast } from 'react-toastify';

const schema = (intl: IntlShape) => {
    return yup.object().shape({
        companyName: yup.string().required(),
        applicationUrl: yup.string().required(),

    });
};
export function CustomizingPage(props) {
    let sapStatusGood = props.sapConfig.statusCode === 200 && props.sapConfig.data.status.message_type === "S";

    const [data, setData] = useState({});
    // let [config, setConfig] = useState(null);

    const intl = useIntl();
    const f = id => intl.formatMessage({ id: id });
    useEffect(() => {
        getAppConfiguration().then(_ => setData(_?.sap?.data));
    }, []);
    let config = props.config;
    return (
        <div className='container' >

            <Formik
                initialValues={config}
                validationSchema={schema(intl)}
                onSubmit={(values, bag) => {

                    bag.setSubmitting(true);
                    updateConfiguration(values).then(() => {
                        toast.success(f("configuration.save_configuration_alert"), { position: toast.POSITION.TOP_RIGHT });
                        bag.setSubmitting(false);
                    })

                }
                }

            >
                {
                    (formProps: any) => (

                        <form onSubmit={formProps.handleSubmit}>

                            <div className="col-lg-9 col-md-12 mt-2 mb-3 pl-1">
                                <h5>{f("configuration.email.parameters")}</h5>
                                <div className={"form-group row"}>
                                    <label
                                        className="col-4 col-form-label-sm text-secondary ">{f("configuration.application_url_label")}</label>
                                    <div className="col">

                                        <BootstrapField name="applicationUrl" placeholder="Application Url" />
                                        <BootstrapError name="applicationUrl" />
                                    </div>
                                </div>

                                <div className={"form-group row"}>
                                    <label
                                        className="col-4 col-form-label-sm text-secondary "><FormattedMessage
                                            id={'configuration.company_name_label'} /></label>
                                    <div className="col">
                                        <BootstrapField name="companyName"
                                            placeholder={intl.formatMessage({ id: 'configuration.company_name_label' })} />
                                        <BootstrapError name="companyName" />
                                    </div>
                                </div>
                                <div>
                                    <button className={"btn btn-primary buttonColor buttonsize"} type={"submit"}><FormattedMessage
                                        id={f("configuration.save_configuration")} /></button>
                                </div>
                                <br/>
                                {/* SAP Connection Status */}
                                <h5>{f("settings.header.sap_details")}</h5>
                                <div className={"row "}>
                                    <div className="col-6">
                                        <strong> {f("configuration.sap_connection_status")}</strong>
                                        <span style={{ fontSize: '1.2rem' }}> {sapStatusGood ? f("configuration.sap_connection_status_good") : f("configuration.sap_connection_status_error")}</span>
                                        {!sapStatusGood && <div>
                                            {props.sapConfig.statusMessage}<br />
                                            {props.sapConfig.statusCode === 200 &&
                                                <React.Fragment>{props.sapConfig.data.status.message_type}<br />
                                                    {props.sapConfig.data.status.message_line_string}<br />
                                                </React.Fragment>}
                                        </div>}
                                    </div>
                                </div>
                                {/* <ReactJson src={data} theme="rjv-default" iconStyle="triangle" enableClipboard="false" /> */}
                                <div className="col-4">
                                    <JSONViewer json={data}></JSONViewer>
                                </div>
                            </div>
                            <br />

                        </form>
                    )
                }
            </Formik>
        </div >

    );
}

export default connect<any, any, any, any>((state, props) => {
    return {
        sapConfig: state.app.config.sap,
    }
})(CustomizingPage);