import React from "react";
import {AccountType} from "../interfaces/Accounts";
import PayerSelect from "./invoice/PayerSelect";
import SoldToSelect from "./invoice/SoldToSelect";
import SoldToSelectAll from "./invoice/SoldToSelectAll";

export function SecondarySelect(props: {accountType: AccountType}) {
    if(props.accountType == AccountType.SoldTo){
        return <PayerSelect/>;
    }
    if(props.accountType == AccountType.Payer){
        return <SoldToSelect/>;
    }
    if(props.accountType == AccountType.PayerAll){
        return <SoldToSelectAll/>;
    }
    return null;
}
