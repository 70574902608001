/* eslint-env browser */
/* eslint-disable no-console*/
import axios from "../axios";
import { toast } from 'react-toastify';
import * as apiTypes from '../constants/ApiConstants';
import * as types from '../constants/ActionTypes';
import * as validationTypes from '../constants/ValidationConstants';
import { Invoice, InvoiceResponse, InvoiceSearchParameters } from '../interfaces/invoice';
import { PayerDetails } from '../interfaces/PayerDetails';
import { PaymentStatus } from '../interfaces/PaymentStatus';
import {
    requestErrored,
    requestStarted,
    requestUnauthorized,
    updateState
} from './CommonActions';
import * as Yup from "yup";
import { InvoiceRequestBody } from "../interfaces/invoice";
import { AxiosResponse } from "axios";
import { AccountType } from "../interfaces/Accounts";
import { ReduxAction, SapResponse } from "../interfaces/utils";
import { saveAs } from "../common/FileSaver";

// import { soldToFormatter } from "../common/invoiceHelpers";

export function adjustCurrency(amount: number, decimals: number) {
    let adjust = 2 - decimals;
    return amount * Math.pow(10, adjust);
}

function setPayerDetails(payerDetails: PayerDetails): ReduxAction<PayerDetails> {
    return {
        type: types.GET_PAYER_DETAILS_SUCCESS,
        payload: payerDetails,
    }
}

function mapDistinctCurrencies(documentList: Invoice[]) {

    const currencies = [...Array.from(new Set(documentList.map(document => document.currency_key)))];

    return currencies.map(currency => ({
        value: currency,
        label: currency,
        clearableValue: false
    }));

}

export function addInvoiceToSelected(document: Invoice, decimal: number) {
    return {
        type: types.ADD_INVOICE_TO_SELECTED,
        payload: {
            open_amount: document.open_amount?.toFixed(decimal),
            ...document
        }
    };
}

export function removeInvoiceFromSelected(document: Invoice) {
    return {
        type: types.REMOVE_INVOICE_FROM_SELECTED,
        payload: document
    };
}

export function setActiveInvoice(document: Invoice) {
    return {
        type: types.SET_ACTIVE_INVOICE,
        payload: document
    };
}

export function setDueSelect(dueSelectValue) {
    return {
        type: types.SET_DUE_SELECT,
        payload: dueSelectValue
    };
}export function setStatementId(statementId) {
    return {
        type: types.SET_STATEMENT_ID,
        payload: statementId
    };
}



export function updatePayAmountForInvoice(documentId, amount, openAmount, partialAllowed, overPaymentAllowed, maxPaymentAllowed = 0) {
   
   
    let error = '';
    let overUnder = 0;
    if (amount < openAmount) {
        overUnder = -1;
    }
    if (amount > openAmount) {
        overUnder = 1;
    }

    if (amount === 0) {
        error = 'Amount cannot be zero.';
    } else if (amount === '') {
        error = 'Amount cannot be empty.';
    } else {
        let test = Yup.number();
        let validate = test.isValidSync(amount);
        if (!validate) {
            error = 'Only numeric values allowed';
        }
    }
    if (amount < 0) {
        error = 'Amount cannot be negative.';
    }
    if (amount < 0) {
        error = 'Amount cannot be negative.';
    }
    if (amount > openAmount && !overPaymentAllowed) {
        error = 'Over payment not allowed.';
    }
    if (amount < openAmount && !partialAllowed) {
        error = 'Partial payment not allowed.';
    }
   
    if (amount > maxPaymentAllowed && maxPaymentAllowed > 0) {
        error = 'Maximum payment exceeded.'
    }

    return {
        type: types.UPDATE_PAY_AMOUNT_FOR_INVOICE,
        payload: {
            documentId,
            amount,
            amountValidation: error,
            overUnder: overUnder,
        }
    };
}

export function updateReasonComment(documentId, comment) {
    return {
        type: types.UPDATE_REASON_COMMENT,
        payload: {
            documentId,
            comment
        }
    };
}

export function updateReason(documentId, reason) {
    return {
        type: types.UPDATE_REASON,
        payload: {
            documentId,
            reason
        }
    };
}

export function getInvoicesAjax(invoiceListRequest: InvoiceRequestBody): Promise<AxiosResponse<InvoiceResponse>> {
    return axios
        .post(apiTypes.INVOICE_LIST_URL, invoiceListRequest);
}

export function getInvoices(invoiceListRequest: InvoiceRequestBody, decimalLookup: (currencyKey: string) => number, selectedCurrency) {
    return (dispatch): Promise<{ invoices: Invoice[], invoiceCurrencies: any[] }> => {
        updateState(dispatch, types.GET_INVOICES_SUCCESS, []);
        return getInvoicesAjax(invoiceListRequest)
            .then(response => {
                let invoices: Invoice[] = [];
                let invoiceCurrencies: any[] = [];
                if (
                    response.status === 200 &&
                    (response.data.status?.message_type === 'S' || response.data.status?.message_type === 'W')
                ) {
                    if (response.data.status.message_type === 'W') {
                        toast.warn(response.data.status.message_line_string, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                    invoices = response?.data?.document_list ?? [];

                    invoiceCurrencies = mapDistinctCurrencies(response?.data?.document_list ?? []);

                    invoices.forEach(invoice => {
                        const soldToNum = parseInt(invoice.soldto_number, 10);
                        const payerNum = parseInt(invoice.payer_number, 10);
                        invoice.open_amount = invoice.open_amount ? adjustCurrency(invoice.open_amount, decimalLookup(invoice.currency_key)) : invoice.open_amount;
                        invoice.total_amount = invoice.total_amount ? adjustCurrency(invoice.total_amount, decimalLookup(invoice.currency_key)) : invoice.total_amount;
                        invoice.paid_amount = invoice.paid_amount ? adjustCurrency(invoice.paid_amount, decimalLookup(invoice.currency_key)) : invoice.paid_amount;
                        invoice.soldto_number = isNaN(soldToNum) ? invoice.soldto_number : soldToNum.toString();
                        invoice.payer_number = isNaN(payerNum) ? invoice.payer_number : payerNum.toString();
                    });
                   
                    //Needs to check more closely 
                    // if (selectedCurrency == "") {
                    //     dispatch(setSelectedCurrency(invoices[0]?.currency_key ?? ""));
                    // } else {
                    //     dispatch(setSelectedCurrency(selectedCurrency));
                    // }

                    updateState(dispatch, types.GET_INVOICES_SUCCESS, invoices);
                    updateState(dispatch, types.GET_INVOICE_CURRENCY_SUCCESS, invoiceCurrencies);

                    return { invoices, invoiceCurrencies };
                } else {
                    updateState(dispatch, types.GET_INVOICES_SUCCESS, []);
                    updateState(dispatch, types.GET_INVOICE_CURRENCY_SUCCESS, []);
                    if (response.data && response.data.status && response.data.status?.message_line_string) {
                        toast.error(response.data.status.message_line_string, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                    return { invoices, invoiceCurrencies };
                }
            });

    }
    // .catch(err => {
    //   if (err && err.response && err.response.status === 401) {
    //     requestUnauthorized(dispatch, err);
    //   } else {
    //     requestErrored(dispatch, err, validationTypes.GET_INVOICE_ERROR);
    //   }
    // });
}

export function setsearchedInvoices(searchedInvoices) {
    return {
        type: types.SEARCHED_INVOICES,
        payload: searchedInvoices
    };
}

export function getInvoicePDF(invoice) {
    return (dispatch) => {
        const docNumber = invoice.billing_document_number;
        const CustomerNumber = invoice.soldto_number;
        const input = { 'document_detail': { 'billing_document_number': `${docNumber}`, 'document_type': '01', 'customer_number': `${CustomerNumber}` } };
        axios
            .post(apiTypes.INVOICE_PDF_URL, input)
            .then(response => {
                if (response.status === 200) {
                    // const file = new Blob([response.data], {type: 'application/pdf'});
                    // const fileURL = URL.createObjectURL(file, {name: `${docNumber}${'.pdf'}`});
                    window.open(response.data, '_blank');
                }
            })
            .catch(err => {
                if (err && err.response && err.response.status === 401) {
                    requestUnauthorized(dispatch, err);
                } else {
                    requestErrored(dispatch, err, validationTypes.GET_INVOICE_PDF_ERROR);
                }
            });

    }
}

export function getInvoiceSearchPDF(dispatch, search) {
    axios
        .post(apiTypes.INVOICE_SEARCH_PDF_URL, search)
        .then(response => {
            if (response.status === 200) {
                window.open(response.data, '_blank');
            }
        })
        .catch(err => {
            if (err && err.response && err.response.status === 401) {
                requestUnauthorized(dispatch, err);
            } else {
                requestErrored(dispatch, err, validationTypes.GET_INVOICE_PDF_ERROR);
            }
        });
}


export function getInvoiceBulkPDF(dispatch, search) {
    var vGridViewDocIds;
    let data = search;
    if (sessionStorage.getItem("invoicesWithKeysData") != null) {
        vGridViewDocIds = JSON.parse(sessionStorage.getItem("invoicesWithKeysData") || '{}');
        data.search_parameters.GridViewDocIds = vGridViewDocIds;
    }
    axios
        .post(apiTypes.INVOICE_BULK_URL, search)
        .then(response => {
            if (response.status === 200) {
                window.open(response.data, '_blank');
            }
        })
        .catch(err => {
            if (err && err.response && err.response.status === 401) {
                requestUnauthorized(dispatch, err);
            } else {
                requestErrored(dispatch, err, validationTypes.GET_INVOICE_PDF_ERROR);
            }
        });
}


export function downloadExcelFile(dispatch, search) {
    axios
        .post(apiTypes.INVOICE_EXCEL_DOWNLOAD, search, {
            responseType: 'blob', // important
        })
        .then(response => {
            // @ts-ignore
            saveAs(
                response.data,
                "InvoiceExport.xlsx"
            );
        });
}


export function extractLastCardNumbers(str, delim = '-') {
    if (str == null) {
        return "";
    }
    const a = str.indexOf(delim, 1);
    if (a === -1) return '';
    const b = str.indexOf(delim, a + 1);
    if (b === -1) return '';
    return str.substr(a + 1, b - a - 1);
}

export function mapCards(cardData: any[]) {
    return cardData.map((card, index) => ({
        value: card.payment_card_token,
        label: `${card.payment_card_type}${'-'}${card.payment_card_name}${'-'}${extractLastCardNumbers(
            card.payment_card_token,
            '-'
        )}`,
        clearableValue: false,
        cardType: card.payment_card_type,
        index: index
    }));
}

export function getPayerDetailsBuilder(primary: any, secondary: any, accountType: AccountType) {
    return (dispatch) => {
        let request;
        if (accountType === AccountType.SoldTo) {
            // Use Secondary Account
            request = {
                customer_number: secondary && secondary.PrimaryAcct,
                company_code: primary && primary.CompanyCode,
                action: '',
                sales_area_data: {
                    sales_organization: secondary && secondary.SalesOrganization,
                    distribution_channel: secondary && secondary.DistributionChannel,
                    division: secondary && secondary.Division
                }
            };
        } else {
            // Use Primary Account
            request = {
                customer_number: primary && primary.PrimaryAcct,
                company_code: primary && primary.CompanyCode,
                action: '',
                sales_area_data: {
                    sales_organization: primary && primary.SalesOrganization,
                    distribution_channel: primary && primary.DistributionChannel,
                    division: primary && primary.Division
                }
            };
        }
        return getPayerDetails(dispatch, request);

    };
}

export function getPayerDetails(dispatch, input) {
    return axios
        .post<SapResponse<PayerDetails>>(apiTypes.PAYER_DETAILS_URL, input)
        .then(response => {
            if (response.status === 200 && response.data.status.message_type === 'S') {
                if (response.data) {
                    dispatch(setPayerDetails(response.data.detail));
                    return response.data.detail;
                }
            }
            return null;
        }
        )
        .catch(err => {
            if (err && err.response && err.response.status === 401) {
                requestUnauthorized(dispatch, err);
            } else {
                requestErrored(dispatch, err, validationTypes.GET_PAYER_DETAILS_ERROR);
            }
        });
}

export function makePayment(paymentDetail, next = () => {
}) {

    return (dispatch) => {
        requestStarted(dispatch, types.POST_PAYMENT_REQUEST);

        return axios
            .post(apiTypes.PAYMENT_URL, paymentDetail)
            .then(response => {
                if (response.status === 200) {
                    if (response.data == null || !response.data.status) {
                        throw ("Invalid status.")
                    }
                    const paymentStatuses = response.data.status.map((status, i) => {
                        let authorizationReferenceCode = '';
                        let documentNumberFinance = '';
                        let currency_key = "";
                        let authorization_amount = null;
                        if (status && status.message_type === 'S' && response.data.payed && response.data.payed[i]) {
                            authorizationReferenceCode = response.data.payed[i].authorization_reference_code;
                            documentNumberFinance = response.data.payed[i].document_number_finance;
                            currency_key = response.data.payed[i].currency_key;
                            authorization_amount = response.data.payed[i].authorization_amount;
                        }

                        let paymentStatus: PaymentStatus = {
                            index_number: i + 1,
                            document_number_finance: documentNumberFinance,
                            message_type: status.message_type,
                            message_line_string: status.message_line_string,
                            authorization_reference_code: authorizationReferenceCode,
                            authorization_amount: authorization_amount,
                            currency_key: currency_key,
                        };
                        return paymentStatus;
                    });


                    // Order matters here.
                    updateState(dispatch, types.POST_PAYMENT_SUCCESS, paymentStatuses);
                    next();
                    updateState(dispatch, types.COPY_SELECTED_INVOICES_TO_COMPLETED, null);
                    if (paymentStatuses.filter(i => i.message_type === 'S').length > 0) {
                        updateState(dispatch, types.CLEAR_SELECTED_INVOICES, null);
                        return true;
                    }
                    return false;
                }
            }).catch(err => {
                if (err && err.response && err.response.status === 401) {
                    requestUnauthorized(dispatch, err);
                } else {
                    requestErrored(dispatch, err, validationTypes.MAKE_PAYMENT_ERROR);
                }
            });
    }

}

export function getInvoiceDetail(billingDocumentNumber, documentType,customerNumber) {
    return (dispatch) => {
        const body = {
            'document_detail': {
                'billing_document_number': `${billingDocumentNumber}`,
                'document_type': `${documentType}`,
                'customer_number': `${customerNumber}`
            }
        };
        return axios
            .post(apiTypes.INVOICE_DETAIL_URL, body)
            .then((invoiceDetail) => {
                if (invoiceDetail.status === 200 && invoiceDetail.data) {
                    if (invoiceDetail.data.status) {
                        if (invoiceDetail.data.status.message_type === 'S') {
                            return invoiceDetail.data.detail;
                        } else if (invoiceDetail.data.status.message_type === 'E') {
                            toast.warn(invoiceDetail.data.status.message_line_string, { position: toast.POSITION.TOP_RIGHT });
                            return null;
                        }
                    }
                }
                return null;
            })
            .catch((err) => {
                if (err && err.response && err.response.status === 401) {
                    requestErrored(dispatch, err, validationTypes.CREDENTIALS_INVALID);
                } else {
                    requestErrored(dispatch, err, validationTypes.GET_INVOICE_DETAIL_ERROR);
                }
                return err;
            });

    }
}

export function updateSelectedSecondaryAccountValue(dispatch, customerOptionValue, cb = () => {
}) {
    updateState(dispatch, types.UPDATE_SELECTED_SECONDARY_ACCOUNT, customerOptionValue, cb);
}

export function updatePrimaryAccount(selectedPrimaryAccountIndex, setSecondary = true): ReduxAction<any> {
    return {
        type: types.SET_SELECTED_ACCOUNT,
        payload: { selectedPrimaryAccountIndex, setSecondary }
    }
}

export function updateSecondaryAccount(account): ReduxAction<any> {
    return {
        type: types.UPDATE_SELECTED_SECONDARY_ACCOUNT,
        payload: account
    }
}

export function updateSelectedCard(selectedCardKey: string) {
    return {
        type: types.UPDATE_SELECTED_CARD,
        payload: selectedCardKey,
    }
}

export function updateInvoiceSearchDueToDate(dueToDate): ReduxAction<any> {
    return {
        type: types.INVOICE_SEARCH_DUE_TO_DATE,
        payload: dueToDate,
    }
}


export function updateInvoiceSearch(invoiceSearch: Partial<InvoiceSearchParameters>): ReduxAction<any> {
    return {
     
        type: types.UPDATE_INVOICE_SEARCH,
        payload: invoiceSearch,
    }
    
}


export function addSoldToToSelected(soldToNumber) {
    return {
        type: types.ADD_SOLD_TO_TO_SELECTED,
        payload: soldToNumber
    };
}

export function removeSoldToFromSelected(soldToNumber) {
    return {
        type: types.REMOVE_SOLD_TO_FROM_SELECTED,
        payload: soldToNumber
    };
}

export function setSelectedSoldTos(soldToNumbers) {
    return {
        type: types.SET_SELECTED_SOLD_TOS,
        payload: soldToNumbers
    };
}

export function setSelectedCurrency(currencyCode: string) {
    return {
        type: types.UPDATE_CURRENCY_CODE,
        payload: currencyCode
    };
}

export function makeDeposit(paymentDetail, next = () => {
}) {
    return (dispatch) => {
        requestStarted(dispatch, types.POST_PAYMENT_REQUEST);

        return axios
            .post(apiTypes.DEPOSIT_URL, paymentDetail)
            .then(response => {
                if (response.status === 200) {
                    if (response.data == null || !response.data.status) {
                        throw ("Invalid status.")
                    }
                    const paymentStatuses = response.data.status.map((status, i) => {
                        let authorizationReferenceCode = '';
                        let documentNumberFinance = '';
                        let authorization_amount = null;
                        let currency_key = "";
                        if (status && status.message_type === 'S' && response.data.payed && response.data.payed[i]) {
                            authorizationReferenceCode = response.data.payed[i].authorization_reference_code;
                            documentNumberFinance = response.data.payed[i].document_number_finance;
                            authorization_amount = response.data.payed[i].authorization_amount;
                            currency_key = response.data.payed[i].currency_key;
                        }
                        let paymentStatus: PaymentStatus = {
                            index_number: i + 1,
                            document_number_finance: documentNumberFinance,
                            message_type: status.message_type,
                            message_line_string: status.message_line_string,
                            authorization_reference_code: authorizationReferenceCode,
                            authorization_amount: authorization_amount,
                            currency_key: currency_key,
                        };
                        return paymentStatus;
                    });
                    updateState(dispatch, types.POST_PAYMENT_SUCCESS, paymentStatuses);
                    next();
                    updateState(dispatch, types.COPY_DEPOSIT_DETAILS, paymentDetail);
                }
            })
            .catch(err => {
                if (err && err.response && err.response.status === 401) {
                    requestUnauthorized(dispatch, err);
                } else {
                    requestErrored(dispatch, err, validationTypes.MAKE_PAYMENT_ERROR);
                }
            });
    }

}

export function clearSelectedInvoices(): ReduxAction<null> {
    return {
        type: types.CLEAR_SELECTED_INVOICES,
        payload: null,
    }
}

export function selectPayer(val): ReduxAction<null> {
    return {
        type: types.UPDATE_SELECTED_SECONDARY_ACCOUNT,
        payload: val,
    }
}

export function updatePrimaryAccountTypes(primaryAccountType): ReduxAction<any> {
    return {
        type: types.UPDATE_PRIMARY_ACCOUNT_TYPES,
        payload: primaryAccountType,
    }
}

export function setPaymentMethods(paymentMethods): ReduxAction<any> {
    return {
        type: types.SET_PAYMENT_METHODS,
        payload: paymentMethods
    }
}

export function setsearchedinvoiceStatus(staus) {
    return {
        type: types.SEARCH_INVOICE_STATUS,
        payload: staus
    };
}
export function setsearchedinvoiceDueDate(dueDateFrom, dueDateTo) {
    let dueDate = { from: dueDateFrom, to: dueDateTo }
    return {
        type: types.SEARCH_INVOICE_DUEDATE,
        payload: dueDate
    };
}
export function setsearchedinvoiceType(type) {
    return {
        type: types.SEARCH_INVOICE_TYPE,
        payload: type
    };
}
export function setsearchedInvoiceNumber(invoiceNumber) {
    return {
        type: types.SEARCH_INVOICE_NUMBER,
        payload: invoiceNumber
    };
}
export function setsearchedArticleNumber(articleNumber) {
    return {
        type: types.SEARCH_ARTILCE_NUMBER,
        payload: articleNumber
    };
}
export function setsearchedArticleDescription(description) {
    return {
        type: types.SEARCH_ARTILCE_DESCRIPTION,
        payload: description
    };
}

export function setinvoiceSearchedPeriod(period) {
    return {
        type: types.INVOICE_SEARCH_PERIOD,
        payload: period
    };
}
export function setinvoiceSearchedPeriodFromTo(periodFrom, periodTo) {
    let periodFromTo = { from: periodFrom, to: periodTo }
    return {
        type: types.INVOICE_SEARCH_PERIOD_FROMTO,
        payload: periodFromTo
    };
}
export function isCredit(invoice: Invoice): boolean {
    return (typeof invoice.open_amount !== "undefined" && invoice.open_amount < 0);
}
export function isOverDue(invoice: Invoice) {
    const datearray: any = invoice.due_date.split('-');
    const dueDate = new Date(datearray[2], datearray[0] - 1, datearray[1]);
    const todaysDate = new Date();
    return dueDate < todaysDate;
}
export function payerInvoiceDueAmount(data) {
    
    return (dispatch) => {
        let openAmout: string[] = [];
        let totalCredits: string[] = [];
        let totalPastDue: string[] = [];
        let dueamount: number = 0;
        let totalCreditsAmount: number = 0;
        let totalPastDueAmount: number = 0;
        let balance: number = 0;
        let payeruncheck: number = 0;
        if (data.length > 0) {
            data.forEach(element => {
             //   if (!isCredit(element) && element.item_is_a_payment != "X") {
                if (!isCredit(element)) {
                    openAmout.push(element.open_amount);
                    let total = openAmout.reduce((a, x) => a + x);
                    dueamount = parseFloat(total);
                    
                }
                if (isCredit(element)) {
                    totalCredits.push(element.open_amount);
                    let total = totalCredits.reduce((a, x) => a + x);
                    totalCreditsAmount = parseFloat(total);
                    
                }
               // if (element.item_is_a_payment != "X" && !isCredit(element) && isOverDue(element)) {
                if ( !isCredit(element) && isOverDue(element)) {
                    totalPastDue.push(element.open_amount);
                    let total = totalPastDue.reduce((a, x) => a + x);
                    totalPastDueAmount = parseFloat(total);
                }
                balance = dueamount + totalCreditsAmount
                
            });
            dispatch({ type: types.PAYER_DUE_AMOUNT, payload: dueamount });
            dispatch({ type: types.TOTAL_CREDIT_AMOUNT, payload: totalCreditsAmount });
            dispatch({ type: types.TOTAL_BALANCE_AMOUNT, payload: balance });
            dispatch({ type: types.TOTAL_PASTDUE_AMOUNT, payload: totalPastDueAmount });
        }
        else {
            dispatch({ type: types.PAYER_DUE_AMOUNT, payload: payeruncheck });
            dispatch({ type: types.TOTAL_CREDIT_AMOUNT, payload: payeruncheck });
            dispatch({ type: types.TOTAL_PASTDUE_AMOUNT, payload: payeruncheck });
            dispatch({ type: types.TOTAL_BALANCE_AMOUNT, payload: payeruncheck });
        }
    }
}
