import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { useIntl, IntlShape } from 'react-intl';
import { BootstrapField, BootstrapError } from '../../helpers/Field';

function schema(t: IntlShape) {
    return yup.object().shape({
        primary_account: yup.string()
            .matches(/^[a-zA-Z0-9]*$/, t.formatMessage({ id: "errors.invalid_characters" }))
            .required(t.formatMessage({ id: "errors.required" })),
        account_type: yup.string()
            .required(t.formatMessage({ id: "errors.required" })),
        sales_organization: yup.string()
            .matches(/^[a-zA-Z0-9]*$/, t.formatMessage({ id: "errors.invalid_characters" }))
            .required(t.formatMessage({ id: "errors.required" })),
        company_code: yup.string()
            .matches(/^[a-zA-Z0-9]*$/, t.formatMessage({ id: "errors.invalid_characters" }))
            .required(t.formatMessage({ id: "errors.required" })),
        division: yup.string()
            .matches(/^[a-zA-Z0-9]*$/, t.formatMessage({ id: "errors.invalid_characters" }))
            .required(t.formatMessage({ id: "errors.required" })),
        distribution_channel: yup.string()
            .matches(/^[a-zA-Z0-9]*$/, t.formatMessage({ id: "errors.invalid_characters" }))
            .required(t.formatMessage({ id: "errors.required" }))
    });
}

function AddManualSapAccount(props: any) {


    const intl = useIntl();
    const t = (id: string) => intl.formatMessage({ id: id });

    const _salesOrgs: string[] = (props.salesOrgs || []).map((item: any) => item.sales_organization);
    const _companyCodes: string[] = (props.companyCodes || []).map((item: any) => item.company_code);

    const initialAccount: any = { ...props.account };
    if (!initialAccount.company_code && _companyCodes.length === 1) {
        initialAccount.company_code = _companyCodes[0];
    }

    if (!initialAccount.sales_organization && _salesOrgs.length === 1) {
        initialAccount.sales_organization = _salesOrgs[0];
    }

    // Formik needs properties as empty strings instead of null.

    for (let propertyName in initialAccount) {
        if (initialAccount[propertyName] === null) {
            initialAccount[propertyName] = '';
        }
    }

    return (
        <div>
            <Formik
                validationSchema={schema(intl)}
                onSubmit={(values, bag) => {

                    bag.setSubmitting(true);
                    props.saveAccount(values).then(() => {
                        // bag.setSubmitting(false);
                    });
                }}
                initialValues={initialAccount}
                enableReinitialize={true}
                render={(formProps: any) => (
                    <form onSubmit={formProps.handleSubmit}>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group d-flex flex-row">
                                    <label className="wa-label col-form-label pr-2 col-4 text-nowrap" >{t('sap.account')}</label>
                                    <div className="flex-grow-1 col-8">
                                        <BootstrapField type="text" name="primary_account" placeholder={t('sap.account')} />
                                    </div>
                                </div>
                                {props.accountTypes.length > 1 && (
                                    <div className="form-group d-flex flex-row">
                                        <label className="wa-label col-form-label pr-2 col-4 text-nowrap">{t('sap.account_type')}</label>
                                        <div className="flex-grow-1 col-8">
                                            <BootstrapField
                                                component="select"
                                                name="account_type"
                                                value={formProps.values.account_type}
                                            >
                                                <option value="" disabled>
                                                    {t('sap.account_type')}
                                                </option>
                                                {props.accountTypes.map((accountType: any) => (
                                                    <option value={accountType.accountTypeId} key={accountType.accountTypeId}>
                                                        {accountType.accountTypeValue}
                                                    </option>
                                                ))}
                                            </BootstrapField>
                                            <BootstrapError name="account_type" />
                                        </div>
                                    </div>
                                )}
                                <div className="form-group d-flex flex-row">
                                    <label className="wa-label col-form-label pr-2 col-4 text-nowrap">{t('sap.company_code')}</label>
                                    <div className="flex-grow-1 col-8">
                                        {_companyCodes.length === 1 ? (
                                            <BootstrapField
                                                component="input"
                                                type="text"
                                                name="company_code"
                                                value={_companyCodes[0]}
                                                readOnly
                                            />
                                        ) : (
                                            <BootstrapField component="select" name="company_code">
                                                <option value="" disabled>
                                                    {t('sap.company_code')}
                                                </option>
                                                {_companyCodes.map((companyCode) => (
                                                    <option value={companyCode} key={companyCode}>
                                                        {companyCode}
                                                    </option>
                                                ))}
                                            </BootstrapField>
                                        )}
                                        <BootstrapError name="company_code" />
                                    </div>
                                </div>
                                <div className="form-group d-flex flex-row">
                                    <label className="wa-label col-form-label pr-2 col-4 text-nowrap">{t('sap.sales_organization')}</label>
                                    <div className="flex-grow-1 col-8">
                                        {_salesOrgs.length === 1 ? (
                                            <BootstrapField
                                                component="input"
                                                type="text"
                                                name="sales_organization"
                                                value={_salesOrgs[0]}
                                                readOnly
                                            />
                                        ) : (
                                            <BootstrapField component="select" name="sales_organization">
                                                <option value="" disabled>
                                                    {t('sap.sales_organization')}
                                                </option>
                                                {_salesOrgs.map((sales_organization) => (
                                                    <option value={sales_organization} key={sales_organization}>
                                                        {sales_organization}
                                                    </option>
                                                ))}
                                            </BootstrapField>
                                        )}
                                        <BootstrapError name="sales_organization" />
                                    </div>
                                </div>
                                <div className="form-group d-flex flex-row">
                                    <label className="wa-label col-form-label pr-2 col-4 text-nowrap">
                                        {t('sap.distribution_channel')}
                                    </label>
                                    <div className="flex-grow-1 col-8">
                                        <BootstrapField type="text" name="distribution_channel" placeholder={t('sap.distribution_channel')} />
                                        <BootstrapError name="distribution_channel" />
                                    </div>
                                </div>
                                <div className="form-group d-flex flex-row">
                                    <label className="wa-label col-form-label pr-2 col-4 text-nowrap">Company Division</label>
                                    <div className="flex-grow-1 col-8">
                                        <BootstrapField type="text" name="division" placeholder={'Company Division'} />
                                        <BootstrapError name="division" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <button type="submit" className={'btn btn-primary buttonColor buttonsize'}>
                                Save
                            </button>
                        </div>
                    </form>
                )}
            />
        </div>
    );
}

export default connect<any, any, any, any>((store, props) => ({
    salesOrgs: store.app.config.sap.data?.sales_area_data,
    companyCodes: store.app.config.sap.data?.company_codes,
}))(AddManualSapAccount);