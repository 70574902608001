import React, {Component} from 'react';
import {completePasswordReset} from "../../requests/UserRequests";
import ResetPasswordFinishForm from "./ResetPasswordFinishForm";
import SimpleHeader from "../header/SimpleHeader";
import { Footer } from "../footer/Footer";
import { FormattedMessage, useIntl } from "react-intl";

class ResetPasswordFinishPage extends Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {
      userNotFound: false,
      user: null
    }
  }


  componentDidMount() {
    this.props.getUserByPasswordReset(this.props.passwordReset).then((user) => {
      this.setState({user: user});
    }).catch(() => {
      this.setState({userNotFound: true});
    });
  }

  render() {
    return (
      <React.Fragment>
        <SimpleHeader/>
        <div className={"container mt-5"}>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mx-auto">
              <div className="card">
                {this.state.user &&
                                <ResetPasswordFinishForm onSubmit={completePasswordReset} id={this.props.passwordReset}/>}
                {this.state.userNotFound && <div>
                                <div className="invoice-currency-warning">
                                    <FormattedMessage id={"ResetPassword.ErrorMessage"}></FormattedMessage>
                                       
                                </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </React.Fragment>
    );
  }
}

export default ResetPasswordFinishPage;
