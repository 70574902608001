import React from "react";
import {getCurrencySymbol} from "./invoiceHelpers";
import {FormattedNumber, IntlShape} from "react-intl";

export function CurrencyString(value: number, currency: string, intl: IntlShape) {
    let isNegative = false;
    let displayValue = value;
    if (value < 0) {
        isNegative = true;
        displayValue = Math.abs(value);
    }
    return `${isNegative ? "-" : ""}${getCurrencySymbol(currency)} ${intl.formatNumber(displayValue, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })}`;
}


export function CurrencySymbol(currency: string) {
    return `${getCurrencySymbol(currency)}`; 
}

export function CurrencyDisplay(props: { currency: string, value: any }) {
    let displayValue = props.value ?? 0;
    if(displayValue == 0){
        displayValue = '0.00';
    }
    let isNegative = false;
    if (props.value < 0) {
        isNegative = true;
        displayValue = Math.abs(props.value);
    }

    if (Number.isNaN(props.value)) {
        displayValue = 0;
    }

    if (props.currency == "JPY") {
        return (<>
            <span>{isNegative && "-"}{getCurrencySymbol(props.currency)} </span> < FormattedNumber
            value={displayValue}
            currency={props.currency}
        /></>)
    } else {
        return (<>
            <span>{isNegative && "-"}{getCurrencySymbol(props.currency)} </span> < FormattedNumber
            value={displayValue}
            currency={props.currency}
            minimumFractionDigits={2}
            maximumFractionDigits={2}
        /></>)
    }
}