import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    addSoldToToSelected,
    removeSoldToFromSelected,
    clearSelectedInvoices,
    setSelectedSoldTos,  
    setsearchedInvoices,
    setsearchedInvoiceNumber,
    setsearchedinvoiceDueDate,
    setinvoiceSearchedPeriodFromTo,
    setsearchedinvoiceStatus,
    setinvoiceSearchedPeriod,
    selectPayer,
} from '../../actions/InvoiceActions';
import {getSelectedPrimaryAccount} from "../../selectors/Selectors";

class SoldToSelectAll extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
        allSelected: true
    };
    this.handleCustomerCheckChange = this.handleCustomerCheckChange.bind(this);
    this.createSelectItems = this.createSelectItems.bind(this);
    // this.getSoldToDetailsForInvoices = this.getSoldToDetailsForInvoices.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.allSelected = this.allSelected.bind(this);
  }
  
  // componentDidMount(){
  //   this.getSoldToDetailsForInvoices();
  // }


  selectAll(){
    if(this.allSelected()){
      this.props.setSelectedSoldTos([]);
    } else {
      this.props.setSelectedSoldTos(this.props.secondaryAccounts.map(s => s.PrimaryAcct));
    }
  }
  
  allSelected() {
    return this.props.secondaryAccounts.length === this.props.selectedSoldTos.length;
  }


  handleCustomerCheckChange = (target) => {
    this.props.clearSelectedInvoices();
    const value = target.currentTarget.id;
    const isChecked = target.currentTarget.checked;

    if (isChecked){
      this.props.addSoldToToSelected(value);
    } else {
      this.props.removeSoldToFromSelected(value);
      this.props.setsearchedInvoiceNumber('');
      this.props.setsearchedinvoiceDueDate('', '');
      this.props.setinvoiceSearchedPeriodFromTo('', '');
      this.props.setsearchedinvoiceStatus('03');
      this.props.setinvoiceSearchedPeriod("all");
      this.props.selectPayer(isNaN(parseInt(value)) ? parseInt(value) : value);
      this.props.setsearchedInvoices([]);  
      this.props.clearSelectedInvoices();  

    }
  };
  
  getName(customer){
    let name = "" + customer.PrimaryAcct;
    if(typeof customer.Name !== "undefined" && customer.Name !== null){
      return name + " - " + customer.Name;
    }
    return name;
  }

  createSelectItems() {
    const account = this.props.selectedPrimaryAccount && this.props.selectedPrimaryAccount.PrimaryAcct ? this.props.selectedPrimaryAccount.PrimaryAcct : '';
    const selectedItems = this.props.selectedSoldTos;

    let items: any[] = [];
    for (let l = 0; l < this.props.secondaryAccounts.length; l += 1) {
      const customer = this.props.secondaryAccounts[l];

      let itemColor = 'checkbox checkbox-primary dropdown-item truncate ';
      let isChecked = false;
      if (selectedItems.indexOf(customer.PrimaryAcct) !== -1){
        itemColor = 'checkbox checkbox-primary dropdown-item truncate multiSelectItemColor';
        isChecked = true;
      }
      
      items.push(<li key={`li_${customer.PrimaryAcct}`}>
        <div className={itemColor} key={`div_${customer.PrimaryAcct}`}>
          <label key={`lbl_${customer.PrimaryAcct}`} htmlFor={`lbl_${customer.PrimaryAcct}`}>
            <input className="checkMargin" type="checkbox" key={`${account}${customer.PrimaryAcct}`} id={customer.PrimaryAcct} onChange={this.handleCustomerCheckChange} checked={isChecked} />{this.getName(customer)}
          </label>
        </div>
      </li>);
    }

    return items;
  }
  
  render() {
    return (
      <div className="btn-group btn-block soldto-select customSelect">
        <button className="form-control soldToBtnColor btn-block dropdown-toggle" id="soldToToggle" type="button" data-toggle="dropdown">
          <span>{ this.props.selectedSoldTos ? this.props.selectedSoldTos.length : 0 } Customers Selected</span>          
          <span className="select-arrow">
            <i className="fas fa-chevron-down"></i>
          </span>
        </button>
        
        <ul className="dropdown-menu multiSelectMenuHeight">
          <li>
            <div className="checkbox checkbox-primary dropdown-item truncate multiSelectItemColor">
              <label htmlFor={`lbl_ship_to_select_all`}>
                <input className="checkMargin" type="checkbox" id="lbl_ship_to_select_all" onChange={this.selectAll} checked={this.allSelected()} />Select All
              </label>
            </div>
          </li>
          {this.createSelectItems()}
        </ul>
      </div>
    );
  }
}

export default connect<any, any, any, any>((store,props) => ({
  secondaryAccounts: store.user_options.secondaryAccounts,
  selectedPrimaryAccount: getSelectedPrimaryAccount(store),
  selectedSoldTos: store.user_options.selectedSoldTos,
  invoices: store.default.invoices,
}), {
  addSoldToToSelected,
  clearSelectedInvoices,
  removeSoldToFromSelected,
  setSelectedSoldTos,
  setsearchedInvoices,
  setsearchedInvoiceNumber,
  setsearchedinvoiceDueDate,
  setinvoiceSearchedPeriodFromTo,
  setsearchedinvoiceStatus,
  setinvoiceSearchedPeriod,
  selectPayer,
})(SoldToSelectAll);
