import React, { useRef, useState, useEffect, Component } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import {
  getInvoiceQuery,
  getSelectedInvoiceKeys,
  getSelectedPrimaryAccount,
  getSelectedSecondaryAccount,
  getInvoiceQueryforPDFandCSVandexcel,
} from "../../selectors/Selectors";
import {
  addInvoiceToSelected,
  removeInvoiceFromSelected,
  payerInvoiceDueAmount,
} from "../../actions/InvoiceActions";
import _ from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import { Invoice, InvoiceWithKey } from "../../interfaces/invoice";
import { AccountType, Account } from "../../interfaces/Accounts";
import { getInvoiceKey } from "../../utils";
import { useHasMultiCurrency, CurrencySelector } from "./CurrencySelector";
import { useInvoiceTotal } from "../../hooks/InvoiceHooks";
import { RootState } from "../../reducers/RootReducer";
import { InvoiceCard } from "./InvoiceCard";
import { CheckSelect, MultiSelect } from "../../common/MultiSelect";
import { useMediaQuery } from "react-responsive";
import { buildColumnConfigs } from "./InvoiceTableObject";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import {
  DownloadExcelButton,
  DownloadPdfZipButton,
  DownloadPdfZipButtonwithCurrency,
  DownloadExcelButtonwithCurrency,
} from "./DownloadPdfZipButton";
import { DropdownButton, Dropdown, Button } from "react-bootstrap";
import {
  useCanMakePayments,
  useCurrencyDecimalLookup,
} from "../../selectors/ConfigSelectors";
import {
  isPayment,
  sortStringDate,
  dueDateSort,
  statementIdFormatter,
} from "../../common/invoiceHelpers";
import { boolean, object } from "yup";
import ExportExcel from "./ExportExcel";
import {
  documentNumberFormatter,
  dueFormatter,
  isCredit,
  isInvoice,
  moneyTableCell,
  paidFormatter,
  pdfFormatter,
  querySort,
} from "../../common/invoiceHelpers";
import moment from "moment";
import {
  clearSelectedInvoices,
  setSelectedCurrency,
} from "../../actions/InvoiceActions";
import { useHistory } from "react-router";

const { ExportCSVButton } = CSVExport;
let AvailableCurrencies: string[] = [];
let invoicesWithKeys: InvoiceWithKey[] = [];
let DDLSelectedCurrency = "";

interface TableConfig {
  columns: { [key: string]: string }[];
}

interface IComponentOwnProps {
  isSearch: boolean;
}

interface IComponentStoreProps {
  accountType: AccountType;
  secondaryAccounts: Account[];
  selectedPrimaryAccount: Account;
  invoices: Invoice[];
  selectedInvoicesKeys: string[];
  tableConfig: TableConfig;
  selectedSoldTos: string[];
}

interface InvoiceSearchComponentOwnProps {
  isSearch: boolean;
  invoices: Invoice[];
  invoiceSearchQuery: any;
}

interface InvoiceListSearchProps {
  accountType: any;
  secondaryAccounts: any;
  selectedPrimaryAccount: any;
  selectedInvoicesKeys: string[];
  tableConfig: TableConfig;
  selectedSoldTos: string[];
}

interface IComponentDispatchProps {
  addInvoiceToSelected: any;
  removeInvoiceFromSelected: any;
  payerInvoiceDueAmount: any;
}

type IComponentProps = IComponentOwnProps &
  IComponentStoreProps &
  IComponentDispatchProps;

interface InvoiceListInternalProps {
  isSearch: boolean;
}

function _InvoiceList(props) {

  const hasMultiCurrency = useHasMultiCurrency();
  const history = useHistory();
  const selectedCurrency = useSelector<RootState, string>(
    (state) => state.default.selectedCurrencyOptionValue
  );
  const canMakePayment = useCanMakePayments();
  const decimalLookup = useCurrencyDecimalLookup();
  const [title, settitle] = useState("hhhh");
  const [data, setchildData] = useState([]);
  const [tempselectedCurrency, settempselectedCurrency] = useState([]);
  const isTablet = useMediaQuery({ query: "(max-width: 820px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const intl = useIntl();
  const f = (id) => intl.formatMessage({ id: id });
  const [invoicesList, setInvoicesList] = useState(props.invoices);
    const dispatch = useDispatch();
   
  let SortOptions = [
    {
      label: f("invoice_export.doc-low-to-high"),
      value: "1",
    },
    {
      label: f("invoice_export.doc-high-to-low"),
      value: "2",
    },
    {
      label: f("invoice_export.due-low-to-high"),
      value: "3",
    },
    {
      label: f("invoice_export.due-high-to-low"),
      value: "4",
    },
  ];

  function onRowSelect(row: Invoice, isSelected) {
    if (isSelected) {
      props.addInvoiceToSelected(row, decimalLookup(row.currency_key));
    } else {
      props.removeInvoiceFromSelected(row, decimalLookup(row.currency_key));
    }
  }

  function renderHeaderCheckbox({ mode, checked, indeterminate }) {
    return (
      <div className="column-header text-center" style={{}}>
        <span>
          <FormattedMessage id={"invoice_table.pay"} />
        </span>
      </div>
    );
  }

  function onSelectAll(isSelect, rows, e) {
    rows.forEach((row) => {
      if (isSelect) {
        props.addInvoiceToSelected(row, decimalLookup(row.currency_key));
      } else {
        props.removeInvoiceFromSelected(row, decimalLookup(row.currency_key));
      }
    });
  }

  function clickSelectAll(e) {
    e.preventDefault();
    invoicesList.forEach((invoice) => {

      props.addInvoiceToSelected(invoice, decimalLookup(invoice.currency_key));
    });
    //props.invoicesList.filter((invoice) => !isNotSelectable(invoice))
    //    .filter(filterFilteredInvoices)
    //    .forEach(invoice => {
    //        props.addInvoiceToSelected(invoice, decimalLookup(invoice.currency_key));
    //    });
  }

  function filterInvoices(invoice, e) {
    //if (
    //    hasMultiCurrency &&
    //    e &&
    //    e !== invoice.currency_key
    //) {
    //    return false;
    //}
    if (
      props.accountType === AccountType.Payer ||
      props.accountType === AccountType.PayerAll
    ) {
      if (
        props.selectedSoldTos.length > 0 &&
        invoice.finance_document_type == "DZ" && 
        invoice.finance_document_only == "X"
      ) {
        return true;
      } else {
        return (
          props.selectedSoldTos.indexOf(
            _.trimStart(invoice.soldto_number, "0")
          ) !== -1
        );
      }
    }
    return true;
  }

  function filterFilteredInvoices(invoice) {
    if (
      hasMultiCurrency &&
      props.selectedCurrency &&
      props.selectedCurrency !== invoice.currency_key
    ) {
      return false;
    }
    if (
      props.accountType === AccountType.Payer ||
      props.accountType === AccountType.PayerAll
    ) {
      if (
        props.selectedSoldTos.length > 0 &&
        invoice.finance_document_type == "DZ" &&
          invoice.finance_document_only == "X" 
          
      ) {
        return true;
      } else {
        return (
          props.selectedSoldTos.indexOf(
            _.trimStart(invoice.soldto_number, "0")
          ) !== -1
        );
      }
    }
    return true;
  }

  function clickDeselectAll(e) {
    e.preventDefault();
    invoicesList.forEach((invoice) => {
      props.removeInvoiceFromSelected(invoice);
    });
    //props.invoicesList.filter((invoice) => !isNotSelectable(invoice))
    //    .filter(filterFilteredInvoices)
    //    .forEach(invoice => {
    //        props.removeInvoiceFromSelected(invoice);
    //    });
  }

  function isNotSelectable(invoice: Invoice) {
    if (
      isPayment(invoice) ||
      (hasMultiCurrency && invoice.currency_key !== props.selectedCurrency)
    ) {
      return true;
    }
    return props.isSearch;
  }

  function hideSelect(): boolean {
    return props.isSearch || !canMakePayment || props.PaymentDisable;
  }
  const selectRowProp = {
    mode: "checkbox",
    onSelect: onRowSelect,
    onSelectAll: onSelectAll,
    selected: props.selectedInvoicesKeys,
    selectionRenderer: MultiSelect,
    selectionHeaderRenderer: renderHeaderCheckbox,
    nonSelectable: props.invoices
      .filter((invoice) => isNotSelectable(invoice))
      .map((invoice) => {
        return getInvoiceKey(invoice);
      }),
    hideSelectColumn: hideSelect(),
  };
  const tab = useRef("table");
  const top = "Top";

  function isPdfFilenotExists(invoicesWithKeys): boolean {
    const PDFFileIds: string[] = [];
    if (sessionStorage.getItem("invoicesWithKeysData") != null) {
      sessionStorage.removeItem("invoicesWithKeysData");
    }
    var pdfExists = false;
    invoicesWithKeys.forEach((invoice) => {
      if (invoice.pdf_document_available === "X") {
        pdfExists = true;
        if (
          invoice.billing_document_number != null &&
   
            invoice.billing_document_type !== "01" && invoice.soldto_number != null
        ) {
            PDFFileIds.push(invoice.billing_document_number, invoice.soldto_number);
        }
      }
    });
    sessionStorage.setItem("invoicesWithKeysData", JSON.stringify(PDFFileIds));
    return pdfExists;
  }

  //let invoices = props.invoices;
  //if (!props.isSearch) {
  //    invoices = props.invoices.filter(filterFilteredInvoices);
  //}

  //invoices = invoices.map((item) => {

  //    if (!Object.keys(item).includes('billing_document_number')) {
  //        return { ...item, billing_document_number: '0' };
  //    }

  //    return item;

  //});

  useEffect(() => {
    dispatch(setSelectedCurrency(""));
    DDLSelectedCurrency = "";

    let currencies: string[] = [];
    invoicesWithKeys = [];
    let invoicestemp: any[] = [];

    props.invoices.forEach((invoice) => {
      if (currencies.indexOf(invoice.currency_key) === -1) {
        currencies.push(invoice.currency_key);
      }
    });
    if (currencies.length > 0) {
      let value;

      value = currencies[0].toString();

      if (currencies.includes("USD")) {
        value = "USD";
      } else {
        value = currencies[0].toString();
      }

      if (!props.isSearch) {
        invoicestemp = props.invoices.filter(
          (item) => item.currency_key === value
        );
        dispatch(setSelectedCurrency(value));
      } else {
        invoicestemp = props.invoices;
      }
    } else {
      let value;
      if (
        props.selectedCurrency === "" ||
        props.selectedCurrency === undefined
      ) {
        value = currencies[0];
      } else {
        value = props.selectedCurrency;
      }

      invoicestemp = props.invoices.filter(
        (item) => item.currency_key === value
      );
      dispatch(setSelectedCurrency(value));
    }

    if (!props.isSearch) {
      invoicestemp = invoicestemp.filter(filterFilteredInvoices);

      invoicestemp = invoicestemp.map((item: any) => {
        if (!Object.keys(item).includes("billing_document_number")) {
          return { ...item, billing_document_number: "0" };
        }
        return item;
      });
    } else {
      invoicestemp = invoicestemp.map((item: any) => {
        if (!Object.keys(item).includes("billing_document_number")) {
          return { ...item, billing_document_number: "0" };
        }
        return item;
      });
      }
    //  if (!props.isSearch && props.StatementId) { 

    //      invoicestemp = invoicestemp.filter(filterFilteredInvoices);

    //      invoicestemp = invoicestemp.map((item: any) => {
    //          if (!Object.keys(item).includes("invoice_filter")) {
    //              return { ...item, billing_document_number: "0" };
    //          }
    //          return item;
    //      });

    //}
    setInvoicesList(invoicestemp);

    // settitle("GridUpdated");
  }, [props.invoices, props.selectedSoldTos]);


  // useEffect(() => {
  //   console.log(invoicesList);
  //   let invoicesLocal: any[] = [];
  //   if (!props.isSearch) {
  //     invoicesLocal = invoicesList.filter(filterFilteredInvoices);

  //     invoicesLocal = invoicesLocal.map((item: any) => {
  //       if (!Object.keys(item).includes("billing_document_number")) {
  //         return { ...item, billing_document_number: "0" };
  //       }
  //       return item;
  //     });
  //   } else {
  //     invoicesLocal = invoicesLocal.map((item: any) => {
  //       if (!Object.keys(item).includes("billing_document_number")) {
  //         return { ...item, billing_document_number: "0" };
  //       }
  //       return item;
  //     });
  //   }

  //   console.log(invoicesLocal);
  //   setInvoicesList(invoicesLocal);

  //   //settitle("GridUpdated");
  // }, [props.selectedSoldTos]);

    let columns = buildColumnConfigs(intl, props.tableConfig, isTablet,props.StatementId);
 

  const handleCurrencySelect = (e) => {
    if (AvailableCurrencies.length == 1) {
      DDLSelectedCurrency = AvailableCurrencies[0].toString();
    } else {
      DDLSelectedCurrency = e;
    }
    const newList = props.invoices
      .filter((item) => item.currency_key === e)
      .filter(filterInvoices);
    // setInvoices(newList);

    if (!props.isSearch) {
      props.invoices.forEach((invoice) => {
        props.removeInvoiceFromSelected(
          invoice,
          decimalLookup(invoice.currency_key)
        );
      });
    }
    if (e != "All") {
      setInvoicesList(newList);
      dispatch(setSelectedCurrency(e));
    } else {
      setInvoicesList(props.invoices);
      DDLSelectedCurrency = "";
      dispatch(setSelectedCurrency(""));
    }
    // if (e != "All") {
    //   if (AvailableCurrencies.length == 1) {
    //     DDLSelectedCurrency = AvailableCurrencies[0].toString();
    //   } else {
    //     DDLSelectedCurrency = e;
    //   }
    //   const newList = props.invoices
    //     .filter((item) => item.currency_key === e)
    //     .filter(filterInvoices);
    //   // setInvoices(newList);
    //   dispatch(setSelectedCurrency(e));
    //   if (!props.isSearch) {
    //     props.invoices.forEach((invoice) => {
    //       props.removeInvoiceFromSelected(
    //         invoice,
    //         decimalLookup(invoice.currency_key)
    //       );
    //     });
    //   }
    //   // invoicesWithKeys = [];
    //   // invoicesWithKeys = newList.map((_: any) => {
    //   //   _._key = getInvoiceKey(_);
    //   //   return _ as InvoiceWithKey;
    //   // });
    //   console.log(newList);
    //   setInvoicesList(newList);
    // } else {
    //   if (AvailableCurrencies.length == 1) {
    //     DDLSelectedCurrency = AvailableCurrencies[0].toString();
    //   } else {
    //     DDLSelectedCurrency = "";
    //   }

    //   console.log(props.invoices);
    //   // invoicesWithKeys = [];
    //   // invoicesWithKeys = props.invoices.map((_: any) => {
    //   //   _._key = getInvoiceKey(_);
    //   //   return _ as InvoiceWithKey;
    //   // });
    // }
    // settitle("GridUpdated");
    // if (sessionStorage.getItem("searched") != null) {
    //   sessionStorage.removeItem("searched");
    // }
  };

  //useEffect(() => {
  //    if (!props.isSearch) {
  //        handleCurrencySelect("USD");
  //    }
  //}, [props.invoices]);

  const handleSortSelect = (e) => {
    switch (e) {
      case "1":
        setSelectedDueFunction("");
        setSelectedFunction("asc");
        return;
      case "2":
        setSelectedDueFunction("");
        setSelectedFunction("desc");

        return;
      case "3":
        setSelectedFunction("");
        setSelectedDueFunction("desc");
        return;
      case "4":
        setSelectedFunction("");
        setSelectedDueFunction("asc");
        return;
      default:
        return;
    }
  };

  // if (sessionStorage.getItem("searched") == "true") {
  //   handleCurrencySelect("All");
  //   DDLSelectedCurrency = "";
  // }
  //const SelectInvoiceBasedOnCurrency = () => {
  //    if (invoicesWithKeys.length === 0) {
  //        AvailableCurrencies = [];
  //    }
  //    invoicesWithKeys.forEach((invoice) => {
  //        if (AvailableCurrencies.indexOf(invoice.currency_key) === -1) {
  //            AvailableCurrencies.push(invoice.currency_key)
  //        }
  //    });
  //    if (AvailableCurrencies.length === 1) {
  //        DDLSelectedCurrency = AvailableCurrencies[0].toString();
  //    }
  //    settitle("DDLUpdated");
  //}

  const LoadCurrencies = () => {
    if (props.invoices.length === 0) {
      AvailableCurrencies = [];
    }
    props.invoices.forEach((invoice) => {
      if (AvailableCurrencies.indexOf(invoice.currency_key) === -1) {
        AvailableCurrencies.push(invoice.currency_key);
      }
    });
    if (AvailableCurrencies.length === 1) {
      DDLSelectedCurrency = AvailableCurrencies[0].toString();
    }

    // settitle("DDLUpdated");
  };

  //useEffect(() => {
  //    invoicesWithKeys = invoices.map((_: any) => {
  //        _._key = getInvoiceKey(_);
  //        return _ as InvoiceWithKey;
  //    });

  //    if (!props.isSearch) {
  //        if (invoicesWithKeys.length > 0) {
  //            props.payerInvoiceDueAmount(invoicesWithKeys);
  //        }
  //    }
  //    else {
  //        SelectInvoiceBasedOnCurrency();
  //    }

  //});

  useEffect(() => {

    if (!props.isSearch || history.action === "POP") {
      invoicesWithKeys = invoicesList.map((_: any) => {
        _._key = getInvoiceKey(_);
        return _ as InvoiceWithKey;
      });

      if (invoicesWithKeys.length > 0) {
        props.payerInvoiceDueAmount(invoicesWithKeys);
      }
    }
      LoadCurrencies();
     
  }, [invoicesList]);

  

  function showDocumentSort(event: any, field: string) {
    var target = event.target;
    var asc = target.classList.contains("asc");
    var desc = target.classList.contains("desc");

    const duesortableItems = document.querySelectorAll(".duesortable");
    duesortableItems.forEach(function (item) {
      item.classList.remove("asc");
      item.classList.remove("desc");
    });
    setSelectedDueFunction("");

    const sortableItems = document.querySelectorAll(".sortable");
    sortableItems.forEach(function (item) {
      item.classList.remove("asc");
      item.classList.remove("desc");
      if (desc || (!asc && !desc)) {
        item.classList.add("asc");

        setSelectedFunction("asc");
      } else {
        item.classList.add("desc");
        setSelectedFunction("desc");
      }
    });
  }

  // function showDueSort(event: any) {

  //     var target = event.target;
  //     var asc = target.classList.contains('asc');
  //     var desc = target.classList.contains('desc');

  //     const sortableItems = document.querySelectorAll(".sortable");
  //     sortableItems.forEach(function (item) {
  //         item.classList.remove('asc')
  //         item.classList.remove('desc');

  //     });

  //     setSelectedFunction("")
  //     const dueSortableItems = document.querySelectorAll(".duesortable");
  //     dueSortableItems.forEach(function (item) {
  //         item.classList.remove('asc')
  //         item.classList.remove('desc');
  //         if (desc || (!asc && !desc)) {
  //             item.classList.add('asc');
  //             setSelectedDueFunction('asc');

  //         } else {
  //             item.classList.add('desc');
  //             setSelectedDueFunction('desc');

  //         }
  //     });
  // }

  const [selectFunction, setSelectedFunction] = useState("");

  function docDefaultSort(rowA: Invoice, rowB: Invoice) {
    return rowA.billing_document_number === rowB.billing_document_number
      ? 1
      : 1;
  }

  function docAscSort(rowA: Invoice, rowB: Invoice) {
    return rowA.billing_document_number > rowB.billing_document_number ? 1 : -1;
  }
  function docDescSort(rowA: Invoice, rowB: Invoice) {
    return rowA.billing_document_number > rowB.billing_document_number ? -1 : 1;
  }

  function getSortMethod() {
    switch (selectFunction) {
      case "asc":
        return docAscSort;
      case "desc":
        return docDescSort;

      default:
        return docDefaultSort;
    }
  }
  if (title != "GridUpdated" && title != "DDLUpdated") {
    invoicesWithKeys = invoicesList.map((_: any) => {
      _._key = getInvoiceKey(_);
      return _ as InvoiceWithKey;
    });
    LoadCurrencies();
    // console.log("inside GridUpdated");
  }
  const [selectDueFunction, setSelectedDueFunction] = useState("");

  function docDefaultDueSort(rowA: Invoice, rowB: Invoice) {
    return 1;
  }

  function docAscDueSort(rowA: Invoice, rowB: Invoice) {
    return dueDateSort(rowA, rowB, "asc"); // sortStringDate(rowA.due_date, rowB.due_date, "asc");
  }
  function docDescDueSort(rowA: Invoice, rowB: Invoice) {
    return dueDateSort(rowA, rowB, "desc"); // sortStringDate(rowA.due_date, rowB.due_date, "desc");
  }

  function getDueSortMethod() {
    switch (selectDueFunction) {
      case "asc":
        return docAscDueSort;
      case "desc":
        return docDescDueSort;

      default:
        return docDefaultDueSort;
    }
  }

  function displayCard(row: Invoice) {
    let i = 0;
    const defaultValue = () => {
      let key = getInvoiceKey(row);
      if (props.selectedInvoicesKeys.indexOf(key) !== -1) return true;
      else return false;
    };
    return (
      <div className="card rounded-lg card-invoice no-gutters">
        <div className="flex card-header card-header-invoice">
          <div className="row">
            <div className="col-2">
              <label
                className="form-check-label h-25 w-50  mt-3 rounded-circle"
                style={{ width: "33%" }}
              >
                <input
                  type="checkbox"
                  checked={defaultValue()}
                  defaultChecked={defaultValue()}
                  onChange={(event) => {
                    if (event.target.checked) {
                      props.addInvoiceToSelected(
                        row,
                        decimalLookup(row.currency_key)
                      );
                    } else {
                      props.removeInvoiceFromSelected(
                        row,
                        decimalLookup(row.currency_key)
                      );
                    }
                  }}
                />
              </label>
            </div>
            <div className="col-8">
              <div>
                <label className="mb-10 text-xl tracking-widest h5 bold">
                  {" "}
                  {f("invoice_table.document_number")}{" "}
                  {documentNumberFormatter("", row)}
                </label>
              </div>
              <div>
                <label className="h5  bold">
                  {f("invoice_table.due_date")}
                </label>
                <label className="h5 ml-3 ">
                  {" "}
                  {dueFormatter(row.due_date, row)}
                </label>
              </div>
            </div>
            <div className="col-2 mt-2">
              <span>{pdfFormatter("", row)}</span>
            </div>
          </div>
        </div>
        <div className="card-body card-body-invoice ">
          <div className="row  row-height" style={{ width: "350px" }}>
            <div className="col-4 h5">{f("invoice_table.total_amount")}</div>
            <div className="col h5">{f("invoice_table.paid_amount")}</div>
            <div className="col-4 h5">{f("invoice_table.open_amount")}</div>
          </div>
          <div className="row" style={{ width: "350px" }}>
            <div className="col-4 h5 main-table-cell-invoice ">
              {" "}
              {moneyTableCell(row.total_amount, row)}
            </div>
            <div className="col h5 main-table-cell-invoice">
              {" "}
              {moneyTableCell(row.paid_amount, row)}{" "}
            </div>
            <div className="col-4 h5 main-table-cell-invoice">
              {" "}
              {moneyTableCell(row.open_amount, row)}{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }

  // console.log("Global Currencies", AvailableCurrencies);

  return (
    <React.Fragment>
      {/* {// console.log(invoicesWithKeys)} */}
      <ToolkitProvider
        data={invoicesWithKeys}
        ref={tab}
        headerStyle={{
          background: "#DFECF5",
          height: "40px",
          borderCollapse: "collapse",
        }}
        classes={"wa-bootstrap-table-container custom-table-header"}
        scrollTop={top}
        columns={columns}
        keyField={"_key"}
        bootstrap4={true}
        exportCSV={{
          noAutoBOM: { autoBom: true },
        }}
      >
        {(toolkitProps) => (
          <>
            <div className="row py-2">
              <div className="col-12 col-md d-flex align-items-end order-2 order-md-0 ">
                {!hideSelect() && (
                  <div>
                    {invoicesWithKeys.length >
                    props.selectedInvoicesKeys.length ? (
                      <button
                        type="button"
                        className="btn btn-light btn-sm"
                        onClick={clickSelectAll}
                      >
                        <FormattedMessage id={"invoice_table.select_all"} />
                      </button>
                    ) : invoicesWithKeys.length === 0 ? null : (
                      <button
                        type="button"
                        className="btn btn-light btn-sm"
                        onClick={clickDeselectAll}
                      >
                        <FormattedMessage id={"invoice_table.deselect_all"} />
                      </button>
                    )}
                  </div>
                )}
              </div>
              {hasMultiCurrency &&
                props.selectedCurrency &&
                !props.isSearch && (
                  <div
                    className={
                      "col-12 col-md d-flex justify-content-center  align-items-baseline"
                    }
                  >
                    <span
                      className={"invoice-currency-warning"}
                      style={{ fontSize: "0.84rem", alignSelf: "center" }}
                    >
                      <FormattedMessage
                        id={"invoices_page.currency_warning"}
                        values={{ currency: selectedCurrency }}
                      />
                    </span>
                  </div>
                )}

              {props.isSearch &&
                invoicesWithKeys.length > 0 &&
                DDLSelectedCurrency != "" && (
                  <div
                    className={
                      "col-12 col-md d-flex justify-content-center  align-items-baseline"
                    }
                  >
                    <span
                      className={"invoice-currency-warning"}
                      style={{ fontSize: "0.84rem", alignSelf: "center" }}
                    >
                      <FormattedMessage
                        id={"invoices_page.currency_warning"}
                        values={{ currency: DDLSelectedCurrency }}
                      />
                    </span>
                  </div>
                )}

              <div
                className={
                  "col d-flex justify-content-end  align-items-baseline order-1 order-md-0"
                }
              >
                {isMobile && (
                  <DropdownButton
                    alignRight
                    size={"sm"}
                    title={intl.formatMessage({ id: "invoice_export.sort" })}
                    variant={"light"}
                    id="dropdown-sort"
                    onSelect={(e) => {
                      handleSortSelect(e);
                    }}
                  >
                    {SortOptions.map((item) => (
                      <Dropdown.Item eventKey={item.value}>
                        {item.label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                )}
                {
                  <DropdownButton
                    alignRight
                    size={"sm"}
                    title={intl.formatMessage({
                      id: "invoice_export.all_currency",
                    })}
                    variant={"light"}
                    id="dropdown-menu-currency"
                    onSelect={(e) => {
                      handleCurrencySelect(e);
                    }}
                  >
                    {props.isSearch && (
                      <Dropdown.Item eventKey="All">
                        {f("invoice_search.status_filter.all")}
                      </Dropdown.Item>
                    )}
                    {AvailableCurrencies.map((currency) => (
                      <Dropdown.Item eventKey={currency}>
                        {currency}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                }
                {invoicesWithKeys.length === 0 ||
                !isPdfFilenotExists(invoicesWithKeys) ? (
                  <DropdownButton
                    disabled
                    alignRight
                    size={"sm"}
                    title={intl.formatMessage({
                      id: "invoice_export.download_label",
                    })}
                    variant={"light"}
                    id="dropdown-menu-align-right"
                  >
                    <DownloadPdfZipButton
                      invoiceSearchQuery={props.invoiceSearchQuery}
                    />
                  </DropdownButton>
                ) : (
                  <DropdownButton
                    alignRight
                    size={"sm"}
                    title={intl.formatMessage({
                      id: "invoice_export.download_label",
                    })}
                    variant={"light"}
                    id="dropdown-menu-align-right"
                  >
                    {props.isSearch ? (
                      <DownloadPdfZipButton
                        invoiceSearchQuery={props.invoiceSearchQuery}
                      />
                    ) : (
                      <DownloadPdfZipButtonwithCurrency
                        invoiceQueryforPDFandCSVandexcel={
                          props.invoiceQueryforPDFandCSVandexcel
                        }
                      />
                    )}
                  </DropdownButton>
                )}
                {invoicesWithKeys.length === 0 ? (
                  <DropdownButton
                    disabled={true}
                    alignRight
                    size={"sm"}
                    title={intl.formatMessage({
                      id: "invoice_export.export_label",
                    })}
                    variant={"light"}
                    id="dropdown-menu-align-right"
                    className={"pl-2"}
                  >
                    <Dropdown.Item
                      onClick={(e) => {
                        e.preventDefault();
                        toolkitProps.csvProps.onExport();
                      }}
                    >
                      {intl.formatMessage({ id: "invoice_export.all_csv" })}
                    </Dropdown.Item>
                    <DownloadExcelButton
                      invoiceSearchQuery={props.invoiceSearchQuery}
                    />
                  </DropdownButton>
                ) : (
                  <DropdownButton
                    alignRight
                    size={"sm"}
                    title={intl.formatMessage({
                      id: "invoice_export.export_label",
                    })}
                    variant={"light"}
                    id="dropdown-menu-align-right"
                    className={"pl-2"}
                  >
                    <Dropdown.Item
                      onClick={(e) => {
                        e.preventDefault();
                        toolkitProps.csvProps.onExport();
                      }}
                    >
                      {intl.formatMessage({ id: "invoice_export.all_csv" })}
                    </Dropdown.Item>
                    {props.isSearch ? (
                      <ExportExcel
                        data={{
                          invoicesData: invoicesWithKeys,
                          columnsData: columns,
                        }}
                        className="dropdown-item !important"
                      />
                    ) : (
                      <ExportExcel
                        data={{
                          invoicesData: invoicesWithKeys,
                          columnsData: columns,
                        }}
                        className="dropdown-item !important"
                      />
                    )}
                  </DropdownButton>
                )}
              </div>
            </div>
            {/*{isMobile && invoices.map((invoice) => (*/}
            {/*    <InvoiceCard invoice={invoice}/>*/}
            {/*))}*/}
            {
              <div className="row">
                {isMobile &&
                  invoicesWithKeys
                    .sort(
                      selectFunction !== ""
                        ? getSortMethod()
                        : getDueSortMethod()
                    )
                    .map((key, index) => displayCard(key))}

                {!isMobile && (
                  <div className="col-12">
                    <BootstrapTable
                      {...toolkitProps.baseProps}
                      defaultSorted={[{ dataField: "due_date", order: "desc" }]}
                      selectRow={selectRowProp}
                      noDataIndication={() => "No documents found"}
                      classes={
                        "wa-bootstrap-table-container custom-table-header"
                      }
                    ></BootstrapTable>
                  </div>
                )}
              </div>
            }
          </>
        )}
      </ToolkitProvider>
    </React.Fragment>
  );
}

export const InvoiceList = connect<
  IComponentStoreProps,
  IComponentDispatchProps,
  IComponentOwnProps,
  any
>(
  (state, props) => {
    return {
      selectedPrimaryAccount: getSelectedPrimaryAccount(state),
      selectedSecondaryAccount: getSelectedSecondaryAccount(state),
      invoices: state.default.invoices,
      selectedInvoicesKeys: getSelectedInvoiceKeys(state),
      selectedSoldTos: state.user_options.selectedSoldTos,
      secondaryAccounts: state.user_options.secondaryAccounts,
      accountType: state.user_options.accountType,
      tableConfig: state.app.config.invoice_table,
      language: state.user.language,
      selectedCurrency: state.default.selectedCurrencyOptionValue,
      invoiceSearchQuery: getInvoiceQuery(state),
      invoiceQueryforPDFandCSVandexcel:
        getInvoiceQueryforPDFandCSVandexcel(state),
    };
  },
  {
    addInvoiceToSelected: addInvoiceToSelected,
    removeInvoiceFromSelected: removeInvoiceFromSelected,
    payerInvoiceDueAmount: payerInvoiceDueAmount,
  }
)(_InvoiceList);

export var InvoiceSearchList = connect<
  InvoiceListSearchProps,
  IComponentDispatchProps,
  InvoiceSearchComponentOwnProps,
  any
>(
  (state, props) => {
    return {
      selectedPrimaryAccount: getSelectedPrimaryAccount(state),
      selectedSecondaryAccount: getSelectedSecondaryAccount(state),
      selectedInvoicesKeys: getSelectedInvoiceKeys(state),
      selectedSoldTos: state.user_options.selectedSoldTos,
      secondaryAccounts: state.user_options.secondaryAccounts,
      accountType: state.user_options.accountType,
      tableConfig: state.app.config.invoice_table,
      language: state.user.language,
      dueSelect: state.invoice.dueSelect,
        selectedCurrency: state.default.selectedCurrencyOptionValue,
        statementId: state.invoice.statementId,
      //  statementId: state.invoice.statementId,
      //title: state.user.title,
    };
  },
  {
    addInvoiceToSelected: addInvoiceToSelected,
    removeInvoiceFromSelected: removeInvoiceFromSelected,
    payerInvoiceDueAmount: payerInvoiceDueAmount,
  }
)(_InvoiceList);
