import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getCurrencySymbol, isCredit, sapDateToDate, unwrap } from '../../common/invoiceHelpers';
import {
    removeInvoiceFromSelected,
    updatePayAmountForInvoice,
    updateReason,
    updateReasonComment
} from "../../actions/InvoiceActions";
import { toast } from 'react-toastify';
import { getSelectedPrimaryAccount, getPayerAccount } from "../../selectors/Selectors";
import { trimLeadingZeroes } from "../../utils";
import { AccountType } from "../../interfaces/Accounts";
import { isInvoiceOverpaymentAllowed, isPartialPaymentsAllowed } from "../../selectors/ConfigSelectors";
import { FormattedDate, useIntl } from "react-intl";
import { CurrencyDisplay, CurrencySymbol } from "../../common/CurrencyDisplay";
import { REASON_CODE } from "../../constants/ApplicationConstants";
import { getMaximumPaymentAllowed } from '../../requests/ConfigRequests';

function _InvoiceDetail(props) {
    const intl = useIntl();
    const f = id => intl.formatMessage({ id: id });
    const [maxPayment, setMaxPayment] = useState(0)

    let readOnly = (!props.isPartialPaymentsAllowed && !props.isInvoiceOverpaymentAllowed) || isCredit(props.invoice);
    let _isCredit = isCredit(props.invoice);

    useEffect(() => {
        getMaximumPaymentAllowed().then((_) => setMaxPayment(_))
    },[]);
   
    function isReasonCodeValid() {
        return props.reason && props.reason.length !== 0;
    }

    function getSoldToName() {
        let custOption = '';
        if (props.accountType === AccountType.SoldTo) {
            const headerItems = props.userCustomerDetails;
            for (let l = 0; l < headerItems.length; l += 1) {
                const headerItem = headerItems[l];
                if (headerItem && headerItem.PrimaryAcct === props.selectedPrimaryAccount.PrimaryAcct) {
                    custOption = `${headerItem.PrimaryAcct}${'-'}${headerItem.Name}`;
                }
            }
        } else {
            for (let l = 0; l < props.secondaryAccounts.length; l += 1) {
                const customer = props.secondaryAccounts[l];
                if (props.invoice.soldto_number === customer.value) {
                    custOption = customer.label;
                    break;
                }
            }
        }
        return custOption;
    }

    function reasonCodeRequired() {
        return props.invoice.open_amount > props.payAmount;
    }

    function onRemoveInvoiceFromSelected(e) {
        props.removeInvoiceFromSelected(props.invoice);
    }

    function onChangePayAmount(e) {
        const { value } = e.target;
            props.updatePayAmountForInvoice(
                props.documentId,
                value ? parseFloat(value) : null,
                props.invoice.open_amount,
                props.isPartialPaymentsAllowed,
                props.isInvoiceOverpaymentAllowed,
                maxPayment
            );
    }

    function handleReasonChange(e) {
        props.updateReasonComment(props.documentId, e.target.value);
    }

    function handleReasonCodeChange(selectedReasonCodeOption) {
        props.updateReason(props.documentId, selectedReasonCodeOption.target.value);
        if (selectedReasonCodeOption.target.value === REASON_CODE) {
            props.updateReasonComment(props.documentId, "");
        }
    }

    const errorClass = valid => (valid ? '' : 'invalid');


    const payerCompanyCode = props.payerAccount && props.payerAccount.CompanyCode ? props.payerAccount.CompanyCode : '';

    const reasonCodes = (props.sapDetails?.payment_reason_codes ?? [])
        .filter(obj => obj.company_code === payerCompanyCode && obj.payment_type === 'O')
        .map(code => {
            return {
                value: code.reason_code,
                label: code.description,
                commentsRequired: code.comments_required,
                clearableValue: false,
            }
        });

    let selectedReasonCode = (props.sapDetails?.payment_reason_codes ?? []).find((reason) => {
        return reason.reason_code === props.reason;
    });
    const postingDate = unwrap(sapDateToDate(props?.invoice?.posting_date ?? ""));
    const dueDate = unwrap(sapDateToDate(props?.invoice?.due_date ?? ""));
    const payAmount = props.payAmount;
    return (
        <div className="checkout-invoice-detail d-flex flex-column pt-4">
            <div>
                <div
                    role="presentation"
                    onClick={onRemoveInvoiceFromSelected}
                    className="remove-invoice-button"
                >
                    <i
                        className="fas fa-times text-secondary"
                    />
                </div>
            </div>
            <div className="row">
                <div
                    className={"payment-invoice-detail col-12 col-sm-3 d-flex justify-content-between justify-content-sm-start"}>
                    <div className="details-label-column">{f("invoice_detail.document_number_label")}</div>
                    <div className="details-value">{trimLeadingZeroes(props.invoice.billing_document_number === undefined ? props.invoice.document_number_finance : props.invoice.billing_document_number)}</div>
                </div>
                <div
                    className={"payment-invoice-detail col-12 col-sm-3 d-flex justify-content-between justify-content-sm-start"}>
                    <div
                        className="details-label-column">{props.invoice.open_amount < 0 ? f("invoice_detail.credit") : f("invoice_detail.open")}</div>
                    <div className={`details-value ${props.invoice.open_amount < 0 ? 'itemPayment' : ''}`}>
                        <CurrencyDisplay currency={props.invoice.currency_key} value={props.invoice.open_amount} />
                    </div>
                </div>
                <div
                    className={"payment-invoice-detail col-12 col-sm-2 d-flex justify-content-between justify-content-sm-start"}>
                    <div className="details-label-column">{f("invoice_detail.posting_date_label")}</div>
                    <div className="details-value">
                        <FormattedDate value={postingDate} />
                    </div>
                </div>
                <div
                    className={"payment-invoice-detail col-12 col-sm-2 d-flex justify-content-between justify-content-sm-start"}>
                    <div className="details-label-column">{f("invoice_detail.due_date_label")}</div>
                    <div className="details-value">
                        <FormattedDate value={dueDate} />
                    </div>
                </div>
                <div
                    className={"payment-invoice-detail col-12 col-sm-2 d-flex justify-content-between justify-content-sm-start"}>
                    <div className="details-label-column">{f("invoice_detail.soldto_number_label")}</div>
                    <div data-toggle="details-value tooltip"
                        title={getSoldToName()}>{trimLeadingZeroes(props.invoice.soldto_number)}</div>
                </div>
                <div
                    className={"payment-invoice-detail col-12 col-sm-3 d-flex justify-content-between justify-content-sm-start"}>
                    <div className="details-label-column">
                        {f("invoice_detail.reference_number_label")}
                    </div>
                    <div
                        className="details-value">
                        {trimLeadingZeroes(props.invoice.reference_number)}
                    </div>
                </div>
                <div className={"payment-invoice-detail-input col-12 col-sm-3 d-flex flex-column flex-sm-row"}>
                    {props.invoice.open_amount >= 0 && <React.Fragment>
                        <div
                            className="details-label-column">
                            {f("invoice_detail.pay_label")}
                        </div>
                        <div
                            className="details-value  invoiceDetailCustomSelect flex-fill" style={{ "position": "relative" }}>
                            <div className='input-group' style={{ "paddingTop": "1.5rem" }}>
                                <div className='input-group-prepend'>
                                    <span className="input-group-text invoice-dollar-input">{CurrencySymbol(props.invoice.currency_key)}</span>
                                    <input
                                        required
                                        id={`${'payAmount_'}${props.invoice.document_number_finance}`}
                                        name={`${'payAmount_'}${props.invoice.document_number_finance}`}
                                        className={`form-control form-control-sm text-left invoice-amount-input ${_isCredit ? 'itemPayment' : ''} ${errorClass(props.amountValidation.length === 0)}`}
                                        type="number"
                                        onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                        onChange={onChangePayAmount}
                                        value={payAmount}
                                        readOnly={readOnly}
                                    />
                                </div>
                               
                            </div>
                            <h6 className="invalid-Text">
                            <small>
                            {props.amountValidation ? 
                                <>{props.amountValidation}</>
                             : <>&nbsp;</>}
                             </small>
                             </h6>

                        </div>
                    </React.Fragment>}
                </div>
                {reasonCodeRequired() &&
                    <div className={"payment-invoice-detail-input col-12 col-sm-3 d-flex flex-column flex-sm-row"}>
                        <div
                            className="details-label-column">
                            Reason
                        </div>
                        <div className="details-value invoiceDetailCustomSelect flex-fill" style={{ "position": "relative" }}>
                            <select
                                name="paymentStatus"
                                className={`form-control ${errorClass(isReasonCodeValid())}`}
                                onChange={handleReasonCodeChange}>
                                value={props.reason}
                                <option value="" disabled
                                    selected={props.reason == ""}>{f("invoice_detail.reason_code_label")}</option>
                                {reasonCodes.map(code => {
                                    return (
                                        <option value={code.value} key={code.value} selected={code.value == props.reason}>
                                            {code.label}
                                        </option>
                                    )
                                })}
                            </select>
                            <i className="fas fa-chevron-down select-arrow" />
                            {isReasonCodeValid() ? '' : <h6 className="invalid-Text" style={{ "position": "absolute" }}>
                                <small>{f("invoice_detail.error.reason_code_required")}</small>
                            </h6>}
                        </div>
                    </div>
                }
                {reasonCodeRequired() && selectedReasonCode && selectedReasonCode.comments_required && selectedReasonCode.comments_required === "X" &&
                    <div className={"payment-invoice-detail-input col-12 col-sm-3 d-flex flex-column flex-sm-row"}>
                        <React.Fragment>
                            <div className="details-label-column d-lg-none">
                                {f("invoice_detail.reason_comment")}
                            </div>
                            <div className={"flex-fill"} style={{ "position": "relative" }}>
                                <input
                                    required
                                    id={`${'reasonDescription_'}${props.invoice.document_number_finance}`}
                                    name={`${'reasonDescription_'}${props.invoice.document_number_finance}`}
                                    className={`form-control form-control-sm text-left ${props.comment.length === 0 && 'invalid'}`}
                                    placeholder="Reason Description"
                                    onChange={handleReasonChange}
                                    value={props.comment}
                                />
                                {props.comment.length === 0 &&
                                    <h6 className="invalid-Text" style={{ "position": "absolute" }}>
                                        <small>{f("invoice_detail.error.reason_comment_required")}</small>
                                    </h6>
                                }
                            </div>
                        </React.Fragment>

                    </div>}
            </div>
        </div>
    );
}

export default connect<any, any, any, any>((store, props) => ({
    sapDetails: store.app.config.sap?.data,
    isPartialPaymentsAllowed: isPartialPaymentsAllowed(store),
    isInvoiceOverpaymentAllowed: isInvoiceOverpaymentAllowed(store),
    payAmount: store.invoice.invoiceDetail[props.documentId].amount,
    amountValidation: store.invoice.invoiceDetail[props.documentId].amountValidation,
    reason: store.invoice.invoiceDetail[props.documentId].reason,
    comment: store.invoice.invoiceDetail[props.documentId].comment,
    invoice: store.invoice.selected[props.documentId],
    selectedInvoice: store.invoice.selected,
    userCustomerDetails: store.user_options.primaryAccounts,
    selectedPrimaryAccount: getSelectedPrimaryAccount(store),
    secondaryAccounts: store.user_options.secondaryAccounts,
    accountType: store.user_options.accountType,
    payerAccount: getPayerAccount(store),
}), {
    removeInvoiceFromSelected,
    updatePayAmountForInvoice,
    updateReasonComment,
    updateReason,
})(_InvoiceDetail);
