import React,{ Component, useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { extractLastCardNumbers } from "../../actions/InvoiceActions";
import { Formik } from "formik";
import { AddCreditCardSchema, EditCreditCardSchema } from './schemas';
import { BootstrapError, BootstrapField } from "../../helpers/Field";
import { ERROR_PAYMENT_METHOD } from "../../constants/ErrorMessages";
import moment from "moment";
import * as cardValidator from 'card-validator';
import { connect } from "react-redux"; 
import { ObjectSchema } from "yup";
import { useIntl } from 'react-intl';
import { valuesIn } from 'lodash';


interface _Props {
    save: Function,
    paymentMethods: any,
    isEditing: boolean,
    alwaysSaveOnFile: boolean,
    payment: any,
    onCardConfirmed: Function
}

export function AddCreditCardForm(props:_Props) {
    const intl = useIntl();

    let [cvvValidationMessage, setCvvValidationMessage] = useState('');
    let [selectedOption, setSelectedOption] = useState('');
    let [card_name, setcard_name] = useState('');
    let [card_type, setcard_type] = useState('');
    let [card_number, setcard_number] = useState('');
    let [card_validation_code, setcard_validation_code] = useState('');
    let [card_month, setcard_month] = useState('');
    let [card_year, setcard_year] = useState('');
    const [cardNumber, setCardNumber] = useState('')

    let year = new Date().getFullYear()
    const years = Array.from(Array(12)).map((_, i) => year + i);

    const f = (id, defaultMessage) => intl.formatMessage({ id, defaultMessage });
    const inputCard = useRef();

    async function saveCard(card) {
        
        props.save(card);
    }

    function buttonText() {
        return f("credit_card_form.save_card_button", "Save Card");
    }

    function resetDetails() {
        setcard_name('');
        setcard_number('');
        setcard_validation_code('');
        setcard_month('');
        setcard_year('');
    }    

    function handleselectvalueChange(e) {
        let value = e.target.value;
        setSelectedOption(value);
        setcard_type(value);
        resetDetails();
    }

    

    function onCardNumberChange(e) {

        if (e.target.value.length > cardNumber.length) {
            let value = e.target.value.replace(/\D/g, "");

            if ((/^\d{0,16}$/).test(value)) { // regular cc number, 16 digits
                value = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{4})/, '$1 $2 ').replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ');
                setCardNumber(value)             
                return value;
            }
        }
        else {
            let value = e.target.value.replace(/\D/g, "");
            setCardNumber(e.target.value)
            if (e.target.value.endsWith(' ')) {
                return e.target.value.slice(0, -1)
            }

            return e.target.value
        }

        return "";


    }

    function renderPaymentMethods() {
        if (props.isEditing === true) {
            return (<BootstrapField component="select" name="card_type" disabled={props.isEditing} value={props.payment.payment_card_type} onChange={handleselectvalueChange}>
                <option value={""}>{f("credit_card_form.card_type_label", "Type")}</option>
                {props.paymentMethods.indexOf("VISA") !== -1 && <option value="VISA">Visa</option>}
                {props.paymentMethods.indexOf("MC") !== -1 && <option value="MC">Master Card</option>}
                {props.paymentMethods.indexOf("AMEX") !== -1 && <option value="AMEX">American Express</option>}
            </BootstrapField>)
        }
        else {
            return (<BootstrapField component="select" name="card_type" disabled={props.isEditing} value={selectedOption} onChange={handleselectvalueChange}>
                <option value={""}>{f("credit_card_form.card_type_label", "Type")}</option>
                {props.paymentMethods.indexOf("VISA") !== -1 && <option value="VISA">Visa</option>}
                {props.paymentMethods.indexOf("MC") !== -1 && <option value="MC">Master Card</option>}
                {props.paymentMethods.indexOf("AMEX") !== -1 && <option value="AMEX">American Express</option>}
            </BootstrapField>)
        }

    }

    let initialValues = {
        card_type: selectedOption,
        card_name: card_name,
        card_number: card_number,
        card_validation_code: card_validation_code,
        save_on_file: props.alwaysSaveOnFile,
        make_default: false,
        card_month: card_month,
        card_year: year.toString(),
    };
    let schema : any = AddCreditCardSchema(intl);
    if (props.isEditing) {
        let date = moment(props.payment.valid_to, "MM-DD-YYYY");
        initialValues = {
            card_type: props.payment.payment_card_type,
            card_name: props.payment.payment_card_name,
            card_number: `ending in *${extractLastCardNumbers(props.payment.payment_card_token, '-')}`,
            card_validation_code: "",
            save_on_file: !props.payment.is_session,
            make_default: props.payment.default === "X" ? true : false,
            card_month: date.format("MM"),
            card_year: date.format("YYYY"),
        };
       schema = EditCreditCardSchema(intl);
    }
    return <div>
        <div className="row">
            <div className="col-md-12">
                <Formik
                    initialValues={initialValues}
                    validationSchema={
                        schema
                    }
                    validateOnChange={true}
                    validate={(values) => {
                        let errors: any = {};
                        if ( values.card_month=== "MM" || values.card_year ==='') {
                            return;
                        } 
                        if (!cardValidator.expirationDate({month: values.card_month, year: values.card_year}).isValid) {
                            errors.card_month = 'Invalid expiration date.';
                        }                       
                        values.card_validation_code = values.card_validation_code.replace(/\D+/g, '');
                        if (                      
                            ((values.card_type === "AMEX" && values.card_validation_code.length < 4)
                            || (values.card_type === "MC" && (values.card_validation_code.length < 3 || values.card_validation_code.length >= 4))
                            || (values.card_type === "VISA" && (values.card_validation_code.length < 3 || values.card_validation_code.length >= 4)))) {
                            setCvvValidationMessage(f("payment.error.cvv_invalid", ""));
                            //return false;
                            errors.card_validation_code = f("payment.error.cvv_invalid", "");
                        } else if (values.card_validation_code.length && values.card_validation_code.length <= 4) {
                            setCvvValidationMessage("");
                            //return true;
                        }
                        return errors;
                    }}
                    enableReinitialize={true}
                    onSubmit={(values, bag) => {
                        bag.setSubmitting(true);
                        saveCard(values).then((paymentcard) => {
                            bag.setSubmitting(false);
                            if (props.onCardConfirmed) {
                                props.onCardConfirmed(paymentcard);
                            }
                        }).catch(() => {
                            toast.error(ERROR_PAYMENT_METHOD, {position: toast.POSITION.TOP_RIGHT});
                            bag.setSubmitting(false);
                        });
                    }}
                    render={(formProps) => (
                        <form className='payment-form' onSubmit={formProps.handleSubmit}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className={"form-group customSelect"}>
                                        {renderPaymentMethods()}
                                        <i className="fas fa-chevron-down select-arrow"></i>
                                        <BootstrapError name="card_type"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <BootstrapField type="text" name="card_name"
                                                        placeholder={f("credit_card_form.name_label", "Name on Card")}/>
                                        <BootstrapError name="card_name"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <BootstrapField type="text" name="card_number"
                                            placeholder="**** **** **** ****"
                                            onChange={e => {
                                                formProps.handleChange(e)
                                                const formatedText = onCardNumberChange(e)
                                                formProps.setFieldValue('card_number', formatedText)
                                             }
                                            }

                                            maxLength="19"
                                                        disabled={props.isEditing}/>
                                        <BootstrapError name="card_number"/>
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="col-md-4 customSelect">
                                    <BootstrapField component="select" name="card_month" className="InvalidIconRemoval"
                                                    placeholder={f("credit_card_form.month_label", "MM")}>
                                        <option value="">{f("credit_card_form.month_label", "MM")}</option>
                                        <option value="01">{f("calendar.months.january", "January")}</option>
                                        <option value="02">{f("calendar.months.february", "February")}</option>
                                        <option value="03">{f("calendar.months.march", "March")}</option>
                                        <option value="04">{f("calendar.months.april", "April")}</option>
                                        <option value="05">{f("calendar.months.may", "May")}</option>
                                        <option value="06">{f("calendar.months.june", "June")}</option>
                                        <option value="07">{f("calendar.months.july", "July")}</option>
                                        <option value="08">{f("calendar.months.august", "August")}</option>
                                        <option value="09">{f("calendar.months.september", "September")}</option>
                                        <option value="10">{f("calendar.months.october", "October")}</option>
                                        <option value="11">{f("calendar.months.november", "November")}</option>
                                        <option value="12">{f("calendar.months.december", "December")}</option>
                                    </BootstrapField>
                                    <i className="fas fa-chevron-down select-arrow"></i>
                                    <BootstrapError name="card_month"/>
                                </div>
                                <div className="col-md-1">
                                    <span className="form-label">/</span>
                                </div>
                                <div className="col-md-4 customSelect">
                                    <BootstrapField component="select" name="card_year" className="InvalidIconRemoval"
                                                    placeholder={f("credit_card_form.year_label", "YY")}>
                                        {years.map(code => {
                                            return (
                                                <option value={code}
                                                    key={code}>
                                                    {code}
                                                </option>
                                            )
                                        })}

                                    </BootstrapField>
                                    <i className="fas fa-chevron-down select-arrow"></i>
                                    <BootstrapError name="card_year"/>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        {
                                            formProps.values.card_type != "AMEX" ?
                                                <div>
                                                    <BootstrapField type="text" name="card_validation_code"
                                                        placeholder={f("credit_card_form.cvv_label", "CVV")} maxLength={3} minLength={3} />
                                                    {
                                                        formProps.values.card_type != "AMEX" && formProps.values.card_validation_code.length <= 0
                                                            ? <BootstrapError name="card_validation_code" /> :

                                                            <h6 className="invalid-Text">
                                                                <small>{cvvValidationMessage}</small>
                                                            </h6>
                                                    }
                                                </div> : <div>
                                                    <BootstrapField type="text" name="card_validation_code"
                                                        placeholder={f("credit_card_form.cvv_label", "CVV")} maxLength={4} minLength={4} />
                                                    {
                                                        formProps.values.card_type === "AMEX" && formProps.values.card_validation_code.length <= 0
                                                            ? <BootstrapError name="card_validation_code" /> : <h6 className="invalid-Text">
                                                                <small>{cvvValidationMessage}</small>
                                                            </h6>
                                                    }
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                {!props.alwaysSaveOnFile &&
                                !props.isEditing &&
                                <div className="col-md-6">
                                  <div className="form-check">
                                    <input className="form-check-input rounded-circle" type="checkbox"
                                           checked={formProps.values.save_on_file}
                                           id="save_on_file"
                                           name={"save_on_file"} onChange={formProps.handleChange}/>
                                    <label className="form-check-label" htmlFor="save_on_file"
                                           style={{"paddingLeft": "24px"}}>
                                        {f("credit_card_form.save_on_file", "Save On File")}
                                    </label>
                                  </div>
                                </div>}
                                <div className="col-md-6">
                                    {formProps.values.save_on_file && <div className="form-check">
                                      <input className="form-check-input rounded-circle" type="checkbox"
                                             checked={formProps.values.make_default}
                                             id="make_default"
                                             name={"make_default"} onChange={formProps.handleChange}/>
                                      <label className="form-check-label" htmlFor="make_default"
                                             style={{"paddingLeft": "24px"}}>
                                          {f("credit_card_form.set_as_default", "Set as default")}
                                      </label>
                                    </div>}
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="col-md-12 text-center">
                                    <button className={"btn btn-primary buttonColor buttonsize save-card-button"}
                                        type="submit"
                                        disabled={formProps.isSubmitting}
                                        style={{width:'157px'} }
                                    >{buttonText()}
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                />
            </div>
        </div>
    </div>;

}

export default connect<any, any, any, any>((state, props) => {
    return {
        paymentMethods: state.app.paymentMethods,
    }
})(AddCreditCardForm);

