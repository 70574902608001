import React, { Component, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getSelectedPrimaryAccount, hasDeposits } from "../../selectors/Selectors";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import { LanguageSelector } from "../footer/LanguageSelector";
import Divider from '@material-ui/core/Divider';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {
  setSelectedSoldTos, updatePrimaryAccount, clearSelectedInvoices, setSelectedCurrency,
  setsearchedinvoiceStatus, setsearchedinvoiceDueDate, setsearchedinvoiceType,
  setsearchedInvoiceNumber, setsearchedArticleNumber, setsearchedArticleDescription,
  setinvoiceSearchedPeriod, setinvoiceSearchedPeriodFromTo, setsearchedInvoices
} from "../../actions/InvoiceActions";
import { Logout } from "../../actions/UserActions";
import {
  canEditSystemConfig,
  canManagePaymentMethods,
  canManageRegistrationRequests,
  canManageUsers, canViewUsers
} from "../../selectors/PermissionSelectors";
import { AccountType } from "../../interfaces/Accounts";
import { useIntl } from "react-intl";
import { useUser } from "../../hooks/CommonHooks";
import { isThirdPartyUser } from "../../helpers/UserHelpers";
import * as appConstants from '../../constants/ApplicationConstants';
import { ThunkFunction } from '../../interfaces/utils';
 
function _MenuDrawer(props: any) {
  let [showAccounts, setShowAccounts] = useState(false);
  const intl = useIntl();
  const f = id => intl.formatMessage({ id: id });
  const user = useUser();

  function toggleShowAccounts() {
    setShowAccounts(!showAccounts)
  }

  function logout() {
    props.logout();
  }

  function onAccountSelect(i) {
    if (props.selectedPrimaryAccountIndex !== props.primaryAccounts[i].PrimaryAcct) {
      props.setSelectedCurrency('');
      props.clearSelectedInvoices();
      let key = props.primaryAccounts[i].PrimaryAcct;
      props.updatePrimaryAccount(key, true);
      props.setsearchedInvoices([]);
      props.setsearchedInvoiceNumber('');
      props.setsearchedinvoiceDueDate('', '');
      props.setinvoiceSearchedPeriodFromTo('', '');
      props.setsearchedinvoiceStatus('03');
      props.setinvoiceSearchedPeriod("all");
      props.onClose();
    }
   // props.clearSelectedInvoices();
   // props.updatePrimaryAccount(i, props.accountType !== AccountType.PayerAll);
   
  }

  return (<div>
    <div style={{ minWidth: "200px" }}>
      <List component="nav">
        <ListSubheader disableSticky={true}>{f("navigation.account")}</ListSubheader>
        <ListItem button onClick={toggleShowAccounts}>
          <ListItemText
            primary={props.selectedPrimaryAccount && (props.selectedPrimaryAccount.Name)}
            secondary={props.selectedPrimaryAccount && (props.selectedPrimaryAccount.PrimaryAcct)} />
          {showAccounts ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </List>
      {showAccounts && <React.Fragment>
        <Divider />
        <List component="nav">
          {props.primaryAccounts.map((account, index) => {
            return (<ListItem button key={account.PrimaryAcct} onClick={() => {
              onAccountSelect(index);
            }}>
              <ListItemText primary={account.Name}
                secondary={account.PrimaryAcct} />
            </ListItem>)
          })}
        </List>
      </React.Fragment>}
      {!showAccounts && <React.Fragment>
        <Divider />
        <List component="nav">
          <ListItem component={({ innerRef, ...props }) => <Link {...props} to="/invoices" />} button
            onClick={props.onClose}>
            <ListItemText primary={f("header.invoices")} />
          </ListItem>

          {props.hasDeposits &&
            <ListItem button component={({ innerRef, ...props }) => <Link {...props} to={'/deposits'} />}
              onClick={props.onClose}>
              <ListItemText primary={f("header.deposits")} />
            </ListItem>}
        </List>
        <Divider />
        <List component="nav">
          <ListSubheader>{f("navigation.settings")}</ListSubheader>
          {!isThirdPartyUser(user) && <ListItem button component={({ innerRef, ...props }) => <Link {...props} to={'/settings/profile'} />}
            onClick={props.onClose}>
            <ListItemText primary={f("settings.header.account")} />
          </ListItem>}
          {props.canViewUsers &&
            <ListItem button component={({ innerRef, ...props }) => <Link {...props} to={'/settings/users'} />}
              onClick={props.onClose}>
              <ListItemText primary={f("settings.header.user_management")} />
            </ListItem>}
          {/* TODO CLIENT SPECIFIC */}
          {/*{this.props.canManageRegistrationRequests && <ListItem button component={Link} to={'/settings/registration-requests'} onClick={this.props.onClose}>*/}
          {/*<ListItemText primary="Registration Request"/>*/}
          {/*</ListItem>}*/}
          {props.canEditSystemConfig && <ListItem button
            component={({ innerRef, ...props }) => <Link {...props}
              to={'/settings/configuration'} />}
            onClick={props.onClose}>
            <ListItemText primary={f("settings.header.configuration")} />
          </ListItem>}
          {props.canManagePaymentMethods && <ListItem button component={({ innerRef, ...props }) =>
            <Link {...props} to={'/settings/payment-methods'} />} onClick={props.onClose}>
            <ListItemText primary={f("settings.header.payment_methods")} />
          </ListItem>}
        </List>
        <Divider />
        <List component="nav">
          <ListItem button component={({ innerRef, ...props }) => <Link {...props} to={"/help"} />}
            onClick={props.onClose}>
            <ListItemText primary={f("header.help")} />
          </ListItem>
          <ListItem button onClick={logout}>
            <ListItemText primary={f("header.logout")} />
          </ListItem>
        </List>
        <Divider />
        <List component="nav">
          <ListItem>
            <ListItemText primary={appConstants.APP_VERSION} />

          </ListItem>

          <ListItem>
            <LanguageSelector />

          </ListItem>
        </List>

      </React.Fragment>}
    </div>
  </div>)
}

export default withRouter(connect<any, any, any, any>((state, props) => ({
  accountType: state.user_options.accountType,
  canEditSystemConfig: canEditSystemConfig(state),
  canManagePaymentMethods: canManagePaymentMethods(state),
  canManageRegistrationRequests: canManageRegistrationRequests(state),
  canManageUsers: canManageUsers(state),
  canViewUsers: canViewUsers(state),
  currentUser: state.default.currentUser,
  hasDeposits: hasDeposits(state),
  primaryAccounts: Object.values(state.user_options.primaryAccounts),
  selectedPrimaryAccount: getSelectedPrimaryAccount(state)
}), {
  logout: Logout,
  clearSelectedInvoices,
  updatePrimaryAccount,
  setSelectedSoldTos,
  setSelectedCurrency,
  setsearchedInvoiceNumber,
  setsearchedinvoiceDueDate,
  setinvoiceSearchedPeriodFromTo,
  setsearchedinvoiceStatus,
  setinvoiceSearchedPeriod,
  setsearchedInvoices,
})(_MenuDrawer)) as any;
