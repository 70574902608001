import React, {useState} from 'react';
import {connect} from 'react-redux';
import {extractLastCardNumbers, getPayerDetailsBuilder} from "../../actions/InvoiceActions";
import { getPayerAccount, getSelectedPrimaryAccount, getSelectedSecondaryAccount, handleDeleteIframe} from "../../selectors/Selectors";
import {deletePaymentMethod} from "../../requests/TokenRequests";
import {deleteSessionCard, deleteSessionCheck} from "../../reducers/PaymentReducer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index";
import {FormattedMessage, useIntl} from 'react-intl';
import {Modal} from "react-bootstrap";
import { TraceLog } from "../../interfaces/TraceLog"
import * as appConstants from '../../constants/ApplicationConstants';
function _PaymentTypeDetails(props) {
    const intl = useIntl();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function displayText() {
        let p = props.payment_card;
        let digits = extractLastCardNumbers(p.payment_card_token);
        if (p.payment_card_type === 'EC') {
            return intl.formatMessage({id: 'payment_methods.echeck_description'}, {digits});
        } else {
            return intl.formatMessage({id: 'payment_methods.card_description'}, {digits, type: p.payment_card_type});
        }
    }

    function displayText2() {
        let p = props.payment_card;
        return `${p.payment_card_name}`;
    }

    function getIconImage() {
        if (props.payment_card.payment_card_type === 'EC') {
            return <FontAwesomeIcon icon={"money-check"}/>
        } else {
             return <FontAwesomeIcon icon={"credit-card"}/>;
        }
    }

    function deletePaymentCard() {
        props.deletePaymentMethod(props);
        handleDeleteIframe();
    }   

    return (<div className={'payment-type-details mb-2'}>
        <div className={"payment-type-icon-container"}>
            <span>{getIconImage()}</span>
            {props.payment_card.default ?
                <span className="p-0" title="Default"><FontAwesomeIcon icon={"check-circle"} style={{color:'#1A75BB'}}/></span> : null}
        </div>
        <div className={"payment-type-description-container"}>
            <div>
                <span className="payment-card-description">{displayText2()}</span>
            </div>
            <div>
                <small className={"text-muted"}>
                    <span className="payment-card-description">{displayText()}</span>
                    {props.payment_card.is_session && <span>{ intl.formatMessage({id: 'payment_method.session'})}</span>}
                </small>
            </div>
        </div>
        <div className={"payment-type-actions"}>
            <span role={"button"} className={"font-button-icon"}
                  onClick={handleShow}>
                  <FontAwesomeIcon icon={"trash"}/> <FormattedMessage id={"delete"}/></span>
            <span role={"button"} className={"font-button-icon"}
                  onClick={() => {
                      props.onEdit(props.payment_card)
                  }}>
              <FontAwesomeIcon icon={"edit"}/> <FormattedMessage id={"edit"}/></span>
        </div>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title><FormattedMessage id={"confirm_delete"}/></Modal.Title>
            </Modal.Header>
            <Modal.Body><FormattedMessage id={"payment_methods.confirm_delete_card_message"}/></Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-primary buttonColor buttonsize" onClick={deletePaymentCard}>
                    <FormattedMessage id={"ok"}/>
                </button>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleClose}
                >
                    <FormattedMessage id={"cancel"}/>
                </button>
            </Modal.Footer>
        </Modal>
    </div>)
}

export default connect<any, any, any, any>((state, props) => {
    return {
        payerAccount: getPayerAccount(state),
        primaryAccount: getSelectedPrimaryAccount(state),
        secondaryAccount: getSelectedSecondaryAccount(state),
        currentUser: state.default.currentUser,
    }
}, (dispatch: any) => {
    return {
        deletePaymentMethod: async (props) => {
            let log:TraceLog={
                id: "DEL_PAYMENT_CARD",
                login:props.currentUser.email,
                epayv:appConstants.APP_VERSION,
                usrag:navigator.userAgent
            }
            if (props.payment_card.is_session) {
                dispatch(deleteSessionCard(props.payment_card, props.payerAccount.PrimaryAcct,log));
                dispatch(deleteSessionCheck(props.payment_card, props.payerAccount.PrimaryAcct,log));
            } else {
              
                await deletePaymentMethod(props.payerAccount.PrimaryAcct, props.primaryAccount.CompanyCode, props.payment_card,log);
                dispatch(getPayerDetailsBuilder(props.primaryAccount, props.secondaryAccount, props.currentUser.primary_account_type_id));
            }
            props.onDelete(props.payment_card);
        }
    }
})(_PaymentTypeDetails);
