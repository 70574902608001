import * as Yup from "yup";
import * as cardValidator from 'card-validator';
import {IntlShape} from "react-intl";


export const AddCreditCardSchema = function (intl: IntlShape) {
    const f = (id, defaultMessage) => intl.formatMessage({id, defaultMessage});

    return Yup.object().shape({
        card_type: Yup.string().required(f("credit_card_form.card_type_required", "Card type is required.")),
        card_name: Yup.string().required(f("credit_card_form.name_required", "Name on card is required.")),
        card_number: Yup.string().required(f("credit_card_form.card_number_required", "Card number is required.")).test({
            name: 'card_number',
            message: f("credit_card_form.invalid_card_number", "Invalid credit card number"),
            test: (value) => {
                
                if (value) value = value.replace(/ /g, "");
                return cardValidator.number(value).isValid;
            }
        }).when('card_type', {
            is: 'VISA', //mc amex
            then: Yup.string().test({
                name: 'card_number',
                message: f("credit_card_form.invalid_card_number", "Invalid credit card number"),
                test: (value) => {
                    if (value) value = value.replace(/ /g, "");                    
                    let validate = cardValidator.number(value);
                    return value && validate.card && validate.card.type === 'visa';
                }
            })
        }).when('card_type', {
            is: 'MC',
            then: Yup.string().test({
                name: 'card_number',
                message: f("credit_card_form.invalid_card_number", "Invalid credit card number"),
                test: (value) => {
                    if (value) value = value.replace(/ /g, "");
                    let validate = cardValidator.number(value);
                    return value && validate.card && validate.card.type === 'mastercard';
                }
            })
        }).when('card_type', {
            is: 'AMEX', //mc amex
            then: Yup.string().test({
                name: 'card_number',
                message: f("credit_card_form.invalid_card_number", "Invalid credit card number"),
                test: function (value) {
                    if (value) value = value.replace(/ /g, "");
                    let validate = cardValidator.number(value);
                    return value && validate.card && validate.card.type === 'american-express';
                }
            })
        }),
        card_validation_code: Yup.string().required(f("credit_card_form.cvv_required", "Card cvv is required.")),
        card_month: Yup.string().required(f("credit_card_form.expiration_date_required", "Expiration month is required.")),
       // card_year: Yup.string().required(f("credit_card_form.expiration_year_required", "Expiration year is required.")),
    });
};

export const EditCreditCardSchema = function (intl: IntlShape) {
    const f = (id, defaultMessage) => intl.formatMessage({id, defaultMessage});

    return Yup.object().shape({
        card_name: Yup.string().required(f("credit_card_form.name_required", "Name on card is required.")),
        card_month: Yup.string().required(f("credit_card_form.expiration_date_required", "Expiration month is required.")),
        card_year: Yup.string().required(f("credit_card_form.expiration_year_required", "Expiration year is required.")),
        //     card_number: Yup.string().required(f("credit_card_form.expiration_year_required", "Expiration year is required.")),

    });
};

/**
 * @return {number}
 */
function checkRoutingNumber2EC(r) {
    let s = 0, m = 0, n = [3, 7, 1];
    for (let i = 0; i < r.length; i++) {
        s += parseInt(r.charAt(i)) * n[m];
        m = (++m < 3 ? m : 0);
    }
    return s
}

/**
 * @return {boolean}
 */
function checkRoutingNumber1EC(r) {
    let s = '';
    for (let i = 0; i < r.length; i++) {
        if (!Number.isNaN(r.charAt(i)))
            s += r.charAt(i)
    }
    if (s.length !== 9)
        return false;
    const c = checkRoutingNumber2EC(s);
    return !!(c && c % 10 === 0);
}

const accountNumberRegExpr = "^[0-9]+$";

/**
 * @return {boolean}
 */
function checkAccountNumberEC(value) {
    let re = new RegExp(accountNumberRegExpr);
    if (value.length < 5 || value.length > 17)
        return false;
    return !!value.match(re);
}


export const AddCheckSchema = function (intl: IntlShape) {
    const f = (id, defaultMessage) => intl.formatMessage({id, defaultMessage});
    
    return Yup.object().shape({
        electronic_check_account_type: Yup.string().required(f("echeck_form.account_type_required","Account type is required.")),
        name: Yup.string().required(f("echeck_form.name_required","Check Holder name is required.")),
        electronic_check_rdfi_number: Yup.string().required(f("echeck_form.routing_number_required","Routing number is required.")).test({
            name: 'electronic_check_rdfi_number',
            message: f("echeck_form.invalid_routing_number","Invalid routing number."),
            test: (value) => {
                if (typeof value === "undefined") {
                    return false;
                }
                if (value === null) {
                    return false;
                }
                if (value.length < 1) {
                    return false;
                }
                return checkRoutingNumber1EC(value);
            }
        }),
        account_number: Yup.string().required(f("echeck_form.account_number_required", "Account number is required.")).test({
            name: 'electronic_check_rdfi_number',
            message: f("echeck_form.invalid_account_number","Invalid account number."),
            test: (value) => {
                if (typeof value === "undefined") {
                    return false;
                }
                if (value === null) {
                    return false;
                }
                if (value.length < 1) {
                    return false;
                }
                return checkAccountNumberEC(value);
            }
        }),
    });
};

export const EditCheckSchema = function (intl: IntlShape) {
    const f = (id, defaultMessage) => intl.formatMessage({id, defaultMessage});
    return Yup.object().shape({
        name: Yup.string().required(f("echeck_form.name_required","Check Holder name is required.")),
    });
};


export const MakeDepositSchema = function (intl: IntlShape) {
    const f = (id, defaultMessage) => intl.formatMessage({id, defaultMessage});
    return Yup.object().shape({
        amount_to_process: Yup.number().required(f("deposits.amount_required", "Numeric value required."))
            .positive()
            .min(1, f("deposits.amount_value_validation", "Amount must be 1 or greater than 1.")),
    });
};
