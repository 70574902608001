import * as types from '../constants/ActionTypes';
import {Invoice} from "../interfaces/invoice";

const CACHED_SELECTED_CARD = "CACHED_SELECTED_CARD";

export interface CurrencyOption {
    value: string;
    label: string;
}


export const cnbsInitialState: DefaultState = {
    paymentStatusOptions: [
        {value: 'open', label: 'Open', clearableValue: false},
        {value: 'paid', label: 'Cleared', clearableValue: false}
    ],
    currencyOptions: [
        {value: 'USD', label: 'USD'},
    ],
    dueOptions: [
        {value: 'Due', label: 'Due up to', clearableValue: false},
        {value: 'All', label: 'Show All', clearableValue: false}
    ],

    invoicePaymentOptions: [
        {value: '009', label: 'Due'},
        {value: '010', label: 'Not due'},
        {value: '011', label: 'Due or not due'}
    ],
    invoices: [],
    currentUser: null,
    animating: 0,
    selectedCurrencyOptionValue: '',
    payerDetails: {
        payment_cards: []
    },
    paymentStatuses: null,
    selectedCard: sessionStorage.getItem(CACHED_SELECTED_CARD) ?? "",
    overPayNote: '',
    accountTypes: [],
    primaryAccountTypes: [],
    showPaymentStatus: true,
    paymentCardOptions: [],
};

// type DefaultState = typeof cnbsInitialState;

export interface DefaultState {
    currentUser: any;
    animating: any;
    invoices: Invoice[];
    selectedCurrencyOptionValue: any;
    currencyOptions: CurrencyOption[];
    accountTypes: any[];
    primaryAccountTypes: any[];
    payerDetails: any;
    paymentCardOptions: any;
    showPaymentStatus: boolean;
    paymentStatusOptions: any;
    dueOptions: any;
    invoicePaymentOptions: any;
    paymentStatuses: any;
    selectedCard: string;
    overPayNote: string;
    reasonNotRequired?: "X" | "";
}

export default function reducer(state: DefaultState = cnbsInitialState, action: any): DefaultState {
    switch (action.type) {
        // Common props

        case types.LOADING_INCREMENT: {
            return {...state, animating: state.animating + 1};
        }
        case types.LOADING_DECREMENT: {
            return {...state, animating: state.animating - 1};
        }

        case types.GET_USER_SUCCESS: {
            return {
                ...state,
                currentUser: action.payload,
            };
        }

        case types.GET_INVOICES_SUCCESS: {
            return {
                ...state,
                invoices: action.payload
            };
        }

        case types.UPDATE_CURRENCY_CODE: {
            return {...state, selectedCurrencyOptionValue: action.payload};
        }

        case types.GET_PAYER_DETAILS_SUCCESS: {
            return {
                ...state,
                payerDetails: action.payload
            };
        }

        case types.UPDATE_PAYMENT_CARD_OPTIONS: {
            return {...state, paymentCardOptions: action.payload};
        }

        case types.POST_PAYMENT_REQUEST: {
            return {
                ...state,
                showPaymentStatus: true
            };
        }

        case types.POST_PAYMENT_SUCCESS: {
            return {
                ...state,
                showPaymentStatus: true,
                paymentStatuses: action.payload
            };
        }

        case types.GET_INVOICE_CURRENCY_SUCCESS: {
            return {
                ...state,
                currencyOptions: action.payload
            };
        }

        case types.UPDATE_PAYMENT_STATUS: {
            return {...state, paymentStatuses: null, showPaymentStatus: false};
        }

        case types.UPDATE_SELECTED_CARD:
            sessionStorage.setItem(CACHED_SELECTED_CARD, action.payload);
            return {...state, selectedCard: action.payload};

        case types.OVER_PAY_NOTE:
            return {...state, overPayNote: action.payload};

        case types.UPDATE_ACCOUNT_TYPES: {
            return {
                ...state, accountTypes: action.payload,
            };
        }

        case types.UPDATE_PRIMARY_ACCOUNT_TYPES: {
            return {
                ...state, primaryAccountTypes: action.payload,
            };
        }

        default: {
            return state;
        }
    }
}
