import React, { useEffect, useState } from 'react';
import {useIntl} from "react-intl";
import AddManualSapAccount from './AddManualSapAccount';
import AddInvoiceSapAccount from './AddInvoiceSapAccount';

export default function ManageUserSapAccount(props: any) {

    const [saveType, setSaveType] = useState("invoice")
    const [initialAccount, setInitialAccount] = useState<any>()

    const intl = useIntl();
    const t = (id:string) => intl.formatMessage({id: id});

    let _salesOrgs: any = {};
    props.salesOrgs?.filter(_ => _.active && _.active === "X").forEach(_ => _salesOrgs[_.sales_organization] = _.sales_organization);
    _salesOrgs = Object.keys(_salesOrgs);

    useEffect(() => {

        const account = { ...props.account }

        //if (!account.company_code && _companyCodes.length === 1) {
        //    account.company_code = _companyCodes[0];
        //}

        if (account.primary_account && account.primary_account.length > 0) {
            setSaveType("manual")
        }
        else {
            setSaveType("invoice")
        }

        if (!account.sales_organization && _salesOrgs.length === 1) {
            account.sales_organization = _salesOrgs[0];
        }

        // Formik needs properties as empty strings instead of null.

        for (let propertyName in account) {
            if (account[propertyName] === null) {
                account[propertyName] = '';
            }
        }

        setInitialAccount(account)
    }, [props.account])


    return (
        <div className="mt-1">

            {
                (!initialAccount?.primary_account || initialAccount?.primary_account.length == 0) &&
                <div className="form-group">
                    <div >
                        <input type="radio" name="saveAccountType" id="invoice" checked={saveType == "invoice"} onClick={() => setSaveType("invoice")} /> {t('settings.account_entry.invoice')}
                    </div>
                    <div >
                        <input type="radio" name="saveAccountType" id="manual" checked={saveType == "manual"} onClick={() => setSaveType("manual")} /> {t('settings.account_entry.manual')}
                    </div>

                </div>
            }
            {
                saveType === "invoice" &&
                <AddInvoiceSapAccount saveAccountByInvoice={props.saveAccountByInvoice} errorMessage={props.errorMessage } />
            }
            {
                saveType === "manual" &&
                <AddManualSapAccount account={props.account} accountTypes={props.accountTypes}
                    saveAccount={props.saveAccount} />
            }
        </div>
    );
}