/* eslint-env browser */
/* eslint-disable no-console */
import React from "react";
import { Link } from "react-router-dom";
import PdfCell from "../components/invoice/PdfCell";
import { Invoice } from "../interfaces/invoice";
import { IntlShape, useIntl } from "react-intl";
import { isStringNotEmptyOrNull } from "../utils";
import { currencySymbolMap } from "../helpers/CurrencySymbols";
import { useDispatch } from "react-redux";
import { setActiveInvoice } from "../actions/InvoiceActions";
import moment from "moment";
import { CurrencyDisplay } from "./CurrencyDisplay";

export function isCredit(invoice: Invoice): boolean {
  return typeof invoice.open_amount !== "undefined" && invoice.open_amount < 0;
}

export function isInvoice(invoice: Invoice): boolean {
  return (
    typeof invoice.open_amount !== "undefined" &&
    invoice.open_amount >= 0 &&
    !isPayment(invoice)
  );
}

export function isPayment(invoice: Invoice): boolean {
  return invoice?.clearing_date ?? "" !== "";
}

type Result<T> = [Error, null] | [null, T];

export function unwrap<T>(result: Result<T>) {
  if (result[0]) {
    // Here 'error' is non-null
    throw result[0];
  }

  // Now 'data' is non-null
  return result[1];
}

export function sapDateToDate(dateStr: string): Result<Date> {
  const datearray: any = dateStr.split("-");
  const date = new Date(datearray[2], datearray[0] - 1, datearray[1]);
  return [null, date];
}

export function paidFormatter(cell, row: Invoice) {
  if (isCredit(row)) {
    return "";
  }
  return moneyTableCell(cell, row);
}

export function moneyTableCell(cell, row: Invoice) {
  return (
    <span className="main-table-cell">
      <CurrencyDisplay currency={row.currency_key} value={cell} />
    </span>
  );
}

export function csvMoneyFormatter(intl: IntlShape) {
  return function (cell, row: Invoice) {
    return cell ?? "";
  };
}

export function amountFormatter(cell, row) {
  return <CurrencyDisplay currency={row.currency_key} value={cell} />;
}

export function getCurrencySymbol(currency_key: string): string {
  return currencySymbolMap[currency_key]?.symbol_native ?? "";
}

export function pdfFormatter(cell, row) {
  return <PdfCell row={row} />;
}

export function dateFormatter(cell) {
  return <_DateFormatter cell={cell} />;
}

export function csvDateFormatter(intl: IntlShape) {
  return function (cell) {
    if (isStringNotEmptyOrNull(cell)) {
      let date = unwrap(sapDateToDate(cell));
      return intl.formatDate(date);
    }
    return "";
  };
}

function _DateFormatter(props) {
  const intl = useIntl();
  const datearray = props.cell.split("-");
  const newDate = new Date(datearray[2], datearray[0] - 1, datearray[1]);
  return <span className="main-table-cell">{intl.formatDate(newDate)}</span>;
}

export function statusFormatter(cell, row: Invoice, index) {
  return <_StatusFormatter row={row} />;
}

export function csvStatusFormatter(intl: IntlShape) {
  return function (cell, row: Invoice, index) {
    let f = (id) => intl.formatMessage({ id: id });
    if (isCredit(row)) {
      return f("invoice_table.credit");
    }
    if ((row?.clearing_date ?? "" != "") || row?.clearing_date != undefined) {
      return f("invoice_table.paid");
    }
    if (row.item_is_a_payment == "X" || row.item_is_a_payment != undefined) {
      return f("invoice_table.paid");
    }
    return f("invoice_table.open");
  };
}
export const statementIdFormatter = (props:{ statementId }, row: Invoice) => {
    //const displayValue = props.statementId ?? "N/A";
    //console.log(displayValue);
    //return <span className="main-table-cell">{displayValue} </span>;
};


function _StatusFormatter(props: { row: Invoice }) {
  const intl = useIntl();
  let f = (id) => intl.formatMessage({ id: id });
  if (props.row?.clearing_date ?? "" != "") {
    return <span className="main-table-cell">{f("invoice_table.paid")}</span>;
  }
  if (isCredit(props.row)) {
    return <span className="main-table-cell">{f("invoice_table.credit")}</span>;
  }
  return <span className="main-table-cell">{f("invoice_table.open")}</span>;
}

// function DocumentNumberFormatterComponent(props: { cell: any, row: Invoice }) {
//     const dispatch = useDispatch();

//     function onClick() {
//         dispatch(setActiveInvoice(props.row));
//     }

//     let docNumber = '';
//     if (props.row.billing_document_number) {
//         docNumber = removeLeadingZeros(props.row.billing_document_number);
//         let path = `${'/invoices/detail/'}${docNumber}`;

//         // @ts-ignore this formatter is being passed to table. this.onContinueClick there may be a cleaner way to do this.
//         return (<Link id={docNumber} href={path} to={path} onClick={
//             onClick
//         }><span className="detail-link">{docNumber}</span></Link>);
//     }
//     if (!props.row.billing_document_number && props.row.document_number_finance) {
//         docNumber = props.row.document_number_finance;
//     }
//     return (<span className="main-table-cell">{docNumber}</span>);
// }

export function documentNumberFormatter(cell, row: Invoice) {
  return <DocumentNumberFormatterComponent cell={cell} row={row} />;
}

function DocumentNumberFormatterComponent(props: { cell: any; row: Invoice }) {
    const dispatch = useDispatch();
    

    function onClick() {
        dispatch(setActiveInvoice(props.row));
    }
    let docNumber = ""
    let path = ""

    if (props.row.finance_document_type === 'Z4') {
        docNumber = props.row.reference_number
    }
    else {
        if (props.row.billing_document_number !== "0"  && props.row.item_is_a_payment !== "X") {
            docNumber = removeLeadingZeros(props.row.billing_document_number);
            path = `${"/invoices/detail/"}${docNumber}`;
        }
        else {
            docNumber = props.row.document_number_finance;
        }
    }

    if (path !== "") {
        return (
            <div>
                <Link id={docNumber} href={path} to={path} onClick={onClick}>
                    <span className="detail-link">{docNumber}</span>
                </Link>
            </div>
        )
    }
    else {
        return (
            <span className="main-table-cell">{docNumber}</span>
        );
    }
}

export function csvDocumentNumberFormatter(intl: IntlShape) {
  return function (cell, row: Invoice) {
  

      let docNumber = ""

      if (row.finance_document_type === 'Z4') {
          docNumber = row.reference_number
      }
      else {
          if (row.billing_document_number !== "0") {
              docNumber = removeLeadingZeros(row.billing_document_number);
          }
          else {
              docNumber = row.document_number_finance;
          }
      }
    return docNumber;
  };
}

export function referenceFormatter(cell) {
  if (cell) {
    if (isNaN(cell)) {
      return <span className="main-table-cell">{cell}</span>;
    } else {
      return (
        <span className="main-table-cell">{removeLeadingZeros(cell)}</span>
      );
    }
  }
  return "";
}

export function csvReferenceFormatter(intl: IntlShape) {
  return function (cell) {
    if (cell) {
      if (isNaN(cell)) {
        return cell;
      } else {
        return removeLeadingZeros(cell);
      }
    }
    return "";
  };
}

export function paymentStatusTypeFormatter(cell) {
  if (cell === "S") {
    return <span style={{ color: "green" }}>{"Success"}</span>;
  }
  return <span style={{ color: "red" }}>{"Error"}</span>;
}

export function invoiceTotalFormatter(total) {
  if (total > 0) {
    return <span style={{ color: "green" }}>{total}</span>;
  }
  return <span style={{ color: "red" }}>{total}</span>;
}

export function dueFormatter(cell, row: Invoice) {
  return <_DueFormatter row={row} cell={cell} />;
}

export function csvDueDateFormatter(intl: IntlShape) {
  return function (cell, row: Invoice) {
    if (isCredit(row) || row.item_is_a_payment === "X") {
      return "";
    }
    const newDate = unwrap(sapDateToDate(cell));
    const todaysDate = new Date();

    if (newDate >= todaysDate) {
      return intl.formatDate(newDate);
    }
    return intl.formatDate(newDate);
  };
}

function _DueFormatter(props: { row: Invoice; cell: any }) {
  const intl = useIntl();

  if (isCredit(props.row) || isPayment(props.row)) {
    return null;
  }
  const newDate = unwrap(sapDateToDate(props.cell));
  const todaysDate = new Date();
  if (newDate >= todaysDate) {
    return (
      <span className="main-table-cell status-good">
        {intl.formatDate(newDate)}
      </span>
    );
  }
  return (
    <span className="main-table-cell status-bad">
      {intl.formatDate(newDate)}
    </span>
  );
}

export function isOverDue(invoice: Invoice) {
  const datearray: any = invoice.due_date.split("-");
  const dueDate = new Date(datearray[2], datearray[0] - 1, datearray[1]);
  const todaysDate = new Date();
  return dueDate < todaysDate;
}

export function daysToPayFormatter(cell, row: Invoice) {
  if (isCredit(row) || isPayment(row)) {
    return null;
  }
  if (!isOverDue(row)) {
    return <span className="main-table-cell status-good">{cell}</span>;
  }
  return <span className="main-table-cell status-bad">-{cell ?? "0"}</span>;
}

export function csvDaysToPayFormatter(intl: IntlShape) {
  return function (cell, row: Invoice) {
    if (
      isCredit(row) ||
      row.item_is_a_payment === "X" ||
      typeof cell === "undefined" ||
      cell === null
    ) {
      return "";
    }
    if (!isOverDue(row)) {
      return parseInt(cell);
    }
    return parseInt(cell) * -1;
  };
}

export function calculateUnitPrice(cell, row) {
  if (row.total_amount && row.billed_quantity) {
    const unitPrice = row.total_amount / row.billed_quantity;
    return (
      <span className="main-table-cell">
        <CurrencyDisplay currency={row.currency_key} value={unitPrice} />
      </span>
    );
  }
  return (
    <span className="main-table-cell">
      <small />
    </span>
  );
}

export function formatTotal(cell, row: Invoice) {
  if (row.total_amount) {
    return (
        <span className="main-table-cell" style={{ fontFamily: 'sans-serif' }}>
        <CurrencyDisplay currency={row.currency_key} value={row.total_amount} />
      </span>
    );
  }
  return (
    <span className="main-table-cell">
      <small />
    </span>
  );
}

export function formatColumn(cell) {
  if (cell) {
    return <span className="main-table-cell">{cell}</span>;
  }
  return "";
}

export function formatColumnRemoveLeading(cell) {
  if (cell) {
    return <span className="main-table-cell">{removeLeadingZeros(cell)}</span>;
  }
  return "";
}

export function removeLeadingZeros(item: string): string {
  return item?.replace(/^0+/, "");
}

export function soldToFormatter(cell, row: Invoice) {
  if (row.zzcustom && row.zzcustom.soldtoad) {
    let address = row.zzcustom.soldtoad;
    return renderAddressForTable(address);
  } else {
    return cell;
  }
}

function renderAddressForTable(address) {
  return (
    <div>
      <div>{address.name}</div>
      <div>{address.street}</div>
      <div>
        {address.city}, {address.region}, {address.country}
      </div>
    </div>
  );
}

export function payerFormatter(cell, row: Invoice) {
  if (row.zzcustom && row.zzcustom.payerad) {
    let address = row.zzcustom.payerad;
    return renderAddressForTable(address);
  } else {
    return cell;
  }
}

export function reasonFormatter(
  cell,
  row: { reason: { description: string }; reason_message: string }
) {
  if (row.reason) {
    return (
      <span>
        {row.reason.description}
        <br />
        {row.reason_message}
      </span>
    );
  } else {
    return <span></span>;
  }
}

export function createInvoice(overrides: Partial<Invoice>): Invoice {
  let i: Invoice = {
    billing_document_number: "",
    clearing_date: "",
    clearing_document_number_finance: "",
    currency_key: "",
    days_in_arrears: 1,
    document_date: "",
    document_number_finance: "",
    due_date: "",
    finance_document_only: "",
    finance_document_type: "",
    fiscal_year_of_the_relevant_invoice: 2018,
    item_is_a_payment: "",
    line_item_in_the_relevant_invoice: 1,
    open_amount: 10,
    paid_amount: 10,
    payer_number: "",
    pdf_document_available: "",
    posting_date: "",
    read_only_flag: "",
    reference_number: "",
    soldto_number: "",
      total_amount: 20,
      STATEMENT_ID: "",
      customer_number: ""
  };

  for (let prop in overrides) {
    i[prop] = overrides[prop];
  }

  return i;
}

export function getPastYears(pastYears: number) {
  let years = [moment().year()];
  for (let i = 0; i < pastYears - 1; i++) {
    years.push(
      moment()
        .add(-(i + 1), "years")
        .year()
    );
  }
  return years;
}

export function dueDateSort(rowA: Invoice, rowB: Invoice, order) {
  if (isInvoice(rowA) && isInvoice(rowB)) {
    return sortStringDate(rowA.due_date, rowB.due_date, order);
  }
  if (isInvoice(rowA) && !isInvoice(rowB)) {
    return -1;
  }
  if (isInvoice(rowB) && !isInvoice(rowA)) {
    return 1;
  }
  if (isCredit(rowA) && !isCredit(rowB)) {
    return -1;
  }
  if (isCredit(rowB) && !isCredit(rowA)) {
    return 1;
  }
  if (isCredit(rowA) && isCredit(rowB)) {
    return sortStringDate(rowA.document_date, rowB.document_date, order);
  }
  return sortStringDate(rowA.document_date, rowB.document_date, order);
}
export function sortStringDate(a: string, b: string, order: "asc" | "desc") {
  let aDate = moment(a, "MM-DD-YYYY");
  let bDate = moment(b, "MM-DD-YYYY");
  if (order === "desc") {
    let diff = aDate.diff(bDate, "days");
    return diff;
  } else {
    let diff = bDate.diff(aDate, "days");
    return diff;
  }
}
export function querySort(rowA: Invoice, rowB: Invoice) {
  if (isInvoice(rowA) && isInvoice(rowB)) {
    return sortStringDate(rowA.due_date, rowB.due_date, "desc");
  }
  if (isInvoice(rowA) && !isInvoice(rowB)) {
    return -1;
  }
  if (isInvoice(rowB) && !isInvoice(rowA)) {
    return 1;
  }
  if (isCredit(rowA) && !isCredit(rowB)) {
    return -1;
  }
  if (isCredit(rowB) && !isCredit(rowA)) {
    return 1;
  }
  if (isCredit(rowA) && isCredit(rowB)) {
    return sortStringDate(rowA.document_date, rowB.document_date, "desc");
  }
  return sortStringDate(rowA.document_date, rowB.document_date, "desc");
}

// export function isMobileDevice() {
//     if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
//         return true
//     }
//     else {
//         return false
//     }

// };
