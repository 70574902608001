/* eslint-env browser */
/* eslint-disable no-console */
import React from 'react';
import {withRouter, useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import {Logout} from '../../actions/UserActions';
import {FormattedMessage} from 'react-intl';



export function SimpleHeaderMaintenance(props: any) {
    const history = useHistory();
    const navLinkLogoutClick = (event) => {
      event.preventDefault();
      event.stopPropagation();
      props.logout();
//        setTimeout(() => {
//            //history.push("/login/admin");
//            window.location.href = "/login";
//        }, 100);
    };
    return (
        <React.Fragment>
                <nav id="appHeader" className="webar-navbar navbar fixed-top navbar-expand-lg navbar-light">
                <div className="container">              
                    <div className="navbar-collapse webar-navbar-collapse" id="navbarSupportedContent">
                      <h5 className="Maintenanceheader-forecolor"><FormattedMessage id="configuration.system_maintenance_title"/> </h5>
                        <ul className="navbar-nav ml-auto">
                          <li className={"align-self-baseline align-self-lg-center Maintenanceheader-forecolor"}>
                            <span className="fas fa-cog fa-2x "/>
                          </li>
                          <li className="nav-item align-self-baseline align-self-lg-center">
                            <a className="nav-link" href="" id="header-logout-button"
                                onClick={navLinkLogoutClick}>
                                <span><FormattedMessage id="header.logout"/></span>
                            </a>
                          </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    );
}


export default withRouter(connect<any, any, any, any>((store,props) => ({
   
}), {
    logout: Logout,
})(SimpleHeaderMaintenance));