import * as types from '../constants/ActionTypes';
import {SapConfig} from "../interfaces/CnbsConfigInterfaces";

export function setConfig(config) {
    return {
        type: types.SET_CONFIG,
        payload: config,
    }
}

export interface AppConfig{
   [key: string]: any; 
   sap?: {
       data: SapConfig;
   }
}

export interface AppState {
    config: AppConfig;
    paymentMethods: any[];
    [key: string]: any;
}

const appInitialState: AppState = {
    config: {
        invoice_table: {columns: []},
    },
    paymentMethods: [],
};

export function appReducer(state = appInitialState, action): AppState {
    switch (action.type) {

        case types.SET_CONFIG: {
            return {
                ...state, config: action.payload,
            };
        }

        case types.SET_PAYMENT_METHODS: {
            return {...state, paymentMethods: action.payload};
        }

        default:
            return {...state};
    }

}
