export const CREDENTIALS_REQUIRED = 'Email And Password Are Required.';
export const CREDENTIALS_INVALID = 'Authentication Failed, Invalid Credentials.';
export const AUTHENTICATION_ERROR = 'Error while authenicating.';
export const GET_USER_ERROR = 'Error getting user profile from API.';
export const GET_USER_PAYER_ERROR = 'Error get user payers from API.';
export const GET_INVOICE_ERROR = 'Error getting invoices from API.';
export const GET_INVOICE_DETAIL_ERROR = 'Error getting invoice detail from API.';
export const GET_INVOICE_PDF_ERROR = 'Error getting invoices PDF from API.';
export const GET_PAYER_DETAILS_ERROR = 'Error getting payer details from API.';
export const MAKE_PAYMENT_ERROR = 'Error when making payment.';
export const RESET_PASSWORD_ERROR = 'Error while resetting the password.';
export const RESET_PASSWORD_SUCCESS = 'Password reset successfully.';
