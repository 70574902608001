import React, {useEffect, useRef, useState} from 'react';
import {getSelectedPrimaryAccount} from "../selectors/Selectors";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

export function AccountChangeRedirect(){

    const primaryAccount = useSelector(getSelectedPrimaryAccount);
    const history = useHistory();
    const isFirstRun = useRef(true);

    useEffect(() => {
        if (!isFirstRun.current) {
            //history.push("/invoices");
            return;
        }
        if(isFirstRun.current){
            isFirstRun.current = false;
            return;
        }
        
    }, [primaryAccount]);
    return null;
}