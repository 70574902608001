/* eslint-env browser */
/* eslint react/prop-types: 0 */
import 'react-activity/dist/react-activity.css';
import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { login, setBlackoutConfig, loginSSO } from '../../actions/UserActions';
import * as validationTypes from '../../constants/ValidationConstants';
import { Link } from 'react-router-dom';
import LoginRedirectHandler from "./LoginRedirectHandler";
import { Footer } from "../footer/Footer";
import Title from "./Title";
import LoginLogo from "./LoginLogo";
import Logo from "../Logo";
import { FormattedMessage, useIntl } from 'react-intl';
import { RootState } from "../../reducers/RootReducer";
import { useSelector } from "react-redux";
import { getBlackoutConfig, getIsBlackoutActive, getAllowRegistration } from "../../requests/ConfigRequests";
import DOMPurify from 'dompurify'

function Login(props: any) {

  
    let [isAllowRegistration, setIsAllowRegistration] = useState(false);
    let [bannerContent, setBannerContent] = useState(null);
    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");
    let t = useIntl();

    let onLogin = (e) => {
        e.preventDefault();
        e.stopPropagation();
        validateCredentials();
    }

    let validateCredentials = () => {
        if (email === null || password === null || email === '' || password === '') {
            toast.error(validationTypes.CREDENTIALS_REQUIRED, { position: toast.POSITION.TOP_RIGHT });
        } else {
            props.login(email, password).then().catch((error) => {

            });
        }
    }

    let backgroundClasses = "";
    if (props.useBackground) {
        backgroundClasses += " login-background";
    }

    //-- blackout --
    let blackoutMessage = "";
    const lang = useSelector<RootState, string>(state => state.user.language);
    const blackoutConfig = props.blackoutConfig;

    useEffect(() => {

        const fetchAllowRegistration = async () => {
            let value = await getAllowRegistration(lang);
            setIsAllowRegistration(value?.item1);
            setBannerContent(value?.item2);
        }
        fetchAllowRegistration();

        if (window.gigya) {
            window.gigya.accounts.getJWT({
                fields: "email", expiration: 86400, "callback": function (resp: any) {
                    let token = ""
                    if (resp.status === "OK") {
                        token = resp.id_token
                        props.loginSSO(token)
                    }
                }
            });
        }
    }, [lang]);

    if (blackoutConfig && blackoutConfig.isEnabled) {
        blackoutMessage = blackoutConfig.messages.find(m => m.language === lang)?.message;
        if (blackoutMessage === null) {
            blackoutMessage = blackoutConfig.messages.find(m => m.language === "en")?.message;
            if (blackoutMessage === null) {
                blackoutMessage = blackoutConfig.messages.find(msg => msg != null)?.message;
            }
        }

        if (blackoutMessage) {
            blackoutMessage = blackoutMessage.replace("<%datefrom%>", new Date(blackoutConfig.fromDateLocal).toLocaleDateString());
            blackoutMessage = blackoutMessage.replace("<%timefrom%>", new Date(blackoutConfig.fromDateLocal).toLocaleTimeString());
            blackoutMessage = blackoutMessage.replace("<%dateto%>", new Date(blackoutConfig.toDateLocal).toLocaleDateString());
            blackoutMessage = blackoutMessage.replace("<%timeto%>", new Date(blackoutConfig.toDateLocal).toLocaleTimeString());
        }
    }

    //blackoutMessage = "blackout in effect";

    let isBlackoutActive = props.isBlackoutActive;
    function createMarkup(html) {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }

    return (
        <React.Fragment>
            <div className={backgroundClasses}>
              
                {/*<nav id="appHeader" className=" ">*/}
                {/*    <div className='row m-4'>*/}
                {/*        <div className='col-9' >*/}

                {/*            <LoginLogo width={350} height={100} />*/}

                {/*        </div>*/}
                {/*        <div className='col  epay-portal-title'>*/}
                {/*            <span className=''><FormattedMessage id={"site.title"}></FormattedMessage></span>*/}
                {/*        </div>*/}
                        
                {/*    </div>*/}
                {/*</nav>*/}
              
                { /* disable all controls when blackout is in effect/is active */}

                <div aria-disabled={isBlackoutActive} className={(isBlackoutActive) ? 'is-disabled' : ''} >
                    <div className={'row justify-content-center banner-Div'} >
                        <div className={'col-8 banner-main-div'}>
                            <div className={'row'}>
                                <div className={'col'} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <div className={"row"} style={{ marginTop: "30px", minHeight: "250px" }} >

                                        {
                                            bannerContent !== "" &&
                                            <div className={"col"} style={{ minHeight: "250px" }}
                                                dangerouslySetInnerHTML={createMarkup(bannerContent)}                                >
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={'row'} style={{ marginBottom: "65px" }} >
                                <div className={'col'}>
                                    <div className={"row align-items-center"}>
                                        <div className="mx-auto login-area mt-3" style={{ minWidth: "350px" }}>

                                            { // display blackout message
                                                blackoutMessage !== "" &&
                                                <div className={"col-12 col-md d-flex justify-content-center align-items-baseline"}>
                                                    <span className={"invoice-currency-warning"}>
                                                        <label>{blackoutMessage}</label>
                                                    </span>
                                                </div>
                                            }
                                            <div className="row">
                                                <div className={"col"}>
                                                    <form action="" id="loginForm" onSubmit={onLogin}>

                                                        <div className="form-group mt-3">
                                                            <input type="text" className="form-control control-border" name="email" placeholder={t.formatMessage({ id: "login" })}
                                                                onChange={e => setEmail(e.target.value)} autoCapitalize="none" autoCorrect="off" />
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="password" className="form-control control-border" name="password" placeholder={t.formatMessage({ id: "password" })}
                                                                onChange={e => setPassword(e.target.value)} />
                                                        </div>
                                                        <div className="form-group d-flex justify-content-center" >
                                                            <button type="submit"
                                                                className="btn btn-primary buttonColor buttonsize form-control control-border button-class" style={{width:"157px"} } 
                                                            ><FormattedMessage id={"site.login"} />
                                                            </button>
                                                        </div>
                                                        <div className="form-group text-center button-class">
                                                            <Link to={"/request-password-reset"}>
                                                                <span className="trouble"><FormattedMessage id={"site.trouble_logging_in"} /></span>
                                                            </Link>
                                                        </div>
                                                        <br />
                                                        <br />
                                                        {isAllowRegistration === true ?
                                                            <div className="form-group d-flex justify-content-center">
                                                                {/*No Registration*/}
                                                                <Link to={"/auto-register/accounts"}>
                                                                    <button type="button"
                                                                        className="btn btn-primary buttonColor buttonsize form-control control-border button-class" style={{ width: "157px" }}>
                                                                        <span className="register-button-text"><FormattedMessage id={"site.register"} /></span>
                                                                    </button>
                                                                </Link>
                                                            </div> : null
                                                        }
                                                    </form>
                                                </div>
                                                {props.isLoggedIn && <LoginRedirectHandler />}
                                                <Footer />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                 
                </div> {/* area-disabled */}
            </div>
        </React.Fragment>
    );

}

// class LoginOld extends Component<any, any> {
//   constructor(props) {
//     super(props);
//     this.state = {
//       email: '',
//       password: '',
//     };

//     this.onLogin = this.onLogin.bind(this);
//     this.handleOnChange = this.handleOnChange.bind(this);
//   }

//   onLogin(e) {
//     e.preventDefault();
//     e.stopPropagation();
//     this.validateCredentials();
//   }

//   validateCredentials() {
//     if (this.state.email === null || this.state.password === null || this.state.email === '' || this.state.password === '') {
//       toast.error(validationTypes.CREDENTIALS_REQUIRED, {position: toast.POSITION.TOP_RIGHT});
//     } else {
//       this.props.login(this.state.email, this.state.password).then().catch((error) => {

//       });
//     }
//   }

//   handleOnChange = input => (e) => {
//     this.setState({
//       [input]: e.target.value,
//     });
//   };

//   render() {
//     let backgroundClasses = "";
//     if (this.props.useBackground) {
//       backgroundClasses += " login-background"; 
//     }

//     return (
//       <React.Fragment>
//         <div className={backgroundClasses}>
//           <div className={"row align-items-center login-row"}>
//             <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mx-auto login-area mt-3">
//               <div className={"row"}>
//                 <div className={"mx-auto mt-3"}>
//                   <LoginLogo width={200} />
//                 </div>
//               </div>
//               <div className="row">
//                 <div className={"col"}>
//                   <form action="" id="loginForm" onSubmit={this.onLogin}>
//                     <h1 className="heading mt-3" style={{ textAlign: "center" }}><Title /></h1>
//                     <br />
//                     <div className="form-group mt-3">
//                       <input type="text" className="form-control control-border" name="email" placeholder="Email"
//                              onChange={e => this.handleOnChange('email')} />
//                     </div>
//                     <div className="form-group">
//                       <input type="password" className="form-control control-border" name="password" placeholder="Password"
//                              onChange={this.handleOnChange('password')} />
//                     </div>
//                     <div className="form-group">
//                       <button type="submit"
//                               className="btn btn-primary loginButtonColor form-control control-border button-class"
//                               ><FormattedMessage id={"site.login"} />
//                       </button>
//                     </div>
//                     <div className="form-group text-center button-class">
//                       <Link to={"/request-password-reset"}>
//                         <span className="trouble"><FormattedMessage id={"site.trouble_logging_in"} /></span>
//                       </Link>
//                     </div>
//                     <br />
//                     <br />
//                     <div className="form-group">
//                       <Link to={"/auto-register/accounts"}>
//                         <button type="button"
//                                 className="btn btn-secondary registerButtonColor form-control control-border button-class">
//                           <span className="register-button-text"><FormattedMessage id={"site.register"} /></span>
//                         </button>
//                       </Link>
//                     </div>
//                   </form>
//                 </div>
//                 {this.props.isLoggedIn && <LoginRedirectHandler />}
//                 <Footer />
//               </div>

//             </div>
//           </div>
//           <div className={"logo-bottom-right"}>
//             <Logo width={"80"} />
//           </div> 
//         </div>
//       </React.Fragment>
//     );
//   }
// }

export default connect<any, any, any, any>(store => ({
    documentsList: store.default.documentsList,
    customer_number: store.default.customer_number,
    company_code: store.default.company_code,
    sales_organization: store.default.sales_organization,
    isLoggedIn: store.user.isLoggedIn,
    useBackground: store.app.config.style.login_use_background_image,
    isBlackoutActive: store.user.isBlackoutActive,
    blackoutConfig: store.user.blackoutConfig,

}), {
    login,
    setBlackoutConfig,
    loginSSO,
})(Login);
