import * as types from '../constants/ActionTypes';
import {combineReducers} from "redux";
import {defaultInvoiceInitialState,  invoiceReducer} from "./InvoiceReducer";
import defaultReducer, {DefaultState} from "./CnbsReducer";
import {appReducer, AppState} from "./AppReducer";
import {userOptionsReducer} from "./UserOptionsReducer";
import {userReducer} from "./UserReducer";
import { paymentReducer } from "./PaymentReducer";
import { statementsReducer } from "./StatementReducer";
import {isStringNotEmptyOrNull} from "../utils";
import {Clear} from "@material-ui/icons";
import {Session} from "inspector";

export interface RootState {
    app: AppState;
    default: DefaultState;
    invoice: any;
    user_options: any;
    user: any;
    payment: any;
    statements: any;
}

const _rootReducer = combineReducers<RootState>({
    app: appReducer,
    default: defaultReducer,
    invoice: invoiceReducer,
    user_options: userOptionsReducer,
    user: userReducer,
    payment: paymentReducer,
    statements: statementsReducer
});

export function clearSessionStorage() {
    sessionStorage.clear();
}

export const rootReducer = (state, action) => {
    if (action.type === types.LOGOUT) {
        clearSessionStorage();
        if (isStringNotEmptyOrNull(state?.default?.currentUser?.third_party_id)) {
            window.location.href = process.env.REACT_APP_SSO_REDIRECT_URL as string;
            return;
        }
        state = {
            user: {
                loginChecked: true,
                lastUrl: state.user.lastUrl,
                loginCount: state.user.loginCount++,
                language: state.user.language,
            },
            app: state.app,
            invoice: defaultInvoiceInitialState,
        };
        
    }

    return _rootReducer(state, action);
};
