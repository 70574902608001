/* eslint-env browser */
/* eslint-disable no-console */
import React from 'react';
import Logo from "../Logo";
import {Link} from "react-router-dom";

export default function SimpleHeader(props) {
  return (
    <nav id="appHeader" className="webar-navbar navbar navbar-expand-lg navbar-light">
      <div className="container">
        <div id="navbarSupportedContent">
          <Link to={"/login"}>
            <ul className="navbar-nav flat-left">
              <Logo height={50}/>
            </ul>
          </Link>
        </div>
      </div>
    </nav>
  );
}


