import React, {Component} from 'react';

interface AddExclusionItemProps{
  onSubmit: (string: string) => any;
}
interface AddExclusionItemState{
  inputValue: string; 
}

class AddExclusionItem extends Component<AddExclusionItemProps, AddExclusionItemState> {

  constructor(props) {
    super(props);

    this.state = {
      inputValue: "",
    };

    this._updateInput = this._updateInput.bind(this);
    this._submit = this._submit.bind(this);
  }

  _updateInput(event) {
    this.setState({
      inputValue: event.target.value,
    });
  }

  _submit(event) {
    event.preventDefault();
    if(this.state.inputValue.length > 0){
      this.props.onSubmit(this.state.inputValue);
      this.setState({
        inputValue: "",
      });
    }
  }

  render() {
    return (
      <form onSubmit={this._submit}>
        <div className="row no-gutters">
          <div className="col-6 col-md-10">
            <div className="input-group input-group-sm mb-1">
              <input type="text" className="form-control" placeholder=""
                     aria-label="account number" aria-describedby="basic-addon2"
                     onChange={this._updateInput}
                     value={this.state.inputValue}
              />
              <div className="input-group-append">
                <button className="btn btn-primary" type="submit" onClick={this._submit}>Add</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}


export default AddExclusionItem;