import React, {useEffect, useRef, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import {MakeDepositSchema} from '../payment-methods/schemas';
import {BootstrapError, BootstrapField} from "../../helpers/Field";
import {Payment} from '../invoice/Payment';
import PayerSelect from '../invoice/PayerSelect';
import {getDepositReasonCodes, getPayerAccounts, getSelectedPrimaryAccount} from "../../selectors/Selectors";
import {Formik, setIn} from "formik";
import {AccountType} from "../../interfaces/Accounts";
import {FormattedMessage, useIntl} from "react-intl";
import {useCurrencyKey} from "../../selectors/ConfigSelectors";
import {AccountChangeRedirect} from "../AccountChangeRedirect";
import {FinalizePaymentProcess} from "../payment-methods/manage-payment-methods/FinalizePaymentProcess";
import {useHistory, useLocation} from "react-router-dom";
import {Session} from "inspector";
import {CurrencySymbol} from '../../common/CurrencyDisplay';
import {RootState} from '../../reducers/RootReducer';
import { getisPaymentDisable } from "../../requests/ConfigRequests";
import { setisPaymentDisable, getMessageForPaymentDisable } from '../../actions/CommonActions';


function _Deposits(props) {
    const deposit_cache = "deposit_cache";
    const intl = useIntl();
    const f = id => intl.formatMessage({id: id});
    let [initialValues, setInitialValues] = useState<{
        payer_option: string,
        reason_code: string,
        amount_to_process: null | number,
        reference_number: string,
        comment: string,
    } | null>({
        payer_option: "",
        reason_code: "",
        amount_to_process: null,
        reference_number: "",
        comment: "",
    });
    let currencyKey = useSelector<RootState, string>(state => state.default.selectedCurrencyOptionValue);
    const language = useSelector<RootState, string>(state => state.user.language);
    const location = useLocation();
    const [ready, setReady] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const search = new URLSearchParams(location.search);
    const accessToken = search.get("id");
    const prevValues = useRef();
    const currency_key = useCurrencyKey();
    let [PaymentDisable, setPaymentDisable] = useState(null);

    //useSelector<RootState, any>(
    //    state => {
    //        state.app.config.sap?.data?.company_codes?.map((item) => {
    //                if (item.company_code === props.selectedPrimaryAccount.CompanyCode) {
    //                    if (item.disable_payments === "X") {
    //                        sessionStorage.setItem("isPaymentDisable", "true");
    //                    }
    //                }
    //        });
    //    }
    //);

    const reasonCodes = props.depositReasonCodes;

    if(currencyKey == ''){
        currencyKey = currency_key;
    }
    useEffect(() => {

        getisPaymentDisable().then(_ => setisPaymentDisable(_));
        let newValues: any = initialValues;
        if (accessToken) {
            let cached = sessionStorage.getItem(deposit_cache);
            if (cached) {
                let temp: any = JSON.parse(cached);
                newValues = {
                    ...newValues,
                    ...temp
                }
            }
        }
        let reasonCode = "";
        if (reasonCodes && reasonCodes.length > 0) {
            reasonCode = reasonCodes[0]?.value;
        }
        
        setInitialValues({
            payer_option: newValues?.payer_option ?? "",
            reason_code: (newValues?.reason_code === "") ? reasonCode : newValues.reason_code,
            amount_to_process: newValues?.amount_to_process ?? null,
            reference_number: newValues?.reference_number ?? "",
            comment: newValues?.comment ?? "",
        });

        setReady(true);
    }, [accessToken, reasonCodes]);


    let schema = MakeDepositSchema(intl);
    const isSoldToAccount = props.accountType;
    
    return (
        <>
            {(initialValues && ready) &&
            <Formik initialValues={initialValues} validationSchema={schema} validateOnChange={true}
                    validateOnBlur={true}
                    enableReinitialize={true}
                    isInitialValid={schema.isValidSync(initialValues)}
                    onSubmit={() => {/* TODO currently no op. This is probably not working at all as is. NOOP*/
                    }}
                render={(formProps) => {                       
                        let isPaymentDisable = (sessionStorage.getItem("isPaymentDisable")?.toString() === "true") ? true : false;
                    var paymentDisableMessage;                    
                    var paymentDisableKey = (language.toString() + "_paymentDisableMessageText".trim());
                    paymentDisableMessage = getMessageForPaymentDisable(paymentDisableKey);                    
                    sessionStorage.setItem(deposit_cache, JSON.stringify(formProps.values));
                    return (
                    <div>
                            {isPaymentDisable &&
                                        <div
                                            className={"col-12 col-md d-flex justify-content-center  align-items-baseline"}>
                                            <span className={"invoice-currency-warning"}>
                                    <label> {paymentDisableMessage} </label>
                                            </span>
                                        </div>}
                            <div aria-disabled={isPaymentDisable}
                                className={(isPaymentDisable) ? 'is-disabled' : ''}>
                                <AccountChangeRedirect/>
                                <FinalizePaymentProcess/>
                                <div className="container">                                    
                                    <div className="row">
                                        <div className="col-lg-2 col-md-4 col-sm-12 col-12">
                                            <h2 className="invoicesHeading bottompadding"><FormattedMessage
                                                id={"deposits.page_title"}/></h2>
                                        </div>
                                    </div>
                                    <div aria-disabled={isPaymentDisable}>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card">
                                                    <div className="card-body px-0 px-sm-4">
                                                        <div className="row">
                                                            <div className={"col-12 col-lg-6"}>
                                                                <div className="form-group row">
                                                                    <div
                                                                        className="d-flex col-sm-3 col-form-label col-form-label-sm text-secondary justify-content-start justify-content-md-end  ">
                                                                        <div><FormattedMessage id={"deposits.payer_label"}/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-9">
                                                                        {isSoldToAccount ?
                                                                            <PayerSelect name={'payer_option'}/> : ''}
                                                                    </div>
                                                                </div>
                                                            
                                                                <div className="form-group row">
                                                                    <label htmlFor="colFormLabelSm"
                                                                           className="d-flex col-sm-3 col-form-label col-form-label-sm text-secondary justify-content-start justify-content-md-end  ">
                                                                        {f("deposits.amount_label")}
                                                                    </label>
                                                                    <div className="col-10 col-sm-9 input-group">
                                                                    <div className="input-group-prepend">
                                                                            <span className="input-group-text">{CurrencySymbol(currencyKey)}</span>
                                                                        </div>
                                                                        <BootstrapField type="number"
                                                                                        onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                                                                        name="amount_to_process"
                                                                                        onChange={formProps.handleChange}/>
                                                                        <BootstrapError name="amount_to_process"/>
                                                                      
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className={"col-12 col-lg-6"}>
                                                                <div className="form-group row">
                                                                    <label htmlFor="colFormLabelSm"
                                                                           className="d-flex col-sm-3 col-form-label col-form-label-sm text-secondary justify-content-start justify-content-md-end text-right"><FormattedMessage
                                                                        id={"deposits.reason_code_label"}/></label>
                                                                    <div className="col-sm-9 customSelect">
                                                                        <BootstrapField component="select"
                                                                                        name="reason_code"
                                                                                        placeholder={f("deposits.reason_code_label")}
                                                                                        value={formProps?.values?.reason_code}
                                                                                        className="form-control">
                                                                            {reasonCodes.map(code => {
                                                                                return (
                                                                                    <option value={code.value}
                                                                                            key={code.value}>
                                                                                        {code.label}
                                                                                    </option>
                                                                                )
                                                                            })}
                                                                        </BootstrapField>
                                                                        <i className="fas fa-chevron-down select-arrow"/>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label htmlFor="colFormLabelSm"
                                                                           className="d-flex col-sm-3 col-form-label col-form-label-sm text-secondary justify-content-start justify-content-md-end  text-right">
                                                                        {f("deposits.document_number_label")}
                                                                    </label>
                                                                    <div className="col-sm-9">
                                                                        <BootstrapField type="text"
                                                                                        name="reference_number"/>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label htmlFor="colFormLabelSm"
                                                                           className="d-flex col-sm-3 col-form-label col-form-label-sm text-secondary justify-content-start justify-content-md-end  text-right">
                                                                        {f("deposits.payment_note_label")}
                                                                    </label>
                                                                    <div className="col-sm-9">
                                                                        <BootstrapField component="textarea" rows="3"
                                                                                        name="comment"/>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br/>

                                        <div className="row">
                                            <div className="col-12">
                                                <Payment mode={"deposit"} form={formProps}/>
                                            </div>
                                        </div>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            </div>
                        );
                    }}
            />}</>
    );
}

export const Deposits = connect<any, any, any, any>((state, props) => {
    return {
        selectedPrimaryAccount: getSelectedPrimaryAccount(state),
        payerAccounts: getPayerAccounts(state),
        depositReasonCodes: getDepositReasonCodes(state),
        invoices: state.default.invoices,
        accountType: state.user_options.accountType,
        depositAmount: state.default.depositAmount,
    }
})(_Deposits);
