import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AutoLinkAccount from "./AutoLinkAccount";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { getRegistrationAccount } from "../../../requests/UserRequests";
import { trimLeadingZeroes } from "../../../utils";
import { Link, useHistory } from "react-router-dom";
import { Footer } from "../../footer/Footer";
import SimpleHeader from "../../header/SimpleHeader";
import BackToLogin from "../../BackToLogin";
import { useIntl } from "react-intl";

function mapStateToProps(state) {
    return {};
}

function AutoRegisterPage(props) {
    const intl = useIntl();
    const f = id => intl.formatMessage({ id: id });
    let [accounts, setAccounts] = useState<any[]>([]);
    const history = useHistory();

    function updateAccounts() {
        const token = sessionStorage.getItem("_registerToken") ?? ""

        getRegistrationAccount(token).then((response) => {
            
            setAccounts(response)
        });
    }

    useEffect(() => {
        updateAccounts();
    }, []);

    function createAccount() {
        history.push("/auto-register/create");
    }

    return (
        <React.Fragment>
            <SimpleHeader />
            <div className={"container mt-5"}>
                <div className="row mt-4">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mx-auto">
                        {accounts.length < 4 && <AutoLinkAccount onAccountAdded={updateAccounts} />}
                        {accounts.length >= 4 && <div className="card">
                            <p>{f("register.max_no_of_accounts")}</p>
                        </div>
                        }
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mx-auto">
                        <div className="card">
                            <div className="row">
                                <div className={"col-12"}>
                                    <p><strong>{f("register.accounts")}</strong></p>
                                    {accounts.length === 0 && <p>{f("register.no_account_yet")}</p>
                                    }
                                    <List>
                                        {accounts.map(a => {
                                          
                                            return (<React.Fragment>
                                                <ListItem>
                                                    <ListItemText primary={`${a.name} - ${trimLeadingZeroes(a.accountId)}`} />
                                                </ListItem>
                                                <Divider />
                                            </React.Fragment>)
                                        })}
                                    </List>
                                    {accounts.length === 0 ?
                                        <button disabled={true} className={"btn buttonsize buttonColor btnPay"} >{f("register.continue")}</button>
                                        :
                                        <button disabled={accounts.length === 0} className={"btn btn-primary buttonsize buttonColor btnPay"}  onClick={createAccount}
                                        >{f("register.continue")}</button>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row justify-content-center flex mt-3">
                    <BackToLogin />
                </div>
            </div>

            <Footer />
        </React.Fragment>
    );
}

export default connect<any, any, any, any>((state, props) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
    }
})(AutoRegisterPage);
