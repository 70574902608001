import React, { useState, useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import { addInvoiceToSelected, adjustCurrency, getInvoiceDetail } from '../../actions/InvoiceActions';
import { FormattedMessage } from "react-intl";
import {
    calculateUnitPrice,
    formatTotal,
    formatColumn,
    removeLeadingZeros,
    isCredit, formatColumnRemoveLeading,
} from '../../common/invoiceHelpers';
import { isStringNotEmptyOrNull } from "../../utils";
import { Invoice } from "../../interfaces/invoice";
import { ThunkFunction } from "../../interfaces/utils";
import { useIntl, FormatDateOptions } from 'react-intl';
import { useCanMakePayments, useCurrencyDecimalLookup } from "../../selectors/ConfigSelectors";
import { AccountChangeRedirect } from "../AccountChangeRedirect";
import { CurrencyDisplay } from "../../common/CurrencyDisplay";
import { getCompanyCode } from "../../selectors/Selectors";
import { RootState } from '../../reducers/RootReducer';
import { getMessageForPaymentDisable } from '../../actions/CommonActions';
import { useMediaQuery } from 'react-responsive'
import { getSelectedPrimaryAccount } from "../../selectors/Selectors";
// interface DetailPageProps {
//     history: any;  // Comes from withRouter
//     match: any; // Comes from withRouter
//     invoices: Invoice[];
//     addInvoiceToSelected: ThunkFunction<typeof addInvoiceToSelected>;
//     getInvoiceDetail: ThunkFunction<typeof getInvoiceDetail>;
// }

// interface InvoiceDetail{
//     header_data: any;
//     item_data: ItemData[];
//     partner_data: any;
// }

interface ItemData {
    base_unit: string;
    billed_quantity: number;
    currency_key: string;
    description: string;
    item_number: number;
    material_number: string;
    pricing_unit: string;
    sales_unit: string;
    total_amount: number;

}


function _Detail(props: any) {
    const [state, setState] = useState<any>({
        itemData: [] as ItemData[],
        billingDocumentNumber: props.match.params.billingDocumentNumber,
        documentDate: '',
        totalAmount: '',
        currencyKey: '',
        customerAddress: { region: '', city: '', postal_code_city: '', name: '', street: '' },
        invoiceRecipientAddress: { region: '', city: '', postal_code_city: '', name: '', street: '' },
        backPage: '/invoices',
        billingDocumentType: '',
        poNumber: '',
    });
   
    const intl = useIntl();
    const f = id => intl.formatMessage({ id: id });
    const d = (dateString: string, options?: FormatDateOptions) => intl.formatDate(dateString, options);
    const top = 'Top';
    const tab = useRef('table');
    const _canMakePayment = useCanMakePayments();
    const [canMakePayment] = useState(_canMakePayment);
    const decimalLookup = useCurrencyDecimalLookup();
    const companyCode = useSelector(getCompanyCode);
    let isPaymentDisable = (sessionStorage.getItem("isPaymentDisable")?.toString() === "true") ? true : false;
    //useSelector<RootState, any>(
    //    state => {
    //        state.app.config.sap?.data?.company_codes?.map((item) => {
    //                if (item.company_code === props.selectedPrimaryAccount.CompanyCode) {
    //                    if (item.disable_payments === "X") {
    //                        sessionStorage.setItem("isPaymentDisable", "true");
    //                    }
    //                }
    //        });
    //    }
    //);
    const language = useSelector<RootState, string>(state => state.user.language);
    var paymentDisableMessage;
    var paymentDisableKey = (language.toString() + "_paymentDisableMessageText".trim());
    paymentDisableMessage = getMessageForPaymentDisable(paymentDisableKey);

    function pay(invoice: Invoice) {
        props.addInvoiceToSelected(invoice, decimalLookup(invoice.currency_key));
        props.history.push("/invoices/pay");
    }

    function goBack() {
        props.history.goBack();
        //  props.history.push(state.backPage,{searchQuery:""});
    }
    const payerNumber = props.activeInvoice.payer_number;
    const soldtoNumber = props.activeInvoice.soldto_number;
    const CustomerNumber = props.activeInvoice.soldto_number?props.activeInvoice.payer_number:'';
    const billingDocumentNumber = props.match.params.billingDocumentNumber;

    const docNumber = billingDocumentNumber ? removeLeadingZeros(billingDocumentNumber) : '';
    const cusNumber = CustomerNumber ? removeLeadingZeros(CustomerNumber) : '';
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

    useEffect(() => {
        if (docNumber) {
            props.getInvoiceDetail(docNumber, '01', cusNumber).then((result) => {
                if (result) {
                    let tempState: any = {
                        itemData: [] as ItemData[],
                        billingDocumentNumber: billingDocumentNumber,
                        documentDate: '',
                        totalAmount: '',
                        currencyKey: '',
                        customerAddress: { region: '', city: '', postal_code_city: '', name: '', street: '' },
                        invoiceRecipientAddress: { region: '', city: '', postal_code_city: '', name: '', street: '' },
                        backPage: '/invoices',
                        billingDocumentType: '',
                        poNumber: '',
                        document_status: '',
                       

                    };

                    if (result.header_data) {
                        tempState.billingDocumentNumber = result.header_data.billing_document_number ? result.header_data.billing_document_number : '';
                        tempState.billingDocumentType = result.header_data.billing_document_type ? result.header_data.billing_document_type : '';
                        tempState.documentDate = result.header_data.document_date ? result.header_data.document_date : '';
                        tempState.totalAmount = result.header_data.total_amount ? adjustCurrency(result.header_data.total_amount, decimalLookup(result.header_data.currency_key)) : '';
                        tempState.currencyKey = result.header_data.currency_key ? result.header_data.currency_key : '';
                        tempState.poNumber = result.header_data?.custom_fields?.customer_purchase_order_nubmer ?? '';
                        tempState.document_status = result.header_data.document_status ? result.header_data.document_status : '';
                        tempState.payer_number = result.header_data.payer_number ? result.header_data.payer_number : '';
                        tempState.soldto_number = result.header_data.soldto_number ? result.header_data.soldto_number : '';
                    }

                    if (result.partner_data) {
                        const customer = result.partner_data.filter(obj => obj.partner_function === 'WE'); //TODO this should be configurable.
                        const invoiceRecipient = result.partner_data.filter(obj => obj.partner_function === 'RG');
                        tempState.customerAddress = customer[0].address_data;
                        tempState.invoiceRecipientAddress = invoiceRecipient[0].address_data;
                        tempState.payer_number = invoiceRecipient[0].payer_number;
                        tempState.soldto_number = invoiceRecipient[0].soldto_number;
                    }
                    
                    if (result.item_data) {
                        tempState.itemData = result.item_data;
                    }

                    tempState?.itemData?.forEach((_) => {
                        _.total_amount = adjustCurrency(_.total_amount, decimalLookup(_.currency_key))
                    });
                    setState(tempState)
                }
            }).catch(() => { /* TODO: Catch error */
            });
        }
    }, [docNumber]);

    let headerDate = state.documentDate ? d(state.documentDate) : '';
    let customerCity = state.customerAddress?.city ?? "";
    let customerRegion = state?.customerAddress?.region ?? "";
    let customerPostalCode = state?.customerAddress?.postal_code_city ?? "";

    let invoiceRecipientCity = state.invoiceRecipientAddress?.city ?? '';
    let invoiceRecipientRegion = state.invoiceRecipientAddress?.region ?? '';
    let invoiceRecipientCode = state.invoiceRecipientAddress?.postal_code_city ?? '';
    let document_status = state.document_status && state.document_status != "03" ? true : false;
    let invoice = props?.activeInvoice;
    
    let allowPay = false;
    if (invoice) {
        allowPay = canMakePayment && !isCredit(invoice) && document_status;
    }

    const columns = [
        {
            headerClasses: "invoice-detail-column-header",
            dataField: "item_number",
            formatter: formatColumn,
            text: f("invoice_detail.item_label"),
            headerStyle: { width: '65px' },
            headerAlign: "center",
            align: "center",
        },
        {
            headerClasses: "invoice-detail-column-header",
            dataField: "billed_quantity",
            formatter: formatColumn,
            text: f("invoice_detail.quantity_label"),
            headerStyle: { width: '55px' },
            headerAlign: "center",
            align: "center"
        },
        {
            headerClasses: "invoice-detail-column-header",
            dataField: "material_number",
            formatter: formatColumnRemoveLeading,
            text: f("invoice_detail.article_number_label"),
            headerStyle: { width: '55px' },
            headerAlign: "center",
            align: "center"
        },
        {
            headerClasses: "invoice-detail-column-header",
            dataField: "description",
            formatter: formatColumn,
            text: f("invoice_detail.description_label"),
            headerStyle: { width: '55px' },
            headerAlign: "center",
            align: "center"
        },
        {
            headerClasses: "invoice-detail-column-header",
            dataField: "total_amount",
            formatter: calculateUnitPrice,
            text: f("invoice_detail.unit_price_label"),
            headerStyle: { width: '55px' },
            headerAlign: "center",
            align: "center"
        },
        {
            headerClasses: "invoice-detail-column-header",
            dataField: "total_amount",
            formatter: formatTotal,
            text: f("invoice_detail.total_label"),
            headerStyle: { width: '55px' },
            headerAlign: "center",
            align: "center"
        },
    ];


    const {
        customerAddress,
        invoiceRecipientAddress,
        itemData,
        totalAmount,
        showPayButton,
       
    } = state;
   
    let itemDataWithKey: any[] = itemData.map((_: any) => {
        _._key = _.item_number;
        return _;
    });

    

    function displayCard(row: any) {

        return (
            <div className="card"  >
                <div className="flex">

                    <div className="row invoice-detail-column-header ">
                        <div className='col ml-1 p-0'>
                            <label className='h5  bold'>{f("invoice_detail.article_number_label")}</label>

                        </div>
                        <div className='col'>


                            <label className='h5  bold'>{f("invoice_detail.description_label")}</label>
                        </div>

                    </div>

                    <div className='row'>
                        <div className='col ml-1 p-0'>
                            {row.material_number}
                        </div>
                        <div className='col'>
                            {row.description}
                        </div>
                    </div>

                    <div className='row ml-1'>
                        <div  >
                            <div className='h5  bold mb-0'>{f("invoice_detail.item_label")}</div>
                           
                            <div  className='  bold '>{ formatColumn(row.item_number)} </div>
                           
                            <div  className='h5  bold mb-0 mt-2'>{f("invoice_detail.quantity_label")}</div>
                             
                            <div  className='  bold'>   {formatColumn(row.billed_quantity)}</div>
                             
                            <div  className='h5  bold mb-0 mt-2'>{f("invoice_detail.unit_price_label")}</div>
                           
                            <div  className='  bold'>{calculateUnitPrice("",row)}</div>
                          
                            <div  className='h5  bold mb-0 mt-2'>{f("invoice_detail.total_label")}</div>
                          
                            <div className='  bold  ' >   {formatTotal("",row)}</div>
                          
                        </div>
                    </div>
                </div>

            </div>
        );

    }

    return (
       
        <div className="container">
            
            <AccountChangeRedirect />
            {isPaymentDisable &&
                <div
                    className={"col-12 col-md d-flex justify-content-center  align-items-baseline"}>
                    <span className={"invoice-currency-warning"}>
                        <label> {paymentDisableMessage} </label>
                    </span>
                </div>}
            <div className="row">
                <div className="col justify-content-between d-flex pl0">
                    <div>
                        <button className="btn button-back" onClick={goBack}>
                            <span>
                                <i className="fas fa-angle-left" /> {f("invoice_detail.back_button")}
                            </span>
                        </button>
                    </div>
                    <div aria-disabled={isPaymentDisable}
                        className={(isPaymentDisable) ? 'is-disabled' : ''}>
                        {invoice && allowPay && document_status &&
                            <button className="btn btn-primary buttonColor " onClick={() => {
                                pay(invoice)
                            }}>
                                <span
                                    className="invoice-detail-pay-button-text">{f("invoice_detail.pay_invoice")}</span>
                            </button>}
                    </div>
                </div>
            </div>
            <br />
            <div className="card" style={{ margin: "0.5em 1em", paddingLeft: "6.5em", paddingRight:"0em" }}>
                
                
            {/*<div className="row align-items-center justify-content-center">*/}
            {/*    {!isCredit(invoice) && <span*/}
            {/*        className="invoice-detail-header-text">{`${f("invoice_detail.invoice_label")}${' '}${docNumber}`}</span>}*/}
            {/*    {isCredit(invoice) &&*/}
            {/*        <span className="invoice-detail-header-text">{`${f("invoice_detail.credit")}${' '}${docNumber}`}</span>}*/}
            {/*</div>*/}
               

                <div className = "row">
                    <div className="col-lg-3 col-md-6 col-sm-12 col-12 px-2">
                            {!isCredit(invoice) &&
                                <>
                            <span className=" col-form-label text-secondary font-weight-bold marginBottom">{`${f("invoice_detail.invoice_label")}`}</span><br></br>
                                <span className="col-form-label text-secondary nopadding">{`${docNumber}`}</span>
                                </>
                            }                            
                            {isCredit(invoice) &&
                                <>
                            <span className=" col-form-label text-secondary font-weight-bold marginBottom">{`${f("invoice_detail.credit")}`}</span><br></br>
                                    <span className="col-form-label text-secondary nopadding">{`${docNumber}`}</span>
                                </>
                            }
                    
                 

                         {isStringNotEmptyOrNull(state.poNumber) && <div className="row">
                        <span
                                className="col-md-4 col-sm-2 col-2 col-form-label text-secondary font-weight-bold marginBottom">{intl.formatMessage({
                                id: "invoice_detail.purchase_order_number",
                                defaultMessage: "PO Number"
                            })}</span>
                        <span
                                className="col-form-label col-form-label-sm text-secondary nopadding">{state.poNumber}</span>
                        </div>
                        }
                    </div>
                        
                        <div className="col-lg-3 col-md-6 col-sm-12 col-12 px-2" >
                            
                                <span
                            className="col-form-label text-secondary font-weight-bold marginBottom" >{f("invoice_detail.date_label")}</span><br></br>
                                <span
                                    className="col-form-label col-form-label-sm text-secondary nopadding">{headerDate}</span>
                          
                            {isStringNotEmptyOrNull(state.poNumber) && <div className="row">
                                <span
                                className="col-md-4 col-sm-2 col-2 col-form-label text-secondary font-weight-bold marginBottom">{intl.formatMessage({
                                        id: "invoice_detail.purchase_order_number",
                                        defaultMessage: "PO Number"
                                    })}</span>
                                <span
                                    className="col-md-8 col-sm-10 col-10 col-form-label col-form-label-sm text-secondary nopadding">{state.poNumber}</span>
                            </div>}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 col-12 px-2 ">
                            {companyCode?.startsWith("JP") ? <div>
                                <span
                                className="col-form-label text-secondary font-weight-bold marginBottom">{f("invoice_detail.customer_label")}</span>
                           
                            &nbsp;
                                <br/>
                           
                                <span
                                    className="col-form-label col-form-label-sm text-secondary nopadding">{customerAddress.name_2}</span>
                        </div> :

                               <>
                               
                                <span
                                    className="col-form-label text-secondary font-weight-bold marginBottom">{f("invoice_detail.customer_label")}</span>
                                <br />
                                <span
                                    className="col-form-label col-form-label-sm text-secondary nopadding">{soldtoNumber}</span>
                                    <br/>
                                <span
                                    className="col-form-label col-form-label-sm text-secondary nopadding">{customerAddress.name}</span>
                               </>
                                }
                            <div className="row">
                                <span
                                    className="col-form-label col-form-label-sm text-secondary nopadding">{customerAddress.street}</span>
                            </div>
                            <div className="row">
                                <span
                                    className="col-form-label col-form-label-sm text-secondary nopadding">{`${customerCity}${' '}${customerRegion}${' '}${customerPostalCode}`}</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 col-12 px-2">
                         
                                <span
                            className=" col-form-label text-secondary font-weight-bold marginBottom">{f("invoice_detail.invoice_recipient_label")}</span>
                        <br />
                        <span
                            className="col-form-label col-form-label-sm text-secondary nopadding">{payerNumber}</span>
                            <br/>
                                <span
                                    className="col-form-label col-form-label-sm text-secondary nopadding">{invoiceRecipientAddress.name}</span>
                            
                            <div className="row">
                                <span
                                    className="col-form-label col-form-label-sm text-secondary nopadding">{invoiceRecipientAddress.street}</span>
                            </div>
                            <div className="row">
                                <span
                                    className="col-form-label col-form-label-sm text-secondary nopadding">{`${invoiceRecipientCity}${' '}${invoiceRecipientRegion}${' '}${invoiceRecipientCode}`}</span>
                            </div>
                            
                   
                    </div>
                </div>
                
                </div>
            
            <br />
            <br />
            <div className="container">
            <div className="row">
                {
                    isMobile && itemDataWithKey.map((key, index) => displayCard(key))
                }
                {!isMobile && <div className={"col-12"}>
                        <BootstrapTable
                            data={itemDataWithKey}
                            ref={tab}
                            headerStyle={{ background: 'red', height: '40px', borderCollapse: 'collapse' }}
                            classes={'wa-bootstrap-table-container invoice-detail-grid-cell'}
                            scrollTop={top}
                            defaultSorted={[{ dataField: "item_number", order: 'desc' }]}
                            columns={columns}
                            keyField={'_key'}
                            bootstrap4={true}
                         

                    >
                    </BootstrapTable>
                </div>}
            </div>
            <div className="row">
                    <div className={"col-md-12 col-sm-12 d-flex  justify-content-end invoice-total-amount"}>
                    <span
                        className="invoice-detail-invoice-total-label">{isCredit(invoice) ? f("invoice_detail.credit_note_total") : f("invoice_detail.invoice_total_label")}:</span>
                    &nbsp;
                    &nbsp;
                    <span
                        className="invoice-detail-invoice-total-text"><CurrencyDisplay currency={state.currencyKey} value={parseFloat(totalAmount)} /></span>
                </div>
                </div>
            
            </div>
        </div>
       
    );
}

export default withRouter(connect<any, any, any, any>((state, props) => ({
    invoices: state.default.invoices,
    activeInvoice: state.invoice.activeInvoice,
    selectedPrimaryAccount: getSelectedPrimaryAccount(state),
}), {
    addInvoiceToSelected,
    getInvoiceDetail
})(_Detail));

