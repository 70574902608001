import React, { useEffect, useState, useRef } from 'react';
import { BootstrapField, BootstrapError } from "../../../helpers/Field";
import * as yup from 'yup';
import { Formik } from "formik";
import { getAppConfiguration, getConfiguration, smtpTest, updateConfiguration, updateApplicationConfiguration } from "../../../requests/ConfigRequests";
import CustomizingPage from "../customizing/CustomizingPage";
import { toast } from 'react-toastify';
import classNames from "classnames";
import { connect } from "react-redux";
import { useIntl, FormattedMessage, IntlShape } from "react-intl";
import { Paper, Tab, Tabs } from "@material-ui/core"
import { EmailConfiguration } from "./email-config/EmailConfiguration";
import { createEmailConfig, EmailConfig, EmailTemplate } from "../../../interfaces/EmailConfig";
import { getEmailConfig, updateEmailConfig } from "../../../requests/ConfigRequests";
import { checkStatus, parseJson, returnNull } from "../../../fetch";
import { Label } from '@material-ui/icons';
import { getisPaymentDisable } from "../../../requests/ConfigRequests";
import { setisPaymentDisable, setMessageForPaymentDisable } from '../../../actions/CommonActions';
import { MaintenancePage } from "./maintenance/MaintenancePage";
import { string } from 'yup';
import { Editor } from "@tinymce/tinymce-react"
import { getAllowRegistration } from "../../../requests/ConfigRequests";
import { makeStyles } from '@material-ui/core/styles'
import JoditEditor from 'jodit-react';
import { useDispatch } from "react-redux";
import { setFooterLinks } from '../../../actions/UserActions';

const schema = (intl: IntlShape) => {
    return yup.object().shape({
        isPaymentsDisable: yup.boolean(),
        registrationRequestEmail: yup.string().email(),
        bannerContent: yup.string(),
        registrationEmailExpiration: yup.string(),
        registrationEmailExpirationMessage: yup.string(),
        //bannerContentLanguage: yup.string(),
        // registrationRequestEmailContent: yup.string(),
        // registrationRequestorEmailContent: yup.string(),
        // welcomeEmailContent: yup.string(),
        // resetPasswordEmailContent: yup.string(),
        maxPaymentAllowed: yup.string(),
        emailConfirmationContent: yup.string(),
        fromAddress: yup.string().email(),
        fromAddressName: yup.string(),

    });
};

const useStyles = makeStyles({
    tabs: {

        "& .MuiTabs-indicator": {
            backgroundColor: "#336699",
        },
        "& .MuiTab-root.Mui-selected": {
            color: '#336699'
        }
    }
})

function _EditConfiguration(props) {
    let sapStatusGood = props.sapConfig.statusCode === 200 && props.sapConfig.data.status.message_type === "S";
    const intl = useIntl();
    const f = id => intl.formatMessage({ id: id });
    let [config, setConfig] = useState(null);
    let [showConfiguration, setShowConfiguration] = useState(false);
    let [testEmail, setTestEmail] = useState("");
    let [settingsTab, setSettingsTab] = useState(0);
    const dispatch = useDispatch();

    const [selectedLanguage, setSelectedLanguage] = useState("en");
    const [selectedKey, setSelectedKey] = useState("registration_request_email");
    const [currentEmailConfig, setCurrentEmailConfig] = useState<EmailConfig>();
    const [emailTemplate, setEmailTemplate] = useState<EmailTemplate>({ email: "", language: "", title: "", template: "" });
    const [selectedLanguageMessage, setSelectedLanguageMessage] = useState("en_paymentDisableMessageText");
    const [selectedLanguageMessageText, setselectedLanguageMessageText] = useState("");
    const [bannerContent, setBannerContent] = useState("");
    const [bannerContentLanguage, setBannerContentLanguage] = useState("");

    const classes = useStyles();

    const editor = useRef(null);
    const [content, setContent] = useState('');

    //const [emailTemplate2, setSelectedTemplate] = emailTemplate;
    // GetEmailTemplate
    //SetEmailTemplate
    let template = "";
    const editorRef = useRef("");
    const languages = [
        { key: "en_paymentDisableMessageText", label: "English" },
        { key: "fr_paymentDisableMessageText", label: "French" },
        { key: "de_paymentDisableMessageText", label: "German" },
        { key: "es_paymentDisableMessageText", label: "Spanish" },
        { key: "it_paymentDisableMessageText", label: "Italian" },
        { key: "ja_paymentDisableMessageText", label: "Japanese" },
        { key: "pt_paymentDisableMessageText", label: "Portuguese" },
        { key: "ru_paymentDisableMessageText", label: "Russian" },
    ];
    const languagesForPayment = [
        { key: "en", label: "English" ,paymentMessageId:"en_paymentDisableMessageText"},
        { key: "fr", label: "French" ,paymentMessageId:"fr_paymentDisableMessageText"},
        { key: "de", label: "German",paymentMessageId:"de_paymentDisableMessageText" },
        { key: "es", label: "Spanish",paymentMessageId:"es_paymentDisableMessageText" },
        { key: "it", label: "Italian",paymentMessageId:"it_paymentDisableMessageText" },
        { key: "ja", label: "Japanese" ,paymentMessageId:"ja_paymentDisableMessageText"},
        { key: "pt", label: "Portuguese",paymentMessageId:"pt_paymentDisableMessageText" },
        { key: "ru", label: "Russian",paymentMessageId:"ru_paymentDisableMessageText" },
    ];

    const languagesForBannerContent = [
        { key: "en", label: "English" },
        { key: "fr", label: "French" },
        { key: "de", label: "German"},
        { key: "es", label: "Spanish" },
        { key: "it", label: "Italian" },
        { key: "ja", label: "Japanese"},
        { key: "pt", label: "Portuguese" },
        { key: "ru", label: "Russian" },
    ];

    const emailExpirationHours = [
        { key: "6", label: "6 Hours" },
        { key: "12", label: "12 Hours" },
        { key: "24", label: "24 Hours" },
        { key: "48", label: "48 Hours" },
        { key: "ne", label: "Never" },
    ];

    const fetchBannerContent = async () => {
        let value = await getAllowRegistration(selectedLanguage);
        setBannerContent(value?.item2);
    }

    //let fetchData = async () => {
    //    let config: EmailConfig | null = await getEmailConfig(selectedKey)
    //        .then(checkStatus(200))
    //        .then(parseJson)
    //        .catch(returnNull);
    //    if (config == null) {
    //        setCurrentEmailConfig(createEmailConfig(selectedKey));
    //        createNewTemplate();
    //    } else {
    //        setCurrentEmailConfig(config);
    //        if (config.templates[selectedLanguage]) {
    //            //this.template = config.templates[selectedLanguage];
    //            setEmailTemplate(config.templates[selectedLanguage]);
    //        } else {
    //            createNewTemplate();
    //        }
    //    }
    //};


    //useEffect(() => {

    //    fetchData();

    //}, [selectedKey, selectedLanguage]);

    

    useEffect(() => {

        fetchBannerContent();

    }, [selectedLanguage]);


    useEffect(() => {
        getConfiguration().then(_ => setConfig(_));
        getisPaymentDisable().then(_ => setisPaymentDisable(_));
    }, []);

    function handleSettingsTabChange(event, newValue) {
        setSettingsTab(newValue);
    }
    function createNewTemplate() {
        setEmailTemplate({ title: "", language: selectedLanguage, email: "", template: "" });
    }
    function toggleShowConfiguration() {
        if (showConfiguration) {
            setShowConfiguration(false);
        } else {
            setShowConfiguration(true);
        }
    }
    function onLanguageChange(e: React.ChangeEvent<HTMLSelectElement>) {
        e.preventDefault();
        setSelectedLanguageMessage(e.target.value.toString());
        setSelectedLanguage(e.target.value);
    }
   
    function isPaymentDisablemsgexists(values: any) {
        if (values['en_paymentDisableMessageText'] == '' &&
            values['fr_paymentDisableMessageText'] == '' &&
            values['de_paymentDisableMessageText'] == '' &&
            values['it_paymentDisableMessageText'] == '' &&
            values['es_paymentDisableMessageText'] == '' &&
            values['pt_paymentDisableMessageText'] == '' &&
            values['ru_paymentDisableMessageText'] == '' &&
            values['ja_paymentDisableMessageText'] == '' &&
            values['ja_paymentDisableMessageText'] == '' &&
            values['isPaymentsDisable']) {
            return false;
        }
        else {
            return true;
        }
    }
    function onPaymentLanguageChange(e: React.ChangeEvent<HTMLSelectElement>) {
        e.preventDefault();
        let key= e.target.value.toString();

        let paymentMessageObject=languagesForPayment.find(item=>item.key===key)

        let paymentMessageId=paymentMessageObject!.paymentMessageId
       setSelectedLanguageMessage(paymentMessageId);
    }

 



    function sendTestEmail(values) {
        // createNewTemplate();
        //values = useState("Template").toString();
        // var templateval = useState("Template").toString();
        // this.state.Template
        //alert(EmailConfig);
        //template=config.templates[selectedLanguage];
        var vTemplate = sessionStorage.getItem("Template");
        let _testEmail = prompt("Test Email", testEmail);
        if (_testEmail == null) {
            setTestEmail("");
        } else {
            setTestEmail(_testEmail);
        }
        smtpTest({ ...values, testEmail: _testEmail, emailTemplate: vTemplate }).then((message) => {
            alert(message);
        });
        //, template: useState("Template").toString()
    }
    function onEditorStateChange(editorState) {
        setBannerContent(editorState);
    };
    return (
        <div className="container">

            {config && <React.Fragment>
                <Paper square elevation={1}>
                    <Tabs
                        className={classes.tabs}
                        value={settingsTab}
                        onChange={handleSettingsTabChange}
                        aria-label="disabled tabs example"
                        
                    >
                        <Tab label={f("settings.header.application")} />
                        <Tab label={f("settings.header.email")} />
                        <Tab label={f("settings.header.maintenance")} />
                        <Tab label={f("settings.header.system")} />
                    </Tabs>
                </Paper>

                {settingsTab == 0 && <Formik
                    
                    onSubmit={(values, bag) => {  
                        if (isPaymentDisablemsgexists(values) == true) {
                            bag.setSubmitting(true);
                            updateApplicationConfiguration(values,selectedLanguage).then(() => {
                                toast.success(f("configuration.save_configuration_alert"), { position: toast.POSITION.TOP_RIGHT });
                                getConfiguration().then((value) => {
                                    setMessageForPaymentDisable(value)
                                    let footerLinks = { privacyPolicy: '//' + value.privacyPolicy, termsAndConditions: '//' + value.termsAndConditions, contactUs: '//' + value.contactUs, }
                                    dispatch(setFooterLinks(footerLinks));
                                })
                                bag.setSubmitting(false);
                            })
                        }
                    }
                    }
                    initialValues={config}
                    render={(formProps: any) => <form onSubmit={formProps.handleSubmit}>
                        <div className="row">
                            <div className="col-12 card">
                                <div className={"row mt-2 mb-3"}>
                                    <div className="col-lg-9 col-md-12">
                                        <div>
                                            
                                            <h5>{f("configuration.email.login_page")}</h5>
                                            <div className={"form-group row"}>
                                                <label
                                                    className="col-4 col-form-label-sm text-secondary ">{"Allow Registration"}</label>
                                                <div className="col form-group form-check">
                                                    <BootstrapField id="allowRegistration" name="allowRegistration"
                                                        placeholder={f("configuration.email_use_auth_user")}
                                                        type={"checkbox"}
                                                        checked={formProps?.values?.allowRegistration} />

                                                </div>
                                            </div>
                                            <div className={"form-group row"}>
                                                <label
                                                    className="col-4 col-form-label-sm text-secondary ">{f("configuration.email.registration_request_email")}</label>
                                                <div className="col">
                                                    <BootstrapField name="registrationRequestEmail"
                                                        placeholder={f("configuration.email.registration_request_email")} />
                                                    <BootstrapError name="registrationRequestEmail" />
                                                    <p>
                                                        <small className="font-weight-light small text-muted text-break font-italic">{f("configuration.email.registration_request_address_desc")}</small>
                                                    </p>
                                                </div>
                                            </div>

                                            {/* Email Expiration*/}
                                            <div className={"form-group row"}>
                                                <label
                                                    className="col-4 col-form-label-sm text-secondary ">{f("configuration.email.registration_email_expiration")}</label>
                                                <div className="col">
                                                    <BootstrapField component="select"
                                                        name="registrationEmailExpiration"
                                                        value={formProps?.values?.registrationEmailExpiration}
                                                        className={"form-control custom-dropdown"}>
                                                        {emailExpirationHours.map(code => {
                                                            return (
                                                                <option
                                                                    value={code.key}
                                                                    selected={code.key == formProps?.values?.registrationEmailExpiration}
                                                                    key={code.label}>
                                                                    {code.label}
                                                                </option>
                                                            )
                                                        })}
                                                    </BootstrapField>

                                                </div>
                                            </div>

                                            <div className={"form-group row"}>
                                                <label
                                                    className="col-4 col-form-label-sm text-secondary ">{f("configuration.email.registration_email_expiration_message")}</label>
                                                <div className="col TextMultiline">
                                                    <BootstrapField component="textarea"
                                                        numberOfLines={4}
                                                        name="registrationEmailExpirationMessage"
                                                        value={formProps?.values?.registrationEmailExpirationMessage}
                                                        className={"form-control"}>
                                                    </BootstrapField>

                                                </div>
                                            </div>


                                            <div className={"form-group row"}>
                                                <label
                                                    className="col-4 col-form-label-sm text-secondary ">{f("configuration.banner_language")}</label>
                                                <div className="col" style={{ width: "650px" }}>
                                                        <select className={"form-control"} onChange={onLanguageChange}>
                                                        {languagesForBannerContent.map(_ => <option key={_.key} value={_.key}>{_.label}</option>)}
                                                        </select>
                                                </div>
                                            </div>
                                            <div className={"form-group row"}>
                                                <label
                                                    className="col-4 col-form-label-sm text-secondary ">{f("configuration.banner")}</label>
                                                <div className="col" style={{width:"650px"}}>
                                                    <JoditEditor
                                                        ref={editor}
                                                        value={bannerContent}
                                                        onChange={newContent => {
                                                            formProps.handleChange({ target: { name: 'bannerContent', value: newContent } })
                                                        }}
                                                    />
                                                    
                                                </div>
                                            </div>


                                            {/* Request Email */}
                                            <h5>{f("configuration.links")}</h5>
                                            <div className={"form-group row"}>
                                                <label
                                                    className="col-4 col-form-label-sm text-secondary ">{f("configuration.site_privacy_policy")}</label>
                                                <div className="col">
                                                    <BootstrapField name="privacyPolicy"
                                                        placeholder={f("configuration.site_privacy_policy")} />
                                                    <BootstrapError name="privacyPolicy" />
                                                </div>
                                            </div>
                                            <div className={"form-group row"}>
                                                <label
                                                    className="col-4 col-form-label-sm text-secondary ">{f("configuration.site_terms_conditions")}</label>
                                                <div className="col">
                                                    <BootstrapField name="termsAndConditions"
                                                        placeholder={f("configuration.site_terms_conditions")} />
                                                    <BootstrapError name="termsAndConditions" />
                                                </div>
                                            </div>
                                            <div className={"form-group row"}>
                                                <label
                                                    className="col-4 col-form-label-sm text-secondary ">{f("configuration.site_contactus")}</label>
                                                <div className="col">
                                                    <BootstrapField name="contactUs"
                                                        placeholder={f("configuration.site_contactus")} />
                                                    <BootstrapError name="contactUs" />
                                                </div>
                                            </div>
                                            {!props.isBlackoutActive && <div>


                                                {/* payments */}
                                                <h5>{f("configuration.payment_Configuration")}</h5>
                                                

                                                {/*{formProps?.values?.isPaymentsDisable &&*/}
                                                {/*<div className={"container mb-1"}>*/}
                                                    <div className={"form-group row"}>
                                                        <label
                                                            className="col-4 col-form-label-sm text-secondary bold">{f("configuration.Payment_Functionality")}</label>
                                                        <div className="col form-group form-check">
                                                            <BootstrapField id="isPaymentsDisable" name="isPaymentsDisable"
                                                                placeholder={f("configuration.Payments-disable")}
                                                                type={"checkbox"}
                                                                checked={formProps?.values?.isPaymentsDisable} />
                                                            <label htmlFor="isPaymentsDisable"
                                                                className="form-check-label">{f("configuration.Payments-disable")}</label>
                                                        </div>
                                                    </div>
                                                    <div className={"form-group row"}>
                                                        <label
                                                            className="col-4 col-form-label-sm text-secondary bold">{f("configuration.payment_Message_Language")}</label>
                                                        <div className={"col"}>
                                                            <select className={"form-control"} onChange={onPaymentLanguageChange}>
                                                                {languagesForPayment.map(_ => <option key={_.key} value={_.key}>{_.label}</option>)}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className={"form-group row"}>
                                                        <label
                                                            className="col-4 col-form-label-sm text-secondary bold">{f("configuration.Payments_Message_Text")}</label>
                                                        <div className="col TextMultiline">
                                                            <BootstrapField name={selectedLanguageMessage} component="textarea" numberOfLines={4} />
                                                            <BootstrapError name={selectedLanguageMessage} />
                                                            {!isPaymentDisablemsgexists(formProps?.values) &&
                                                                <div
                                                                    className={"col-12 col-md d-flex justify-content-center  align-items-baseline"}>
                                                                    <span className={"invoice-currency-warning"}>
                                                                        <label><FormattedMessage
                                                                            id={f("configuration.disable_Payments_Validation_Message")} /></label>
                                                                    </span>
                                                                </div>}
                                                        </div>
                                                    </div>
                                                    <div className={"form-group row"}>
                                                        <label
                                                            className="col-4 col-form-label-sm text-secondary bold">{f("configuration.maximum_card_payment_allowed")}</label>
                                                        <div className="col">
                                                            <BootstrapField name="maxPaymentAllowed" type="number" min="0"
                                                            />
                                                            <BootstrapError name="maxPaymentAllowed" />
                                                            <p className="font-weight-light small text-muted text-break font-italic">
                                                                {f("configuration.max_amount_info")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className={"form-group row"}>
                                                        <label
                                                            className="col-4 col-form-label-sm text-secondary bold">{f("configuration.maximum_echeck_payment_allowed")}</label>
                                                        <div className="col">
                                                            <BootstrapField name="maxECheckPaymentAllowed" type="number" min="0"
                                                            />
                                                            <BootstrapError name="maxECheckPaymentAllowed" />
                                                            <p className="font-weight-light small text-muted text-break font-italic">
                                                                {f("configuration.max_amount_info")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                {/*</div>*/}
                                            </div>}

                                            <div>
                                                <button className={"btn btn-primary buttonColor buttonsize"}  type={"submit"}><FormattedMessage
                                                    id={f("configuration.save_configuration")} /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>} />}
                {/* email templates */}
                {
                    settingsTab === 1 && <EmailConfiguration />
                }

                {/* maintenance */}
                {
                    settingsTab === 2 && <MaintenancePage paymentConfig={config} setConfig={setConfig} />
                }

                {/* SAP Details */}
                {
                    settingsTab === 3 && <div>
                        <div className="row">
                            <div className="col-12 card">
                                <CustomizingPage config={config} setConfig={setConfig} />
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
            }
        </div>

    );
}

export default connect<any, any, any, any>((state, props) => {
    return {
        sapConfig: state.app.config.sap,
    }
})(_EditConfiguration);
