// Authentication
export const LOGIN_URL = `/api/token/login?`;
export const LOGIN_SSO_URL = `/api/token/login-sso?`;
export const REFRESH_TOKEN_URL = `/api/token/refresh?`;
export const LOGOUT_URL = `/api/token/logout`;
export const GET_USER_URL = `/api/token/getuseraccounts`;
export const RESET_PASSWORD = `/api/token/resetpassword`;

// Invoice
export const INVOICE_LIST_URL = `/api/Invoice`;
export const INVOICE_DETAIL_URL = `/api/invoice/detail`;
export const INVOICE_PDF_URL = `/api/Invoice/pdf-request`;
export const INVOICE_SEARCH_PDF_URL = `/api/invoice/search-pdf-request`;
export const INVOICE_BULK_URL = `/api/Invoice/bulk-pdf-request`;
export const INVOICE_EXCEL_DOWNLOAD = `/api/invoice/invoice-excel`;
export const PAYMENT_URL = `/api/invoice/payments`;

// Customer
export const PAYER_DETAILS_URL = `/api/customer/payer`;
export const USER_HEADER_ACCOUNTS = `/api/customer/header-accounts?`;
export const MANAGE_PAYMENTS_URL = `/api/customer/payment_cards?`;
export const DEPOSIT_URL = `/api/customer/makedeposit`;

// Payment Methods
export const GET_PAYMENT_ACCESS_TOKEN = `/api/tokenization/access_token?`;
export const GET_PAYMENT_RESPONSE = `/api/tokenization/response?`;

// User Management
export const GET_USER_LIST = '/api/users';

// AccountType Management
export const GET_ACCOUNT_TYPE_LIST = '/api/configuration/getaccounttypes';
export const GET_PRIMARY_ACCOUNT_TYPE_LIST = '/api/configuration/getprimaryaccounttypes';

//StatementId Management

export const GET_STATEMENTS_LIST = '/api/function_module';
export const GET_STATEMENT_PDF = 'api/statement/statement-pdf-request'
