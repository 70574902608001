import {PayerData} from '../../interfaces/PayerData';
import {Invoice} from "../../interfaces/invoice";
import {StringSchema} from "yup";

export function getValue(property, defaultVal) {
    if (typeof property === 'undefined') {
        return defaultVal;
    }
    return property;
}

export function valueEquals(property, equals) {
    if (typeof property === 'undefined') {
        return false;
    }
    return property === equals;
}

export function mapInvoiceToPaymentDocument(invoice: Invoice, details) {
    return {
        document_number_finance: invoice.document_number_finance,
        line_item_in_the_relevant_invoice: invoice.line_item_in_the_relevant_invoice,
        fiscal_year_of_the_relevant_invoice: invoice.fiscal_year_of_the_relevant_invoice,
        open_amount: invoice.open_amount,
        amount_to_process: details.amount,
        currency_key: invoice.currency_key,
        reason_code: details.reason,
        comment: details.comment,
        reference_number: invoice.reference_number,
        billing_document_number: invoice.billing_document_number,
        document_date: invoice.document_date,
    }
}

export function mapInvoiceListToPaymentDocuments(invoices, details) {
    return Object.keys(invoices).map((key) => {
        return mapInvoiceToPaymentDocument(invoices[key], details[key]);
    });
}

interface PaymentDetails {
    payment_card_name: any | string | StringSchema<Exclude<string, undefined>>;
    card_validation_code: string;
    payment_card_token: any | string;
    valid_to: any | string;
    payment_card_type: any | string | "AMEX" | "VISA" | "MC" | "EC" | StringSchema<Exclude<string, undefined>>;
    address_data: { name_3: any | string; name_2: any | string; country: any; city: any; postal_code_city: any; street: any; district: any; name: any; region: any; name_4: any | string };
    payment_method: string;
    electronic_check_account_type: any | StringSchema<Exclude<string, undefined>> | string;
    electronic_check_rdfi_number: any | StringSchema<Exclude<string, undefined>> | { test: (value) => (boolean | boolean); name: string; message: string } | string
    cardinal?: any,
}

export function mapPaymentDetails(payment_card, cvv = '', address: any = {}, cardinal: null | any = null) {
    const paymentMethod = payment_card.payment_card_type === 'EC' ? 'EC' : 'CC';
    const details: PaymentDetails = {
        payment_method: paymentMethod,
        payment_card_type: payment_card.payment_card_type,
        payment_card_token: payment_card.payment_card_token,
        payment_card_name: payment_card.payment_card_name,
        electronic_check_account_type: payment_card?.electronic_check_account_type ?? "",
        electronic_check_rdfi_number: payment_card?.electronic_check_rdfi_number ?? "",
        valid_to: payment_card.valid_to,
        card_validation_code: cvv,
        address_data: {
            name: address?.name ?? '',
            name_2: address?.name_2 ?? '',
            name_3: address?.name_3 ?? '',
            name_4: address?.name_4 ?? '',
            city: getValue(address.city, ''),
            district: getValue(address.district, ''),
            street: getValue(address.street, ''),
            postal_code_city: getValue(address.postal_code_city, ''),
            region: getValue(address.region, ''),
            country: getValue(address.country, ''),
        }

    };
    if (cardinal != null) {
        details.cardinal = cardinal;
    }
    return details;
}

export function createPayerData(customerNumber, companyCode): PayerData {
    return {company_code: companyCode, customer_number: customerNumber};
}

export function mapDepositDetails(values, currency_key: string) {
    return {
        amount_to_process: values.amount_to_process,
        currency_key: currency_key,
        reason_code: values.reason_code,
        reference_number: values.reference_number,
        comment: values.comment
    }
}
