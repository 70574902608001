import {Component} from 'react';
import {withRouter} from "react-router-dom";
import {setLastUrl} from "../actions/UserActions";
import {connect} from 'react-redux';

function isPublicUrl(path){
    let ignoreList = [
        "^/login",
        "^/reset-password",
        "^/registration-request",
        "^/auto-register/create",
        "^/request-password-reset",
        "^/register",
    ];
    
    let ignore = false;
    for(let i = 0; i < ignoreList.length; i++){
        let results = path.match(ignoreList[i]);
        if(results !== null){
            ignore = true;
        }
    }
    return ignore;
}

class RouterListener extends Component<any, any> {
  componentDidMount() {
    if (!isPublicUrl(this.props.location.pathname)) {
      this.props.setLastUrl(this.props.location.pathname);
    }
    this.props.history.listen((e) => {
      if (!isPublicUrl(e.pathname)) {
        this.props.setLastUrl(e.pathname);
      }
    });
  }
  

  render() {
    return null;
  }
}

export default withRouter(connect<any, any, any, any>(state => ({}), {
  setLastUrl,
})(RouterListener));
