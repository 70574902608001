import 'react-activity/dist/react-activity.css';
import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import { toast } from 'react-toastify';
import { login } from '../../../actions/UserActions';
import {AuthStatus} from "../../../interfaces/AuthStatus";
//import * as validationTypes from '../../constants/ValidationConstants';
import * as validationTypes from '../../../constants/ValidationConstants';
import { Link } from 'react-router-dom';
import MaintenanceLoginRedirectHandler from "./MaintenanceLoginRedirectHandler";
import { Footer } from "../../footer/Footer";
//import Title from "./Title";
//import LoginLogo from "./LoginLogo";
//import Logo from "../Logo";
import { FormattedMessage, useIntl } from 'react-intl';
import SimpleHeaderMaintenanceLogin from "../../header/SimpleHeaderMaintenanceLogin";
//header / SimpleHeader";
//const LoginSchema = Yup.object().shape({
//    email: Yup.string().email('Email is invalid').required('Email is required'),
//    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
//});

const MaintenanceLogin = (props: any) => {
    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");
    let t = useIntl();

    let onMaintenanceLogin = (e) => {
        e.preventDefault();
        e.stopPropagation();
        validateCredentials();
    }

    let validateCredentials = () => {
        if (email === null || password === null || email === '' || password === '') {
            toast.error(validationTypes.CREDENTIALS_REQUIRED, { position: toast.POSITION.TOP_RIGHT });
        } else {
            props.login(email, password).then(()=> {}).catch((error) => {});
        }
    }

    let backgroundClasses = "";
    //if (props.useBackground) {
    //    backgroundClasses += " login-background";
    //}
    return (

        <React.Fragment>
            <SimpleHeaderMaintenanceLogin/>
            <div > 
                <div className={"row align-items-center Maintenance-login-row"}>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mx-auto login-area mt-3">             
              <div className="row">
                <div className={"col"}>
                                <form action="" id="loginForm" onSubmit={onMaintenanceLogin}> {/*onSubmit={onLogin}*/}
                    <div className="form-group mt-3">
                      <input type="text" className="form-control control-border" name="email" placeholder={t.formatMessage({id: "Email"})}
                             onChange={e => setEmail(e.target.value)} autoCapitalize="none" autoCorrect="off"/>
                    </div>
                    <div className="form-group">
                      <input type="password" className="form-control control-border" name="password" placeholder={t.formatMessage({id:"password"})}
                             onChange={e => setPassword(e.target.value)}/>
                    </div>
                    <div className="form-group">
                      <button type="submit"
                                            className="btn btn-primary buttonColor buttonsize  form-control control-border button-class"> <FormattedMessage id={"site.login"} />
                      </button>
                    </div>                    
                    <br/>
                    <br/>                  
                  </form>
                </div>
               {/*props.authStatus === AuthStatus.Authenticated && <MaintenanceLoginRedirectHandler/>*/}
                <Footer/>
              </div>
            
            </div>
          </div>
          <div className={"logo-bottom-right"}>
          {/*  <Logo width={"80"}/>*/}
          </div> 
        </div>
      </React.Fragment>
    );
}
//}

export default withRouter(connect<any, any, any, any>((store,props) => ({
    isLoggedIn: store.user.isLoggedIn,
    useBackground: store.app.config.style.login_use_background_image,
    authStatus: store.user.authStatus,
}), {
    login,
})(MaintenanceLogin));
