import React, { useEffect, useState } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { Deposits } from "./deposits/Deposits";
import { useHistory } from "react-router";
import Statements from "./statements/Statements";
import Help from "./help/Help";
import Header from "./header/Header";
import { Footer } from "./footer/Footer";
import InvoicePage from "./invoice/InvoicePage";
import PaymentPage from "./invoice/PaymentPage";
import PaymentStatusPage from "./invoice/PaymentStatusPage";
import DetailPage from "./invoice/DetailPage";
import InvoiceSearch from "./invoice/InvoiceSearch";
import { connect, useDispatch } from "react-redux";
import { getSelectedPrimaryAccount } from "../selectors/Selectors";
import PayerDetailsDataManager, {
  reestablishSelectedValues,
} from "./invoice/PayerDetailsDataManager";
import { getUser, getUserCustomers } from "../actions/UserActions";
import SettingsPage from "./settings-page/SettingsPage";
// import InvoiceDataManager from "./invoice/InvoiceDataManager";
import { canEditSystemConfig } from "../selectors/PermissionSelectors";
import EditConfiguration from "./settings-page/configuration/EditConfiguration";
import BasePageErrorBoundary from "../error-boundaries/BasePageErrorBoundary";
import PaymentStatusErrorBoundary from "../error-boundaries/PaymentStatusErrorBoundary";
import { IdleHandlerConnected } from "./IdleHandler";
import { DepositPaymentStatusPage } from "./deposits/DepositPaymentStatusPage";
import { useCanMakePayments } from "../selectors/ConfigSelectors";
import { ProcessingPaymentPage } from "./invoice/ProcessingPaymentPage";
import { ProcessingDepositPage } from "./invoice/ProcessingDepositPage";
import AutoRegisterWaitingConfirmation from "./register/auto-register/AutoRegisterWaitingConfirmation";
import {
  IMPERSONATED_USER_ID,
  SELECTED_PRIMARY_INDEX_KEY,
  SELECTED_SECONDARY_INDEX_KEY,
} from "../reducers/UserOptionsReducer";
import {
  updatePrimaryAccount,
  updateSecondaryAccount,
} from "../actions/InvoiceActions";

export function BasePage(props) {
  const [systemError, setSystemError] = useState(false);
  const canMakePayment = useCanMakePayments();
  var waitingconfirmation = false;
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [title, settitle] = useState("hhhh");
  useEffect(() => {
    props.getUser().then(async (user) => {
        if (user) {
        if (
          props.sap.statusCode === 200 &&
          props.sap?.data.status.message_type === "S"
        ) {
          if (user && user.status === "waiting-confirmation") {
            props.history.push("/auto-register/waiting-confirmation");
            waitingconfirmation = true;
            settitle("true");
          }
        } else {
          if (user.Permissions.indexOf("CanEditSystemConfig") > -1) {
            props.history.push("/settings/configuration");
          } else {
            props.history.replace("/system-error");
          }
          setSystemError(true);
        }

        const _primaryAccount = sessionStorage.getItem(
          SELECTED_PRIMARY_INDEX_KEY
        );
        const _secondaryAccount = sessionStorage.getItem(
          SELECTED_SECONDARY_INDEX_KEY
        );

        const _impersonatedUserId =
          sessionStorage.getItem(IMPERSONATED_USER_ID);

        if (_impersonatedUserId) {
          await dispatch(
            getUserCustomers(JSON.parse(_impersonatedUserId), true)
          );
        } else {
          await dispatch(getUserCustomers("", true));
        }

        if (
          _primaryAccount !== null &&
          _primaryAccount !== "" &&
          _primaryAccount !== undefined
        ) {
          dispatch(updatePrimaryAccount(JSON.parse(_primaryAccount), false));
        }

        if (
          _secondaryAccount !== null &&
          _secondaryAccount !== "" &&
          _secondaryAccount !== undefined
        ) {
          if (_secondaryAccount.toString() !== "undefined") {
            dispatch(updateSecondaryAccount(JSON.parse(_secondaryAccount)));
          }
        }

        setUser(user);
      }
    });
  }, [setUser]);

  return (
    <React.Fragment>
      {user && (
        <React.Fragment>
          <IdleHandlerConnected />
          {title != "true" ? <Header /> : null}
          {!systemError ? (
            <React.Fragment>
              {/*<InvoiceDataManager render={*/}
              {/*    (*/}
              <PayerDetailsDataManager
                render={
                  <div className={"app-content"}>
                    <Switch>
                      <Route path="/invoices">
                        <React.Fragment>
                          <Switch>
                            {canMakePayment && (
                              <Route
                                path={"/invoices/pay"}
                                render={() => (
                                  <BasePageErrorBoundary>
                                    <PaymentPage />
                                  </BasePageErrorBoundary>
                                )}
                              />
                            )}
                            {canMakePayment && (
                              <Route
                                path={"/invoices/processing-payment"}
                                render={() => (
                                  <BasePageErrorBoundary>
                                    <ProcessingPaymentPage />
                                  </BasePageErrorBoundary>
                                )}
                              />
                            )}
                            {canMakePayment && (
                              <Route
                                path={"/invoices/payment-status"}
                                render={() => (
                                  <PaymentStatusErrorBoundary>
                                    <PaymentStatusPage />
                                  </PaymentStatusErrorBoundary>
                                )}
                              />
                            )}
                            <Route
                              path={"/invoices/detail/:billingDocumentNumber"}
                              render={() => (
                                <BasePageErrorBoundary>
                                  <DetailPage />
                                </BasePageErrorBoundary>
                              )}
                            />

                            <Route
                              path={"/invoices"}
                              render={() => (
                                <BasePageErrorBoundary>
                                  <InvoicePage />
                                </BasePageErrorBoundary>
                              )}
                            />
                          </Switch>
                        </React.Fragment>
                      </Route>
                      <Route
                        path={"/invoice-history"}
                        render={() => (
                          <BasePageErrorBoundary>
                            <InvoiceSearch />
                          </BasePageErrorBoundary>
                        )}
                      />
                      <Route path="/deposits">
                        <React.Fragment>
                          <Switch>
                            <Route
                              path={"/deposits/payment-status"}
                              render={() => (
                                <PaymentStatusErrorBoundary>
                                  <DepositPaymentStatusPage />
                                </PaymentStatusErrorBoundary>
                              )}
                            />
                            <Route
                              path={"/deposits/processing-payment"}
                              render={() => (
                                <PaymentStatusErrorBoundary>
                                  <ProcessingDepositPage />
                                </PaymentStatusErrorBoundary>
                              )}
                            />
                            <Route
                              path="/deposits"
                              render={() => (
                                <BasePageErrorBoundary>
                                  <Deposits />
                                </BasePageErrorBoundary>
                              )}
                            />
                          </Switch>
                        </React.Fragment>
                      </Route>
                      <Route
                        path="/statements"
                        render={() => (
                          <BasePageErrorBoundary>
                            <Statements />
                          </BasePageErrorBoundary>
                        )}
                      />
                      <Route
                        path="/help"
                        render={() => (
                          <BasePageErrorBoundary>
                            <Help />
                          </BasePageErrorBoundary>
                        )}
                      />
                      <Route path={"/settings"} component={SettingsPage} />
                      <Route
                        path={"/auto-register/waiting-confirmation"}
                        component={AutoRegisterWaitingConfirmation}
                      />
                      <Route
                        path={"/"}
                        render={() => (
                          <BasePageErrorBoundary>
                            <InvoicePage />
                          </BasePageErrorBoundary>
                        )}
                      />
                    </Switch>
                  </div>
                }
              />
              {/*)*/}
              {/*}/>*/}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {" "}
              {/* we want to still be able to get to system settings even if user doesn't have SAP accounts setup. */}
              <Switch>
                {props.canEditSystemConfig && (
                  <Route
                    path={"/settings/configuration"}
                    render={(props) => (
                      <div className={"settings-page"}>
                        <EditConfiguration />
                      </div>
                    )}
                  />
                )}
              </Switch>
            </React.Fragment>
          )}
          <Footer />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default withRouter(
  connect<any, any, any, any>(
    (state, props) => {
      return {
        selectedPrimaryAccount: getSelectedPrimaryAccount(state),
        isLoggedIn: state.user.isLoggedIn,
        sap: state.app.config.sap,
        canEditSystemConfig: canEditSystemConfig(state),
      };
    },
    {
      getUser,
    }
  )(BasePage)
);
