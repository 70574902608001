import React, {Component} from 'react';
import {BootstrapField, BootstrapError} from "../../helpers/Field";
import * as yup from 'yup';
import {Formik} from "formik";
import {passwordResetRequest} from "../../requests/UserRequests";
import BackToLogin from "../BackToLogin";
import SimpleHeader from "../header/SimpleHeader";
import { Footer } from "../footer/Footer";
import { FormattedMessage, useIntl } from 'react-intl';

class ResetPasswordPage extends Component<any, any> {
   
    constructor(props) {
        super(props);
        this.state = {
            message: null,
        }
    }

    render() {
         
        return (
            <React.Fragment>
                <SimpleHeader/>
                <div className={"container mt-5"}>
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mx-auto">
                            {!this.state.message && <React.Fragment>
                              <div className="card">
                                <Formik
                                  initialValues={{}}
                                  validationSchema={yup.object().shape({email: yup.string().email().required()})}
                                  onSubmit={(values, bag) => {
                                      passwordResetRequest(values).then((message) => {
                                          this.setState({message});
                                      })
                                  }}
                                  render={props =>
                                      <form onSubmit={props.handleSubmit}>
                                          <div className={"form-group"}>
                                              <label><FormattedMessage id={"register.create_own_password"} /></label>
                                              <FormattedMessage id="settings.account.email" defaultMessage="email">
                                                  {(placeholder) => (<BootstrapField placeholder={placeholder} name="email" type={"email"} />)}
                                              </FormattedMessage>
                                              {/*<BootstrapField name="email" type={"email"} placeholder={intl.formatMessage({ id: "invoice_export.sort" })} />*/}
                                              <BootstrapError name={"email"}/>
                                          </div>
                                          <div>
                                              <button type={"submit"} className={"btn btn-primary buttonColor buttonsize"}> <FormattedMessage id={"settings.account.reset_password" }/>
                                              </button>
                                          </div>
                                      </form>
                                  }/>
                              </div>
                              <div className="row justify-content-center flex mt-3">
                                <BackToLogin/>
                              </div>
                            </React.Fragment>

                            }
                            {this.state.message && <React.Fragment>
                              <div className="card">
                                  {this.state.message}
                              </div>
                              <div className="row justify-content-center flex mt-3">
                                <BackToLogin/>
                              </div>
                            </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
                <Footer/>
            </React.Fragment>
        );
    }
}

export default ResetPasswordPage;
