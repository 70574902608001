import React, {Component} from 'react';
import * as yup from 'yup';
import {BootstrapError, BootstrapField} from "../../helpers/Field";
import {Formik} from "formik";
import {Redirect} from "react-router-dom";
import { FormattedMessage } from "react-intl";

const schema = yup.object().shape({
  password: yup.string().required('Password required.'),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords do not match').required("Confirm password required."),
});

class Register extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      complete: false,
    }
  }

  render() {
    return (
      <div>
        <Formik
            initialValues={{
              password: '',
              confirmPassword: '',
            }}
          validationSchema={schema}
          onSubmit={(values, bag) => {
            bag.setSubmitting(true);
            this.props.completeRegistration(this.props.inviteId, values.password, values.confirmPassword).then(response => {
              bag.setSubmitting(false);
              this.setState({complete: true});
            });
          }}
          render={(props) =>
            <form onSubmit={props.handleSubmit}>
                  <div className={"row"}>
                      {/*<div className="invoicesHeading">*/}
                      {/*    <label> <FormattedMessage id={"register.create_own_password"}/></label>*/}
                      {/*</div>*/}
                      <div className="form-group">
                          <FormattedMessage id={"register.type_confirm_password"}/>
                      </div>
                <div className="col-12">
                  <div className="form-group">
                    <label><FormattedMessage id={"password"}/></label>
                    <BootstrapField name={"password"} type={"password"}/>
                    <BootstrapError name={"password"}/>
                  </div>
                  <div className="form-group">
                    <label>  <FormattedMessage id={"register.confirm_password"}/></label>
                    <BootstrapField name={"confirmPassword"} type={"password"}/>
                    <BootstrapError name={"confirmPassword"}/>
                  </div>
                  <div>
                    <button type={"submit"} className={"btn btn-primary buttonColor buttonsize"}><FormattedMessage id={"register.finish"}/></button>
                  </div>
                </div>
                {this.state.complete && <Redirect to={"/login"}/>}
              </div>
            </form>
          }
        />
      </div>
    );
  }
}

export default Register;
