/* eslint-env browser */
import React from 'react';
import { toast } from 'react-toastify';
import * as types from '../constants/ActionTypes';

export function loadingDecrement(dispatch) {
  dispatch({ type: types.LOADING_DECREMENT});
}
export function loadingIncrement(dispatch) {
    dispatch({ type: types.LOADING_INCREMENT});
}

export function requestStarted(dispatch, actionType) {
  dispatch({ type: actionType });
}

export function updateState(dispatch, actionType, data, cb = () =>{}) {
  dispatch({ type: actionType, payload: data }, cb);
}

export function setisPaymentDisable(ispaymentdisable,) {
    sessionStorage.setItem("isPaymentDisable", ispaymentdisable);
  
}
export function setMessageForPaymentDisable(MessageForPaymentDisable,) {
    for (let key in MessageForPaymentDisable)
    {
        if (key == "en_paymentDisableMessageText"  ||
            key == "fr_paymentDisableMessageText"  ||
            key == "de_paymentDisableMessageText"  ||
            key == "es_paymentDisableMessageText"  ||
            key == "it_paymentDisableMessageText"  ||
            key == "ja_paymentDisableMessageText"  ||
            key == "pt_paymentDisableMessageText"  ||
            key == "ru_paymentDisableMessageText")
        {
            localStorage.setItem(key, MessageForPaymentDisable[key]);
        }
    }
    if (sessionStorage.getItem("Language") == undefined) {
        sessionStorage.setItem("Language", "en");
    } 
       
}


export function getMessageForPaymentDisable(paymentDisableKey) {

    if (localStorage.getItem(paymentDisableKey) != '' && localStorage.getItem(paymentDisableKey) != undefined) {
        paymentDisableKey = localStorage.getItem(paymentDisableKey)!?.toString();
    }
    else if (localStorage.getItem("en_paymentDisableMessageText") != '' && localStorage.getItem("en_paymentDisableMessageText") != undefined) {
        paymentDisableKey = localStorage.getItem("en_paymentDisableMessageText")!?.toString();
    }
    else {
        for (var i = 0; i < localStorage.length; i++) {
            let keyExists = localStorage.key(i)!.toString().endsWith("_paymentDisableMessageText");
            if (keyExists == true) {
                if (localStorage.getItem(localStorage.key(i)!) != '' && localStorage.getItem(localStorage.key(i)!) != undefined) {
                    paymentDisableKey = localStorage.getItem(localStorage.key(i)!)!?.toString();
                    if (paymentDisableKey != "") {
                        break;
                    }
                }
            }
        }
    }
    return paymentDisableKey;
}

export function requestErrored(dispatch, errorObj, actionType) {
  dispatch({ type: types.CALL_FAILED, payload: errorObj, call: actionType });
  if (errorObj && errorObj.response && errorObj.response.status === 401) {
    dispatch({ type: types.LOGOUT});
  } else if (errorObj && errorObj.response.status >= 500) {
    toast.error("System Error", { position: toast.POSITION.TOP_RIGHT });
  } else if (errorObj && errorObj.response && typeof errorObj.response.data) {
    const uiError = <div>{'Error: '}{errorObj.response.data.error}{<br />}{'Unique Id: '}{errorObj.response.data.uniqueId}</div>;
    toast.error(uiError, { position: toast.POSITION.TOP_RIGHT });
  }
  else if (errorObj && errorObj.response && errorObj.response.statusText) {
    toast.error(errorObj.response.statusText, { position: toast.POSITION.TOP_RIGHT });
  } else if (errorObj && errorObj.message) {
    toast.error("System Error", { position: toast.POSITION.TOP_RIGHT });
  }
}

export function requestUnauthorized(dispatch, errorObj) {
  dispatch({ type: types.UN_AUTHORIZED, payload: errorObj });
  dispatch({ type: types.LOGOUT });
}

export function loginFailed(dispatch, errorObj) {
  dispatch({ type: types.UN_AUTHORIZED, payload: errorObj });

  if (errorObj && errorObj.response && errorObj.response.status === 401) {
    toast.error(errorObj.response.data, { position: toast.POSITION.TOP_RIGHT });
  }
}