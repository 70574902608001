import React, {useCallback,useEffect,useState} from 'react';
import {connect} from 'react-redux';
import {mapCards, updateSelectedCard} from "../../actions/InvoiceActions";
import {getAllPaymentOptions} from "../../selectors/Selectors";
import {useIntl} from "react-intl";
import {getCreditCardsSelector as getCrediCardsSelector,getChecksSelector} from "../payment-methods/PaymentCardsSelectors";

interface PaymentMethodDropdownStoreProps {
    allPaymentOptions: any[];
    selectedCardIndex: string;
    cards:any;
    checks:any;
}

interface PaymentMethodDropdownDispatchProps {
    updateSelectedCard: (selectedCardKey: string) => any;
}

type PaymentMethodDropdownProps = PaymentMethodDropdownStoreProps & PaymentMethodDropdownDispatchProps;

export function PaymentMethodDropdown(props: PaymentMethodDropdownProps) {
    const intl = useIntl();
    let [selectedCard, setSelectedCard] = useState('');
    const f = id => intl.formatMessage({id: id});
    const paymentCardOptions = mapCards(props.allPaymentOptions);
    const defaultCard = props.cards.filter((card =>  card.default === "X"));
    const defaultCheck = props.checks.filter((check =>  check.default === "X"));
    
    useEffect(() => {
       if((defaultCard && defaultCard.length > 0) && (defaultCard[0].payment_card_token === selectedCard || selectedCard === '')){
        props.updateSelectedCard(defaultCard[0].payment_card_token);
       }else if((defaultCheck && defaultCheck.length) > 0 && (defaultCheck[0].payment_card_token === selectedCard || selectedCard === '')){
        props.updateSelectedCard(defaultCheck[0].payment_card_token);
       }
        
    });
   

   
    const handlePaymentCardChange = (selectedCard) => {
        
        if (selectedCard.target.value) {
            setSelectedCard(selectedCard.target.value);
            props.updateSelectedCard(selectedCard.target.value);
        } else {
            props.updateSelectedCard("");
        }
    };

    return (<div className="col-lg-8 col-md-6 col-sm-6 text-center mb-3 paymentCustomSelect">
        <select
            name="paymentStatus"
            placeholder={f("payment.pay_with")}
            className="form-control"
            value={props.selectedCardIndex}
            onChange={handlePaymentCardChange}>
            <option value="" disabled>{f("payment.pay_with")}</option>
            {paymentCardOptions.map(option => {
                return (
                    <option value={option.value} key={option.index}>
                        {option.label}
                    </option>
                )
            })}
        </select>
        <i className="fas fa-chevron-down select-arrow"/>
    </div>);
}

export const PaymentMethodDropdownConnected = connect<PaymentMethodDropdownStoreProps, PaymentMethodDropdownDispatchProps, any, any>((store:any, props:any) => {
    return {
        allPaymentOptions: getAllPaymentOptions(store),
        selectedCardIndex: store.default.selectedCard,
        cards: getCrediCardsSelector(store),
        checks: getChecksSelector(store),
    }
}, {
    updateSelectedCard
})(PaymentMethodDropdown);

