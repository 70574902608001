import React, { Component } from 'react';
//import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from "react-router-dom";

function nameColumn(cell, row) {
  return `${row.first_name} ${row.last_name}`;
}

class RegistrationRequestList extends Component<any, any> {

  constructor(props) {
    super(props);
    this.renderActionButton = this.renderActionButton.bind(this);
  }


  renderActionButton(val, row) {
    return <Link to={`/settings/users/add/${row.registrationRequestId}`}>Create User</Link>
  }

  render() {
    const columns = [{
      dataField: 'nameColumn',
      text: "Name",
      align: "center",
      headerAlign: 'center',
    }, {
      dataField: 'company',
      text: "company",
      align: "center",
      headerAlign: 'center',

    }, {
      dataField: 'email',
      text: "Email",
      align: "center",
      headerAlign: 'center'

    }, {
      dataField: 'message',
      text: "Message",
      align: "center",
      headerAlign: 'center'

    }, {
      dataField: this.renderActionButton,
      text: "Email",
      align: "center",
      headerAlign: 'center'

    }];

    const defaultSorted = [{
      dataField: 'login',
      order: 'desc'
    }];

    const defaultEmptyDataMessage=()=>{
      return "There is no data to display";
    }
    return (
      <div>
        <BootstrapTable
          bootstrap4
          keyField="registrationRequestId"
          data={this.props.requests}
          noDataIndication={defaultEmptyDataMessage}
          columns={columns}
          headerClasses="headerColumnColor"
          defaultSorted={defaultSorted}
          bodyClasses="wa-bootstrap-small-text"
          wrapperClasses="wa-bootstrap-table-container"
        />

      </div>
    );
  }
}

export default RegistrationRequestList;
