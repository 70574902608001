import React from 'react';
import {AccountType, Account} from "../../interfaces/Accounts";
import {getSelectedPrimaryAccount} from "../../selectors/Selectors";
import {connect} from 'react-redux';

interface SoldToPropsOwn{
    value:string;
}
interface SoldToStoreProps{
    accountType: AccountType;
    selectedPrimaryAccount: Account;
    secondaryAccounts: Account[];
}

export function SoldToCell(props: SoldToPropsOwn & SoldToStoreProps){
    let custOption = '';
    if (props.accountType === AccountType.SoldTo) {
        custOption = props?.selectedPrimaryAccount?.Name ?? "";
    } else {
        for (let l = 0; l < props.secondaryAccounts.length; l += 1) {
            const customer = props.secondaryAccounts[l];
            if (props.value === customer.PrimaryAcct) {
                custOption = customer.Name;
                break;
            }
        }
    }
    if (props.value) {
        return (<span data-toggle="tooltip" className={"main-table-cell"} title={custOption}>{props.value}</span>);
    }
    return (<span/>);
}

export var SoldToCellConnected = connect<SoldToStoreProps, any, SoldToPropsOwn, any>((state, props) => ({
    accountType: state.user_options.accountType,
    selectedPrimaryAccount: getSelectedPrimaryAccount(state),
    secondaryAccounts: state.user_options.secondaryAccounts,
}))(SoldToCell);
