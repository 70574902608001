import React, { Component } from 'react';
import { connect } from 'react-redux';
import RegistrationRequestList from "./RegistrationRequestList";
import {canManageRegistrationRequests} from "../../../selectors/PermissionSelectors";

class RegistrationRequestsPage extends Component<any, any> {

  constructor(props) {
    super(props);
    this.state = { requests: [] };
  }

  componentDidMount() {
    this.props.getRequests().then(requests => {
      this.setState({ requests: requests });
    });
  }

  render() {
    return (
      <div className={"container"}>
        {
          this.props.canAccess ?
            <div className="row">
              <div className="col-md-12">
                <RegistrationRequestList requests={this.state.requests} />
              </div>
            </div>
            : null
          }
      </div>
    );
  }
}

export default connect<any, any, any, any>((state, props) => ({
  canAccess: canManageRegistrationRequests(state) 
}))(RegistrationRequestsPage);
