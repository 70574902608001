import React, {Component} from 'react';
import {connect, useSelector} from 'react-redux';
//import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import BootstrapTable from 'react-bootstrap-table-next';
import {amountFormatter, payerFormatter, reasonFormatter, soldToFormatter} from '../../common/invoiceHelpers';
import {FormattedDate, FormattedMessage, useIntl} from "react-intl";
import {trimLeadingZeroes} from "../../utils";
import {getSelectedPaymentOption} from "../../selectors/Selectors";
import {adjustCurrency, extractLastCardNumbers} from "../../actions/InvoiceActions";
import {RootState} from "../../reducers/RootReducer";
import {useCurrencyDecimalLookup} from "../../selectors/ConfigSelectors";

interface PaymentStatus {
    document_number_finance: string;
    authorization_reference_code: string;
    authorization_amount: number;
    currency_key: string;
}

function DepositPaymentStatus(props:any) {
    const intl = useIntl();
    const f = id => intl.formatMessage({ id: id });
    const statuses = props.paymentStatuses;
    const successMessage = statuses?.filter(_ => _.message_type === 'S').map(_ => _.message_line_string).join("; ");
    const errorMessage = statuses?.filter(_ => _.message_type === 'E').map(_ => _.message_line_string).join("; ");
    const success = successMessage.length > 0;
    const currencyKey = useSelector<RootState, string>(state => state.default.selectedCurrencyOptionValue);
    const decimalLookup = useCurrencyDecimalLookup();
    
    let paymentStatus: null | PaymentStatus = null;
    if (success) {
        paymentStatus = statuses[0];
    }
    const alertClass = successMessage.length > 0 ? 'alert alert-success' : 'alert alert-danger';
    const documentNumber = paymentStatus?.document_number_finance ?? '';
    const referenceCode = paymentStatus?.authorization_reference_code ?? '';
    const top = 'Top';
    const columns = [{
        dataField: 'authorization_amount',
        text: f("deposit_status.table.paid_header"),
        formatter :amountFormatter,
        align:"center",
        headerAlign: 'center'
    }, {
        dataField: 'payer',
        text: f("deposit_status.table.payer_header"),
        formatter :payerFormatter,
        align:"center",
        headerAlign: 'center'
    }, {
        dataField: 'deposit_note',
        text: f("deposit_status.table.deposit_note_header"),
        align:"center",
        headerAlign: 'center'
    }, {
        dataField: 'reference_number',
        text: f("deposit_status.table.reference_number_header"),
        align:"center",
        headerAlign: 'center'
    } ];

    const defaultSorted = [{
        dataField: '_index',
        order: 'desc'
    }];

    let deposits = [{
        _index: 0,
        authorization_amount: adjustCurrency(paymentStatus?.authorization_amount ?? 0, decimalLookup(currencyKey)),
        currency_key: props.deposit?.deposit_detail?.currency_key,
        payer: props.deposit?.payer_data?.customer_number,
        reason_code: props.deposit?.deposit_detail?.reason_code,
        deposit_note: props.deposit?.deposit_detail?.comment,
        reference_number: props.deposit?.deposit_detail?.reference_number,
    }];

    return (
        <div>
            <div className={alertClass}>
                <strong><FormattedMessage id={"status"} defaultMessage={"Message"}/>: </strong> {success ? <FormattedMessage id={"payment.success_message"} defaultMessage={"Payment Success"}/> : <FormattedMessage id={"payment.error_message"} defaultMessage={"Error Processing Payment"}/>}  <br/>
                <strong><FormattedMessage
                    id={"payment_status.document_number"}/>:</strong> {documentNumber.replace(/^0+/, '')}
                <br/>
                <strong><FormattedMessage
                    id={"payment_status.reference_code"}/>:</strong> {referenceCode.replace(/^0+/, '')}
                <br/>
                {props.paymentCard &&
                <React.Fragment><strong><FormattedMessage id={"payment_status.payment_method_label"}/>: </strong>
                  <FormattedMessage
                    id={"payment_status.payment_method"}
                    values={{
                        type: props.paymentCard.payment_card_type,
                        numbers: extractLastCardNumbers(props.paymentCard.payment_card_token, '-')
                    }}
                  /></React.Fragment>} <br/>
                <strong><FormattedMessage id={"payment_status.processed_by"}/>:</strong> {props.currentUser.login}
                <br/>
                <strong><FormattedMessage id={"payment_status.processed_date"}/>:</strong> <FormattedDate
                value={Date.now()}/>
            </div>
            <br/>
            <div>

                
            <BootstrapTable
                bootstrap4
                keyField="_index"
                data={deposits}
                columns={columns}
                headerClasses ="headerColumnColor"
                defaultSorted={defaultSorted}
            />
{/* 
                <BootstrapTable data={deposits} scrollTop={top}
                                keyField='_index'
                                headerStyle={{background: '#DFECF5', height: '40px'}}>
                    <TableHeaderColumn width="55" className="headerColumnColor" headerAlign="center" dataAlign="right"
                                       dataField="authorization_amount" dataFormat={amountFormatter}>
                        <FormattedMessage id={"deposit_status.table.paid_header"}/>
                    </TableHeaderColumn>
                    <TableHeaderColumn width="55" className="headerColumnColor" headerAlign="center" dataAlign="right"
                                       dataField="payer"  dataFormat={payerFormatter}>
                        <FormattedMessage id={"deposit_status.table.payer_header"}/>
                    </TableHeaderColumn>
                    <TableHeaderColumn width="90px" className="headerColumnColor" headerAlign="center"
                                       dataField="deposit_note">
                        <FormattedMessage id={"deposit_status.table.deposit_note_header"}/>
                    </TableHeaderColumn>
                    <TableHeaderColumn width="90px" className="headerColumnColor" headerAlign="center"
                                       dataField="reference_number">
                        <FormattedMessage id={"deposit_status.table.reference_number_header"}/>
                    </TableHeaderColumn>
                </BootstrapTable> */}
                <br />
                <br />
                <br/>

                <div className={"row d-print-none"}>
                    <div className={"col"}>
                        <p className={"mt-2"}><FormattedMessage id={"payment_status.message"}/>
                        </p>
                        <button className={"btn btn-primary buttonColor buttonsize"} style={{ backgroundColor: "#336699" }}
          onClick={() => window.print()}>{f('print')}</button>
                    </div>
                bbrbr</div>
            </div>
        </div>
    );
}

export default connect<any, any, any, any>((store, props) => ({
    paymentStatuses: store.default.paymentStatuses,
    deposit: store.invoice.completed.deposit,
    details: store.invoice.completed.details,
    paymentCard: getSelectedPaymentOption(store),
    currentUser: store.default.currentUser,
    sapDetails: store.app.config.sap?.data,
}))(DepositPaymentStatus);
