import 'react-app-polyfill/ie11';
import 'core-js/fn/array/find';
import 'core-js/fn/array/from';
import 'core-js/fn/array/map';
import 'core-js/fn/array/includes';
import 'core-js/fn/array/filter';
import 'core-js/fn/object/values';
import 'core-js/fn/object/keys';
import 'core-js/fn/array/some';
import 'core-js/fn/promise';
import 'core-js/fn/set';
import 'core-js/fn/string/starts-with';
import 'core-js/fn/number/is-nan';
import 'url-search-params-polyfill';
import React from 'react';
import './index.scss';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import store from './store';
import App from './App';



import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/fr';

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/fr';



export const Index = () => (
    <Provider store={store}>
        <App/>
    </Provider>
);

ReactDOM.render(<Index/>, document.getElementById('root'));
