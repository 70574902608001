import axios from '../axios';
import {GET_USER_LIST} from "../constants/ApiConstants";
import {SapAccount} from "../interfaces/SapAccount";
import {User} from "../interfaces/User";

export function getUserList(status = "", profile = "", search = "") {
    if ((status == "") && (profile ?? "") == "" && (search ?? "") == "") {
        return Promise.resolve([]);
    }
    return axios.get(GET_USER_LIST, {params: {status, profile, search}}).then((response) => {
        return response.data;
    });
}

export function getUser(userId) {
    return () => {
        return axios.get(`/api/users/${userId}`).then((response) => {
            return response.data;
        });
    }
}

export function saveUser(user: User) {
    // user.use = parseInt(user.userProfileId);
    // user.profile = null; // passing a profile object will cause problems on .net side
    user.email = user.email.toLowerCase();
    if (user.user_id) {
        return axios.put(`/api/users/${user.user_id}`, user).then((response) => {
            return response.data;
        });
    } else {
        return axios.post('/api/users', user).then((response) => {
            return response.data;
        });
    }
}

// export function getProfiles() {
//   return axios.get('/api/profiles').then((response) => {
//     return response.data;
//   })
// }

function updateUserSapAccount(userId, account: SapAccount) {
    return axios.put(`/api/users/${userId}/accounts/${account.account_id}`, account).then((response) => {
        return response.data;
    });
}

function createUserSapAccount(userId, account: SapAccount) {
    return axios.post(`/api/users/${userId}/accounts`, account).then((response) => {
        return response.data;
    });
}

export function saveUserSapAccountByInvoice(userId, invoice) {
    return axios.post(`/api/users/${userId}/accounts/byinvoice`, invoice)

}
export function deleteUserSapAccount(userId, account: SapAccount) {
    return axios.delete(`/api/users/${userId}/accounts/${account.account_id}`).then((response) => {
        return response.data;
    });
}

export function saveUserSapAccount(userId, account: SapAccount) {
    if (account.account_id && account.account_id !== "") {
        return updateUserSapAccount(userId, account);
    }
    return createUserSapAccount(userId, account);
}

export function getUserSapAccounts(userId) {
    return axios.get(`/api/users/${userId}/accounts`).then((response) => {
        return response.data;
    });
}

export function getUserByInvite(invite) {
    return axios.get(`/api/users/invite/${invite}`).then((response) => {
        return response.data;
    });
}

export function getUserByPasswordReset(id) {
    return axios.get(`/api/users/password-reset/${id}`).then((response) => {
        return response.data;
    });
}

export function completeRegistration(inviteId, password, confirmPassword) {
    return axios.post(`/api/token/complete-registration`, {inviteId, password, confirmPassword}).then((response) => {
        return response.data;
    });
}

export function passwordResetRequest(requestForm) {
    return axios.post(`/api/users/password-reset-request`, requestForm).then((response) => {
        return response.data;
    });
}

export function completePasswordReset(id, password, confirmPassword) {
    return axios.post(`/api/token/complete-password-reset`, {id, password, confirmPassword}).then((response) => {
        return response.data;
    });
}

export function getExclusionList(userId) {
    return axios.get(`/api/users/${userId}/exclusions`).then((response) => {
        if (response.status === 404) {
            throw("Cold not find exclusion list.");
        }
        return response.data;
    });
}

export function updateExclusionList(userId, list) {
    return axios.put(`/api/users/${userId}/exclusions`, list).then((response) => {
        return response.data;
    });
}

export function validateAccount(data) {
    return axios.put(`/api/users/account/validate`, data).then((response) => {
        return response.data;
    });
}

export function getRegistrationAccount(token: string) {
    return axios.post(`/api/token/auto-register/accounts`, { key: token }).then((response) => {
        return response.data;
    })
}

export function getUserAccounts() {
    return axios.get(`/api/token/auto-register/accounts`).then((response) => {
        return response.data;
    })
}

export function registerUser(form) {
   let request={
        email: form.email,
        login: form.email,
        user_role: "user",
        first_name: form.first_name,
        last_name: form.last_name,
        Status: "active",
        Profile:"dummy",
        primary_account_type_id:"Payer",
        company: form.company,
        password: form.password,
       verify_password: form.verify_password,
       token: sessionStorage.getItem("_registerToken")
    }
    return axios.post(`/api/token/auto-register/user`, request).then((response) => {
        
        return response.data;
    });
}

export function confirmAccount(token: string) {
    return axios.post(`/api/token/auto-register/user-confirm/${token}`, {}).then((response) => {
        return response.data;
    })
}

export function deleteUser(userId: number | string) {
    return axios.delete(`/api/users/${userId}`, {}).then((response) => {
        return response.data;
    })
}

export function resendConfirmation(email) {
    return axios.put(`/api/token/auto-register/resend-confirmation`, {}).then((response) => {
        return response.data;
    });
}
