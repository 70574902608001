import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceQuery } from "../../selectors/Selectors";
import { getInvoices, setSelectedSoldTos } from "../../actions/InvoiceActions";
import { getSoldToNames } from "../../requests/InvoiceRequests";
import { Invoice, InvoiceRequestBody } from "../../interfaces/invoice";
import { useCurrencyDecimalLookup } from "../../selectors/ConfigSelectors";

export const InvoicesContext = React.createContext([]);

export function useInvoiceSearchQuery() {
  const invoiceSearchQuery: InvoiceRequestBody = useSelector<any, any>(
    (state) => getInvoiceQuery(state)
  );
  return invoiceSearchQuery;
}

//This is called by default in invoice login page.
export function useInvoices(selectedCurrency) {
  const invoiceSearchQuery = useInvoiceSearchQuery();
  useSelector<any, any>((state) => getInvoiceQuery(state));
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const decimalLookup = useCurrencyDecimalLookup();
  const dispatch = useDispatch();
  useEffect(() => {
    if (invoiceSearchQuery !== null) {
      getInvoices(
        invoiceSearchQuery,
        decimalLookup,
        selectedCurrency
      )(dispatch).then((data) => {
        setInvoices(data.invoices);
      });
    }
  }, [invoiceSearchQuery]);

  return invoices;
}
