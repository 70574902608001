import * as types from "../constants/ActionTypes";
import { getInvoiceKey } from "../utils";

const INVOICE_STATE_KEY = "invoiceState";

export function refreshInvoices() {
  return {
    type: types.FORCE_INVOICE_REFRESH,
    payload: {},
  };
}

export let defaultInvoiceInitialState = {
  forceRefresh: 0, // sometimes we need to force the invoice list to refresh. Increment this number to do so.
  selected: {},
  invoiceDetail: {},
  completed: {
    invoices: {},
    detials: {},
    deposit: {},
  },
  activeInvoice: {},
  dueSelect: "all",
  searchInvoices: [],
  searchedinvoiceStatus: "03",
  searchedinvoiceDueDateFrom: "",
  searchedinvoiceDueDateTo: "",
  searchedinvoiceType: "",
  searchedInvoiceNumber: "",
  searchedArticleNumber: "",
  searchedArticleDescription: "",
  invoiceSearchedPeriod: "all",
  invoiceSearchedPeriodFrom: "",
  invoiceSearchedPeriodTo: "",
  payerDueAmount: "",
  totalCreditAmount: "",
  totalPastDueAmount: "",
    balanceAmount: "",
  statementId:""
};

let invoiceInitialState = defaultInvoiceInitialState;
let _sessionStorage = sessionStorage.getItem(INVOICE_STATE_KEY);

if (_sessionStorage) {
  invoiceInitialState = JSON.parse(_sessionStorage);
}

export interface InvoiceState {}

export function invoiceReducer(
  state = invoiceInitialState,
  action
): InvoiceState {
  let newState = invoiceReducerInternal(state, action);
  sessionStorage.setItem(INVOICE_STATE_KEY, JSON.stringify(newState));
  return newState;
}

export function invoiceReducerInternal(
  state = invoiceInitialState,
  action
): InvoiceState {
  switch (action.type) {
    case types.ADD_INVOICE_TO_SELECTED:
      var key = getInvoiceKey(action.payload);
      return {
        ...state,
        selected: {
          ...state.selected,
          [key]: action.payload,
        },
        invoiceDetail: {
          ...state.invoiceDetail,
          [key]: {
            amount: action.payload.open_amount,
            amountValidation: "",
            reason: "",
            comment: "",
          },
        },
      };
    case types.REMOVE_INVOICE_FROM_SELECTED:
      var key = getInvoiceKey(action.payload);
      let newState = {
        ...state,
        selected: { ...state.selected },
        invoiceDetail: { ...state.invoiceDetail },
      };
      delete newState.selected[key];
      delete newState.invoiceDetail[key];
      return newState;
    case types.UPDATE_PAY_AMOUNT_FOR_INVOICE:
      let reason = "";
      let currentDetails = state.invoiceDetail[action.payload.documentId];
      if (action.payload.overUnder === currentDetails.overUnder) {
        reason = currentDetails.reason;
      }

      return {
        ...state,
        invoiceDetail: {
          ...state.invoiceDetail,
          [action.payload.documentId]: {
            ...currentDetails,
            amount: action.payload.amount,
            amountValidation: action.payload.amountValidation,
            reason: reason,
            overUnder: action.payload.overUnder,
          },
        },
      };
    case types.UPDATE_REASON:
      return {
        ...state,
        invoiceDetail: {
          ...state.invoiceDetail,
          [action.payload.documentId]: {
            ...state.invoiceDetail[action.payload.documentId],
            reason: action.payload.reason,
          },
        },
      };
    case types.UPDATE_REASON_COMMENT:
      return {
        ...state,
        invoiceDetail: {
          ...state.invoiceDetail,
          [action.payload.documentId]: {
            ...state.invoiceDetail[action.payload.documentId],
            comment: action.payload.comment,
          },
        },
      };
    case types.COPY_SELECTED_INVOICES_TO_COMPLETED:
      return {
        ...state,
        completed: {
          invoices: {
            ...state.selected,
          },
          details: {
            ...state.invoiceDetail,
          },
        },
      };
    case types.COPY_DEPOSIT_DETAILS:
      return {
        ...state,
        completed: {
          ...state.completed,
          deposit: action.payload,
        },
      };
    case types.CLEAR_SELECTED_INVOICES:
      return {
        ...state,
        selected: {},
        invoiceDetail: {},
      };
    case types.SET_ACTIVE_INVOICE:
      return {
        ...state,
        activeInvoice: action.payload,
      };
    case types.SET_DUE_SELECT:
      return {
        ...state,
        dueSelect: action.payload,
          };
      case types.SET_STATEMENT_ID:
          return {
              ...state,
              statementId: action.payload,
          };
    case types.FORCE_INVOICE_REFRESH:
      return { ...state, forceRefresh: state.forceRefresh + 1 };
    case types.SEARCHED_INVOICES:
      return {
        ...state,
        searchInvoices: action.payload,
      };
    case types.SEARCH_INVOICE_STATUS:
      return {
        ...state,
        searchedinvoiceStatus: action.payload,
      };
    case types.SEARCH_INVOICE_DUEDATE:
      return {
        ...state,
        searchedinvoiceDueDateFrom: action.payload.from,
        searchedinvoiceDueDateTo: action.payload.to,
      };
    case types.SEARCH_INVOICE_TYPE:
      return {
        ...state,
        searchedinvoiceType: action.payload,
      };
    case types.SEARCH_INVOICE_NUMBER:
      return {
        ...state,
        searchedInvoiceNumber: action.payload,
      };
    case types.SEARCH_ARTILCE_NUMBER:
      return {
        ...state,
        searchedArticleNumber: action.payload,
      };
    case types.SEARCH_ARTILCE_DESCRIPTION:
      return {
        ...state,
        searchedArticleDescription: action.payload,
      };
    case types.INVOICE_SEARCH_PERIOD:
      return {
        ...state,
        invoiceSearchedPeriod: action.payload,
      };
    case types.INVOICE_SEARCH_PERIOD_FROMTO:
      return {
        ...state,
        invoiceSearchedPeriodFrom: action.payload.from,
        invoiceSearchedPeriodTo: action.payload.to,
      };
    case types.PAYER_DUE_AMOUNT:
      return {
        ...state,
        payerDueAmount: action.payload,
      };
    case types.TOTAL_CREDIT_AMOUNT:
      return {
        ...state,
        totalCreditAmount: action.payload,
      };
    case types.TOTAL_PASTDUE_AMOUNT:
      return {
        ...state,
        totalPastDueAmount: action.payload,
      };
    case types.TOTAL_BALANCE_AMOUNT:
      return {
        ...state,
        balanceAmount: action.payload,
      };

    default:
      return { ...state };
  }
}
