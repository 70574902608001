// These should match what is possible in the database.
export enum AccountType{
    SoldTo = 'SoldTo',  
    Payer = 'Payer',
    PayerAll = 'PayerAll',
}

export interface Account{
    PrimaryAcct: string;
    Name: string;
}