import React, {Component} from 'react';

class JsonViewer extends Component<any, any> {
  
  renderArray(arr, tab = 0){
      return arr.map(e => {
        return (<div>
          {this.renderProp("", e, tab)}
        </div>)
      })
  } 
  
  renderObject(json, tab = 0) {
    return Object.keys(json).map((p) => {
      return this.renderProp(p, json[p], tab);
    });
  }
  
  renderProp(key, val, tab = 0){
    let style = {marginLeft: (tab * 12) + "px"};
    if(Array.isArray(val)){
      return (<div style={style}>
        <div><strong>{this.renderName(key)}</strong></div>
        {this.renderArray(val, tab + 1)}
      </div>);
    } else if((typeof val === "object")) {
      return (<div style={style}>
        <div><strong>{this.renderName(key)}</strong></div>
        {this.renderObject(val, tab + 1)}
      </div>);
    } else {
      return (<div style={style}><strong>{this.renderName(key)}</strong> - {val}</div>);
    }
  }
  
  renderName(string){
    let words = string.split('_');
    words = words.map(w => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase());
    return words.join(' ');
  }
  
  render() {
    return (
      this.renderObject(this.props.json)
    );
  }
}

export default JsonViewer;