import { CardType, PaymentOption } from "./interfaces/CnbsConfigInterfaces";

export function trimLeadingZeroes(str) {
  if (typeof str === "undefined" || str === null) {
    return "";
  }
  return str.replace(/^0+/, "");
}

export function getInvoiceKey(invoice) {
  return (
    (invoice.billing_document_number ?? "0") +
      "__" +
      (invoice.document_number_finance ?? "") +
      "__" +
      (invoice.posting_date ?? "") +
      "__" +
      invoice.line_item_in_the_relevant_invoice ?? ""
  );
}

export function isStringNotEmptyOrNull(val: string) {
  return typeof val !== "undefined" && val !== null && val !== "";
}

export function convertArrayToObject<T>(
  array: T[],
  key: string
): { [key: string]: T } {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
}

export function getAndClearSessionStorage(
  key: string,
  defaultValue: any = null
) {
  const jsonValue = sessionStorage.getItem(key);

  try {
    if (jsonValue !== null && typeof jsonValue !== "undefined") {
      return JSON.parse(jsonValue);
    }
    sessionStorage.removeItem(key);
  } catch (e) {}
  return defaultValue;
}

export function getPreAuthAmount(
  card_type: CardType,
  payment_options: PaymentOption[]
) {
  const found = payment_options.find((p) => p.payment_card_type);
  if (found) {
    return found.preauthorization_amount ?? 0.1 * 100;
  }
  return 0.1 * 100;
}
