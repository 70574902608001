import React, {Component} from 'react';
import ExclusionItem from "./ExclusionItem";
import AddExclusionItem from "./AddExclusionItem";
import {toast} from "react-toastify";
import {refreshInvoices} from "../../../reducers/InvoiceReducer";
import {getImpersonatedUserId} from "../../../selectors/Selectors";
import {connect} from "react-redux";

class ExclusionList extends Component<any, any> {

  constructor(props) {
    super(props);

    this.state = {
      exclusions: [],
    };

   
    this._addExclusion = this._addExclusion.bind(this);
    this._removeExclusion = this._removeExclusion.bind(this);
  }
  
  componentDidMount() {
    this.props.getExclusions(this.props.userId).then((list) => {
      this.setState({
        exclusions: list
      });
    });
  }

  _addExclusion(value) {
    let newList = this.state.exclusions.slice(0);
    newList.push(value);
    this._updateExclusionList(newList);
  }
  
  _updateExclusionList(newList){
    this.props.updateExclusions(this.props.userId, newList).then((list) => {
      if(typeof list.status !== 'undefined' && list.status.message_type === "E" ){
        toast.error(list.status.message_line_string, { position: toast.POSITION.TOP_RIGHT });
      } else {
        this.setState({
          exclusions: list
        });
        this._refreshInvoices();
      }
    });
    
  }
  
  _refreshInvoices(){
    if(this.props.userId === this.props.impersonatedUserId){
      this.props.refreshInvoices();
    } 
  }

  _removeExclusion(index) {
    let newList = this.state.exclusions.slice(0);
    newList.splice(index, 1);
    this._updateExclusionList(newList);
  }

  render() {
    return (
      <div className="card">
        <div className="row">
          <div className="col-12">
            <div className="row justify-content-between">
              <h3>Exclusion List</h3>
            </div>
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-12 col-md-4">
            <div>
              {this.state.exclusions && this.state.exclusions.map((value, i, arr) => {
                return <React.Fragment key={`${value}-${i}`}>
                  <ExclusionItem value={value} index={i} onDelete={this._removeExclusion}/>
                  {i < arr.length &&
                  <div className={"dropdown-divider"}/>
                  }
                </React.Fragment>
              })}
              <AddExclusionItem onSubmit={this._addExclusion}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default connect<any, any, any, any>((store, props) => ({
  impersonatedUserId: getImpersonatedUserId(store)
}), {
  refreshInvoices,
})(ExclusionList);


