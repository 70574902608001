import {
    
    createTheme 
} from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createTheme' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    // allow configuration using `createMuiTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

export const defaultTheme = createTheme ({
    typography:{
    }
});
