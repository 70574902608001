import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {clearSelectedInvoices, setSelectedCurrency} from "../../actions/InvoiceActions";
import {CurrencyOption} from "../../reducers/CnbsReducer";
import { MULTI_CURRENCY_LENGTH } from '../../constants/ApplicationConstants';

export function CurrencySelector(props:any) {
    const currencies = useSelector<any, CurrencyOption[]>(state => state.default.currencyOptions);
    const dispatch = useDispatch();

    function handleSelectCurrency(e: React.ChangeEvent<HTMLSelectElement>) {
        dispatch(setSelectedCurrency(e.target.value));
        dispatch(clearSelectedInvoices());
    }

    useEffect(() => {
        if(currencies.length > 0){
            if(props.selectedCurrency == ""){
                //Instead Of Setting the first value as default currency, set USD as default
              //  dispatch(setSelectedCurrency(currencies[0].value));
              dispatch(setSelectedCurrency("USD"));

            }
        }
    }, [currencies]);

    return (<select className={"form-select-control invoicesHeadingInput"} onChange={handleSelectCurrency}>
        {Array.from(currencies).map(c => <option selected={props.selectedCurrency == c.value} key={c.value} value={c.value}>{c.label}</option>)}
    </select>);
}


export function useHasMultiCurrency(): boolean{
    const currencies = useSelector<any, CurrencyOption[]>(state => state.default.currencyOptions);
    return currencies?.length > MULTI_CURRENCY_LENGTH; //If 1 it will show only when multiple currency is available
}
