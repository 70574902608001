import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { LanguageSelector } from "./LanguageSelector";
import { RootState } from "../../reducers/RootReducer";
import { useSelector } from "react-redux";
import { useUser } from "../../hooks/CommonHooks";
import * as appConstants from '../../constants/ApplicationConstants';

import { getFooterLinks } from "../../requests/ConfigRequests";
import { useDispatch } from "react-redux";
import { setFooterLinks } from '../../actions/UserActions';

export function Footer() {
    const t = useIntl();
    const language = useSelector<RootState, string>(state => state.user.language);
    const country = useSelector<RootState, string>(state => state.user.country);
    const stateFooterLinks = useSelector<RootState, any>(state => state.user.footerLinks);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    const user = useUser();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchConfiguration = async () => {
            try {
                let links = await getFooterLinks();

                if (links.privacyPolicy) {
                    links = {
                        ...links,
                        termsAndConditions: links.termsAndConditions,
                        contactUs: links.contactUs,
                    };

                    let footerLinks = {
                        privacyPolicy: links.privacyPolicy,
                        termsAndConditions: links.termsAndConditions,
                        contactUs: links.contactUs,
                    };

                    dispatch(setFooterLinks(footerLinks));
                } else {
                    console.error('Privacy policy is missing');
                }
            } catch (error) {
                console.error('Error fetching footer links:', error);
            }
        };

        fetchConfiguration();
    }, []);

    return (
        <nav className="navbar navbar-expand-lg navbar-light fixed-bottom" id="appFooter">
            <div className="collapse navbar-collapse container text-secondary">
                <ul className="navbar-nav pl-md-5 hideonsmall">
                    <li className="nav-item">
                        <span><FormattedMessage id={"site.copyright"} values={{ year: currentYear }} /></span>
                    </li>
                </ul>
                <ul className="navbar-nav pr-5">
                    
                        
                            <li className="nav-item active hideonsmall">
                                <span>{appConstants.APP_VERSION}</span>
                            </li>
                            
                      
                    
                    {user && stateFooterLinks.privacyPolicy && (
                        <li className="nav-item hideonsmall">
                            <span>
                                <a target="_blank" rel="noopener noreferrer" href={stateFooterLinks.privacyPolicy}>
                                    <FormattedMessage id="site.privacy" />
                                </a>
                            </span>
                        </li>
                    )}
                    {user && stateFooterLinks.termsAndConditions && (
                        <li className="nav-item hideonsmall">
                            <span>
                                <a target="_blank" rel="noopener noreferrer" href={stateFooterLinks.termsAndConditions}>
                                    <FormattedMessage id="site.terms_conditions" />
                                </a>
                            </span>
                        </li>
                    )}
                    {user && stateFooterLinks.contactUs && (
                        <li className="nav-item hideonsmall">
                            <span>
                                <a target="_blank" rel="noopener noreferrer" href={stateFooterLinks.contactUs}>
                                    <FormattedMessage id="site.contact_us" />
                                </a>
                            </span>
                        </li>
                    )}
                    {<li className="nav-item  hideonsmall">
                        <LanguageSelector />
                    </li>}
                </ul>
            </div>
        </nav>
    );
}

export default connect<any, any, any, any>(store => ({
    privacyPolicy: store.user.privacyPolicy
}), {}, null, { pure: false })(Footer);
