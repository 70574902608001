import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addSoldToToSelected,
  removeSoldToFromSelected,
  clearSelectedInvoices,
  setSelectedSoldTos,
  setsearchedInvoices,
  setsearchedInvoiceNumber,
  setsearchedinvoiceDueDate,
  setinvoiceSearchedPeriodFromTo,
  setsearchedinvoiceStatus,
  setinvoiceSearchedPeriod,
  selectPayer,
  payerInvoiceDueAmount,
} from "../../actions/InvoiceActions";
import { getSelectedPrimaryAccount } from "../../selectors/Selectors";
class SoldToSelect extends Component<any, any> {
    constructor(props) {
   
    super(props);
    this.handleCustomerCheckChange = this.handleCustomerCheckChange.bind(this);
    this.createSelectItems = this.createSelectItems.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.allSelected = this.allSelected.bind(this);
  }
  selectAll() {
    if (this.allSelected()) {
      this.props.setSelectedSoldTos([]);
      this.props.payerInvoiceDueAmount(0);
      this.props.clearSelectedInvoices();
      this.props.setsearchedInvoices([]);
    } else {
      this.props.setSelectedSoldTos(
        Object.keys(this.props.secondaryAccounts).map((s) => s)
      );
    }
  }

  allSelected() {
    const selectedItems = this.props.selectedSoldTos;
    const secondaryAccounts = this.props.secondaryAccounts;
    return selectedItems.length === Object.keys(secondaryAccounts).length;
  }

  handleCustomerCheckChange = (target) => {
    this.props.clearSelectedInvoices();
    const value = target.currentTarget.id;
    const isChecked = target.currentTarget.checked;
    const isSearch = target.currentTarget.formAction.split("/").pop();

    let vGridViewDocIds = sessionStorage.getItem("dueamount");

    if (isChecked) {
      this.props.addSoldToToSelected(value);
      if (isSearch === "invoices" && isChecked === true) {
        this.props.setsearchedInvoiceNumber("");
        this.props.setsearchedinvoiceDueDate("", "");
        this.props.setinvoiceSearchedPeriodFromTo("", "");
        this.props.setsearchedinvoiceStatus("03");
        this.props.setinvoiceSearchedPeriod("all");
        this.props.selectPayer(
          isNaN(parseInt(value)) ? parseInt(value) : value
        );
        this.props.setsearchedInvoices([]);
        this.props.clearSelectedInvoices();
      }
    } else {
      this.props.removeSoldToFromSelected(value);
      this.props.setsearchedInvoiceNumber("");
      this.props.setsearchedinvoiceDueDate("", "");
      this.props.setinvoiceSearchedPeriodFromTo("", "");
      this.props.setsearchedinvoiceStatus("03");
      this.props.setinvoiceSearchedPeriod("all");
      this.props.selectPayer(isNaN(parseInt(value)) ? parseInt(value) : value);
      //Commented by prasanna while testing
      // this.props.setsearchedInvoices([]);
      this.props.clearSelectedInvoices();
      this.props.payerInvoiceDueAmount(0);
    }
  };

  createSelectItems() {
    const account =
      this.props.selectedPrimaryAccount &&
      this.props.selectedPrimaryAccount.PrimaryAcct
        ? this.props.selectedPrimaryAccount.PrimaryAcct
        : "";
    const selectedItems = this.props.selectedSoldTos;
    const items: any[] = [];
    //for (let l = 0; l < Object.keys(this.props.secondaryAccounts).length; l += 1) {
    for (var acc in this.props.secondaryAccounts) {
      const customer = this.props.secondaryAccounts[acc];
      let itemColor = "checkbox checkbox-primary dropdown-item truncate ";
      let isChecked = false;
      if (selectedItems.indexOf(customer.PrimaryAcct) !== -1) {
        itemColor =
          "checkbox checkbox-primary dropdown-item truncate multiSelectItemColor";
        isChecked = true;
      }
      items.push(
        <li key={`li_${customer.PrimaryAcct}`}>
          <div className={itemColor} key={`div_${customer.PrimaryAcct}`}>
            <label>
              {/*key={`lbl_${customer.PrimaryAcct}`} htmlFor={`lbl_${customer.PrimaryAcct}`}>*/}
              <input
                className="checkMargin"
                type="checkbox"
                key={`${account}${customer.PrimaryAcct}`}
                id={customer.PrimaryAcct}
                onChange={this.handleCustomerCheckChange}
                checked={isChecked}
              />
                      {customer.Name} ({customer.PrimaryAcct})
            </label>
          </div>
        </li>
      );
    }
    return items;
  }
  render() {
    return (
      <div className="btn-group btn-block soldto-select customSelect">
        <button
          className="form-control soldToBtnColor btn-block dropdown-toggle"
          id="soldToToggle"
          type="button"
          data-toggle="dropdown"
        >
          <span>
            {this.props.selectedSoldTos ? this.props.selectedSoldTos.length : 0}{" "}
            Customers Selected
          </span>
          <span className="select-arrow">
            <i className="fas fa-chevron-down"></i>
          </span>
        </button>
        <ul className="dropdown-menu multiSelectMenuHeight">
          <li>
            <div className="checkbox checkbox-primary dropdown-item truncate">
              <label htmlFor={`lbl_ship_to_select_all`}>
                <input
                  className="checkMargin"
                  type="checkbox"
                  id="lbl_ship_to_select_all"
                  onChange={this.selectAll}
                  checked={this.allSelected()}
                />
                Select All
              </label>
            </div>
          </li>
          {this.createSelectItems()}
        </ul>
      </div>
    );
  }
}
export default connect<any, any, any, any>(
  (store, props) => ({
    secondaryAccounts: store.user_options.secondaryAccounts,
    selectedPrimaryAccount: getSelectedPrimaryAccount(store),
    selectedSoldTos: store.user_options.selectedSoldTos,
    invoices: store.default.invoices,
  }),
  {
    addSoldToToSelected,
    removeSoldToFromSelected,
    clearSelectedInvoices,
    setSelectedSoldTos,
    setsearchedInvoices,
    setsearchedInvoiceNumber,
    setsearchedinvoiceDueDate,
    setinvoiceSearchedPeriodFromTo,
    setsearchedinvoiceStatus,
    setinvoiceSearchedPeriod,
    selectPayer,
    payerInvoiceDueAmount,
  }
)(SoldToSelect);
