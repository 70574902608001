/* eslint-env browser */
/* eslint-disable no-console */
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LoginLogo from '../login/LoginLogo';
import Logo from "../Logo";


export default function SimpleHeaderMaintenanceLogin(props: any) {
  const intl = useIntl();
  const f = id => intl.formatMessage({ id: id });
  return (


    <nav id="appHeader" className=" ">
      <div className='row m-4'>
        <div className='col-9' >

          <LoginLogo width={350} height={100} />

        </div>
        <div className='col  epay-portal-title'>
          <span className=''><FormattedMessage id={"site.maitanence_title"}></FormattedMessage></span>
        </div>
      </div>
    </nav>
  )
}



