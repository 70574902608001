import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getCreditCardsSelector as getCrediCardsSelector} from "./PaymentCardsSelectors";
import PaymentTypeDetails from "./PaymentTypeDetails";
import {FormattedMessage} from 'react-intl';

export function CreditCardsList(props) {
    return <div>{
        props.cards.map((card, index) => {
            return <div key={card.payment_card_token}><PaymentTypeDetails payment_card={card} onEdit={props.onEdit} 
                                                                          onDelete={props.onDelete}/></div>
        })
    }{
        props.cards.length === 0 && <span><FormattedMessage id={"payment_methods.cards_empty"}/></span>
    }</div>
}

export default connect<any, any, any, any>((state, props) => {
    return {
        cards: getCrediCardsSelector(state),
    }
})(CreditCardsList);
