import React, { useEffect, useState } from 'react';
import { BootstrapField, BootstrapError } from "../../../helpers/Field";
import * as yup from 'yup';
import { Formik, Field } from "formik";
import { toast } from 'react-toastify';

import { connect } from "react-redux";
import { useIntl, FormattedMessage, IntlShape } from "react-intl";
import { Paper, Tab, Tabs } from "@material-ui/core"

import { SystemMaintentenceUpdateConfig, getSystemMaintenanceEnabled, updateSystemMaintenanceConfig, SystemMaintentenceConfig, EnumMessages } from "../../../interfaces/SystemMaintenance";
import { updateBlackoutConfig, getBlackoutConfig, getIsBlackoutActive, getConfiguration, updateConfiguration } from "../../../requests/ConfigRequests";
import SimpleHeaderMaintenance from "../../header/SimpleHeaderMaintenance";
import { Footer } from '../../footer/Footer';
import "./SystemMaintenance.css";
import { LocalConvenienceStoreOutlined } from '@material-ui/icons';
import { resolveAny } from 'dns';
import _ from 'lodash';
import { setMessageForPaymentDisable } from '../../../actions/CommonActions';

const schema = (intl: IntlShape) => {
    return yup.object().shape({
        isEnabled: yup.boolean(),
        fromDateLocal: yup.string().nullable(),
        toDateLocal: yup.string().nullable(),
        language: yup.string(),
        messages: yup.array(),
        redirectUrl: yup.string().nullable()
    });
};


function _SystemMaintenance(props) {
    //let sapStatusGood = props.sapConfig.statusCode === 200 && props.sapConfig.data.status.message_type === "S";
    const intl = useIntl();
    const [isMaintenaceMode, setIsMaitenanceMode] = useState(false)
    const f = id => intl.formatMessage({ id: id });
    const [maintenanceConfig, setMaintenanceConfig] = useState({});
    const [config, setConfig] = useState({
        selectedLanguage: "en",
        messages: [{ language: "en", message: "" }],
        isEnabled: false,
        fromDateLocal: "",
        toDateLocal: ""
    });
    const [selectedLanguage, setselectedLanguage] = useState("en");
    const [selectedLanguageMessage, setSelectedLanguageMessage] = useState("en_paymentDisableMessageText");
  //  const [selectedPaymentLanguageMessage, setSelectedPaymentLanguageMessage] = useState("en_paymentDisableMessageText");



    useEffect(() => {
        getBlackoutConfig().then((_) => {
            const initialMessage = _.messages.find(firstMessage => firstMessage.language === "en")
            setConfig({
                ..._,
                ...props.paymentConfig,
                selectedLanguage: "en",
                notification: initialMessage?.message ?? "",
                isEnabled: _.isEnabled,
                fromDateLocal: _.fromDateLocal,
                toDateLocal: _.toDateLocal,
            })
            setIsMaitenanceMode(_.isEnabled)
        });
    }, []);

    const languages = [
        { key: "en", label: "English" ,paymentMessageId:"en_paymentDisableMessageText"},
        { key: "fr", label: "French" ,paymentMessageId:"fr_paymentDisableMessageText"},
        { key: "de", label: "German",paymentMessageId:"de_paymentDisableMessageText" },
        { key: "es", label: "Spanish",paymentMessageId:"es_paymentDisableMessageText" },
        { key: "it", label: "Italian",paymentMessageId:"it_paymentDisableMessageText" },
        { key: "ja", label: "Japanese" ,paymentMessageId:"ja_paymentDisableMessageText"},
        { key: "pt", label: "Portuguese",paymentMessageId:"pt_paymentDisableMessageText" },
        { key: "ru", label: "Russian",paymentMessageId:"ru_paymentDisableMessageText" },
    ];
    function onPaymentLanguageChange(e: React.ChangeEvent<HTMLSelectElement>) {
        e.preventDefault();
        let key= e.target.value.toString();

        let paymentMessageObject=languages.find(item=>item.key===key)

        let paymentMessageId=paymentMessageObject!.paymentMessageId
       setSelectedLanguageMessage(paymentMessageId);
    }


    function isPaymentDisablemsgexists(values: any) {
        if (values['en_paymentDisableMessageText'] == '' &&
            values['fr_paymentDisableMessageText'] == '' &&
            values['de_paymentDisableMessageText'] == '' &&
            values['it_paymentDisableMessageText'] == '' &&
            values['es_paymentDisableMessageText'] == '' &&
            values['pt_paymentDisableMessageText'] == '' &&
            values['ru_paymentDisableMessageText'] == '' &&
            values['ja_paymentDisableMessageText'] == '' &&
            values['ja_paymentDisableMessageText'] == '' &&
            values['isPaymentsDisable']) {
            return false;
        }
        else {
            return true;
        }
    }

    function onLanguageChange(e, notificationValue) {
        if (config.messages) {
            const Messages = config.messages;
            let selectedMessage = Messages.find((translationObjects) =>
                translationObjects.language.toString() === e.target.value.toString()
            )
            if (selectedMessage) {
                setselectedLanguage(e.target.value.toString());
                return selectedMessage.message.toString()
            } else {
                setselectedLanguage(e.target.value.toString());
                return ""
            }
        }
    }

    function isMaintenancemsgexists(values: any) {
        let isAnyMessageSet = values.messages.find((translationObjects) =>
            translationObjects.message.toString().trim() !== ''
        )
        // Early return if maintenence checkbox is not enabled
        if (!values.isEnabled) return true;

        if (isAnyMessageSet === undefined && values.isEnabled) {
            return false;
        } else {
            return true;
        }
    }

    function isStartDateValid(values: any) {
        if (!values.isEnabled) return true;

        if (values.isEnabled && (values.fromDateLocal === null || values.fromDateLocal === undefined || values.fromDateLocal === "")) {
            return false;
        } else {
            return true;
        }
    }

    function isEndDateValid(values: any) {
        if (!values.isEnabled) return true;

        if (values.isEnabled && (values.toDateLocal === null || values.toDateLocal === undefined || values.toDateLocal === "")) {
            return false;
        } else {
            return true;
        }
    }

    function updateMessage(e: React.ChangeEvent<HTMLTextAreaElement>) {
        const updatedMessage = e.target.value;
        if (config.messages && selectedLanguage) {
            let messageStorageObject = config.messages;
            let messageObject = messageStorageObject.find((msgObj) =>
                msgObj.language.toString() === selectedLanguage
            )
            if (!messageObject) {
                messageStorageObject.push({ language: selectedLanguage, message: updatedMessage })
            } else {
                messageObject.message = updatedMessage;
            }
            setConfig(config => ({ ...config, messages: messageStorageObject }))
        }
    }

    let showHeader = true;
    if (props.hideHeader)
        showHeader = false;

    const parts = window.location.href.split('/');
    const hostname = parts[0] + "/" + parts[1] + "/" + parts[2];

    return (

    
        <Formik
            validationSchema={schema(intl)}
            onSubmit={(values, bag) => {

                if (isMaintenaceMode && !values.isEnabled) {
                    bag.setSubmitting(true);
                    updateBlackoutConfig(values).then(() => {
                        toast.success(f("configuration.maintnenance_turn_off"), { position: toast.POSITION.TOP_RIGHT });
                        bag.setSubmitting(false);
                    })
                    return;
                }
                if (isMaintenancemsgexists(values) && isStartDateValid(values) && isEndDateValid(values)) {
                    bag.setSubmitting(true);
                    updateBlackoutConfig(values).then(() => {
                        // toast.success("Configuration Saved", { position: toast.POSITION.TOP_RIGHT });
                        // bag.setSubmitting(false);
                        props.setParentConfig(values)
                    })
                    if (isPaymentDisablemsgexists(values) == true) {
                        updateConfiguration(values).then(() => {
                            toast.success(f("configuration.saved_configuration_message"), { position: toast.POSITION.TOP_RIGHT });
                            getConfiguration().then((value) => setMessageForPaymentDisable(value))
                            bag.setSubmitting(false);
                        })
                    }
                }


            }}
            enableReinitialize={true}
            initialValues={config}
            render={(formProps: any) =>
                <form onSubmit={formProps.handleSubmit}>
                    <div className={showHeader ? "container pt-4" : ""}>
                        <React.Fragment>
                            {showHeader && <SimpleHeaderMaintenance />}
                            {/*<Paper square elevation={1}>*/}
                            <div className={showHeader ? "col-12 card mt-5" : "col-12"} >
                                <div className={"row"}>
                                    <div className="col-lg-9 col-md-12 mt-2 mb-3 pl-1">
                                        <h5>{f("configuration.settings")}</h5>
                                        <p className="font-weight-light small text-muted text-break font-italic">
                                            {
                                                // @todo add translation text}
                                            }
                                            {f("configuration.system_maintenance_title_info_line_one")}

                                            {f("configuration.system_maintenance_title_info_line_two")}

                                        </p>
                                        <div className={"form-group row"}>
                                            <label
                                                className="col-4 col-form-label-sm text-secondary  ">{f("configuration.system_maintenance_UserLogins")}</label>
                                            <div className="col form-group form-check">
                                                <BootstrapField id="isEnabled" name="isEnabled"
                                                    placeholder={f("configuration.system_maintenance_DisableUserLogins")}
                                                    type={"checkbox"}
                                                    checked={formProps?.values?.isEnabled} onChange={formProps.handleChange} />
                                                <label htmlFor="isEnabled"
                                                    className="form-check-label">{f("configuration.system_maintenance_DisableUserLogins")}</label>
                                                <BootstrapError name="isEnabled" />
                                            </div>
                                        </div>
                                        <div className={"form-group row"}>

                                            <label
                                                className="col-4 col-form-label-sm text-secondary  ">{f("configuration.system_maintenance_Period")}</label>
                                            <div className="col datetimepickerdiv">
                                                <div className='row'>
                                                    <div className='col-1'>
                                                        <label className="datetimepickerLabel">Start</label>
                                                    </div>
                                                    <div className='col-5'>
                                                        <div className="">

                                                            <BootstrapField
                                                                className="form-control-sm "
                                                                type="datetime-local"
                                                                name="fromDateLocal"
                                                                onChange={formProps.handleChange}
                                                            />

                                                            {!isStartDateValid(formProps?.values) &&
                                                                <small className="form-text invalid-feedback show">Start time is required</small>

                                                            }
                                                        </div>
                                                    </div>

                                                    <div className='col-1'>
                                                        <label className="datetimepickerLabel">End</label>
                                                    </div>
                                                    <div className='col-5 ' >
                                                        <div className="">


                                                            <BootstrapField
                                                                className={"form-control-sm datePicker"}
                                                                type="datetime-local"
                                                                name="toDateLocal"
                                                                onChange={formProps.handleChange}
                                                            />
                                                            {!isEndDateValid(formProps?.values) &&
                                                                <small className="form-text invalid-feedback show">End time is required</small>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                        <div className={"form-group row"}>
                                            <label
                                                className="col-4 col-form-label-sm text-secondary bold">{f("configuration.system_maintenance_Notification_Language")}</label>
                                            <div className={"col"}>
                                                <select name="selectedLanguage" className={"form-control"} onChange={(e) => formProps.setFieldValue('notification', onLanguageChange(e, formProps.values.notification))}>
                                                    {languages.map(_ =>
                                                        <option key={_.key} value={_.key}>{_.label}</option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>

                                        <div className={"form-group row"}>
                                            <label
                                                className="col-4 col-form-label-sm text-secondary ">{f("configuration.system_maintenance_Notification_Text")}</label>
                                            <div className="col">

                                                <BootstrapField name="notification" component="textarea" numberOfLines={4} onKeyUp={(e) => updateMessage(e)} />
                                                {!isMaintenancemsgexists(formProps?.values) &&
                                                    <small className="form-text invalid-feedback show">Notification text is required</small>}

                                                <BootstrapField type="hidden" name="messages" onChange={formProps.handleChange} />

                                                <p className="font-weight-light small text-muted text-break">
                                                    {
                                                        // @todo add translation text}
                                                    }
                                                    {f("configuration.system_maintenance_login_message_text")}
                                                </p>
                                            </div>

                                        </div>
                                        <div className={"form-group row"}>
                                            <label className="col-4 col-form-label-sm text-secondary bold" htmlFor="exampleInputEmail1">{f("configuration.system_maintenance_URL")}</label>
                                            <div className={"col"}>
                                                <BootstrapField name="redirectUrl" onChange={formProps.handleChange}
                                                    placeholder={f("configuration.system_maintenance_URL.place_holder")} />
                                                <BootstrapError name="redirectUrl" />
                                                <p className="font-weight-light small text-muted text-break">
                                                    {f("configuration.system_maintenance_redirect_text")}{" ( " + hostname + "/login/admin" + " )"}
                                                </p>
                                            </div>

                                        </div>
                                        <br />
                                    


                                        <div>
                                            <button className={"btn btn-primary buttonColor buttonsize"} type={"submit"}><FormattedMessage id={f("configuration.save_configuration")} /> </button>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            {/*</Paper>*/}
                        </React.Fragment>

                        <Footer />
                    </div>
                </form>
            } />
    )
}

export default connect<any, any, any, any>((state, props) => {
    return {
        sapConfig: state.app.config.sap,
        paymentConfig: props.paymentConfig,
        setParentConfig: props.setConfig,
        isBlackoutActive: state.user.isBlackoutActive,
    }
})(_SystemMaintenance);

