import React, { Component } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
    clearSelectedInvoices, selectPayer, setSelectedCurrency, setsearchedinvoiceStatus, setsearchedinvoiceDueDate, setsearchedinvoiceType,
    setsearchedInvoiceNumber, setsearchedArticleNumber, setsearchedArticleDescription,
    setinvoiceSearchedPeriod, setinvoiceSearchedPeriodFromTo, setsearchedInvoices
} from "../../actions/InvoiceActions";
import { getPayerAccounts } from "../../selectors/Selectors";
import { FormattedMessage } from "react-intl";
export class PayerSelect extends Component<any, any> {

    constructor(props) {
        super(props);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);
    }

    handleCustomerChange = (e) => {
        this.props.setSelectedCurrency('');
        this.props.clearSelectedInvoices();
        this.props.selectPayer(isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : e.target.value);
        this.props.setsearchedInvoices([]);
        this.props.setsearchedInvoiceNumber('');
        this.props.setsearchedinvoiceDueDate('', '');
        this.props.setinvoiceSearchedPeriodFromTo('', '');
        this.props.setsearchedinvoiceStatus('03');
        this.props.setinvoiceSearchedPeriod("all");
    };

    truncateOption(text) {
        const maxLength = 30;
        if (text.length > maxLength) {
            return text.substr(0, maxLength) + '...';
        }
        return text;
    }
    render() {
        let value = this.props.selectedSecondaryAccount;        
        let options = Object.values(this.props.payerAccounts).map((account: any, index) => {
            return { value: account.PrimaryAcct, label: `${account.Name} (${account.PrimaryAcct})`, account_number: account.PrimaryAcct }
        });
        let disabled = this.props.payerAccounts && this.props.payerAccounts.length <= 1;
        let isPaymentDisable = (sessionStorage.getItem("isPaymentDisable")?.toString() === "true") ? true : false;
        return ( 
                <div className='customSelect payer-select'>
                    <select
                        onChange={this.handleCustomerChange}
                        /*disabled={true}*/
                        value={value}
                        className="invoicesHeadingInput form-control">
                        {options.map(option => {
                            return (
                                <option
                                    key={option.value}
                                    value={option.value}>
                                    {this.truncateOption(option.label)}
                                </option>
                            )
                        })}
                    </select>
                    <i className="fas fa-chevron-down select-arrow" />
                </div>            
            )
    }
}

export default connect<any, any, any, any>((state, props) => {
    return {
        selectedSecondaryAccount: state.user_options.selectedSecondaryAccount,
        payerAccounts: getPayerAccounts(state),
    }
}, {
    clearSelectedInvoices,
    selectPayer,
    setSelectedCurrency,
    setsearchedInvoices,
    setsearchedInvoiceNumber,
    setsearchedinvoiceDueDate,
    setinvoiceSearchedPeriodFromTo,
    setsearchedinvoiceStatus,
    setinvoiceSearchedPeriod
})(PayerSelect);