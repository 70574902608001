import React from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {getPayerAccount, getSelectedPrimaryAccount, getSelectedSecondaryAccount} from "../../selectors/Selectors";
import {extractLastCardNumbers} from "../../actions/InvoiceActions";
import {Formik} from "formik";
import {AddCheckSchema, EditCheckSchema} from "./schemas";
import {BootstrapError, BootstrapField} from "../../helpers/Field";
import {ERROR_PAYMENT_METHOD} from "../../constants/ErrorMessages";
import {ObjectSchema} from "yup";
import {useIntl} from 'react-intl';

interface _Props {
    save: Function,
    paymentMethods: any,
    isEditing: boolean,
    alwaysSaveOnFile: boolean,
    payment: any,
    onCardConfirmed: Function,
    isDefault: boolean
}

export function AddCheckForm(props:_Props) {
    const intl = useIntl();
    const f = (id, defaultMessage) => intl.formatMessage({id, defaultMessage});

    async function saveCard(card) {
        props.save(card);
    }

    function buttonText() {
        return f("echeck_form.save_button", "Save eCheck");
    }

    let initialValues = {
        electronic_check_account_type: "",
        name: "",
        electronic_check_rdfi_number: "",
        account_number: "",
        save_on_file: props.alwaysSaveOnFile,
        make_default: false,
    };
    let schema: ObjectSchema<any> = AddCheckSchema(intl);
    if (props.isEditing) {
        initialValues = {
            electronic_check_account_type: props.payment.electronic_check_account_type,
            name: props.payment.payment_card_name,
            electronic_check_rdfi_number: props.payment.electronic_check_rdfi_number,
            account_number: `ending in *${extractLastCardNumbers(props.payment.payment_card_token, '-')}`,
            save_on_file: !props.payment.is_session,
            make_default: props.payment.default === 'X' ? true :false,
        };
        schema = EditCheckSchema(intl);
    }
    return <div>
        <Formik
            initialValues={
                initialValues
            }
            validationSchema={
                schema
            }
            enableReinitialize={true}
            onSubmit={(values, bag) => {
                bag.setSubmitting(true);
                saveCard(values).then((paymentcard) => {
                    bag.setSubmitting(false);
                    if (props.onCardConfirmed) {
                        props.onCardConfirmed(paymentcard);
                    }
                }).catch(() => {
                    toast.error(ERROR_PAYMENT_METHOD, {position: toast.POSITION.TOP_RIGHT});
                    bag.setSubmitting(false);
                });
            }}
            render={(formProps) => (
                <form name="add_credit_card" className="payment-form" onSubmit={formProps.handleSubmit}>
                    {!props.isEditing && <div className="row">
                      <div className="col-md-12">
                        <div className="form-group customSelect">
                          <BootstrapField component="select" name="electronic_check_account_type">
                            <option value={""}>{f("echeck_form.account_type_label", "Account Type")}</option>
                            <option value="C">{f("echeck_form.account_type_checking", "Checking")}</option>
                            <option value={"S"}>{f("echeck_form.account_type_savings", "Saving Account")}</option>
                            <option value={"X"}>{f("echeck_form.account_type_corporate_checking", "Corporate Checking")}</option>
                          </BootstrapField>
                          <i className="fas fa-chevron-down select-arrow"></i>
                          <BootstrapError name="electronic_check_account_type"/>
                        </div>
                      </div>
                    </div>}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <BootstrapField type="text" name="electronic_check_rdfi_number"
                                                placeholder={f("echeck_form.routing_number", "Routing Number")}
                                                disabled={props.isEditing}/>
                                <BootstrapError name="electronic_check_rdfi_number"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <BootstrapField type="text" name="account_number" placeholder={f("echeck_form.account_number", "Account Number")}
                                                disabled={props.isEditing}/>
                                <BootstrapError name="account_number"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <BootstrapField type="text" name="name" placeholder={f("echeck_form.check_holder", "Check Holder")}/>
                                <BootstrapError name="name"/>
                            </div>
                        </div>
                    </div>
                    <div className={"row"}>
                        {!props.alwaysSaveOnFile && !props.isEditing && <div className="col-md-6">
                          <div className="form-check">
                            <input className="form-check-input rounded-circle" type="checkbox"
                                   checked={formProps.values.save_on_file}
                                   id="save_on_file"
                                   name={"save_on_file"} onChange={formProps.handleChange}/>
                            <label className="form-check-label" htmlFor="save_on_file" style={{"paddingLeft": "24px"}}>
                              {f("credit_card_form.save_on_file", "Save On File")}
                            </label>
                          </div>
                        </div>}
                        <div className="col-md-6">
                            {formProps.values.save_on_file && <div className="form-check">
                              <input className="form-check-input rounded-circle" type="checkbox"
                                     checked={formProps.values.make_default}
                                     id="make_default"
                                     name={"make_default"} onChange={formProps.handleChange}/>
                              <label className="form-check-label" htmlFor="make_default"
                                     style={{"paddingLeft": "24px"}}>
                                {f("credit_card_form.set_as_default", "Set as default")}
                              </label>
                            </div>}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className="col-md-12 text-center">
                            <button className={"btn btn-primary buttonColor buttonsize save-card-button"} type="submit"
                                disabled={formProps.isSubmitting}
                                style={{width:'157px'} }
                            >{buttonText()}
                            </button>
                        </div>
                    </div>
                </form>
            )}/>
    </div>;

}

export default connect<any, any, any, any>((state, props) => {
        return {
            payerAccount: getPayerAccount(state),
            selectedPrimaryAccount: getSelectedPrimaryAccount(state),
            selectedSecondaryAccount: getSelectedSecondaryAccount(state),
        }
    }
)(AddCheckForm);
