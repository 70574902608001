import * as types from '../constants/ActionTypes';
export const initialState = {
    statements: [],
    statementId: "",
    selectMonthStatement: ""
};
export interface StatementsState {
}
export function statementsReducer(state = initialState, action): StatementsState {
    switch (action.type) {
        case types.GET_STATEMENTS_LIST:
            return { ...state, statements: action.payload };
        case types.SET_SELECT_MONTH:

            return { ...state, selectMonthStatement: action.payload };
        case types.GET_SELECT_MONTH:

            return { ...state, selectMonthStatement: action.payload };

        default:
            return { ...state };
    }
}