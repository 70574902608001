import { Route } from 'react-router-dom';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PaymentStatus from './PaymentStatus';
import { getSelectedPrimaryAccount } from '../../selectors/Selectors';
import { FormattedMessage } from "react-intl";

class PaymentStatusPage extends Component<any, any> {

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className={"col-12"}>
                        <Route render={({ history, location }) => {
                            return <button
                                type="button"
                                id="closePaymentStatus"
                                className="btn button-back d-print-none"

                                onClick={() => {
                                    history.push("/");
                                }}
                                
                            >
                                {' '}
                                <i className="fas fa-angle-left" /> <FormattedMessage id={"payment_page.back_button"} />
                            </button>
                        }} />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className={"col-12"}>
                        <PaymentStatus />
                    </div>
                </div>
            </div>
        )
    }
}

export default connect<any, any, any, any>((store, props) => ({
    selectedInvoices: store.default.selectedInvoices,
    secondaryAccounts: store.user_options.secondaryAccounts,
    selectedPrimaryAccount: getSelectedPrimaryAccount(store),
    userCustomerDetails: store.user_options.primaryAccounts,
}))(PaymentStatusPage);
