export const SET_EDITING_PAYMENT = 'SET_EDITING_PAYMENT';
export const ADD_SESSION_CARD = 'ADD_SESSION_CARD';
export const UPDATE_SESSION_CARD = 'UPDATE_SESSION_CARD';
export const DELETE_SESSION_CARD = 'DELETE_SESSION_CARD';
export const ADD_SESSION_CHECK = 'ADD_SESSION_CHECK';
export const UPDATE_SESSION_CHECK = 'UPDATE_SESSION_CHECK';
export const DELETE_SESSION_CHECK = 'DELETE_SESSION_CHECK';
export const CLEAR_SESSION_PAYMENT_METHODS = 'CLEAR_SESSION_PAYMENT_METHODS';

export const CACHE_SESSION_CARDS = "CACHE_SESSION_CARDS";
export const CACHE_SESSION_CHECKS = "CACHE_SESSION_CHECKS";

export function addSessionCard(payment, payer,log) {
    return {
        type: ADD_SESSION_CARD,
        payload: {
            payment,
            payer,
            log
        }
    }
}

export function updateSessionCard(payment, payer,log) {
    return {
        type: UPDATE_SESSION_CARD,
        payload: {
            payment,
            payer,
            log
        }
    }
}

export function deleteSessionCard(payment, payer,log) {
    return {
        type: DELETE_SESSION_CARD,
        payload: {
            payment,
            payer,
            log
        }
    }
}

export function addSessionCheck(payment, payer,log) {
    return {
        type: ADD_SESSION_CHECK,
        payload: {
            payment,
            payer,
            log
        }
    }
}

export function updateSessionCheck(payment, payer,log) {
    return {
        type: UPDATE_SESSION_CHECK,
        payload: {
            payment,
            payer,
            log
        }
    }
}

export function deleteSessionCheck(payment, payer,log) {
    return {
        type: DELETE_SESSION_CHECK,
        payload: {
            payment,
            payer,
            log
        }
    }
}

export function clearSessionPaymentMethods() {
    return {
        type: CLEAR_SESSION_PAYMENT_METHODS,
    }
}

export interface PaymentState {
    editingPayment: any;
    sessionCards: any;
    sessionChecks: any;

}

const initialState: PaymentState = {
    editingPayment: null, // payment details of the payment method being edited.
    sessionCards: JSON.parse(sessionStorage.getItem(CACHE_SESSION_CARDS) ?? "{}"),
    sessionChecks: JSON.parse(sessionStorage.getItem(CACHE_SESSION_CHECKS) ?? "{}"),
};


export function paymentReducer(state = initialState, action): PaymentState {
    switch (action.type) {
        case SET_EDITING_PAYMENT:
            return {...state, editingPayment: action.payload};

        case ADD_SESSION_CARD: {
            let all = {...state.sessionCards};
            let cards = {...state.sessionCards[action.payload.payer]};
            cards[action.payload.payment.payment_card_token] = action.payload.payment;
            all[action.payload.payer] = cards;
            sessionStorage.setItem(CACHE_SESSION_CARDS, JSON.stringify(all));
            return {...state, sessionCards: all};
        }

        case UPDATE_SESSION_CARD: {
            let all = {...state.sessionCards};
            let cards = {...state.sessionCards[action.payload.payer]};
            cards[action.payload.payment.payment_card_token] = {...action.payload.payment};
            all[action.payload.payer] = cards;
            sessionStorage.setItem(CACHE_SESSION_CARDS, JSON.stringify(all));
            return {...state, sessionCards: all};
        }

        case DELETE_SESSION_CARD: {
            let all = {...state.sessionCards};
            let cards = {...state.sessionCards[action.payload.payer]};
            if (typeof cards[action.payload.payment.payment_card_token] !== 'undefined') {
                delete cards[action.payload.payment.payment_card_token];
            }
            all[action.payload.payer] = cards;
            sessionStorage.setItem(CACHE_SESSION_CARDS, JSON.stringify(all));
            return {...state, sessionCards: all};
        }
        case ADD_SESSION_CHECK: {
            let all = {...state.sessionChecks};
            let checks = {...state.sessionChecks[action.payload.payer]};
            checks[action.payload.payment.payment_card_token] = action.payload.payment;
            all[action.payload.payer] = checks;
            sessionStorage.setItem(CACHE_SESSION_CHECKS, JSON.stringify(all));
            return {...state, sessionChecks: all};
        }
        case UPDATE_SESSION_CHECK: {
            let all = {...state.sessionChecks};
            let checks = {...state.sessionChecks[action.payload.payer]};
            checks[action.payload.payment.payment_card_token] = {...action.payload.payment};
            all[action.payload.payer] = checks;
            sessionStorage.setItem(CACHE_SESSION_CHECKS, JSON.stringify(all));
            return {...state, sessionChecks: all};
        }
        case DELETE_SESSION_CHECK: {
            let all = {...state.sessionChecks};
            let checks = {...state.sessionChecks[action.payload.payer]};
            if (typeof checks[action.payload.payment.payment_card_token] !== 'undefined') {
                delete checks[action.payload.payment.payment_card_token];
            }
            all[action.payload.payer] = checks;
            sessionStorage.setItem(CACHE_SESSION_CHECKS, JSON.stringify(all));
            return {...state, sessionChecks: all};
        }
        case CLEAR_SESSION_PAYMENT_METHODS: {
            return {...state, sessionChecks: {}, sessionCards: {}};
        }
        default: {
            return {...state};
        }
    }
}
