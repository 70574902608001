import axios from '../axios';

export function getSoldToNames(soldToNumbersList) {
    return axios.put(`/api/customer/soldto-details-list`, soldToNumbersList).then((response) => {
        return response.data;
    });
}

export function getPaymentMethods(details) {
    return axios.get(`/api/payment-methods`, {params: details}).then((response) => {
        return response.data;
    });
}
