import React, { Component, useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PaymentCardLists from "../payment-card-lists/PaymentCardLists";
import AddCreditCardForm from "../AddCreditCardForm";
import AddCheckForm from "../AddCheckForm";
import { getPayerAccount, getSelectedPrimaryAccount, getSelectedSecondaryAccount, handleDeleteIframe } from "../../../selectors/Selectors";
import { toast } from 'react-toastify';
import {
    addPaymentMethod, buildCardinalData,
    buildCCRequest, buildECRequest, cardFormToUpdateObject, checkFormToUpdateObject,
    getPaymentAccessToken,
    getPaymentResponse,
    getPaymetricResponse, updatePaymentMethod
} from "../../../requests/TokenRequests";
import { getPayerDetailsBuilder } from "../../../actions/InvoiceActions";
import { addSessionCard, addSessionCheck, updateSessionCard, updateSessionCheck } from "../../../reducers/PaymentReducer";
import { FormattedMessage } from 'react-intl';
import * as moment from "moment";
import { useCurrencyKey } from "../../../selectors/ConfigSelectors";
import { RootState } from "../../../reducers/RootReducer";
import { finalizePaymentMethodAndRefresh } from "./FinalizePaymentProcess";
import { PaymentOption } from "../../../interfaces/CnbsConfigInterfaces";
import { getAndClearSessionStorage, getPreAuthAmount } from "../../../utils";
import * as appConstants from '../../../constants/ApplicationConstants';
import { TraceLog } from "../../../interfaces/TraceLog"

export const PAYMENT_METHOD_DEFAULT = "PAYMENT_METHOD_DEFAULT";
export const PAYMENT_METHOD_SAVE = "PAYMENT_METHOD_SAVE";

export function ManagePaymentMethods(props: any) {
    const [state, setState] = useState<{
        type: 'EC' | 'CC',
        editingPayment: any,
        isEditing: boolean,
    }>({
        type: 'CC',
        editingPayment: null,
        isEditing: false,
    });
    const currency_key = useCurrencyKey();
    const secure_3ds_enabled = useSelector<RootState, any>(state => state.app.config.sap?.data?.payment_provider[0]?.secure_3ds_enabled === "X");
 const secure_3ds_version = useSelector<RootState, any>(state => state.app.config.sap?.data?.payment_provider[0]?.secure_3ds_version);
    const dispatch = useDispatch();
    const payer = useSelector<any, any>(getPayerAccount);
    const primaryAccount = useSelector<any, any>(getSelectedPrimaryAccount);
    const secondaryAccount = useSelector<any, any>(getSelectedSecondaryAccount);
    const currentUser = useSelector<any, any>(state => state.default.currentUser);
    const config_payment_cards = useSelector<RootState, PaymentOption[]>(state => state?.app?.config?.sap?.data.payment_cards ?? []);
    let isPaymentDisable = (sessionStorage.getItem("isPaymentDisable")?.toString() === "true") ? true : false;


    function addPaymentMethodComponent() {
        setState({ ...state, editingPayment: {}, isEditing: false });
    }

    function onTabChange(type) {
        setState({ ...state, type: type, editingPayment: null });
        //update the parent state with the payment type EC or CC
        props.updateState(type);
        // props.setEditingPayment(null);
    }

    function isEC() {
        return state.type === 'EC';
    }

    function isCC() {
        return state.type === 'CC';
    }

    function onCardConfirmed(payment_card) {
        setState({ ...state });
       
        // props.setEditingPayment(null);
    }

    const editPayment = function (payment) {
        setState({
            ...state,
            editingPayment: payment,
            isEditing: true,
        });
    };

    async function saveCard(card) {
        await processCard(card);
        props.getPayerDetailsBuilder(props.primaryAccount, props.secondaryAccount, props.currentUser.primary_account_type_id);
        setState({ ...state, editingPayment: null });
        handleDeleteIframe();
    }

    async function saveCheck(card) {
        await processCheck(card);
        props.getPayerDetailsBuilder(props.primaryAccount, props.secondaryAccount, props.currentUser.primary_account_type_id);
        setState({ ...state, editingPayment: null });
    }


    async function processCheck(card) {
        if (state.isEditing) {
            let log:TraceLog={
                id: "MOD_PAYMENT_CARD",
                login:currentUser.email,
                epayv:appConstants.APP_VERSION,
                usrag:navigator.userAgent
            }
            if (card.save_on_file) {
                
                let response = await updatePaymentMethod(
                    props.payerAccount.PrimaryAcct,
                    props.primaryAccount.CompanyCode,
                    checkFormToUpdateObject(card, state.editingPayment.payment_card_token),
                    log,
                    card.make_default
                    );
                return response;
            } else {
                props.updateSessionCheck(checkFormToUpdateObject(card, state.editingPayment.payment_card_token), props.payerAccount.PrimaryAcct,log);
            }
        } else {
            const { access_token, merchant_guid, paymetric_xi_url } = await getPaymentAccessToken('EC');
            let body = buildECRequest(card, merchant_guid, access_token);
            await getPaymetricResponse(paymetric_xi_url, body, "2.1.0");
            let { paymentcard } = await getPaymentResponse(access_token);

            let log:TraceLog={
                id: "ADD_PAYMENT_CARD",
                login:currentUser.email,
                epayv:appConstants.APP_VERSION,
                usrag:navigator.userAgent
            }
           
            // We don't want to do  pass payment card to props method
            if (card.save_on_file) {

              
                try {

                  
                    await addPaymentMethod(props.payerAccount.PrimaryAcct, props.primaryAccount.CompanyCode, paymentcard, log,card.make_default);
                } catch (error:any) {
                    if (error.message_number === 74) {
                        let matches = error.message_string.match(/Payment card (CCINS) (.*) already exist, action ([0-9]*) not possible/);
                        toast.error(`${matches[2]} already exists.`, { position: toast.POSITION.TOP_RIGHT });
                    } else if (error.message_number === 76) {
                        let matches = error.message_string.match(/Payment card (CCINS) (.*) already assigned to account ([0-9]*)/);
                        toast.error(`${matches[2]} already assigned to account ${matches[3]}`, { position: toast.POSITION.TOP_RIGHT });
                    } else {
                        toast.error(error.message_string, { position: toast.POSITION.TOP_RIGHT });
                    }
                }
            } else {
                props.addSessionCheck(paymentcard, props.payerAccount.PrimaryAcct,log);
            }
            return paymentcard;
        }
    }

    async function processCard(card) {
     
        if (state.isEditing) {
            let log:TraceLog={
                id: "MOD_PAYMENT_CARD",
                login:currentUser.email,
                epayv:appConstants.APP_VERSION,
                usrag:navigator.userAgent
            }
            if (card.save_on_file) {
              
                let response = await updatePaymentMethod(
                    props.payerAccount.PrimaryAcct,
                    props.primaryAccount.CompanyCode,
                    cardFormToUpdateObject(card, state.editingPayment.payment_card_token),
                    log,
                    card.make_default);
                return response;
            } else {
                props.updateSessionCard(cardFormToUpdateObject(card, state.editingPayment.payment_card_token), props.payerAccount.PrimaryAcct,log);
            }
        } else {
            if (secure_3ds_enabled) {
                sessionStorage.setItem(PAYMENT_METHOD_DEFAULT, JSON.stringify(card.make_default));
                sessionStorage.setItem(PAYMENT_METHOD_SAVE, JSON.stringify(card.save_on_file));
            }
            const send_cardinal: boolean = secure_3ds_enabled && card.card_type !== "AMEX" && card.save_on_file;
            const preauth_amount = getPreAuthAmount(card.card_type, config_payment_cards);
            const cardinalData = buildCardinalData(preauth_amount, props.currentUser, props.payerDetails, secure_3ds_version, window.location.href, currency_key);
            const { access_token, merchant_guid, paymetric_xi_url } = await getPaymentAccessToken('CC', send_cardinal ? cardinalData : {});
            let body = buildCCRequest(card, merchant_guid, access_token);
            await getPaymetricResponse(paymetric_xi_url, body, secure_3ds_version);

            getAndClearSessionStorage(PAYMENT_METHOD_DEFAULT, false);
            getAndClearSessionStorage(PAYMENT_METHOD_SAVE, false);

            let log:TraceLog={
                id: "ADD_PAYMENT_CARD",
                login:currentUser.email,
                epayv:appConstants.APP_VERSION,
                usrag:navigator.userAgent
            }
            await finalizePaymentMethodAndRefresh(access_token, dispatch, payer, primaryAccount, secondaryAccount, currentUser, card.save_on_file,log, card.make_default);
        }
    }

    function deletePayment(payment) {
        if (state.editingPayment && payment.payment_card_token === state.editingPayment.payment_card_token) {
            setState({ ...state, editingPayment: null });
        }
    }

    function managePaymentsMethodAddClick(event) {
        handleDeleteIframe();
        addPaymentMethodComponent();
    }


    /**/
    return <React.Fragment>
        <div className="row">
            <div className="col-md-6">
                <PaymentCardLists onTabChange={onTabChange} onEdit={editPayment}
                    onDelete={deletePayment} />
                <div className={(isPaymentDisable || state.editingPayment) ? 'is-disabled text-center mt-3' : 'text-center mt-3'}>
                    {isCC() &&
                        <button className={"btn btn-primary buttonColor buttonsize "} style={{ width: "157px"}} onClick={managePaymentsMethodAddClick}><FormattedMessage
                            id={"payment_methods.add_card_button"} /></button>}
                    {isEC() &&
                        <button className={"btn btn-primary buttonColor buttonsize"} style={{ width: "157px" }} onClick={addPaymentMethodComponent}><FormattedMessage
                            id={"payment_methods.add_check_button"} /></button>}
                </div>
            </div>
            <div className="col-md-6">
                {(state.editingPayment && isCC()) &&
                    <AddCreditCardForm payment={state.editingPayment} save={saveCard}
                        isEditing={state.isEditing}
                        alwaysSaveOnFile={props.alwaysSaveOnFile} />}
                {(state.editingPayment && isEC())
                    &&
                    <AddCheckForm payment={state.editingPayment} isEditing={state.isEditing} save={saveCheck}
                        alwaysSaveOnFile={props.alwaysSaveOnFile} />
                       
                }
            </div>
        </div>
    </React.Fragment>

}

export default connect<any, any, any, any>((state, props) => {
    return {
        payerAccount: getPayerAccount(state),
        primaryAccount: getSelectedPrimaryAccount(state),
        secondaryAccount: getSelectedSecondaryAccount(state),
        currentUser: state.default.currentUser,
        payerDetails: state.default.payerDetails,
        selectedType:props.selectedType,
        updateType:props.updateState,
    }
}, {
    getPayerDetailsBuilder,
    updateSessionCheck,
    addSessionCheck,
    updateSessionCard,
    addSessionCard,
})(ManagePaymentMethods)
