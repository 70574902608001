import React, { Component } from 'react';
import { connect, Field } from 'formik';
import classNames from 'classnames';

export class BootstrapFieldInner extends Component<any, any> {

  render() {
    let errors = this.props.formik.errors;
    let touched = this.props.formik.touched;
    let fieldErrors = errors[this.props.name];
    let hasErrors = typeof fieldErrors !== 'undefined';
    let isTouched = typeof touched[this.props.name] !== 'undefined';
    let wasSubmitted = this.props.formik.submitCount > 0;
    let classes: {[className: string]: boolean}[] = [{'is-invalid': hasErrors && (isTouched || wasSubmitted)}];
    if (this.props.type !== "checkbox"){
     classes.push({'form-control': true}); 
    } 
    if (this.props.className){
      classes.push(this.props.className);
    } 
    if (this.props.children) {
      return <Field {...this.props} className={classNames(classes)}>{this.props.children}</Field>
    } else {
      return <Field {...this.props} className={classNames(classes)}/>
    }
  }
}

export const BootstrapField = connect(BootstrapFieldInner);

class BootstrapErrorInner extends Component<any, any> {

  render() {
    let errors = this.props.formik.errors;
    let fieldErrors = errors[this.props.name];
    if(fieldErrors === "amount_to_process must be a `number` type, but the final value was: `NaN`."){
      fieldErrors = "Numeric value required.";
    }
    if (fieldErrors){
      return <div className="invalid-feedback">{fieldErrors}</div>
    } 
    return null;
  }

}

export const BootstrapError = connect(BootstrapErrorInner);


