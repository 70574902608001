import React from 'react';
import { Formik } from "formik";
import { BootstrapError, BootstrapField } from "../../../helpers/Field";
import * as yup from 'yup';
import { registerUser } from "../../../requests/UserRequests";
import { useHistory } from "react-router-dom";
import axios from "../../../axios";
import { connect } from "react-redux";
import { AuthStatus } from "../../../interfaces/AuthStatus";
import { setAuthStatus } from "../../../actions/UserActions";
import { useState } from "react";
import { useIntl } from "react-intl";

const schema = yup.object().shape({
    email: yup.string().email("Enter valid email.").required("Email Required."),
    first_name: yup.string().required("First Name Required."),
    last_name: yup.string().required("Last Name Required."),
    company: yup.string().required("Company Required."),
    password: yup.string().required("Password Required."),
    verify_password: yup.string().required("Passwords must match."),
});


function RegistrationRequestForm(props) {
    const intl = useIntl();
    const f = id => intl.formatMessage({ id: id });
    let [errorMessage, setErrorMessage] = useState(null);
    const history = useHistory();
    return (
        <div>
            <Formik
                initialValues={{}}
                validationSchema={schema}
                onSubmit={(formProps, bag) => {

                    registerUser(formProps).then((response) => {
                        if (!response.user.isConfirmed) {                            
                            history.push("/auto-register/confirm-instructions");
                        }
                        else {
                            sessionStorage.setItem('token', response.token);
                            sessionStorage.setItem('refreshToken', response.refreshToken);
                            axios.instance.defaults.headers.common['Authorization'] = `Bearer ${response.token}`;
                            props.setAuthStatus(AuthStatus.Authenticated);                            
                            history.push("/invoices");       
                            sessionStorage.removeItem("_registerToken");
                        }                        
                    }).catch(error => {
                        setErrorMessage(error.response.data.message);
                    });
                }}
                render={props =>
                    <div className="card">
                        <form onSubmit={props.handleSubmit}>
                            <div className="row">
                                <div className={"col-12"}>
                                    {errorMessage && <p className={"text-danger"}>{errorMessage}</p>}
                                    <div className="form-group">
                                        <label>{f("edit_user.email_label")}</label>
                                        <BootstrapField type={"email"} name={"email"} />
                                        <BootstrapError name={"email"} />
                                    </div>
                                    <div className="form-group">
                                        <label>{f("edit_user.first_name_label")}</label>
                                        <BootstrapField type={"text"} name={"first_name"} />
                                        <BootstrapError name={"first_name"} />
                                    </div>
                                    <div className="form-group">
                                        <label>{f("edit_user.last_name_label")}</label>
                                        <BootstrapField type={"text"} name={"last_name"} />
                                        <BootstrapError name={"last_name"} />
                                    </div>
                                    <div className="form-group">
                                        <label>{f("edit_user.company_label")}</label>
                                        <BootstrapField type={"text"} name={"company"} />
                                        <BootstrapError name={"company"} />
                                    </div>
                                    <div className="form-group">
                                        <label>{f("edit_user.password_label")}</label>
                                        <BootstrapField type={"password"} name={"password"} />
                                        <BootstrapError name={"password"} />
                                    </div>
                                    <div className="form-group">
                                        <label>{f("edit_user.verify_password")}</label>
                                        <BootstrapField type={"password"} name={"verify_password"} />
                                        <BootstrapError name={"verify_password"} />
                                    </div>
                                    <div className={"form-group"}>
                                        <button type={"submit"} className={"btn btn-primary buttonColor buttonsize"}>{f("edit_user.next")}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                } />
        </div>
    );
}

export default connect<any, any, any, any>(() => { }, {
    setAuthStatus,
})(RegistrationRequestForm);
