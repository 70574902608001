import React, {useEffect} from "react";
import {Logout} from "../actions/UserActions";
import {connect} from "react-redux";

function SystemError(props) {
    useEffect(() => {
        props.logout();
    }, []);

    return (<div className={"container"}>
        <div className={"row justify-content-md-center mt-5"}>
            <div className={"col-6"}>
                <h4>There was an error with the system.<br/>Please try again later.</h4>
            </div>
        </div>
    </div>);
}

export default connect<any, any, any, any>(() => {
}, {logout: Logout})(SystemError);

