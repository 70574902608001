import React from 'react';
import InvoiceDetail from "./InvoiceDetail";
import {Payment} from "./Payment";
import {Link, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
// import 'react-select/dist/react-select.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'react-activity/dist/react-activity.css';
import {getSelectedPrimaryAccount} from "../../selectors/Selectors";
import {FormattedMessage} from "react-intl";
import {AccountChangeRedirect} from "../AccountChangeRedirect";
import {FinalizePaymentProcess} from "../payment-methods/manage-payment-methods/FinalizePaymentProcess";

export function _PaymentPageComponent(props) {

    return (<div className="container">
        {/*<AccountChangeRedirect/>*/}
        <FinalizePaymentProcess />
        <div className="row" style={{ height:80 }}>
            {/* we duplicate this heading section for response purposes*/}
            <div className="d-flex flex-wrap d-sm-none">
                <div className="col-12">
                    <h2 className="invoicesHeading">
                        <FormattedMessage id={"payment_page.invoice_count"}
                                          values={{"count": Object.keys(props.selectedInvoices).length}}/>
                    </h2>
                </div>
                <div className="col-12">
                    <h2 className="invoicesHeading">
                        <FormattedMessage id={"payment_page.title"}/>
                    </h2>
                </div>
                <div className="col-12">
                    <Link
                        id="closeDetails"
                        to={"/invoices"}
                        className="btn btn-lg invoice-detail-back-button"
                    >
                        {' '}
                        <i className="fas fa-angle-left"/> <FormattedMessage id={"payment_page.back_button"}/>{' '}
                    </Link>
                </div>
            </div>
            <div style={{ width: '100%', lineHeight:3 }} className="d-none d-sm-flex">
                <div className="row" style={{
                width: '100%',
                }}>
                    <div className="column" style={{ width: '35%' }}>
                    <div className="col-sm-4 text-left">
                        <Link
                            id="closeDetails"
                            to={"/invoices"}
                                className="btn button-back"
                            >
                            {' '}
                            <i className="fas fa-angle-left" />  <FormattedMessage id={"payment_page.back_button"} />{' '}
                        </Link>
                        </div>
                        <div className="col-sm-4 mt-4">
                        <h2 className="invoicesHeading">
                            <FormattedMessage id={"payment_page.invoice_count"}
                                values={{ "count": Object.keys(props.selectedInvoices).length }} />
                        </h2>
                    </div>
                </div>
              
                <div className="col-sm-4 text-center" style={{ paddingTop: '12px' }}>
                    <h2 className="invoicesHeading">
                        <FormattedMessage id={"payment_page.title"}/>
                    </h2>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <div className="row">
            <div className="col-12">
                <div className="card" id="mainCard">
                    <div>
                        {Object.keys(props.selectedInvoices)
                            .map((key, index) => (
                                <InvoiceDetail documentId={key} key={key}/>
                            ))}
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <div className="row">
            <div className="col-12">
                <Payment mode={"invoice"} form={null}/>
            </div>
        </div>
        {Object.keys(props.selectedInvoices).length === 0 && <Redirect to={"/invoices"}/>}
    </div>);
}

export default connect<any, any, any, any>((store, props) => ({
    selectedInvoices: store.invoice.selected,
    secondaryAccounts: store.user_options.secondaryAccounts,
    selectedPrimaryAccount: getSelectedPrimaryAccount(store),
    userCustomerDetails: store.user_options.primaryAccounts,
}))(_PaymentPageComponent);
