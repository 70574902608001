import React from "react";

export function MultiSelect(props: { mode: string, checked: boolean, disabled: boolean }) {
    const { mode, checked, disabled } = props;
    return (
        <div className="checkbox-personalized" >
            {!disabled ? (
                <div>
                    <input
                        type={mode}
                        checked={checked}
                        disabled={disabled}
                        readOnly={true}
                    />
                    <label htmlFor={`checkbox`}>
                        <div className="check" />
                    </label>
                </div>
            ) : (
                <div />
            )}
        </div>
    );
};

export function CheckSelect(props: { isChecked: boolean, disabled: boolean }) {
    const { isChecked, disabled } = props;
    return (
        <div className="checkbox-personalized" >
            {!disabled ? (
                <div>
                    <input
                        type="checkbox"
                        checked={isChecked}
                        disabled={disabled}
                        readOnly={true}
                         
                    />
                    <label htmlFor={`checkbox`}>
                        <div className="check" />
                    </label>
                </div>
            ) : (
                <div />
            )}
        </div>
    );
};