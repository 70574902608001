import axios from '../axios';
import * as apiTypes from "../constants/ApiConstants";
import moment from "moment";
import { AddressData } from "../interfaces/AddressData";
import { User } from "../interfaces/User";
import { PayerDetails } from "../interfaces/PayerDetails";
import { Guid } from 'js-guid';
import { toast } from 'react-toastify';
declare var window: any;

export function getPaymentAccessToken(type: 'EC' | 'CC' | 'TO', cardinalData = {}) {
    if (!(type === 'EC' || type === 'CC' || type === 'TO')) {
        return Promise.reject({ error: "Invalid payment type." });
    }
    return axios.post(apiTypes.GET_PAYMENT_ACCESS_TOKEN, {
        ...cardinalData,
        action: '01',
        payment_method: type,
    }).then(response => {
        return response.data;
    }).catch(error => {

        toast.error("Internal Error - please contact customer service", {
            position: toast.POSITION.TOP_RIGHT,
        });
   
    });
}

export function buildCCRequest(card_details, merchant_id, access_token) {
    let data = window.$XIPlugin.createJSRequestPacket(merchant_id, access_token);
    data.addField(window.$XIPlugin.createField('PAYMET', false, 'CC'));
    data.addField(window.$XIPlugin.createField('CCINS', false, card_details.card_type));
    data.addField(window.$XIPlugin.createField('CCNUM', true, card_details.card_number.replace(/\s/g, '')));
    data.addField(window.$XIPlugin.createField('CCNAME', false, card_details.card_name));
    data.addField(window.$XIPlugin.createField('VALTM', false, parseInt(card_details.card_month, 10)));
    data.addField(window.$XIPlugin.createField('VALTY', false, parseInt(card_details.card_year, 10)));
    data.addField(window.$XIPlugin.createField('CVVAL', false, card_details.card_validation_code));
    return data;
}

export function buildTORequest(token_id, merchant_id, access_token) {
    let data = window.$XIPlugin.createJSRequestPacket(merchant_id, access_token);
    // data.addField(window.$XIPlugin.createField('PAYMET', false, 'CC'));
    // data.addField(window.$XIPlugin.createField('CCINS', false, card_details.card_type));
    // data.addField(window.$XIPlugin.createField('CCNUM', true, card_details.card_number.replace(/\s/g, '')));
    // data.addField(window.$XIPlugin.createField('CCNAME', false, card_details.card_name));
    // data.addField(window.$XIPlugin.createField('VALTM', false, parseInt(card_details.card_month, 10)));
    // data.addField(window.$XIPlugin.createField('VALTY', false, parseInt(card_details.card_year, 10)));
    // data.addField(window.$XIPlugin.createField('CVVAL', false, card_details.card_validation_code));
    return data;
}

export function buildECRequest(ec_details, merchant_id, access_token) {
    let data = window.$XIPlugin.createJSRequestPacket(merchant_id, access_token);
    data.addField(window.$XIPlugin.createField('PAYMET', false, 'EC'));
    data.addField(window.$XIPlugin.createField('CCINS', false, 'EC'));
    data.addField(window.$XIPlugin.createField('CCNUM', true, ec_details.account_number));
    data.addField(window.$XIPlugin.createField('CCNAME', false, ec_details.name));
    data.addField(window.$XIPlugin.createField('ECRDFI', false, ec_details.electronic_check_rdfi_number));
    data.addField(window.$XIPlugin.createField('ECACTTYP', false, ec_details.electronic_check_account_type));
    return data;
}

export function getPaymetricResponse(paymetricUrl: string, data: any, iThreeds_version: string) {
    return new Promise((resolve, reject) => {
        $XIPlugin.ajax({
            url: paymetricUrl + "/Ajax",
            type: "POST",
            data: data,
            success: function (a) {
                resolve(data);
            },
            error: function (status, text_status) {
                reject({ status, text_status });
            },
            threeDSVersion: iThreeds_version
        });
    });

}

export function paymetric3DSCheck(paymetricUrl: string, data: any, iThreeds_version: string) {
    return new Promise((resolve, reject) => {
        $XIPlugin.ajax({
            url: paymetricUrl + "/Cardinal3DSWithToken",
            type: "POST",
            data: data,
            success: function (a) {
                resolve(data);
            },
            error: function (status, text_status) {
                reject({ status, text_status });
            },
            threeDSVersion: iThreeds_version
        });
    });
}

export function getPaymetric3DSTO(paymetricUrl, data) {
    return axios.post(paymetricUrl + '/Cardinal3DSWithToken', data).then(response => {
        return response.data;
    });
}

export function getPaymentResponse(access_token) {
    return axios.post(apiTypes.GET_PAYMENT_RESPONSE, {
        action: '02',
        access_token: access_token,
    }).then(response => {
        return response.data;
    });
}

export function addPaymentMethod(customer_number, company_code, payment_card, log, make_default = false) {
    let action = '01';
    return sendManagePaymentCardsRequest(action, customer_number, company_code, payment_card, make_default, log);
}

export function updatePaymentMethod(customer_number, company_code, payment_card, log, make_default = false) {
    let action = '02';
    return sendManagePaymentCardsRequest(action, customer_number, company_code, payment_card, make_default, log);
}

export function deletePaymentMethod(customer_number, company_code, payment_card, log, make_default = false) {
    let action = '03';
    return sendManagePaymentCardsRequest(action, customer_number, company_code, payment_card, make_default, log);
}

function sendManagePaymentCardsRequest(action, customer_number, company_code, payment_cards, make_default, log) {

    let payload = {
        payer_data: {
            customer_number: customer_number,
            company_code: company_code,
        },
        action: action,
        payment_cards: [{
            payment_card_name: payment_cards.payment_card_name,
            payment_card_token: payment_cards.payment_card_token,
            payment_card_type: payment_cards.payment_card_type,
            valid_from: "",
            valid_to: payment_cards.valid_to,
            default: make_default ? 'X' : '',
            card_validation_code: payment_cards.card_validation_code,
            electronic_check_account_type: payment_cards.electronic_check_account_type,
            electronic_check_rdfi_number: payment_cards.electronic_check_rdfi_number,
        }],
        log: log

    };

    return axios.post(apiTypes.MANAGE_PAYMENTS_URL, payload).then(response => {
        if (response.data && response.data.message_type === "S") {
            return response.data;
        } else if (response.data && response.data.status && response.data.status.message_type === "S") {
            return response.data;
        } else {
            throw { message_number: response.data.message_number, message_string: response.data.message_line_string };
        }
    });
}

export function cardFormToUpdateObject(card, token) {
    let valid_to = moment();
    valid_to.year(card.card_year);
    valid_to.month(parseInt(card.card_month) - 1);
    valid_to.endOf('month');
    return {
        payment_card_name: card.card_name,
        payment_card_token: token,
        payment_card_type: card.card_type,
        valid_from: "",
        valid_to: valid_to.format('MM-DD-YYYY'),
        card_validation_code: card.card_validation_code,
        electronic_check_account_type: card.electronic_check_account_type,
        electronic_check_rdfi_number: card.electronic_check_rdfi_number,
    }
}

export function checkFormToUpdateObject(card, token) {
    return {
        payment_card_name: card.name,
        payment_card_token: token,
        payer_account: card.payer_account,
        payment_card_type: "EC",
        electronic_check_account_type: card.electronic_check_account_type,
        electronic_check_rdfi_number: card.electronic_check_rdfi_number,
    }
}

export function buildCardinalData(amount: number, user: User, payer: PayerDetails, version: string, redirect_url: string, currency_code: string, paymentOption: any | null = null) {
    let cardinalData = {
        amount, // Authorization amount.... get preauthorization amount
        billing_address1: payer.address_data.street,
        billing_city: payer.address_data.city,
        billing_country_code: getCardinalCountryCode(payer.address_data.country),
        billing_email: user.email,
        billing_first_name: user.first_name,
        billing_last_name: user.last_name,
        billing_phone: getPayerPhoneNumber(payer), // TODO is this Nobel's Number?
        billing_postal_code: payer.address_data.postal_code_city,
        billing_state: payer.address_data.region,
        currency_code: getCardinalCurrencyCode(currency_code), // TODO is the the currency code from SAP?
        mobile_phone: getPayerPhoneNumber(payer),
        order_number: Guid.newGuid().toString(),
        redirect_uri: redirect_url,
        shipping_address1: payer.address_data.street,
        shipping_city: payer.address_data.city,
        shipping_country_code: getCardinalCountryCode(payer.address_data.country),
        shipping_postal_code: payer.address_data.postal_code_city,
        shipping_state: payer.address_data.region,
        threeds_version: version,
        work_phone: getPayerPhoneNumber(payer),
    };

    if (paymentOption) {
        cardinalData['payment_card_token'] = paymentOption?.payment_card_token;
        cardinalData['expiration_month'] = paymentOption?.valid_to.split('-')[0] ?? "";
        cardinalData['expiration_year'] = paymentOption?.valid_to.split('-')[2] ?? "";
        cardinalData['payment_method'] = 'TO';
    } else {
        cardinalData['payment_method'] = 'CC';
        cardinalData['fn_ccnum'] = "CCNUM"; // hardcoded
        cardinalData['fn_valtm'] = "VALTM"; // hardcoded
        cardinalData['fn_valty'] = "VALTY"; // hardcoded
    }

    return cardinalData;

}

function getPayerPhoneNumber(payer: PayerDetails) {
    let phone = "+18593094411"; // TODO CHECK DEFAULT
    if (!!payer?.communcation_data?.phone) {
        return phone;
    }
    if (payer.communcation_data?.phone?.length == 1) {
        phone = payer.communcation_data?.phone[0]?.telephone;
    }
    if (payer.communcation_data?.phone?.length > 1) {
        phone = payer.communcation_data?.phone?.find(c => c.standard == "X")?.telephone ?? phone;
    }
    return phone;
}

function getCardinalCountryCode(country_code: string) {
    return tokenCountryCodes[country_code];
}

function getCardinalCurrencyCode(currency_code: string) {
    return tokenCurrencyCodes[currency_code];
}

const tokenCountryCodes = {
    "AD": 20,
    "AE": 784,
    "AF": 4,
    "AG": 28,
    "AI": 660,
    "AL": 8,
    "AM": 51,
    "AO": 24,
    "AQ": 10,
    "AR": 32,
    "AS": 16,
    "AT": 40,
    "AU": 36,
    "AW": 533,
    "AX": 248,
    "AZ": 31,
    "BA": 70,
    "BB": 52,
    "BD": 50,
    "BE": 56,
    "BF": 854,
    "BG": 100,
    "BH": 48,
    "BI": 108,
    "BJ": 204,
    "BL": 652,
    "BM": 60,
    "BN": 96,
    "BO": 68,
    "BQ": 535,
    "BR": 76,
    "BS": 44,
    "BT": 64,
    "BV": 74,
    "BW": 72,
    "BY": 112,
    "BZ": 84,
    "CA": 124,
    "CC": 166,
    "CD": 180,
    "CF": 140,
    "CG": 178,
    "CH": 756,
    "CI": 384,
    "CK": 184,
    "CL": 152,
    "CM": 120,
    "CN": 156,
    "CO": 170,
    "CR": 188,
    "CU": 192,
    "CV": 132,
    "CW": 531,
    "CX": 162,
    "CY": 196,
    "CZ": 203,
    "DE": 276,
    "DJ": 262,
    "DK": 208,
    "DM": 212,
    "DO": 214,
    "DZ": 12,
    "EC": 218,
    "EE": 233,
    "EG": 818,
    "EH": 732,
    "ER": 232,
    "ES": 724,
    "ET": 231,
    "FI": 246,
    "FJ": 242,
    "FK": 238,
    "FM": 583,
    "FO": 234,
    "FR": 250,
    "GA": 266,
    "GB": 826,
    "GD": 308,
    "GE": 268,
    "GF": 254,
    "GG": 831,
    "GH": 288,
    "GI": 292,
    "GL": 304,
    "GM": 270,
    "GN": 324,
    "GP": 312,
    "GQ": 226,
    "GR": 300,
    "GS": 239,
    "GT": 320,
    "GU": 316,
    "GW": 624,
    "GY": 328,
    "HK": 344,
    "HM": 334,
    "HN": 340,
    "HR": 191,
    "HT": 332,
    "HU": 348,
    "ID": 360,
    "IE": 372,
    "IL": 376,
    "IM": 833,
    "IN": 356,
    "IO": 86,
    "IQ": 368,
    "IR": 364,
    "IS": 352,
    "IT": 380,
    "JE": 832,
    "JM": 388,
    "JO": 400,
    "JP": 392,
    "KE": 404,
    "KG": 417,
    "KH": 116,
    "KI": 296,
    "KM": 174,
    "KN": 659,
    "KP": 408,
    "KR": 410,
    "KW": 414,
    "KY": 136,
    "KZ": 398,
    "LA": 418,
    "LB": 422,
    "LC": 662,
    "LI": 438,
    "LK": 144,
    "LR": 430,
    "LS": 426,
    "LT": 440,
    "LU": 442,
    "LV": 428,
    "LY": 434,
    "MA": 504,
    "MC": 492,
    "MD": 498,
    "ME": 499,
    "MF": 663,
    "MG": 450,
    "MH": 584,
    "MK": 807,
    "ML": 466,
    "MM": 104,
    "MN": 496,
    "MO": 446,
    "MP": 580,
    "MQ": 474,
    "MR": 478,
    "MS": 500,
    "MT": 470,
    "MU": 480,
    "MV": 462,
    "MW": 454,
    "MX": 484,
    "MY": 458,
    "MZ": 508,
    "NA": 516,
    "NC": 540,
    "NE": 562,
    "NF": 574,
    "NG": 566,
    "NI": 558,
    "NL": 528,
    "NO": 578,
    "NP": 524,
    "NR": 520,
    "NU": 570,
    "NZ": 554,
    "OM": 512,
    "PA": 591,
    "PE": 604,
    "PF": 258,
    "PG": 598,
    "PH": 608,
    "PK": 586,
    "PL": 616,
    "PM": 666,
    "PN": 612,
    "PR": 630,
    "PS": 275,
    "PT": 620,
    "PW": 585,
    "PY": 600,
    "QA": 634,
    "RE": 638,
    "RO": 642,
    "RS": 688,
    "RU": 643,
    "RW": 646,
    "SA": 682,
    "SB": 90,
    "SC": 690,
    "SD": 736,
    "SE": 752,
    "SG": 702,
    "SH": 654,
    "SI": 705,
    "SJ": 744,
    "SK": 703,
    "SL": 694,
    "SM": 674,
    "SN": 686,
    "SO": 706,
    "SR": 740,
    "ST": 678,
    "SV": 222,
    "SX": 534,
    "SY": 760,
    "SZ": 748,
    "TC": 796,
    "TD": 148,
    "TF": 260,
    "TG": 768,
    "TH": 764,
    "TJ": 762,
    "TK": 772,
    "TL": 626,
    "TM": 795,
    "TN": 788,
    "TO": 776,
    "TR": 792,
    "TT": 780,
    "TV": 798,
    "TW": 158,
    "TZ": 834,
    "UA": 804,
    "UG": 800,
    "UM": 581,
    "US": 840,
    "UY": 858,
    "UZ": 860,
    "VA": 336,
    "VC": 670,
    "VE": 862,
    "VG": 92,
    "VI": 850,
    "VN": 704,
    "VU": 548,
    "WF": 876,
    "WS": 882,
    "YE": 887,
    "YT": 175,
    "ZA": 710,
    "ZM": 894,
    "ZW": 716,
};

const tokenCurrencyCodes = {
    "AED": 784,
    "AFN": 971,
    "ALL": 8,
    "AMD": 51,
    "ANG": 532,
    "AOA": 973,
    "ARS": 32,
    "AUD": 36,
    "AWG": 533,
    "AZN": 944,
    "BAM": 977,
    "BBD": 52,
    "BDT": 50,
    "BGN": 975,
    "BHD": 48,
    "BIF": 108,
    "BMD": 60,
    "BND": 96,
    "BOB": 68,
    "BOV": 984,
    "BRL": 986,
    "BSD": 44,
    "BTN": 64,
    "BWP": 72,
    "BYN": 933,
    "BZD": 84,
    "CAD": 124,
    "CDF": 976,
    "CHE": 947,
    "CHF": 756,
    "CHW": 948,
    "CLF": 990,
    "CLP": 152,
    "CNY": 156,
    "COP": 170,
    "COU": 970,
    "CRC": 188,
    "CUC": 931,
    "CUP": 192,
    "CVE": 132,
    "CZK": 203,
    "DJF": 262,
    "DKK": 208,
    "DOP": 214,
    "DZD": 12,
    "EGP": 818,
    "ERN": 232,
    "ETB": 230,
    "EUR": 978,
    "FJD": 242,
    "FKP": 238,
    "GBP": 826,
    "GEL": 981,
    "GHS": 936,
    "GIP": 292,
    "GMD": 270,
    "GNF": 324,
    "GTQ": 320,
    "GYD": 328,
    "HKD": 344,
    "HNL": 340,
    "HRK": 191,
    "HTG": 332,
    "HUF": 348,
    "IDR": 360,
    "ILS": 376,
    "INR": 356,
    "IQD": 368,
    "IRR": 364,
    "ISK": 352,
    "JMD": 388,
    "JOD": 400,
    "JPY": 392,
    "KES": 404,
    "KGS": 417,
    "KHR": 116,
    "KMF": 174,
    "KPW": 408,
    "KRW": 410,
    "KWD": 414,
    "KYD": 136,
    "KZT": 398,
    "LAK": 418,
    "LBP": 422,
    "LKR": 144,
    "LRD": 430,
    "LSL": 426,
    "LYD": 434,
    "MAD": 504,
    "MDL": 498,
    "MGA": 969,
    "MKD": 807,
    "MMK": 104,
    "MNT": 496,
    "MOP": 446,
    "MRU[11]": 929,
    "MUR": 480,
    "MVR": 462,
    "MWK": 454,
    "MXN": 484,
    "MXV": 979,
    "MYR": 458,
    "MZN": 943,
    "NAD": 516,
    "NGN": 566,
    "NIO": 558,
    "NOK": 578,
    "NPR": 524,
    "NZD": 554,
    "OMR": 512,
    "PAB": 590,
    "PEN": 604,
    "PGK": 598,
    "PHP": 608,
    "PKR": 586,
    "PLN": 985,
    "PYG": 600,
    "QAR": 634,
    "RON": 946,
    "RSD": 941,
    "RUB": 643,
    "RWF": 646,
    "SAR": 682,
    "SBD": 90,
    "SCR": 690,
    "SDG": 938,
    "SEK": 752,
    "SGD": 702,
    "SHP": 654,
    "SLL": 694,
    "SOS": 706,
    "SRD": 968,
    "SSP": 728,
    "STN[13]": 930,
    "SVC": 222,
    "SYP": 760,
    "SZL": 748,
    "THB": 764,
    "TJS": 972,
    "TMT": 934,
    "TND": 788,
    "TOP": 776,
    "TRY": 949,
    "TTD": 780,
    "TWD": 901,
    "TZS": 834,
    "UAH": 980,
    "UGX": 800,
    "USD": 840,
    "USN": 997,
    "UYI": 940,
    "UYU": 858,
    "UYW": 927,
    "UZS": 860,
    "VES": 928,
    "VND": 704,
    "VUV": 548,
    "WST": 882,
    "XAF": 950,
    "XAG": 961,
    "XAU": 959,
    "XBA": 955,
    "XBB": 956,
    "XBC": 957,
    "XBD": 958,
    "XCD": 951,
    "XDR": 960,
    "XOF": 952,
    "XPD": 964,
    "XPF": 953,
    "XPT": 962,
    "XSU": 994,
    "XTS": 963,
    "XUA": 965,
    "XXX": 999,
    "YER": 886,
    "ZAR": 710,
    "ZMW": 967,
    "ZWL": 932,
};