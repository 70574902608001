import axios from 'axios'
import React, {Component, useEffect, useState} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {Spinner} from 'react-activity';
import {connect} from 'react-redux';
import Login from './components/login/Login';
import BasePage from "./components/BasePage";
import RegisterPage from "./components/register/RegisterPage";
import {getUserByInvite, getUserByPasswordReset} from "./requests/UserRequests";
import RegistrationRequestPage from "./components/register/RegistrationRequestPage";
import ResetPasswordPage from "./components/reset-password-page/ResetPasswordPage";
import ResetPasswordFinishPage from "./components/reset-password-page/ResetPasswordFinishPage";
import RouterListener from "./components/RouterListener";
import './components/layout/Layout.scss';
import {IntlProvider} from "react-intl";
import {
    ThemeProvider,
} from '@material-ui/core/styles';

import MaintenanceLogin from './components/Maintenance/MaintenanceLogin/MaintenanceLogin';
import SystemMaintenance from './components/Maintenance/MaintenanceLogin/SystemMaintenance';

//fontawesome
import {library as faLibrary} from '@fortawesome/fontawesome-svg-core'
import {faMoneyCheck} from '@fortawesome/free-solid-svg-icons'
import {getAppConfiguration, getIsBlackoutActive, getBlackoutConfig} from "./requests/ConfigRequests";
import {setConfig} from "./reducers/AppReducer";
import DocumentTitle from "./DocumentTitle";
import AutoRegisterPage from "./components/register/auto-register/AutoRegisterPage";
import AutoRegisterConfirmInstructions from "./components/register/auto-register/AutoRegisterConfirmInstructions";
import AutoRegisterConfirm from "./components/register/auto-register/AutoRegisterConfirm";
import AutoRegisterAccounts from "./components/register/auto-register/AutoRegisterAccounts";
import AutoRegisterWaitingConfirmation from "./components/register/auto-register/AutoRegisterWaitingConfirmation";
import SystemError from "./components/SystemError";
import SiteErrorBoundary from "./error-boundaries/SiteErrorBoundary";
import {AuthStatus} from "./interfaces/AuthStatus";
import {setAuthStatus, setIsBlackoutActive} from "./actions/UserActions";
import {setToken, checkStatus, parseJson, returnNull} from "./fetch";
import {defaultTheme} from "./theme/default";
import {useParams} from "react-router-dom";
import {isStringNotEmptyOrNull} from "./utils";
import {clearSessionStorage} from "./reducers/RootReducer";

faLibrary.add(faMoneyCheck);

declare global {
    interface Window {
        gigya: any;
    }
}

interface LanguageMessages {
    [language: string]: { [key: string]: string }
}

function buildMessages(allMessages: LanguageMessages, language: string, country: string) {
    let messages: { [key: string]: string } = {};
    if (language == "") {
        language = "en";
    }
    let defaultMessages = allMessages["xx"] ?? {};
    messages = Object.assign(messages, defaultMessages);
    let languageMessages = allMessages[language] ?? {};
    messages = Object.assign(messages, languageMessages);
    if (country !== "") {
        let countryMessages = allMessages["xx-" + country] ?? {};
        messages = Object.assign(messages, countryMessages);
        let languageCountryMessages = allMessages[language + "-" + country] ?? {};
        messages = Object.assign(messages, languageCountryMessages);
    } else {
        let countryMessages = allMessages["xx-" + country] ?? {};
        messages = Object.assign(messages, countryMessages);
    }
    return messages;
}

function App(props) {

    let [forceRefresh, setForceRefresh] = useState(false);

    const fetchIsBlackoutActiveAsync = async () => {
        const blackoutConfig = await getBlackoutConfig();

        //const isBlackoutActive = await getIsBlackoutActive();
        let isBlackoutActive = await getIsBlackoutActive();
        props.setIsBlackoutActive(isBlackoutActive);

        if (isBlackoutActive && blackoutConfig) {
            const blackoutRedirectUrl = blackoutConfig.redirectUrl;

            if (blackoutRedirectUrl && blackoutRedirectUrl !== "") {
                window.location.href = blackoutRedirectUrl;
            }
        }
    };

    const executeAfterGigyaLoad = (results: any) => {
        props.setConfig(results[0]);

        if (results[1].data.isLoggedIn) {
            if (results[1].data.token) {
                clearSessionStorage();
                setToken(results[1].data.token, results[1].data.refreshToken);
            }
            props.setAuthStatus(AuthStatus.Authenticated);
        } else {
            props.setAuthStatus(AuthStatus.Unauthenticated);
        }
    }
    useEffect(() => {
        let configPromise = getAppConfiguration();
        let loginStatusPromise = axios.get<{ isLoggedIn: boolean, token: string, refreshToken: string }>("/api/login-status");
        const apiKeyPromise = axios.get('/api/configuration/gigyaapikey')
        Promise.all([configPromise, loginStatusPromise, apiKeyPromise]).then((results) => {
            const apiKey = results[2].data;

            if (apiKey && !window.gigya && !document.getElementById('gigyaScriptEl')) {

                const scriptTag = document.createElement("script");

                scriptTag.id = 'gigyaScriptEl';

                scriptTag.src = `https://cdns.us1.gigya.com/js/gigya.js?apikey=${apiKey}&lang=en_US`;

                scriptTag.async = false;

                scriptTag.onload = () => {
                    executeAfterGigyaLoad(results)
                }

                document.head.appendChild(scriptTag);
            }
            else {
                executeAfterGigyaLoad(results)
            }
        });


        fetchIsBlackoutActiveAsync();

    }, []);

    useEffect(() => {
      if (forceRefresh) {
        setForceRefresh(false);
        
        fetchIsBlackoutActiveAsync();
      }
    }, [forceRefresh]);

    function getLanguageMessages() {
        let messages = {};
        if (typeof props.config.languages !== "undefined") {
            messages = buildMessages(props.config.languages, props.language, props.country);
        }
        return messages;
    }

    let messages = getLanguageMessages();

    const isBlackoutActive = props.isBlackoutActive;

    if (forceRefresh === false && (isBlackoutActive === null || isBlackoutActive === undefined)) {
      setForceRefresh(true);
    }

    return (
        <SiteErrorBoundary>
            <ThemeProvider theme={defaultTheme}>

                <IntlProvider locale={props.language} messages={messages}>
                    <BrowserRouter>
                        <div>
                            <RouterListener/>
                            <ToastContainer/>
                            <Spinner animating={props.animating > 0} size={50} className="centered"/>
                            {props.loginChecked && <DocumentTitle/>}
                            {
                                (() => {
                                    switch (props.authStatus) {
                                        case AuthStatus.Waiting:
                                            return <div/>;
                                        case AuthStatus.Authenticated:
                                            return <Switch>
                                                {isBlackoutActive && <Route path={"/blackout"} component={SystemMaintenance} />}
                                                {isBlackoutActive && <Route path={"/"} component={SystemMaintenance} />}

                                                {!isBlackoutActive && <Route path="/" component={BasePage}/>}
                                            </Switch>;
                                        case AuthStatus.Failed:
                                        case AuthStatus.TimedOut:
                                        case AuthStatus.LoggedOut:
                                        case AuthStatus.Unauthenticated:
                                            return <Switch>

                                                {isBlackoutActive && <Route path={"/login/admin"} component={MaintenanceLogin} />}
                                                {!isBlackoutActive && <Route path={"/login"} component={Login} />}
                                                
                                                <Route path="/register/:token" render={props =>
                                                    <RegisterPage invite={props.match.params.token}
                                                                  getUserByInvite={getUserByInvite}/>
                                                }/>
                                                <Route path="/system-error" render={props =>
                                                    <SystemError/>
                                                }/>
                                                {/*<Route path="/registration-request" render={props =>*/}
                                                {/*    <RegistrationRequestPage/>*/}
                                                {/*}/>*/}
                                             <Route path="/auto-register/create" render={props =>
                                                   <AutoRegisterPage/>
                                                }/>
                                                <Route path="/request-password-reset" render={props =>
                                                    <ResetPasswordPage/>
                                                }/>
                                                <Route path="/reset-password/:id" render={props =>
                                                    <ResetPasswordFinishPage
                                                        getUserByPasswordReset={getUserByPasswordReset}
                                                        passwordReset={props.match.params.id}/>
                                                }/>
                                                <Route path="/auto-register/confirm-instructions" render={props =>
                                                    <AutoRegisterConfirmInstructions/>
                                                }/>
                                                <Route path="/auto-register/confirm/:token" render={props =>
                                                    <AutoRegisterConfirm/>
                                                }/>
                                                <Route path="/auto-register/waiting-confirmation" render={props =>
                                                    <AutoRegisterWaitingConfirmation/>
                                                }/>
                                                <Route path="/auto-register/accounts" render={props =>
                                                    <AutoRegisterAccounts/>
                                                }/>
                                                {isStringNotEmptyOrNull(process.env.REACT_APP_SSO_REDIRECT_URL as string) &&
                                                <Route path={"/"} render={props => {
                                                    window.location.href = process.env.REACT_APP_SSO_REDIRECT_URL as string;
                                                    return null;
                                                }}/>
                                                }
                                                {!isStringNotEmptyOrNull(process.env.REACT_APP_SSO_REDIRECT_URL as string) &&
                                                <Route path={"/"} component={Login}/>
                                                }
                                            </Switch>
                                    }
                                })()
                            }
                        </div>
                    </BrowserRouter>
                </IntlProvider>
            </ThemeProvider>
        </SiteErrorBoundary>
    );
}


export default connect<any, any, any, any>(state => ({
    animating: state.default.animating,
    authStatus: state.user.authStatus,
    language: state.user.language,
    country: state.user.country,
    config: state.app.config,
    isBlackoutActive: state.user.isBlackoutActive,
}), {
    setAuthStatus,
    setConfig,
    setIsBlackoutActive,
})(App);
