import React, { Component } from 'react';
//import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import BootstrapTable from 'react-bootstrap-table-next';
import UserImpersonate from './UserImpersonate';
import { Link } from 'react-router-dom';
import { canImpersonate, canManageUsers } from "../../selectors/PermissionSelectors";
import { connect, useDispatch } from "react-redux";
import { User } from "../../interfaces/User";
import { useIntl } from "react-intl";
import * as types from '../../constants/ActionTypes';
import {
    updateState,
    requestErrored,
    loginFailed
} from  '../../actions/CommonActions';

function formatColumn(cell) {
    if (cell) {
        return (`${''}${cell}${''}`);

        // return (`${'<div class="truncate"><span>'}${cell}${'</span></div>'}`);
    }
}

function impersonateFormatter(cell, row) {   // String example
    return <UserImpersonate user={row} />;
};

function formatStatusColumn(cell) {
    return <_StatusDisplay status={cell} />;
}

function _StatusDisplay(props: { status: string }) {
    const intl = useIntl();
    const f = id => intl.formatMessage({ id: id });
    if (props.status.toLowerCase() === 'active') {
        return (<span style={{ color: "#00b700" }}>{f("edit_user.status.active")}</span>);
    }
    if (props.status.toLowerCase() === 'deactive') {
        return (<span style={{ color: "red" }}>{f("edit_user.status.deactive")}</span>);
    }
    if (props.status.toLowerCase() === 'locked') {
        return (<span style={{ color: "red" }}>{f("edit_user.status.locked")}</span>);
    }
    if (props.status.toLowerCase() === 'waiting-confirmation') {
        return (<span style={{ color: "red" }}>{f("edit_user.status.waiting_confirmation")}</span>);
    }
    return <span />;
}

function nameColumn(cell, row) {

    if (row.first_name || row.last_name) { 
        return (`${''}${row.first_name} ${row.last_name}${''}`);
    }
}

function profileColumn(cell, row) {
    return <_ProfileLabel status={cell} />
}

function _ProfileLabel(props: { status: string }) {
    const intl = useIntl();
    const f = id => intl.formatMessage({ id: id });


    if (props.status === 'SoldTo') {
        return (<span>{f("edit_user.primary_account_type.sold_to")}</span>);
    }
    if (props.status === 'Payer') {
        return (<span>{f("edit_user.primary_account_type.payer")}</span>);
    }
    return <span />;
}


function accountsColumn(cell, row) {
    let accountNumbers: string[] = [];
    row.accounts.forEach((a) => accountNumbers.push(a.primaryAcct));
    if (accountNumbers.length > 0) {
        return (`${'<div class="truncate"><span>'}${accountNumbers.join(", ")}${'</span></div>'}`);
    }
}

function UserList(props) {
    const intl = useIntl();
    const f = id => intl.formatMessage({ id: id });

    function userName(cell, row: User) {
        if (cell) {
            if (props.canManageUsers) {
                return <Link to={`/settings/users/${row.user_id}`}>{cell}</Link>;
            } else {
                return cell;
            }
        }
    }
    const columns = [{
        headerClasses: "column-header",
        dataField: 'login',
        text: f("manage_users.table.impersonate"),
        formatter: impersonateFormatter,
        hidden: !props.canImpersonate,
        align: "center",
        headerAlign: 'center',
        style: { width: '155px' }
    }, {
        headerClasses: "column-header",
        dataField: 'login',
        text: f("manage_users.table.user_id"),
        formatter: userName,
        align: "center",
        headerAlign: 'center',
        style: { width: '55px' }

        }, {
        headerClasses: "column-header",
        text: f("manage_users.table.name"),
        formatter: nameColumn,
        classes: "truncate",
        align: "center",
        headerAlign: 'center'

        }, {
        headerClasses: "column-header",
        dataField: 'company',
        text: f("manage_users.table.company"),
        formatter: formatColumn,
        align: "center",
        headerAlign: 'center'

        }, {
        headerClasses: "column-header",
        dataField: 'email',
        text: f("manage_users.table.email"),
        formatter: formatColumn,
        align: "center",
        headerAlign: 'center'

        }, {
        headerClasses: "column-header",
        dataField: 'status',
        text: f("manage_users.table.status"),
        formatter: formatStatusColumn,
        align: "center",
        headerAlign: 'center'

        }, {
        headerClasses: "column-header",
        dataField: 'primary_account_type_id',
        text: f("manage_users.table.profile"),
        formatter: profileColumn,
        align: "center",
        headerAlign: 'center'

    }];

    const defaultSorted = [{
        dataField: 'login',
        order: 'desc'
    }];
    const defaultEmptyDataMessage = () => {
        return "There is no data to display";
    }
    return (
        <div>

            <BootstrapTable
                bootstrap4
                keyField="login"
                data={props.users}
                noDataIndication={defaultEmptyDataMessage}
                columns={columns}
                headerClasses="column-header"
                defaultSorted={defaultSorted}
                bodyClasses="wa-bootstrap-small-text"
              //  wrapperClasses="wa-bootstrap-table-container custom-table-header"
                classes={
                    "wa-bootstrap-table-container custom-table-header"
                }
            />
            {/* 
            <BootstrapTable data={props.users} headerStyle={{background: '#DFECF5'}}
                            tableContainerClass={'wa-bootstrap-table-container'}
                            tableBodyClass='wa-bootstrap-small-text'>
                {props.canImpersonate &&
                <TableHeaderColumn width="50" className="headerColumnColor" dataAlign="center" dataField="login"
                                   dataFormat={impersonateFormatter}>
                    {f("manage_users.table.impersonate")}
                </TableHeaderColumn>}
                <TableHeaderColumn width="65" className="headerColumnColor" dataAlign="center" dataField="login"
                                   dataFormat={userName} isKey>
                    {f("manage_users.table.user_id")}
                </TableHeaderColumn>
                <TableHeaderColumn width="55" className="headerColumnColor" dataAlign="center"
                                   dataFormat={nameColumn}>
                    {f("manage_users.table.name")}
                </TableHeaderColumn>
                <TableHeaderColumn width="55" className="headerColumnColor" dataAlign="center" dataField="company"
                                   dataFormat={formatColumn}>
                    {f("manage_users.table.company")}
                </TableHeaderColumn>
                <TableHeaderColumn width="55" className="headerColumnColor" dataAlign="center" dataField="email"
                                   dataFormat={formatColumn}>
                    {f("manage_users.table.email")}
                </TableHeaderColumn>
                <TableHeaderColumn width="55" className="headerColumnColor" dataAlign="center" dataField="status"
                                   dataFormat={formatStatusColumn}>
                    {f("manage_users.table.status")}
                </TableHeaderColumn>
                <TableHeaderColumn width="55" className="headerColumnColor" dataAlign="center" dataField={"primary_account_type_id"}
                                   dataFormat={profileColumn}>
                    {f("manage_users.table.profile")}
                </TableHeaderColumn>
               
            </BootstrapTable> */}
        </div>
    );
}

export default connect<any, any, any, any>((state, props) => ({
    canManageUsers: canManageUsers(state),
    canImpersonate: canImpersonate(state)
}))(UserList);
