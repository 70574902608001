import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class ExclusionItem extends Component<any, any> {
  
  constructor(props){
    super(props); 
    
    this._deleteItem = this._deleteItem.bind(this);
  }
  
  _deleteItem(){
    this.props.onDelete(this.props.index); 
  }
  
  render() {
    return (
      <div className="row no-gutters">
        <div className="col-4">
          <span>{this.props.value}</span>
        </div> 
        <div className="col-4 flex">
          <div className={"payment-type-actions"}>
          <span role={"button"} className={"font-button-icon"} onClick={this._deleteItem}>
            <FontAwesomeIcon icon={"trash"} /> Delete
          </span>
          </div>
        </div>
      </div>
    );
  }
}

export default ExclusionItem;