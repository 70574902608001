import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import BootstrapTable from 'react-bootstrap-table-next';
import { amountFormatter, payerFormatter, reasonFormatter, soldToFormatter } from '../../common/invoiceHelpers';
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { trimLeadingZeroes } from "../../utils";
import { getSelectedPaymentOption } from "../../selectors/Selectors";
import { extractLastCardNumbers } from "../../actions/InvoiceActions";
//import { isNullOrUndefined } from 'util';
import Logo from "../Logo";
interface PaymentStatus {
    document_number_finance: string;
    authorization_reference_code: string;
}



function PaymentStatus(props) {
    const intl = useIntl();
    const f = id => intl.formatMessage({ id: id });
    const statuses = props.paymentStatuses;
    const successMessage = statuses?.filter(_ => _.message_type === 'S').map(_ => _.message_line_string).join("; ");
    const errorMessage = statuses?.filter(_ => _.message_type === 'E').map(_ => _.message_line_string).join("; ");
    const success = successMessage.length > 0;
    let paymentStatus: null | PaymentStatus = null;
    if (success) {
        paymentStatus = statuses[0];
    }
    const alertClass = successMessage.length > 0 ? 'alert alert-success' : 'alert alert-danger';
    const documentNumber = paymentStatus?.document_number_finance ?? '';
    const referenceCode = paymentStatus?.authorization_reference_code ?? '';
    const top = 'Top';
    const [logo, setLogo] = useState("");
    const [isPrint, setIsPrint] = useState(false);
    const columns = [{
        headerClasses: "column-header",
        dataField: 'billing_document_number',
        text: f("payment_status.table.document_number_header"),
        formatter: trimLeadingZeroes,
        headerAlign: "center",
        align: "center",
    }, {
        headerClasses: "column-header",
        dataField: 'open_amount',
        text: f("payment_status.table.invoice_amount_header"),
        formatter: amountFormatter,
        headerAlign: "center",
        align: "center",
    }, {
        headerClasses: "column-header",
        dataField: 'amount_to_process',
        text: f("payment_status.table.paid_header"),
        formatter: amountFormatter,
        headerAlign: "center",
        align: "center",
    }, {
        headerClasses: "column-header",
        dataField: 'sold_to',
        text: f("payment_status.table.sold_to_header"),
        formatter: soldToFormatter,
        headerAlign: "center",
        align: "center",
    }, {
        headerClasses: "column-header",
        dataField: 'payer',
        text: f("payment_status.table.payer_header"),
        formatter: payerFormatter,
        headerAlign: "center",
        align: "center",
    },
        //{
        //headerClasses: "column-header",
        //dataField: 'payer',
        //text: f("payment_status.table.partial_payment"),
        //formatter: reasonFormatter,
        //headerAlign: "center",
        //align: "center",
        //}
    ];

    const defaultSorted = [{
        dataField: 'billing_document_number',
        order: 'desc'
    }];
    useEffect(() => {
        // Check if the component is being viewed in print mode
        const mediaQueryList = window.matchMedia('print');
        const handleMediaQueryChange = (e) => setIsPrint(e.matches);

        mediaQueryList.addListener(handleMediaQueryChange);

        // Set initial value
        setIsPrint(mediaQueryList.matches);

        return () => {
            // Cleanup event listener
            mediaQueryList.removeListener(handleMediaQueryChange);
        };
    }, []);


    let invoices = Object.keys(props.invoices).map((key) => {
        let invoice = props.invoices[key];
        let details = props.details[key];
        return {

            billing_document_number: invoice.billing_document_number ?? invoice.document_number_finance,
            document_number_finance: invoice.document_number_finance,
            open_amount: invoice.open_amount,
            amount_to_process: success ? details.amount : '',
            sold_to: invoice.soldto_number,
            payer: invoice.payer_number,
            currency_key: invoice.currency_key,
            reason: (props.sapDetails?.payment_reason_codes ?? []).find(r => r.reason_code === details.reason),
            reason_message: details.comment,
            zzcustom: invoice.zzcustom,
        };
    });
    return (
        <div>
            <div className="row d-flex" style={{ "marginBottom": "50px" }}>
                <div className="mtb-3 d-md-none d-print-block col-6 ">
                    <Logo src="logo" alt="Company Logo" height={120} />
                </div>
                <div className="mtb-3 d-md-none text-right d-print-block col-6">
                    <h3 className="d-print-block m-0" style={{ "fontSize": "20px" }}>
                        <strong>Payment Receipt</strong>
                    </h3>
                </div>
            </div>

            <div className={alertClass}>
                <strong><FormattedMessage id={"status"} defaultMessage={"Status"} />: </strong> {success ? <FormattedMessage id={"payment.success_message"} defaultMessage={"Payment Success"} /> : <FormattedMessage id={"payment.error_message"} defaultMessage={"Error Processing Payment"} />} <br />
                <strong><FormattedMessage
                    id={"payment_status.document_number"} />:</strong> {documentNumber.replace(/^0+/, '')}
                <br />
                <strong><FormattedMessage
                    id={"payment_status.reference_code"} />:</strong> {referenceCode.replace(/^0+/, '')}
                <br />
                {props.paymentCard &&
                    <React.Fragment><strong><FormattedMessage id={"payment_status.payment_method_label"} />: </strong>
                        <FormattedMessage
                            id={"payment_status.payment_method"}
                            values={{
                                type: props.paymentCard.payment_card_type,
                                numbers: extractLastCardNumbers(props.paymentCard.payment_card_token, '-')
                            }}
                        /></React.Fragment>} <br />
                {/*<strong><FormattedMessage id={"payment_status.processed_by"}/>:</strong> {props.currentUser.login} <br/>*/}
                <strong><FormattedMessage id={"payment_status.processed_date"} />:</strong> <FormattedDate
                    value={Date.now()} />
            </div>
            <br />


            <BootstrapTable
                bootstrap4
                keyField="billing_document_number"
                data={invoices}
                columns={columns}
                headerStyle={{
                    background: "#DFECF5",
                    height: "40px",
                    borderCollapse: "collapse",
                }}
                headerClasses="column-header"
                defaultSorted={defaultSorted}
              //  bodyClasses="wa-bootstrap-body-style"
                classes={
                    "wa-bootstrap-table-container custom-table-header"
                }
            />
            <div>
                {/* <BootstrapTable data={invoices} scrollTop={top} headerStyle={{ background: '#DFECF5', height: '40px' }} containerClass={'table-responsive-md'}>
                    <TableHeaderColumn className="headerColumnColor" headerAlign="center" dataAlign="center"
                        dataField="billing_document_number" isKey dataFormat={trimLeadingZeroes}>
                        <FormattedMessage id={"payment_status.table.document_number_header"} />
                    </TableHeaderColumn>
                    <TableHeaderColumn className="headerColumnColor" headerAlign="center" dataAlign="right"
                        dataField="open_amount" dataFormat={amountFormatter}>
                        <FormattedMessage id={"payment_status.table.invoice_amount_header"} />
                    </TableHeaderColumn>
                    <TableHeaderColumn className="headerColumnColor" headerAlign="center" dataAlign="right"
                        dataField="amount_to_process" dataFormat={amountFormatter}>
                        <FormattedMessage id={"payment_status.table.paid_header"} />
                    </TableHeaderColumn>
                    <TableHeaderColumn className="headerColumnColor" headerAlign="center" dataAlign="center"
                        dataField="sold_to" dataFormat={soldToFormatter}>
                        <FormattedMessage id={"payment_status.table.sold_to_header"} />
                    </TableHeaderColumn>
                    <TableHeaderColumn className="headerColumnColor" headerAlign="center" dataAlign="center"
                        dataField="payer" dataFormat={payerFormatter}>
                        <FormattedMessage id={"payment_status.table.payer_header"} />
                    </TableHeaderColumn>
                    <TableHeaderColumn className="headerColumnColor" headerAlign="center"
                        dataField="payer" dataFormat={reasonFormatter}>
                        <FormattedMessage id={"payment_status.table.partial_payment"} />
                    </TableHeaderColumn>
                </BootstrapTable> */}
                <div className={"row d-print-none"} style={{ "marginTop": "20px" }}>
                    <div className={"col"}>
                        <p className={"mt-2 print-details"}><FormattedMessage id={"payment_status.message"} />
                        </p>
                        <button className={"btn btn-primary buttonColor buttonsize"} style={{ backgroundColor: "#336699" }}
          onClick={() => window.print()}>{f("print")}</button>
                    </div>
                </div>
                <div className={"row d-print-text-none"} style={{ "marginTop": "30px" }}>
                    <div className="col">
                        <p className="mt-2 print-details">
                          <FormattedMessage id={"payment_confirmation.message"} />
                        </p>
                    </div>
                </div>

            </div>
        </div>
    );

}
export default connect<any, any, any, any>((store, props) => ({
    paymentStatuses: store.default.paymentStatuses,
    invoices: store.invoice.completed.invoices,
    details: store.invoice.completed.details,
    paymentCard: getSelectedPaymentOption(store),
    currentUser: store.default.currentUser,
    sapDetails: store.app.config.sap?.data,
    logo: store.app.config.style.login_logo,
}))(PaymentStatus);
