import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Footer } from "../../footer/Footer";
import SimpleHeader from "../../header/SimpleHeader";
import BackToLogin from "../../BackToLogin";

function mapStateToProps(state) {
    return {};
}

class AutoRegisterConfirmInstructions extends Component<any, any> {
    render() {
        return (
            <React.Fragment>
                <SimpleHeader />
                <div className={"container mt-5"}>
                    <div className="row mt-4">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mx-auto">
                            <div className="card">
                                <div className="row">
                                    <div className={"col-12"}>
                                        <p>
                                            You should receive a confirmation email shortly.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center flex mt-3">
                        <BackToLogin />
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

export default connect<any, any, any, any>(
    mapStateToProps,
)(AutoRegisterConfirmInstructions);