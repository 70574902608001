import React from "react";
import { Invoice, InvoiceWithKey } from "../../interfaces/invoice";
import {downloadExcelFile, getInvoiceBulkPDF} from "../../actions/InvoiceActions";
import {useDispatch} from "react-redux";
import {Dropdown} from "react-bootstrap";
import {useIntl} from "react-intl";


export function DownloadPdfZipButton(props: { invoiceSearchQuery: any }) {
    const dispatch = useDispatch();
    const intl = useIntl();

    function downloadZip() {
        try {
            getInvoiceBulkPDF(dispatch, props.invoiceSearchQuery);
        } catch (e) {
            return null;
        }
    }

    return (
        <Dropdown.Item onClick={downloadZip}>{intl.formatMessage({id:"invoice_export.all_pdfs"})}</Dropdown.Item>
    )
}

export function DownloadPdfZipButtonwithCurrency(props: { invoiceQueryforPDFandCSVandexcel: any }) {
    const dispatch = useDispatch();
    const intl = useIntl();

    function downloadZip() {
        try {
            getInvoiceBulkPDF(dispatch, props.invoiceQueryforPDFandCSVandexcel);
        } catch (e) {
            return null;
        }
    }

    return (
        <Dropdown.Item onClick={downloadZip}>{intl.formatMessage({id:"invoice_export.all_pdfs"})}</Dropdown.Item>
    )
}


export function DownloadExcelButton(props: { invoiceSearchQuery: any }) {
    const dispatch = useDispatch();
    const intl = useIntl();

    function downloadZip() {
        try {
            downloadExcelFile(dispatch, props.invoiceSearchQuery);
        } catch (e) {
            return null;
        }
    }

    return (
        <Dropdown.Item onClick={downloadZip}>{intl.formatMessage({id:"invoice_export.all_excel"})}</Dropdown.Item>
    )
}






export function DownloadExcelButtonwithCurrency(props: { invoiceQueryforPDFandCSVandexcel: any }) {
    const dispatch = useDispatch();
    const intl = useIntl();
 
    function downloadZip() {
        try {
            downloadExcelFile(dispatch, props.invoiceQueryforPDFandCSVandexcel);
            
        } catch (e) {
            return null;
        }
    }
 
    return (
        <Dropdown.Item onClick={downloadZip}>{intl.formatMessage({id:"invoice_export.all_excel"})}</Dropdown.Item>
    )
}