import React, {useContext, useState} from 'react';
import {Formik} from "formik";
import {BootstrapError, BootstrapField} from "../../../helpers/Field";
import * as yup from 'yup';
import {InvoicesContext} from "../../invoice/InvoiceDataManager";
import {validateAccount} from "../../../requests/UserRequests";
import { IntlShape, useIntl } from 'react-intl';

function schema(t: IntlShape) {
    return yup.object().shape({
        invoiceNumber: yup.string()
            .required(t.formatMessage({ id: "errors.invoice.required" })),
        invoiceAmount: yup.string()
            .test('amount_validation', t.formatMessage({ id: "errors.invalid_amount" }),
                (value) => {
                    if (!value) {
                        return true
                    }
                    // Convert number to string and check the format

                    return /^-?\d+(\.\d{2})?$/.test(value);
                }
            )
            .required(t.formatMessage({ id: "errors.amount.required" })),

        accountNumber: yup.string()
            .matches(/^[a-zA-Z0-9]*$/, t.formatMessage({ id: "errors.invalid_characters" }))
            .required(t.formatMessage({ id: "errors.account.required" })),
        registerToken: yup.string()
    })
}


function AutoLinkAccountForm(props) {
    let [requestComplete, setRequestComplete] = useState(false);
    const invoicesContext = useContext(InvoicesContext);
    const intl = useIntl();
    const f = id => intl.formatMessage({ id: id });
    return (
        <div>
            {!requestComplete && <Formik
                initialValues={{
                    registerToken: ""
                }}
              validationSchema={schema(intl)}
                onSubmit={(formProps, bag) => {
                  formProps.registerToken = sessionStorage.getItem("_registerToken") ?? ""
                    validateAccount(formProps).then(response => {
                      sessionStorage.setItem("_registerToken", response.token)
                      props.onSuccess(response.invoice);
                    }).catch((error) => {
                        if (error.response?.data?.token) {
                            sessionStorage.setItem("_registerToken", error.response?.data?.token)
                        }
                        if (error.response.status === 404) {
                            props.onError(f("account.error_no_account_found"))
                        } else {
                            if (error.response.data.key === "duplicate") {
                                props.onError(f("account.error.already_added"))
                            } else {
                                props.onError(f("account.error.too_many_attempts"))
                            }
                        }
                  });
              }}
              render={formProps =>
                  <div className="card">
                      <p>{f("account.header_message")}</p>
                      <form onSubmit={formProps.handleSubmit}>
                          <div className="row">
                              <div className={"col-12"}>
                                  <div className="form-group">
                                      <label>{f("account.billto_number")}</label>
                                      <BootstrapField type={"text"} name={"accountNumber"}/>
                                      <BootstrapError name={"accountNumber"}/>
                                  </div>
                                  <div className="form-group">
                                      <label>{f("invoice_search.search_options.invoice_number")}</label>
                                      <BootstrapField type={"text"} name={"invoiceNumber"} maxLength={10} />
                                      <BootstrapError name={"invoiceNumber"}/>
                                  </div>
                                  <div className="form-group">
                                      <label> {f("account.invoice_amount_label")}</label>
                                      <BootstrapField type={"text"} name={"invoiceAmount"}/>
                                      <BootstrapError name={"invoiceAmount"}/>
                                  </div>
                                  <div className={"form-group"}>
                                      <button type={"submit"} className={"btn btn-primary buttonColor buttonsize"}>{f("settings.user.linked_account_add_button")}</button>
                                  </div>
                              </div>
                          </div>
                      </form>
                  </div>
              }/>}
            {requestComplete && <React.Fragment>
              <div className="card">
                <div>
                  {f("account.request_message")}
                </div>
              </div>

            </React.Fragment>
            }
        </div>
    );
}

export default AutoLinkAccountForm;
