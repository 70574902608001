import axios from "../axios";
import { toast } from 'react-toastify';
import * as apiTypes from '../constants/ApiConstants';
import * as types from '../constants/ActionTypes';
import * as validationTypes from '../constants/ValidationConstants';

import {
    requestErrored,
    requestStarted,
    requestUnauthorized,
    updateState
} from './CommonActions';
import * as Yup from "yup";
import { InvoiceRequestBody } from "../interfaces/invoice";
import { StatementRequest, StatementResponse, Statement, StatementPDF } from "../interfaces/StatementDropdown";
import { AxiosResponse } from "axios";
import { AccountType } from "../interfaces/Accounts";
import { ReduxAction, SapResponse } from "../interfaces/utils";
import { saveAs } from "../common/FileSaver";
import { InvoiceSearchParameters } from "../interfaces/invoice"


export const getStatements = async (value): Promise<Statement[]> => {
    try {
        const formattedRequest = {
            function_module_name: "Z_CNBS_X_GET_STATEMENT_IDS",
            export: [
                {
                    name: "IV_ACCOUNT",
                      value: value,
                
                },
                
            ],
           
        };
        const response = await axios.post(apiTypes.GET_STATEMENTS_LIST, formattedRequest, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response.data as Statement[];
    } catch (error) {
        console.error('Error fetching statements:', error);
        throw error;
    }
};


export function setStatements(statements): ReduxAction<any> {
    return {
        type: types.GET_STATEMENTS_LIST,
        payload: statements,
    };
}
export function getStatementList(StatementId) {

}
export function updateInvoiceSearch(invoiceSearch: Partial<InvoiceSearchParameters>): ReduxAction<any> {
    return {
        type: types.UPDATE_INVOICE_SEARCH,
        payload: invoiceSearch,
    }
}

export const getStatementsPDF = async(StatementId,DocumentId ): Promise<StatementPDF[]> => {
    try {
        const formattedRequest = {
            FM_NAME: "Z_CNBS_X_GET_STATEMENT_IDS",
            export: [
                {
                    name: "IV_ACCOUNT",
                    value: StatementId,
             
                },
                {
                    name: "IV_DOCUMENT_ID",
                    value: DocumentId,
                  
                },

            ],

        };

        const response = await axios.post(apiTypes.GET_STATEMENTS_LIST, formattedRequest, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response.data as StatementPDF[];
    } catch (error) {
        console.error('Error fetching statements:', error);
        throw error;
    }
};
export function getStatementInvoicePDF(statementId) {
    return (dispatch) => {
        //  const docNumber = props.invoices[0].billing_document_number;
        const input = { 'statementId': statementId };

        axios
            .post(apiTypes.GET_STATEMENT_PDF, input)
            .then(response => {
                if (response.status === 200) {
                    // const file = new Blob([response.data], {type: 'application/pdf'});
                    // const fileURL = URL.createObjectURL(file, {name: `${docNumber}${'.pdf'}`});
                  
                    window.open(response.data, '_blank');
                }
            })
            .catch(err => {
                if (err && err.response && err.response.status === 401) {
                    requestUnauthorized(dispatch, err);
                } else {
                    requestErrored(dispatch, err, validationTypes.GET_INVOICE_PDF_ERROR);
                }
            });

    }
}


