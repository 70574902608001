export var FORCE_INVOICE_REFRESH = "FORCE_INVOICE_REFRESH";
export var SET_SELECTED_SOLD_TOS = 'SET_SELECTED_SOLD_TOS';
export var REMOVE_SOLD_TO_FROM_SELECTED = 'REMOVE_SOLD_TO_FROM_SELECTED';
export var ADD_SOLD_TO_TO_SELECTED = 'ADD_SOLD_TO_TO_SELECTED';
export var UPDATE_MULTI_SELECT_SOLD_TO = 'UPDATE_MULTI_SELECT_SOLD_TO';
export var SET_AUTH_STATUS = 'SET_AUTH_STATUS';
export const INVOICE_DUE_AMOUNT = 'INVOICE_DUE_AMOUNT';
export const PAYER_DUE_AMOUNT = 'PAYER_DUE_AMOUNT';
export const TOTAL_CREDIT_AMOUNT = 'TOTAL_CREDIT_AMOUNT';
export const TOTAL_PASTDUE_AMOUNT = 'TOTAL_PASTDUE_AMOUNT';
export const TOTAL_BALANCE_AMOUNT = 'TOTAL_BALANCE_AMOUNT';

// Common Actions
export const LOADING_INCREMENT = 'LOADING_INCREMENT';
export const LOADING_DECREMENT = 'LOADING_DECREMENT';
export const CALL_FAILED = 'CALL_FAILED';

// User Actions
export const LOGOUT = 'LOGOUT';
export const SET_LAST_URL = 'SET_LAST_URL';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_SELECTED_ACCOUNT = 'SET_SELECTED_ACCOUNT';
export const UN_AUTHORIZED = 'UN_AUTHORIZED';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const UPDATE_PASSWORD_RESPONSE = 'UPDATE_PASSWORD_RESPONSE';

// Invoice List Actions
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const SEARCHED_INVOICES = 'SEARCHED_INVOICES';

export const GET_PAYER_DETAILS_SUCCESS = 'GET_PAYER_DETAILS_SUCCESS';

export const POST_PAYMENT_REQUEST = 'POST_PAYMENT_REQUEST';
export const POST_PAYMENT_SUCCESS = 'POST_PAYMENT_SUCCESS';

export const GET_INVOICE_CURRENCY_SUCCESS = 'GET_INVOICE_CURRENCY_SUCCESS';

export const GET_PRIMARY_ACCOUNTS_SUCCESS = 'GET_PRIMARY_ACCOUNTS_SUCCESS';

export const SET_CONFIG = 'SET_CONFIG';

export const ADD_INVOICE_TO_SELECTED = 'ADD_INVOICE_TO_SELECTED';
export const REMOVE_INVOICE_FROM_SELECTED = 'REMOVE_INVOICE_FROM_SELECTED';
export const UPDATE_PAY_AMOUNT_FOR_INVOICE = 'UPDATE_PAY_AMOUNT_FOR_INVOICE';
export const UPDATE_REASON = 'UPDATE_REASON';
export const UPDATE_REASON_COMMENT = 'UPDATE_REASON_COMMENT';
export const COPY_SELECTED_INVOICES_TO_COMPLETED = 'MOVE_SELECTED_INVOICES_TO_COMPLETED';
export const COPY_DEPOSIT_DETAILS = 'COPY_DEPOSIT_DETAILS';
export const CLEAR_SELECTED_INVOICES = 'CLEAR_SELECTED_INVOICES';
export const SET_ACTIVE_INVOICE = 'SET_ACTIVE_INVOICE';

export const UPDATE_PAYMENT_CARD_OPTIONS = 'UPDATE_PAYMENT_CARD_OPTIONS';
export const UPDATE_INVOICE_LIST_REQUEST = 'UPDATE_INVOICE_LIST_REQUEST';
export const UPDATE_CURRENCY_CODE = 'UPDATE_CURRENCY_CODE';
export const UPDATE_PAYMENT_STATUS = 'UPDATE_PAYMENT_STATUS';
export const UPDATE_SELECTED_SECONDARY_ACCOUNT = 'UPDATE_SELECTED_SECONDARY_ACCOUNT';
export const ADD_SELECTED_SOLDTO = 'ADD_SELECTED_SOLDTO';
export const REMOVE_UN_SELECTED_SOLDTO = 'REMOVE_UN_SELECTED_SOLDTO';
export const REMOVE_ALL_SELECTED_SOLDTOS = 'REMOVE_ALL_SELECTED_SOLDTOS';
export const UPDATE_CVV = 'UPDATE_CVV';
export const UPDATE_SELECTED_CARD = 'UPDATE_SELECTED_CARD';
export const OVER_PAY_NOTE = 'OVER_PAY_NOTE';
export const INVOICE_SEARCH_TO_DATE = 'INVOICE_SEARCH_TO_DATE';
export const INVOICE_SEARCH_DUE_FROM_DATE = 'INVOICE_SEARCH_DUE_FROM_DATE';
export const INVOICE_SEARCH_DUE_TO_DATE = 'INVOICE_SEARCH_DUE_TO_DATE';
export const UPDATE_INVOICE_SEARCH = 'UPDATE_INVOICE_SEARCH';
export const UPDATE_IMPERSONATE_USER_ID = 'UPDATE_IMPERSONATE_USER_ID';
export const UPDATE_IMPERSONATE_USER_NAME = 'UPDATE_IMPERSONATE_USER_NAME';

export const SET_ACCOUNT_TYPE = 'SET_ACCOUNT_TYPE';
export const UPDATE_ACCOUNT_TYPES = 'UPDATE_ACCOUNT_TYPES';
export const UPDATE_PRIMARY_ACCOUNT_TYPES = 'UPDATE_PRIMARY_ACCOUNT_TYPES';

export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const SET_DUE_SELECT = 'SET_DUE_SELECT';
export const SET_STATEMENT_ID = 'SET_STATEMENT_ID';
export const SEARCH_INVOICE_STATUS = 'SEARCH_INVOICE_STATUS';
export const SEARCH_INVOICE_DUEDATE = 'SEARCH_INVOICE_DUEDATE';
export const SEARCH_INVOICE_TYPE = 'SEARCH_INVOICE_TYPE';
export const SEARCH_INVOICE_NUMBER = 'SEARCH_INVOICE_NUMBER';
export const SEARCH_ARTILCE_NUMBER = 'SEARCH_ARTILCE_NUMBER';
export const SEARCH_ARTILCE_DESCRIPTION = 'SEARCH_ARTILCE_DESCRIPTION';
export const INVOICE_SEARCH_PERIOD = 'INVOICE_SEARCH_PERIOD';
export const INVOICE_SEARCH_PERIOD_FROMTO = 'INVOICE_SEARCH_PERIOD_FROMTO';

// blackout actions
export const SET_IS_BLACKOUT_ACTIVE = 'SET_IS_BLACKOUT_ACTIVE';
export const SET_BLACKOUT_CONFIG = 'SET_BLACKOUT_CONFIG';
export const SET_FOOTER_LINKS = 'SET_FOOTER_LINKS';

//statement actions
export const GET_STATEMENTS_LIST = 'GET_STATEMENTS_LIST';
export const GET_STATEMENTS_PDF = 'GET_STATEMENTS_PDF';
export const SET_SELECT_MONTH = 'SET_SELECT_MONTH';
export const GET_SELECT_MONTH = 'GET_SELECT_MONTH';
export const GET_MONTH_SELECT = 'GET_MONTH_SELECT';
