import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';

const validRedirects = [
  "settings-page/registration-requests",
];

function isValidRedirect(lastUrl){
  for(let i = 0; i < validRedirects.length; i++){
    if(lastUrl.indexOf(validRedirects[i]) !== -1){
      return true;
    }
  }
  return false;
}

class LoginRedirectHandler extends Component<any, any> {
  
  componentDidMount() {
    if(isValidRedirect(this.props.lastUrl)){
      this.props.history.push(this.props.lastUrl);
    } else {
      this.props.history.push("/invoices");
    }
  }

  render() {
    return (
      <div>

      </div>
    );
  }
}

export default withRouter(connect<any, any, any, any>((store, props) => ({
  lastUrl: store.user.lastUrl,
}))(LoginRedirectHandler));
