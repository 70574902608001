import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {getUserCustomers, ResetPassword as ResetPasswordMethod} from '../../actions/UserActions';
import LinkedSapAccounts from "../user-management/LinkedSapAccounts";
import {
  deleteUserSapAccount,
  getExclusionList,
  getUserSapAccounts,
  updateExclusionList
} from "../../requests/UserRequests";
import ExclusionList from "../user-management/exclusions/ExclusionList";
import {canManageOwnSAPAccounts} from "../../selectors/PermissionSelectors";
import {FormattedMessage, useIntl} from 'react-intl';

let errorClass = valid => (valid ? '' : 'invalid');

function AccountSettingsPage(props:any){
  const t = useIntl();

  let [currentPassword, setCurrentPassword] = useState('');
  let [newPassword, setNewPassword] = useState('');
  let [confirmNewPassword, setConfirmNewPassword] = useState('');
  let [currentPasswordValid, setCurrentPasswordValid] = useState(true);
  let [newPasswordValid, setNewPasswordValid] = useState(true);
  let [confirmNewPasswordValid, setConfirmNewPasswordValid] = useState(true);
  
  let onResetClick = () => {
    if (validateFields()) {
      props.ResetPasswordMethod(currentPassword, newPassword, confirmNewPassword);
    }
  }

  let validateFields = () => {
    let isModelStateValid = false;
    setCurrentPasswordValid(currentPassword !== '');
    setNewPasswordValid(newPassword !== '');
    setConfirmNewPasswordValid(confirmNewPassword !== '');

    if (currentPassword !== '' && newPassword !== '' && confirmNewPassword !== '') {
      isModelStateValid = true;
    }
    return isModelStateValid;
  };

  let passwordChange = (e) => {
    if (e.currentTarget.name === 'currentPassword') {
      setCurrentPassword(e.target.value);
      setCurrentPasswordValid(e.target.value !== '');
    }
    if (e.currentTarget.name === 'newPassword') {
      setNewPassword(e.target.value);
      setNewPasswordValid(e.target.value !== '');
    }
    if (e.currentTarget.name === 'confirmNewPassword') {
      setConfirmNewPassword(e.target.value);
      setConfirmNewPasswordValid(e.target.value !== '');
    }
  };
  
  return (
      <div className="container">
        <div className={"row"}>
          <div className={"col"}>
            <div className={"card"}>
              <div className={"row"}>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <h5><FormattedMessage id={"settings.account.reset_password"}/></h5>
                  <div className="form-group">
                    <label className="col-form-label-sm text-secondary" htmlFor="first_name"><FormattedMessage id={"settings.account.reset_password_confirm_password"}/></label>
                    <input type="password" className={`form-control ${errorClass(currentPasswordValid)}`}
                           name="currentPassword" placeholder={t.formatMessage({id:'settings.account.reset_password_confirm_password_placeholder'})} onChange={passwordChange}/>
                    {currentPasswordValid ? '' : <h6 className="invalid-Text">
                      <small>Required.</small>
                    </h6>}
                  </div>
                  <div className="form-group">
                    <label className="col-form-label-sm text-secondary" htmlFor="lastName"><FormattedMessage id={"settings.account.reset_password_new_password"}/></label>
                    <input type="password" className={`form-control ${errorClass(newPasswordValid)}`}
                           name="newPassword"
                           placeholder={t.formatMessage({id:'settings.account.reset_password_new_password_placeholder'})}
                           onChange={passwordChange}/>
                    {newPasswordValid ? '' : <h6 className="invalid-Text">
                      <small>Required.</small>
                    </h6>}
                  </div>
                  <div className="form-group">
                    <input type="password" className={`form-control ${errorClass(confirmNewPasswordValid)}`}
                           name="confirmNewPassword" placeholder={t.formatMessage({id: 'settings.account.reset_password_new_password_confirm_placeholder'})} onChange={passwordChange}/>
                    {confirmNewPasswordValid ? '' : <h6 className="invalid-Text">
                      <small>Required.</small>
                    </h6>}
                  </div>
                  <div className="form-group text-center">
                    <button className="btn btn-primary buttonColor buttonsize" 
                            onClick={onResetClick}>
                      {t.formatMessage({id: 'settings.account.reset_password_button'})}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default connect<any, any, any, any>((store, props) => ({
  userId: store.default.currentUser.user_id,
  primaryAccountTypeId: store.default.currentUser.PrimaryAccountTypeId,
  accountTypes: store.default.accountTypes,
  canManageOwnSAPAccounts: canManageOwnSAPAccounts(store),
}), {
  getUserCustomers,
  ResetPasswordMethod,
})(AccountSettingsPage);
