export enum DocumentType {
    Invoice = "01",
}

export enum DocumentStatus {
    Open = "01",
    Complete = "02",
    OpenOrComplete = "03",
}

export interface InvoiceSearchParameters {
    document_type: DocumentType;
    status: DocumentStatus;
    date_from: string;
    date_to: string;
    due_date_from: string;
    due_date_to: string;
    currency_key: string;
    SoldTo_SearchParams: string[];
    filter: { filter_type: string, value: any }[];
    STATEMENT_ID: string;
    invoice_filter: string;
    

}

export interface InvoiceRequestBody {
    payer_data: any,
    soldto_data: any,
    search_parameters: InvoiceSearchParameters;
}

export interface Invoice {
    billing_document_number: string;
    clearing_date: any;
    clearing_document_number_finance: string;
    currency_key: string;
    days_in_arrears: number;
    document_date: string;
    document_number_finance: string;
    due_date: string;
    finance_document_only: "" | "X";
    finance_document_type: string;
    fiscal_year_of_the_relevant_invoice: number;
    item_is_a_payment?: "" | "X";
    line_item_in_the_relevant_invoice: number;
    open_amount?: number;
    paid_amount: number;
    payer_number: string;
    pdf_document_available: string;
    posting_date?: string;
    read_only_flag: "" | "X";
    reference_number: string;
    soldto_number: string;
    total_amount: number;
    STATEMENT_ID: string;
    // Used for customization core product shouldn't use this.
    zzcustom?: any;
    customer_number: string;
}

export interface InvoiceResponse {
    document_list: Invoice[];
    status: any;
}

export interface InvoiceWithKey extends Invoice {
    _key: string;
}
