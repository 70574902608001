import React, {Dispatch, useEffect} from 'react';
import {useLocation} from "react-router-dom";
import {addPaymentMethod, getPaymentResponse} from "../../../requests/TokenRequests";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {addSessionCard} from "../../../reducers/PaymentReducer";
import {getPayerAccount, getSelectedPrimaryAccount, getSelectedSecondaryAccount} from "../../../selectors/Selectors";
import {getPayerDetailsBuilder, updateSelectedCard} from "../../../actions/InvoiceActions";
import {PaymentCard} from "../../../interfaces/PayerDetails";
import {getAndClearSessionStorage} from "../../../utils";
import {PAYMENT_METHOD_DEFAULT, PAYMENT_METHOD_SAVE} from "./ManagePaymentMethods";
import { useHistory } from "react-router-dom";
import * as appConstants from '../../../constants/ApplicationConstants';
import { TraceLog } from '../../../interfaces/TraceLog';

export async function finalizeAddPaymentMethod(access_token: string, save_on_file: boolean, log:any ,make_default: boolean, dispatch, account_number: string, company_code: string) {
    let {paymentcard} = await getPaymentResponse(access_token);
    // paymentcard.card_validation_code = card.card_validation_code; // TODO this shouldn't be necessary.
    // We don't want to do this. pass payment card to props method
    if (save_on_file) {
        try {
          
            await addPaymentMethod(account_number, company_code, paymentcard,log, make_default);
        } catch (error:any) {
            if (error.message_number === 74) {
                let matches = error.message_string.match(/Payment card (CCINS) (.*) already exist, action ([0-9]*) not possible/);
                toast.error(`${matches[2]} already exists.`, {position: toast.POSITION.TOP_RIGHT});
            } else if (error.message_number === 76) {
                let matches = error.message_string.match(/Payment card (CCINS) (.*) already assigned to account ([0-9]*)/);
                toast.error(`${matches[2]} already assigned to account ${matches[3]}`, {position: toast.POSITION.TOP_RIGHT});
            } else {
                toast.error(error.message_string, {position: toast.POSITION.TOP_RIGHT});
            }
        }
    } else {
        //Add to session payment methods.
        await dispatch(addSessionCard(paymentcard, account_number,log));
    }
    return paymentcard;
}

export async function finalizePaymentMethodAndRefresh(accessToken: string, dispatch: Dispatch<any>, payer, primaryAccount, secondaryAccount, currentUser, save_on_file, log,make_default) {
    let payment_card: PaymentCard = await finalizeAddPaymentMethod(accessToken, save_on_file, log, make_default, dispatch, payer.PrimaryAcct, primaryAccount.CompanyCode);
    await dispatch(getPayerDetailsBuilder(primaryAccount, secondaryAccount, currentUser.primary_account_type_id));
    dispatch(updateSelectedCard(payment_card.payment_card_token));
}

export function FinalizePaymentProcess() {
    const location = useLocation();
    const history = useHistory();
    const search = new URLSearchParams(location.search);
    const accessToken = search.get("id");
    const dispatch = useDispatch();
    const payer = useSelector<any, any>(getPayerAccount);
    const primaryAccount = useSelector<any, any>(getSelectedPrimaryAccount);
    const secondaryAccount = useSelector<any, any>(getSelectedSecondaryAccount);
    const currentUser = useSelector<any, any>(state => state.default.currentUser);
    

    useEffect(() => {
        (async () => {
            if (accessToken) {
                const make_default = getAndClearSessionStorage(PAYMENT_METHOD_DEFAULT, false);
                const save_on_file = getAndClearSessionStorage(PAYMENT_METHOD_SAVE, false);
                let log:TraceLog={
                    id: "ADD_PAYMENT_CARD",
                    login:currentUser.email,
                    epayv: appConstants.APP_VERSION,
                    usrag:navigator.userAgent
                }
                await finalizePaymentMethodAndRefresh(accessToken, dispatch, payer, primaryAccount, secondaryAccount, currentUser, save_on_file,log, make_default);
                history.replace(location.pathname);
            }
        })();
    }, [accessToken]);

    return (<></>);
}