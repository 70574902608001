import React, { Component, useEffect, useState } from "react";
import { getUserCustomers, setAccountType } from "../../actions/UserActions";
import { connect, useDispatch } from "react-redux";
import { clearSessionPaymentMethods } from "../../reducers/PaymentReducer";
import { canImpersonate } from "../../selectors/PermissionSelectors";
import { User } from "../../interfaces/User";
import * as types from '../../constants/ActionTypes';
import {
    setsearchedinvoiceStatus, setsearchedinvoiceDueDate, setsearchedinvoiceType,
    setsearchedInvoiceNumber, setsearchedArticleNumber, setsearchedArticleDescription,
    setinvoiceSearchedPeriod, setinvoiceSearchedPeriodFromTo, setsearchedInvoices, payerInvoiceDueAmount
} from "../../actions/InvoiceActions";
import { updateState } from "../../actions/CommonActions";
interface UserImpersonateProps {
    impersonatedUserId?: string,
    user: User,
    currentUser: User,
    canImpersonate: boolean,
    getUserCustomers: Function,
    clearSessionPaymentMethods: Function,
    setAccountType: Function,
    setsearchedInvoiceNumber: Function,
    setsearchedinvoiceDueDate: Function,
    setinvoiceSearchedPeriodFromTo: Function,
    setsearchedinvoiceStatus: Function,
    setinvoiceSearchedPeriod: Function,
    setsearchedInvoices: Function,
    payerInvoiceDueAmount: Function,

}
function UserImpersonate(props: UserImpersonateProps) {
    const dispatch = useDispatch();

    const [isChecked, setIsChecked] = useState(false);
    useEffect(() => {
        const impersonatedUserId = props.impersonatedUserId;
        if (impersonatedUserId && impersonatedUserId === props.user.user_id) {
            setIsChecked(true);
        } else {
            setIsChecked(false);
        }
    }, [props.impersonatedUserId]);

    function onCheckClick() {
        let name = props.user.first_name + ' '+  props.user.last_name;
        if (props.user.user_id !== props.impersonatedUserId) {
            props.getUserCustomers(props.user.user_id, true);
            props.setAccountType(props.user.primary_account_type_id);
            props.setsearchedInvoices([]);
            props.setsearchedInvoiceNumber('');
            props.setsearchedinvoiceDueDate('', '');
            props.setinvoiceSearchedPeriodFromTo('', '');
            props.setsearchedinvoiceStatus('03');
            props.setinvoiceSearchedPeriod("all");
            props.payerInvoiceDueAmount(0);
            updateState(dispatch, types.UPDATE_IMPERSONATE_USER_NAME, name);
                  
            // setIsChecked(true);
        } else {
            props.getUserCustomers("", true);
            props.setAccountType(props.currentUser.primary_account_type_id);
            props.setsearchedInvoices([]);
            props.setsearchedInvoiceNumber('');
            props.setsearchedinvoiceDueDate('', '');
            props.setinvoiceSearchedPeriodFromTo('', '');
            props.setsearchedinvoiceStatus('03');
            props.setinvoiceSearchedPeriod("all");
            updateState(dispatch, types.UPDATE_IMPERSONATE_USER_NAME, '');

            // setIsChecked(false);
        }
        props.clearSessionPaymentMethods();
    };
    return (
       
        <div>
            {props.canImpersonate && <input
                className="rounded-circle"
                type="checkbox"
                onChange={onCheckClick}
                checked={isChecked}
                id={props.user.user_id}
                name={props.user.user_id}
            // disabled={user.accounts.length <= 0}
            />}
        </div>
    );
}
export default connect<any, any, any, any>((state, props) => ({
    impersonatedUserId: state.user_options.impersonatedUserId,
    currentUser: state.default.currentUser,
    canImpersonate: canImpersonate(state)
}), {
    getUserCustomers,
    clearSessionPaymentMethods,
    setAccountType,
    setsearchedInvoiceNumber,
    setsearchedinvoiceDueDate,
    setinvoiceSearchedPeriodFromTo,
    setsearchedinvoiceStatus,
    setinvoiceSearchedPeriod,
    setsearchedInvoices,
    payerInvoiceDueAmount
})(UserImpersonate);