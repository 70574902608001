/* eslint-env browser */
/* eslint-disable no-console */
import React, { Component, useEffect, useState } from 'react';
import { NavLink, withRouter, Link, useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Logout, getUserCustomers } from '../../actions/UserActions';
import NavAccountSelect from "./NavAccountSelect";
import { hasDeposits } from "../../selectors/Selectors";
import Drawer from '@material-ui/core/Drawer';
import MenuDrawer from "./MenuDrawer";
import Logo from "../Logo";
import { ThunkFunction } from "../../interfaces/utils";
import { RootState } from "../../reducers/RootReducer";
import { FormattedMessage, useIntl } from 'react-intl';
import { useUser } from "../../hooks/CommonHooks";
import { isThirdPartyUser } from "../../helpers/UserHelpers";
import { useCanMakeDeposits } from "../../selectors/ConfigSelectors";

interface HeaderProps {
    hasDeposits: boolean;
    loginName: string,
    impersonatedUserId: string,
    impersonatedUserName: string,
}

interface HeaderDispatchProps {
    getUserCustomers: ThunkFunction<typeof getUserCustomers>;
    logout: ThunkFunction<typeof Logout>;
}

export function Header(props: HeaderProps & HeaderDispatchProps) {
    const [collapsed, setCollapsed] = useState(true);
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);
    const history = useHistory();
    const user = useUser();
    const canDeposit = useCanMakeDeposits();
    const intl = useIntl();
    let collapsedClass = 'navbar-collapse webar-navbar-collapse';
    let settingsLink = '/settings/profile';
    if (isThirdPartyUser(user)) {
        settingsLink = '/settings/payment-methods';
    }

    if (collapsed) {
        collapsedClass = 'collapse navbar-collapse webar-navbar-collapse';
    }

    const navLinkLogoutClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setCollapsed(true);
        // this.setState({collapsed: true});
        props.logout();
        setTimeout(() => {
            history.push("/login");
            window.location.reload();
        }, 100);
    };

    const toggleDrawer = (isOpen) => {
        setDrawerIsOpen(isOpen);
    };

    return (
        <React.Fragment>
            <Drawer open={drawerIsOpen} onClose={() => toggleDrawer(false)}>
                <MenuDrawer onClose={() => toggleDrawer(false)} />
            </Drawer>
            <nav id="appHeader" className="webar-navbar navbar fixed-top navbar-expand-lg navbar-light">
                <div className="container">
                    <button className="navbar-toggler" type="button" onClick={() => toggleDrawer(true)}>
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className={"d-md-none"}>
                        <Logo height={50} />
                    </div>
                    <div className={collapsedClass} id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto" style={{ minHeight: "56px" }}>
                            <Logo height={50} />
                            <NavAccountSelect />
                        </ul>
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <NavLink to="/invoices" className="nav-link" activeClassName={"active"}>
                                    <span><FormattedMessage id="header.invoices" /></span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/invoice-history" className="nav-link" activeClassName={"active"}>
                                    {intl.formatMessage({ id: "invoice_search.label", defaultMessage: "Invoice History" })}
                                </NavLink>
                            </li>
                            {canDeposit && user?.user_role != 'internal' && <li className="nav-item">
                                <NavLink to="/deposits" className="nav-link" activeClassName={"active"}>
                                    <span><FormattedMessage id="header.deposits" /></span>
                                </NavLink>
                            </li>}
                            {/*<li className="nav-item ">*/}
                            {/*    <NavLink to="/help" className="nav-link" activeClassName={"active"}>*/}
                            {/*        <span><FormattedMessage id="header.help" /></span>*/}
                            {/*    </NavLink>*/}
                            {/*</li>*/}
                            <li className="nav-item ">
                                <Link to={settingsLink} >
                                    <span style={{ marginTop:'6px' }} className="fas fa-cog fa-2x " />
                                </Link>
                            </li>
                            {user?.third_party_id === "" && <li className="nav-item "
                                title={"You are logged in as " + (props.loginName ? props.loginName : '')}><div>
                                    <a className="nav-link" href="" id="header-logout-button"
                                        onClick={navLinkLogoutClick}>
                                        <span><FormattedMessage id="header.logout" /></span>
                                    </a>
                                    <span className={`header-user-detail p-0 pl-2 small`}>
                                        {!props.impersonatedUserName && intl.formatMessage({ id: "header.login_as", defaultMessage: "Logged in as " }) + (props.loginName ? props.loginName : '')}
                                        {props.impersonatedUserName && intl.formatMessage({ id: "header.working_as", defaultMessage: "Impersonating as  " }) + '  ' +  (props.impersonatedUserName ? props.impersonatedUserName : '')}
                                    </span>
                                </div>


                            </li>}
                        </ul>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    );
}

const getUserName = user => user ? user.login : "";

export default withRouter(connect((state: RootState, props) => ({
    loginName: getUserName(state.default.currentUser),
    hasDeposits: hasDeposits(state),
    impersonatedUserId: state.user_options.impersonatedUserId,
    impersonatedUserName: state.user_options.impersonatedUserName,
}), {
    getUserCustomers,
    logout: Logout,
})(Header));
