import React, {Component} from 'react';

function Help() {
    return (
        <div className="container">
        </div>);
}


export default Help;
