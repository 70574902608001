import React, {
  ReactEventHandler,
  ReactHTMLElement,
  useEffect,
  useState,
} from "react";
import { Link, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Flatpickr from "react-flatpickr";
import { toast } from "react-toastify";
import moment from "moment";
// import 'react-select/dist/react-select.css';
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import "react-activity/dist/react-activity.css";
import "../../../node_modules/flatpickr/dist/themes/material_blue.css";
import {
  getInvoiceQuery,
  getInvoiceTotal,
  getSelectedInvoiceKeys,
} from "../../selectors/Selectors";
import { Invoice, InvoiceWithKey } from "../../interfaces/invoice";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/RootReducer";
import {
  clearSelectedInvoices,
  updateInvoiceSearch,
  updateInvoiceSearchDueToDate,
  updateSelectedCard,
  setDueSelect,
    setSelectedCurrency,
    setStatementId,
    getInvoicePDF, getInvoiceBulkPDF
} from "../../actions/InvoiceActions";
import { getisPaymentDisable } from "../../requests/ConfigRequests";
import {
  setisPaymentDisable,
  setMessageForPaymentDisable,
  getMessageForPaymentDisable,
} from "../../actions/CommonActions";
import { getStatements, setStatements, getStatementList, getStatementsPDF, getStatementInvoicePDF } from "../../actions/StatementActions";
import { InvoiceList } from "./InvoiceList";
import { getPastYears } from "../../common/invoiceHelpers";
import { ThunkFunction } from "../../interfaces/utils";
import { FormattedMessage, useIntl } from "react-intl";
import { CurrencySelector, useHasMultiCurrency } from "./CurrencySelector";
import { useCalendarTranslation } from "../../hooks/CalendarTranslation";
import { SecondarySelect } from "../SecondarySelect";
import { DashboardDetails } from "../dashboard/DashboardPage";
import { useInvoices } from "./InvoiceDataManager";
import { useCanMakePayments } from "../../selectors/ConfigSelectors";
import { DocumentStatus } from "../../interfaces/invoice";
import { CurrencyDisplay } from "../../common/CurrencyDisplay";
import { useUser } from "../../hooks/CommonHooks";
import { boolean } from "yup";
import { convertArrayToObject } from "../../utils";
import { StatementResponse } from "../../interfaces/StatementDropdown";
import { TableItem} from "../../interfaces/StatementTypes";
interface InvoicePageProps {
  accountType: any;
  invoiceSearchDueDateTo: any;
  invoiceTotal: any;
  secondaryAccounts: any;
  selectedInvoicesKeys: any;
  selectedCurrency: string;
  invoiceSearchQuery: any;
  dueSelect: any;
    selectedSoldTos: any;
    statementId: string;
    invoices: Invoice[];
}


interface InvoiceDispatchProps {
  updateSelectedCard: ThunkFunction<typeof updateSelectedCard>;
  clearSelectedInvoices: ThunkFunction<typeof clearSelectedInvoices>;
  updateInvoiceSearchDueToDate: ThunkFunction<
    typeof updateInvoiceSearchDueToDate
    >;
    updateInvoiceSearch: ThunkFunction<
    typeof updateInvoiceSearch
    >;
    getStatementInvoicePDF: ThunkFunction<typeof getStatementInvoicePDF>;
    getInvoiceBulkPDF: ThunkFunction<typeof getInvoiceBulkPDF>;
    setDueSelect: ThunkFunction<typeof setDueSelect>;
    statements: ThunkFunction<typeof setStatements>;
  setStatementId: ThunkFunction<typeof setStatementId>;
  dispatch: any;
}
interface Statement {
    STATEMENT_ID: string | null;
    DESCRIPTION: string;
}

function _Invoices(props: InvoicePageProps & InvoiceDispatchProps) {
  const intl = useIntl();
  let history = useHistory();
  const user = useUser();
  const f = (id) => intl.formatMessage({ id: id });
  const calIl8n = useCalendarTranslation();
  const hasMultiCurrency = useHasMultiCurrency();

  //This is called by default. The method calls InvoiceDataManager with currency selected(USD by default)
  //The invoices are stored in state of the page and used between this page and invoicelist
  const invoices = useInvoices(props.selectedCurrency);
  const dispatch = useDispatch();
  //const [invoices, updateInvoices, clearInvoices] = useContext(InvoicesContext);
  let [config, setConfig] = useState(null);
  let [selectedDueOption, setSelectedDueOption] = useState<
    "" | "all" | "all-paid" | "due-up-to"
  >("");
  let [selectedYear, setSelectedYear] = useState(moment().year());
  const canMakePayment = useCanMakePayments();
  const [showSummary, setShowSummary] = useState(true);
  const [country, setcountry] = useState("en");
    const [DisableMessage, setDisableMessage] = useState("");
    
  var paymentDisableMessage;
  const language = useSelector<RootState, string>(
    (state) => state.user.language
    );
    //const [stateStatements, setStateStatements] = useState([]);
    const [selectedMonthId, setSelectedMonthId] = useState("");
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [selectMonth,setSelectMonth] = useState("");
    const [selectedMonth, setSelectedMonth] = useState("");
    let [selectedStatementId, setSelectedStatementId] = useState("");
  var paymentDisableKey =
        language.toString() + "_paymentDisableMessageText".trim();
   // const [stateStatements, setStateStatements] = useState<Statement[]>([]);
    const [selectedStatement, setSelectedStatement] = useState<{
        STATEMENT_ID: string | null;
        DESCRIPTION: string;
    }>({ STATEMENT_ID: null, DESCRIPTION: '' });

    const [stateStatements, setStateStatements] = useState<Statement[]>([]);

    const [statementList, setStatementList] = useState(props.invoices);
  paymentDisableMessage = getMessageForPaymentDisable(paymentDisableKey);
   
      
  useEffect(() => {
    history.push("/invoices");
      getisPaymentDisable().then((_) => setisPaymentDisable(_));
      const fetchStatements = async () => {
          try {
              const value = props.selectedSoldTos[0];

              const statementResponse = await getStatements(value);

              setStateStatements(statementResponse);

              if (statementResponse.length > 0) {
                  setSelectedStatement(statementResponse[0]);
              }
          } catch (error) {
              console.error('Error fetching statements:', error);
          }
      };


      fetchStatements();


      //getStatements()
      //    .then(statements => {
      //        setStatements(statements);
      //    })
      //    .catch(error => {
      //        console.error('Error fetching statements:', error);
      //    });
    props.updateSelectedCard("");
    setSelectedDueOption(props.dueSelect);
    //dispatch(setSelectedCurrency("USD"));
    let defaultToDate =
      props.invoiceSearchDueDateTo !== ""
        ? props.invoiceSearchDueDateTo
        : moment(new Date()).format("MM-DD-YYYY");
    let status = DocumentStatus.Open;
    if (props.dueSelect === "due-up-to") {
      defaultToDate =
        props.invoiceSearchDueDateTo !== ""
          ? props.invoiceSearchDueDateTo
          : moment(new Date()).format("MM-DD-YYYY");
    } else if (props.dueSelect === "all-paid") {
      status = DocumentStatus.Complete;
    } else if (props.dueSelect === "all" || props.dueSelect === "") {
      defaultToDate = "";
      status = DocumentStatus.Open;
    }

    if (props.dueSelect === "all-paid") {
      dispatch(
        updateInvoiceSearch({
          status: status,
          //due_date_to: '',
          date_from: moment()
            .year(selectedYear)
            .startOf("year")
            .format("MM-DD-YYYY"),
          date_to: moment()
            .year(selectedYear)
            .endOf("year")
            .format("MM-DD-YYYY"),
        })
      );
    } else {
      dispatch(
        updateInvoiceSearch({
          status: status,
          //due_date_to: defaultToDate,
          date_to: "",
          date_from: "",
        })
      );
    }
  }, [props.dueSelect, selectedYear, props.selectedCurrency]);
    //useEffect(() => {
    //    fetchStatements();
    //}, [props.selectedSoldTos]); // Add any other dependencies as needed

    let onContinueClick = (event) => {
    if (props.selectedInvoicesKeys.length === 0) {
      event.preventDefault();
      toast.warn(f("invoices_page.error.no_invoices_selected"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (props.invoiceTotal <= 0) {
      event.preventDefault();
      toast.warn(f("invoices_page.error.credit_amount_validation"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
  };
  function handleSelectedDates(selectedDates) {
    props.clearSelectedInvoices();
    let toDate = "";
    if (selectedDates && selectedDates.length > 0) {
      toDate = moment(selectedDates[0]).format("MM-DD-YYYY");
    } else {
      toDate = moment([new Date().getFullYear(), 11, 31]).format("MM-DD-YYYY");
    }
    props.updateInvoiceSearchDueToDate(toDate);
    }
    const handleMonthChange = (selectedStatementId: string) => {
        props.clearSelectedInvoices();
        const selectedStatement = stateStatements.find(
            (item) => item.STATEMENT_ID === selectedStatementId
        );

        const description = selectedStatement ? selectedStatement.DESCRIPTION || '' : '';

        setSelectedStatement({
            STATEMENT_ID: selectedStatementId,
            DESCRIPTION: description,
        });
        props.setStatementId(selectedStatementId)
        setSelectedStatementId(selectedStatementId);
        updateInvoiceSearchCriteria(selectedStatementId);


    };
    const updateInvoiceSearchCriteria = (selectedStatementId: string) => {
   
        if (selectedStatementId) {
  
            props.updateInvoiceSearch({
                filter: [{ filter_type: "95", value: selectedStatementId }]

            });
        
        }
        else props.updateInvoiceSearch({ filter: [{ filter_type: "90", value: selectedStatementId }]})
        
    };
    const handleButtonClick = (selectedStatementId) => {
       
        const StatementId = selectedStatementId;
        if (StatementId && StatementId.length > 0) {
            props.getStatementInvoicePDF(StatementId)
        }
            
        
    }

  let handleDueChange = (event) => {
    props.clearSelectedInvoices();
    let selectedDueOption = event.target.value;
    setSelectedDueOption(selectedDueOption);
    props.setDueSelect(event.target.value);
    props.updateInvoiceSearchDueToDate(moment(new Date()).format("MM-DD-YYYY"));
  };

  const toDate = props.invoiceSearchDueDateTo
    ? new Date(props.invoiceSearchDueDateTo)
    : "";
  let isPaymentDisable =
    sessionStorage.getItem("isPaymentDisable")?.toString() === "true"
      ? true
      : false;
  function handleYearChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setSelectedYear(parseInt(e.target.value));
  }
  return (
    <div className="container invoicePage">
      {isPaymentDisable && (
        <div
          className={
            "col-12 col-md d-flex justify-content-center  align-items-baseline"
          }
        >
          <span className={"invoice-currency-warning"}>
            {/* <FormattedMessage id={f("configuration.Payments-disable-info-message")} />*/}
            <label>{paymentDisableMessage}</label>
          </span>
        </div>
      )}
      <br />

      <div id="divFilterControls" className="row">
        <div className="col-lg-3 col-sm-4 form-group">
          <SecondarySelect accountType={props.accountType} />
        </div>
        {/*{hasMultiCurrency && <div className="col-lg-2  col-md-2 col-sm-4 w-50 customSelect">*/}
        {/*  <CurrencySelector selectedCurrency={props.selectedCurrency}/>*/}
        {/*  <i className="fas fa-chevron-down select-arrow"></i>*/}
        {/*</div>}*/}
        <div className=" col-lg-2 col-sm-4 mt-3 mt-md-0 form-group customSelect">
          <select
            className={"form-control invoicesHeadingInput"}
            onChange={handleDueChange}
            value={selectedDueOption}
          >
            <option value="all">{f("invoices_page.filter.show_all")}</option>
            <option value="due-up-to">
              {f("invoices_page.filter.due_till")}
            </option>
          </select>
          <i className="fas fa-chevron-down select-arrow"></i>
        </div>
        {selectedDueOption === "due-up-to" && (
          <div className="col-lg-2 col-sm-4 form-group">
            <div>
              <i className="calendarPosition fa fa-calendar-alt" />
              <Flatpickr
                className="inp form-control inv-calendar"
                value={toDate}
                options={{
                  formatDate: (date) => {
                    return intl.formatDate(date);
                  },
                  locale: calIl8n,
                }}
                onClose={handleSelectedDates}
                placeholder="Due Til"
              />
            </div>
          </div>
        )}
        {selectedDueOption === "all-paid" && (
          <div className={"col-12 col-md"}>
            <div className={"row align-items-center"}>
              <div className={"form-group"}>
                <FormattedMessage id={"invoice_search.period_label"} />
              </div>
              <div className="col form-group customSelect">
                <select
                  className={"form-control invoicesHeadingInput"}
                  onChange={handleYearChange}
                  value={selectedYear}
                >
                  {getPastYears(5).map((year) => {
                    return <option value={year}>{year}</option>;
                  })}
                </select>
                <i className="fas fa-chevron-down select-arrow"></i>
              </div>
            </div>
          </div>
              )}
              <div className={"col-12 col-md"}>
                  <div className={"row align-items-center"}>
                      <div className="col form-group customSelect">
                         
                          
                          <select
                              className={'form-control invoicesHeadingInput'}
                              onChange={(e) => handleMonthChange(e.target.value)}
                              value={selectedStatement?.STATEMENT_ID ?? ''} // Use empty string as default value
                          >
                              {Array.isArray(stateStatements) &&
                                  stateStatements.length > 0 &&
                                  stateStatements.map((item) => (
                                      <option key={item.STATEMENT_ID} value={item.STATEMENT_ID ?? ''}>
                                          {item.DESCRIPTION}
                                      </option>
                                  ))}
                          </select>




                          <i className="fas fa-chevron-down select-arrow"></i>
                      </div>

                      <div>
                          <img
                              src={selectedStatementId && selectedStatementId.length > 0 ? "/images/ic_pdf.png" : "/images/ic_pdf_disabled.png"}
                              alt="pdf"
                              key={`${'docId_'}${props.invoices}`}
                              onClick={() => handleButtonClick(selectedStatementId)}
                              role="presentation"
                              style={{ width: '20px', height: '20px', verticalAlign:'10px' }}
                          />
                          
                          {/*<img*/}
                          {/*                            src={"/images/ic_pdf_disabled.png"}*/}
                          {/*                            alt="pdf"*/}
                          {/*                            key={`${'docId_'}${row.document_number_finance}`}*/}
                          {/*                            onClick={this.handleClick}*/}
                          {/*                            role="presentation"*/}
                          {/*                        />);*/}
                      </div>
                  </div>
              </div>
        <div
          aria-disabled={isPaymentDisable}
          className={
            isPaymentDisable
              ? "is-disabled col-12 col-md my-3 my-md-0"
              : "col-12 col-md my-3 my-md-0"
          }
        >
          <div className="col-12 col-md my-3 my-md-0">
            {user?.user_role != "internal" && (
              <div
                className={
                  "row flex-fill d-flex justify-content-between justify-content-md-end align-items-center"
                }
              >
                <div>
                  <span className="invoicesHeading">
                    <FormattedMessage id={"invoices_page.continue"} />{" "}
                  </span>
                  <span
                    className={
                      props.invoiceTotal >= 0
                        ? "credit-positive"
                        : "credit-negative"
                    }
                  >
                    &nbsp;&nbsp;
                    <CurrencyDisplay
                      currency={props.selectedCurrency}
                      value={props.invoiceTotal}
                    />
                  </span>
                </div>
                <div className="ml-md-2 ml-0">
                  {canMakePayment && (
                    <Link
                      className="btn btn-primary buttonColor"
                      to="/invoices/pay"
                      onClick={onContinueClick}
                    >
                      <FormattedMessage id={"invoices_page.continue"} />{" "}
                      <i className="fas fa-angle-right" />
                    </Link>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
          {showSummary && <DashboardDetails />}
          <InvoiceList isSearch={false} PaymentDisable={isPaymentDisable} StatementId={selectedStatement.STATEMENT_ID} />
    </div>
  );
}

export default connect<InvoicePageProps, any, any, any>(
  (store: any, props) => ({
    accountType: store.user_options.accountType,
    invoiceSearchDueDateTo: store.user_options.invoiceSearchDueDateTo,
        // invoiceSearchQuery: [],
        invoiceSearchQuery: getInvoiceQuery(store),
    selectedCurrency: store.default.selectedCurrencyOptionValue,
    invoiceTotal: getInvoiceTotal(store),
    secondaryAccounts: store.user_options.secondaryAccounts,
    dueSelect: store.invoice.dueSelect,
    selectedInvoicesKeys: getSelectedInvoiceKeys(store),
        selectedSoldTos: store.user_options.selectedSoldTos,
        statements: store.statements.statements,
        statementId: store.invoice.statementId,
        invoices: store.default.invoices,

  }),
  {
    updateSelectedCard,
    clearSelectedInvoices,
    updateInvoiceSearchDueToDate,
      setDueSelect,
      updateInvoiceSearch,
      getStatementInvoicePDF,
      getInvoiceBulkPDF,
      setStatementId
  }
)(_Invoices);
